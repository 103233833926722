import WorkspaceIntegrationClients from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClients";

const WorkspaceIntegrationItem = ({
  size,
  index,
  length,
  is_global = false,
  workspaceId = "",
  integration = {},
  workspaceDetails = {},
  editCredential = () => {},
  openCreatedByModal = () => {},
  removeCredential = () => {},
  makeDefaultButtonDisabled = false,
  setMakeDefaultButtonDisabled = () => {},
  refreshIntegrations = () => {},
  workspaces = [],
  workspaceAdd = false,
  openPageListModal = () => {},
}) => {
  return (
    <>
      <div
        className="relative space-y-2"
        style={{ zIndex: length - index }}>
        <div className="relative z-[1] mb-1 flex">
          <div className="flex rounded-t-md border border-b-0 bg-white px-2 py-1">
            <div className="h-7 w-7 flex-shrink-0 overflow-hidden rounded">
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: integration.image }}
              />
            </div>
            <div className="w-full flex-shrink px-4">
              <div className="text-lg font-semibold text-gray-400">{integration.name}</div>
            </div>
          </div>
          <div className="w-full border-b"></div>
        </div>

        <WorkspaceIntegrationClients
          size={size}
          integration={integration}
          workspaceId={workspaceId}
          workspaceDetails={workspaceDetails}
          is_global={is_global}
          workspaceAdd={workspaceAdd}
          editCredential={editCredential}
          openCreatedByModal={openCreatedByModal}
          removeCredential={removeCredential}
          makeDefaultButtonDisabled={makeDefaultButtonDisabled}
          setMakeDefaultButtonDisabled={setMakeDefaultButtonDisabled}
          refreshIntegrations={refreshIntegrations}
          workspaces={workspaces}
          openPageListModal={openPageListModal}
        />
      </div>
    </>
  );
};

export default WorkspaceIntegrationItem;
