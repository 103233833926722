import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageWorkspaceSettings } from "src/actions/workspace";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { baseUrl, noLogo, publicImageStorage } from "src/config/host";

const WorkspaceSquareLogo = ({ ...props }) => {
  const [squareImage, setSquareImage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

  const onImageChange = (e, type) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        setSquareImage(e.target.files[0]);
      }
    }
  };

  const ImageHandler = ({ image = "", layout = "square" }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const areFilesEqual = (file1, file2) => {
      if (!(file1 instanceof File) || !(file2 instanceof File)) return false;

      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified;
    };

    let imageSource = useMemo(() => {
      if (image instanceof File && areFilesEqual(image, currentImage)) {
        return imageUrl;
      }

      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
        setImageUrl(objUrl);
        setCurrentImage(image);
      }

      return image instanceof File ? objUrl : image?.includes(publicImageStorage) ? image + "?" + Date.now() : image?.includes("data:image/") ? image : baseUrl + image;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, currentImage, imageUrl]);

    const imageComponent = useCallback(() => {
      return (
        <div className={`group relative cursor-pointer ${layout === "rectangle" ? "w-40" : "w-20"} h-20 rounded-md border border-gray-200/60 bg-gray-300/20 hover:border-highlightColor`}>
          <img
            src={imageSource}
            alt="Logo"
            className="h-full w-full rounded-lg object-contain"
            onError={(e) => (e.target.src = noLogo)}
          />
          <>
            <label
              htmlFor={`change_logo_square_logo`}
              className="absolute left-0 top-0 z-40 h-full w-full cursor-pointer">
              <div className="absolute bottom-0 left-0 flex h-10 w-full cursor-pointer items-center justify-center gap-x-2 rounded-b bg-gray-700/60 px-2 py-1 text-lg text-white transition-all duration-200 group-hover:bg-highlightColor">
                <PhotoIcon className="h-6 w-6 stroke-2" />
                Edit
              </div>
            </label>
            <input
              type="file"
              id={`change_logo_square_logo`}
              name={`change_logo_square_logo`}
              className="hidden"
              onChange={(e) => onImageChange(e)}
            />
          </>
          {imageSource && (
            <div
              className="absolute -right-3 -top-3 z-40 hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-center transition-all hover:bg-gray-300 group-hover:flex"
              onClick={() => {
                setSquareImage("");
              }}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </div>
          )}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource, layout, squareImage, props?.me]);

    return imageComponent();
  };

  const ImageHandlerMemoized = useMemo(
    () => (
      <ImageHandler
        image={squareImage}
        onImageChange={onImageChange}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [squareImage],
  );

  const handleSquareLogoUpdate = async () => {
    try {
      setButtonDisabled(true);
      const message = await props.manageWorkspaceSettings(
        {
          workspace_id: props?.workspaceDetails?._id,
          square_logo: squareImage,
        },
        "/workspaces/:workspace_id/layouts/square-logo",
        { "Content-Type": "multipart/form-data" },
      );
      setButtonDisabled(false);
      toast.success(message);
    } catch (error) {
      setButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setSquareImage(props?.workspaceDetails?.square_logo || noLogo);
  }, [props?.workspaceDetails?.square_logo]);

  return (
    <EditContainer
      title="Square Logo"
      defaultOptions={{
        onSuccessLoaderVisible: buttonDisabled,
      }}
      preview={{
        custom: (
          <div className="flex h-20 w-20 items-center justify-center rounded border">
            <img
              src={squareImage instanceof File ? URL.createObjectURL(squareImage) : squareImage}
              alt="Logo"
              className="h-full w-full rounded-lg object-contain"
              onError={(e) => (e.target.src = noLogo)}
            />
          </div>
        ),
        border: false,
      }}
      onSuccess={handleSquareLogoUpdate}
      onCancel={() => setSquareImage(props?.workspaceDetails?.square_logo || noLogo)}>
      <>
        <div className="flex items-end justify-between">
          <div className="flex-shrink">
            <div className="text-base font-semibold text-gray-700">Used for dropdowns and buttons.</div>
            <div className="mb-2 text-sm font-medium text-gray-400">Recomended image ratio is 1x1</div>
            <div className="flex gap-x-7">{ImageHandlerMemoized}</div>
            {!squareImage && (props?.me?.workspaces?.length > 1 || props?.me?.type === "admin") && (
              <InformationAlert
                content="Add a unique logo to tell workspaces apart"
                type="info"
              />
            )}
          </div>
        </div>
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceSquareLogo);
