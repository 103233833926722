import { PlusIcon } from "@heroicons/react/24/outline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DisplayPageForm from "src/components/PageForm/DisplayPageForm";
import Button from "src/components/Shared/Buttons/Button";
import NoData from "src/components/Shared/NoData/NoData";
import { H3 } from "src/components/Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";

const NestedPageContent = ({ newPage = {}, pages = [], onDragEnd = () => {}, onAddPage = () => {}, onUpdatePage = () => {}, onRemovePage = () => {} }) => {
  const nestedPages = Array.isArray(newPage.nested_pages) ? newPage.nested_pages : [];

  return (
    <div>
      <div className="flex items-end justify-between">
        <H3>Nested Pages</H3>
        <div className="mb-2">
          <Button
            version="gray"
            onClick={onAddPage}>
            <PlusIcon className="h-4 w-4 stroke-[3px]" />
            Add page
          </Button>
        </div>
      </div>
      <div className="border-gray-20 rounded border px-5 py-2">
        {/* Nested Pages */}
        <div className="-mx-4 mb-8 mt-3 max-h-80 space-y-8 overflow-y-scroll px-2">
          {newPage?.nested_pages?.length === 0 ? (
            <NoData />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className={classNames(newPage?.nested_pages?.length > 1 ? "space-y-4" : "")}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {nestedPages.map((page, i) => (
                      <Draggable
                        key={page?.id}
                        draggableId={page?.id}
                        index={i}>
                        {(provided) => (
                          <DisplayPageForm
                            className={"!left-0"}
                            permission={page}
                            setPermission={(e) => onUpdatePage(e, i)}
                            pageOptions={pages}
                            removePermission={() => onRemovePage(i)}
                            innerRef={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            showNestedData={false}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
};

export default NestedPageContent;
