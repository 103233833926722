import React from "react";
import { DocumentDuplicateIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import { v4 } from "uuid";

const FormContentAction = ({ section = null, content = null, setFormData = () => {} }) => {
  const duplicateContent = () => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [
                ...prevSection?.contents,
                {
                  ...content,
                  id: v4(),
                  options: [
                    ...content?.options?.map((prevOption) => ({
                      ...prevOption,
                      id: v4(),
                    })),
                  ],
                  rows: [
                    ...content?.rows?.map((prevRow) => ({
                      ...prevRow,
                      id: v4(),
                    })),
                  ],
                },
              ],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };
  const deleteContent = () => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [...prevSection?.contents?.filter((prevContent) => prevContent?.id !== content?.id)],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };
  const onChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [
                ...prevSection?.contents?.map((prevContent) => {
                  if (prevContent?.id === content?.id) {
                    return {
                      ...prevContent,
                      validation: {
                        ...prevContent?.validation,
                        required: e.target.checked,
                      },
                    };
                  } else {
                    return prevContent;
                  }
                }),
              ],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };
  return (
    <div className="col-span-3 border-t border-gray-200">
      <div className="mt-4 flex items-center">
        <div className="flex gap-2">
          <Button
            hoverText={"Duplicate"}
            version="default"
            onClick={duplicateContent}
            className="flex aspect-square !h-10 items-center justify-center rounded-md px-0 py-0 text-gray-400 transition-all duration-300 hover:bg-gray-100 hover:text-highlightColor">
            <DocumentDuplicateIcon className="h-6 w-6" />
          </Button>
          {section?.contents?.length > 1 && (
            <Button
              hoverText={"Delete"}
              version="default"
              onClick={deleteContent}
              className="flex aspect-square !h-10 items-center justify-center rounded-md px-0 py-0 text-gray-400 transition-all duration-300 hover:bg-gray-100 hover:text-highlightColor">
              <TrashIcon className="h-6 w-6" />
            </Button>
          )}

          {content?.type === "question" && (
            <div className="flex items-center border-l border-gray-200 pl-5">
              <Checkbox
                isChecked={content?.validation?.required}
                checkboxInputClass={"!h-6 !w-6 !aspect-square !flex-shrink-0"}
                checkboxBoxClass={"!h-6 !w-6 !aspect-square !flex-shrink-0"}
                checkboxIconClass={"!h-5 !w-5"}
                checkboxLabel={"Required"}
                checkboxLableClass={"!text-base"}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormContentAction;
