import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Button from "src/components/Shared/Buttons/Button";

const SsoLogout = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <div className="flex min-h-full w-full flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">You have been logged out</h2>
        <div className="text-regular mt-1 text-center text-gray-600">Get access to all your data in one perfectly-organized place by logging back in.</div>
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="px-4 py-4 sm:px-10">
          <Button
            width="w-full"
            onClick={() => navigate(`/`)}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(SsoLogout);
