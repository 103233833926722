import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { managePageData } from "src/actions/page";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Image from "src/components/Shared/Image";
import SlideOver from "src/components/Shared/SlideOver";
import { newPageJsonArr, pageCreatePayload, pageTypes } from "src/helpers/pageHelper";
import useFetch from "src/hooks/useFetch";
import PageEdit from "../PageComponents/PageEdit";
import DomoPage from "../PageTypes/DomoPage/DomoPage";
import FormPage from "../PageTypes/FormPage";
import HtmlPage from "../PageTypes/HtmlPage";
import LinkPage from "../PageTypes/LinkPage";
import MetabasePage from "../PageTypes/MetabasePage/MetabasePage";
import QuickSightPage from "../PageTypes/QuickSightPage/QuickSightPage";

const PageManageSlider = ({ isOpen, handleClose, newPage, setNewPage, prevPageDetails, ...props }) => {
  const navigate = useNavigate();

  const [startIndex, setStartIndex] = useState(-1);
  const [credentialAlertData, setCredentialAlertData] = useState(null);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [platformEmbeds, setPlatformEmbeds] = useState([]);
  const [formList, setFormList] = useState([]);

  const {
    response: { data: forms },
  } = useFetch("/forms", { method: "get", query: { is_global: true } });

  useEffect(() => {
    if (!newPage?.is_global) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: props?.pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value || null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage.workspace_id, newPage?.is_global, props?.pageCredentialOptions]);

  useEffect(() => {
    setStartIndex(props?.pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id));
  }, [props?.pageCredentialOptions, newPage]);

  useEffect(() => {
    const authorizedWorkspaces = props?.workspaces?.find((wrk) => wrk?._id?.toString() === newPage?.workspace_id?.toString())?.authorized_workspaces || [];
    setPlatformEmbeds(authorizedWorkspaces?.filter((item) => item?.provider_code === newPage.page_type?.toLowerCase()));
  }, [props?.workspaces, newPage.workspace_id, newPage.page_type]);

  useEffect(() => {
    if (newPage?.is_global || newPage?.workspace_id?.page?.is_global_template) {
      setFormList(forms);
    } else {
      setFormList(props?.workspaces?.find((wrk) => wrk?._id?.toString() === newPage?.workspace_id)?.forms || []);
    }
  }, [props?.workspaces, newPage?.workspace_id, newPage?.is_global, newPage?.workspace_id?.page?.is_global_template, forms]);

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    props?.setSelectedWorkspace(updatedSelectedWorkspaces);
  };

  const nestedAllPages = props?.allPages
    .filter((page) => page._id !== newPage._id && (!Array.isArray(page.workspace_id) ? page.workspace_id?._id === newPage.workspace_id : page.workspace_id.map((item) => item._id).some((item) => props?.selectedWorkspace.includes(item))))
    .map((page) => ({ _id: page._id, name: page.name }));

  const reorderNestedPages = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setNewPage((page) => ({
      ...page,
      nested_pages: result,
    }));
  };

  const redirectToCredential = () => {
    if (newPage?.is_global) {
      navigate(`/site-settings?tab=Auth+Credentials&opencredentialmodal=true&credentialtype=${credentialAlertData?.key?.toLowerCase()}`);
    } else if (newPage?.workspace_id) {
      navigate(`/workspaces/${newPage?.workspace_id}?tab=Settings&opencredentialmodal=true&credentialtype=${credentialAlertData?.key?.toLowerCase()}`);
    } else if (!newPage?.is_global && !newPage?.workspace_id) {
      toast.error("Please select the workspace for redirection");
    }
  };

  const redirectToAddForm = () => {
    const workspaceIds = Array.isArray(props?.selectedWorkspace) ? props?.selectedWorkspace : [props?.selectedWorkspace];
    if (newPage?.is_global) {
      navigate(`/forms/add?workspace_id=${encodeURIComponent(JSON.stringify(workspaceIds))}&is_global=true&pathname=/forms?tab=Global+forms`);
    } else if (newPage?.workspace_id) {
      navigate(`/forms/add?workspace_id=${newPage?.workspace_id}&is_global=false&pathname=/${window.location.pathname.includes("/workspaces") ? "workspaces" : ""}?tab=All+forms`);
    } else if (!newPage?.is_global && !newPage?.workspace_id) {
      toast.error("Please select the workspace for redirection");
    }
  };

  const pageTypeSetFun = useMemo(() => {
    let types = [];

    if (newPage._id) {
      types.push({ key: newPage.page_type, value: newPage.page_type, Component: () => pageTypes[newPage.page_type] });
    } else {
      const pageOptions = [{ key: "DOMO", allowed: true }, { key: "METABASE" }, { key: "QUICKSIGHT" }, { key: "FORM", allowed: true }, { key: "CUSTOM_HTML", allowed: true }, { key: "EXTERNAL_LINK", allowed: true }];

      pageOptions.forEach(({ key, allowed }) => {
        if (allowed || props?.editPageCredentialOptions?.some((cred) => cred?.code === key.toLowerCase())) {
          types.push({ key, value: key, Component: () => pageTypes[key], notClickable: allowed && !props?.editPageCredentialOptions?.some((cred) => cred?.code === key.toLowerCase()) });
        }
      });
    }

    return types;
  }, [newPage.page_type, newPage._id, props?.editPageCredentialOptions]);

  useEffect(() => {
    if (newPage?.page_type && props?.editPageCredentialOptions?.find((cred) => cred?.code === newPage?.page_type.toLowerCase())) {
      setCredentialAlertData(null);
    } else {
      setCredentialAlertData(newPage?.page_type);
    }
  }, [newPage?.page_type, props?.editPageCredentialOptions]);

  const createNewPage = async () => {
    try {
      props?.setSuccessLoaderStart(true);
      const page = pageCreatePayload(newPage, nestedAllPages, props?.selectedWorkspace, props?.workspaceDetails, props?.user);
      const { message, data } = await props.managePageData({
        pages: [page],
        workspaceId: props?.workspaceId,
        pageId: newPage._id || null,
      });
      props?.menuPageList();
      if (!isOpen) {
        setAdvancedFeatures(false);
        setNewPage(newPageJsonArr(props?.workspaceId, false));
      } else {
        if (data?.workspace_id.length > 0 || data?.is_global) {
          let workspace_id = [];
          for (const workspaceid of data?.workspace_id) {
            workspace_id.push(workspaceid._id);
          }
          props?.setSelectedWorkspace(workspace_id);
          setNewPage({ ...data, workspace_id: "", is_global: true });
        } else {
          props?.setSelectedWorkspace([]);
          setNewPage({ ...data, workspace_id: data.workspace_id?._id, is_global: false });
        }
        props?.setPrevPageDetails({ data, body: { user_id: props?.user?._id, page_id: data?._id, workspace_id: props?.workspaceId } });
      }
      setTimeout(() => {
        props?.setSuccessLoaderStart(false);
        toast.success(message);
      }, 200);
    } catch (error) {
      setTimeout(() => {
        props?.setSuccessLoaderStart(false);
        toast.error(error.message);
      }, 300);
    }
  };

  const workspaceOptions = props?.workspaces
    .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
    .map((wrk) => {
      return {
        key: wrk.name,
        value: wrk._id,
        Component: () => (
          <div className="flex items-center gap-x-3">
            <div
              style={!wrk?.square_logo && wrk.image_logo ? { background: wrk.top_bar_color } : {}}
              className="h-5 w-5 overflow-hidden rounded bg-white/10">
              <Image
                image={wrk.square_logo || wrk.image_favicon || wrk.image_logo}
                alt={"Workspace"}
                isDate={false}
              />
            </div>
            <p>{wrk.name}</p>
          </div>
        ),
      };
    });

  const pageForm = () => {
    return (
      <div className="grid gap-y-4">
        {!props?.workspaceId && (
          <div className="space-y-8 whitespace-nowrap rounded-md border p-4 text-sm text-gray-500 shadow">
            <div>
              {!newPage?._id && (
                <div className="my-3 ml-1">
                  <Checkbox
                    checkboxLabel="Make this page globaly accessible so it can be assigned to multiple workspaces"
                    isChecked={newPage?.is_global || newPage?.workspace_id?.page?.is_global_template}
                    onChange={() => {
                      setNewPage((pre) => ({ ...pre, workspace_id: null, is_global: !(newPage?.is_global || newPage?.workspace_id?.page?.is_global_template) }));
                      props?.setSelectedWorkspace([]);
                    }}
                  />
                </div>
              )}
              {newPage?.is_global || newPage?.workspace_id?.page?.is_global_template ? (
                <MultiSelectObject
                  inline={true}
                  defaultOptions={
                    props?.workspacesLoaded &&
                    props?.workspaces
                      .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                      .map((wrk) => {
                        return {
                          key: wrk._id,
                          value: wrk.name,
                          selected: props?.selectedWorkspace.includes(wrk._id),
                          object: wrk,
                          image: wrk.square_logo || wrk.image_favicon || wrk.image_logo,
                        };
                      })
                  }
                  searchableFields={["name"]}
                  searchComponent={(wrk) => (
                    <div className="flex items-center gap-x-3">
                      <div
                        style={!wrk?.square_logo && wrk.image_logo ? { background: wrk.top_bar_color } : {}}
                        className="h-5 w-5 overflow-hidden rounded bg-white/10">
                        <Image
                          image={wrk.square_logo || wrk.image_favicon || wrk.image_logo}
                          alt={"Workspace"}
                          isDate={false}
                        />
                      </div>
                      <p>{wrk.name}</p>
                    </div>
                  )}
                  title="Assign to mutiple workspaces"
                  onChange={handleSelectedWorkspaces}
                />
              ) : (
                <>
                  <div className="grid gap-y-5">
                    <SelectMenu
                      label="Assign to a workspace"
                      inline={true}
                      startIndex={props?.workspaces?.filter((item) => item.workspace_type !== "JWT_FULL_EMBED")?.findIndex((item) => item?._id === newPage?.workspace_id)}
                      options={workspaceOptions}
                      setOption={(option) => {
                        setNewPage((pre) => ({ ...pre, workspace_id: option.value, credential_id: null, credential_name: null, form_id: null }));
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className="whitespace-nowrap rounded-md border p-4 text-sm text-gray-500 shadow">
          <div className="mb-8">
            <div className="mb-5 space-y-2">
              <div className="flex w-full flex-col gap-x-3 gap-y-3">
                <div className="flex-none sm:w-1/3">
                  <SelectMenu
                    disabled={pageTypeSetFun.length === 1 || pageTypeSetFun.length === 0}
                    isSelected={pageTypeSetFun.length === 1}
                    label="Page type"
                    inline={true}
                    dropdownClass={"!min-w-0 w-full"}
                    startIndex={pageTypeSetFun?.findIndex((page) => page?.key === newPage?.page_type)}
                    options={pageTypeSetFun}
                    setOption={(e) => {
                      setNewPage((pre) => ({ ...pre, page_type: e.value, credential_id: null, dashboard_id: null, embed_id: "", embed_type: "EMBED_PAGE", bordered: false, titled: false, theme: "", platform_embed: null, form_id: null }));
                    }}
                  />
                </div>
                <div className="flex-grow">
                  <Input
                    type="text"
                    name="page-name"
                    label="Name"
                    inline={true}
                    value={newPage.name}
                    onChange={(e) => setNewPage((pre) => ({ ...pre, name: e.target.value }))}
                  />
                </div>
              </div>
            </div>
            <hr className="mb-6" />
            <div className="relative">
              {newPage.page_type === "FORM" && (
                <FormPage
                  newPage={newPage}
                  setNewPage={setNewPage}
                  forms={formList}
                />
              )}

              {newPage.page_type === "CUSTOM_HTML" && (
                <HtmlPage
                  newPage={newPage}
                  setNewPage={setNewPage}
                />
              )}

              {newPage.page_type === "EXTERNAL_LINK" && (
                <LinkPage
                  newPage={newPage}
                  setNewPage={setNewPage}
                />
              )}

              {["DOMO", "QUICKSIGHT", "METABASE"].includes(newPage.page_type) && (
                <>
                  <>
                    {newPage.page_type === "DOMO" && (
                      <DomoPage
                        pageTypes={pageTypes}
                        newPage={newPage}
                        setNewPage={setNewPage}
                        startIndex={startIndex}
                        pageCredentialOptions={props?.pageCredentialOptions}
                        advancedFeatures={advancedFeatures}
                        setAdvancedFeatures={setAdvancedFeatures}
                        nestedAllPages={nestedAllPages}
                        reorderNestedPages={reorderNestedPages}
                        workspaceDetails={props?.workspaceDetails}
                        user={props?.user}
                        workspaceId={props?.workspaceId}
                        platformEmbeds={platformEmbeds}
                        setStartIndex={setStartIndex}
                      />
                    )}

                    {newPage.page_type === "QUICKSIGHT" && (
                      <QuickSightPage
                        newPage={newPage}
                        setNewPage={setNewPage}
                        startIndex={startIndex}
                        setStartIndex={setStartIndex}
                        pageCredentialOptions={props?.pageCredentialOptions}
                        platformEmbeds={platformEmbeds}
                      />
                    )}

                    {newPage.page_type === "METABASE" && (
                      <MetabasePage
                        newPage={newPage}
                        setNewPage={setNewPage}
                        startIndex={startIndex}
                        setStartIndex={setStartIndex}
                        pageCredentialOptions={props?.pageCredentialOptions}
                        platformEmbeds={platformEmbeds}
                      />
                    )}
                  </>
                  {credentialAlertData && !platformEmbeds.length && (
                    <div className="relative">
                      <InformationAlert
                        content={
                          <div className="flex flex-wrap space-y-1">
                            <p className="font-extrabold">No credentials for embedding {credentialAlertData} content found.</p>
                            <p className="pr-1">If you'd like to create a {credentialAlertData} embedding credential please click</p>
                            <Button
                              version="default"
                              className="!inline-flex !h-auto text-highlightColor underline"
                              onClick={redirectToCredential}>
                              here
                            </Button>
                          </div>
                        }
                        type="warning"
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            {newPage.page_type === "FORM" && formList?.length === 0 && (
              <div className="relative">
                <InformationAlert
                  content={
                    newPage?.workspace_id ? (
                      <p className="flex flex-wrap space-x-1">
                        <span>There is no Form , to create a Form please click</span>
                        <Button
                          version="default"
                          className="!inline-flex !h-auto text-highlightColor underline"
                          onClick={redirectToAddForm}>
                          here
                        </Button>
                      </p>
                    ) : (
                      <span>Please select a workspace first.</span>
                    )
                  }
                  type="warning"
                />
              </div>
            )}
          </div>
          {isOpen && (
            <div className="flex justify-end gap-4">
              <Button
                version="secondary"
                disabled={props?.successLoaderStart}
                onClick={() => {
                  handleClose();
                }}>
                Cancel
              </Button>
              <Button
                loading={props?.successLoaderStart}
                disabled={props?.successLoaderStart}
                onClick={createNewPage}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <SlideOver
      isOpen={isOpen}
      handleClose={handleClose}
      title={newPage?._id ? "Edit page" : "Add Page"}
      description="View, update, and modify page details and information below."
      maxWidth="xl:max-w-[1300px] lg:max-w-[1100px]">
      <>
        <PageEdit
          pageForm={pageForm}
          isSliderOpen={isOpen}
          pageDetails={prevPageDetails}
        />
      </>
    </SlideOver>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {
  managePageData,
})(PageManageSlider);
