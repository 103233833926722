import { GET_SITE_SETTINGS_DATA, GET_AUTO_SITE_SETTINGS_DATA } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const handleUpdateDomoData = async (type = "pull") => {
  try {
    let url = `/update-domo-dataset/pull`;
    if (type === "push") {
      url = `/update-domo-dataset/push`;
    }
    const { data: resData } = await apiRequest("POST", url, {});

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageAutoSiteSettings = (body) => (dispatch) => {
  dispatch({ type: GET_AUTO_SITE_SETTINGS_DATA, payload: body });
};

export const manageSiteSettings =
  (body = {}, endpoint = "/settings", headers = "") =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest(
        "PUT",
        endpoint,
        {
          body: body,
        },
        headers
          ? {
              headers: headers,
            }
          : undefined,
      );

      if (data.status === 200) {
        if (data?.isCredentialInvalidOrMissing) {
          return data;
        }
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        localStorage.setItem("siteSettings", JSON.stringify(data.data));
        return data.message || data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getSiteSettingsCache = () => (dispatch) => {
  const checkData = localStorage.getItem("siteSettings");

  if (checkData) {
    const cacheData = JSON.parse(localStorage.getItem("siteSettings"));
    dispatch({ type: GET_SITE_SETTINGS_DATA, payload: cacheData });
  }
};

export const getOpenSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/settings/details`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/settings`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        localStorage.setItem("siteSettings", JSON.stringify(data.data));
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const pushDataPotal = async (body = null, signal, headers, url) => {
  try {
    const { data } = await apiRequest("PUT", url, { body }, { signal, headers });

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const createDataset = async (body = null, signal, headers, url) => {
  try {
    const { data } = await apiRequest("POST", url, { body }, { signal, headers });

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (error) {
    return error.message;
  }
};

export const getDatasetQuery = async (body, signal, headers, url) => {
  try {
    const { data } = await apiRequest("POST", url, { body }, { signal, headers });

    if (!data?.datasource) {
      throw new Error(`Error: ${JSON.stringify(data)}`);
    } else {
      // dispatch({ type: GET_DATASET_QUERY, payload: JSON.stringify(data) });
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteMailgunSmtp = () => async (dispatch) => {
  try {
    const { data } = await apiRequest("DELETE", `/settings/smtp/mailgun`);

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageStripeCredential = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest("PUT", `/settings/billing/stripe/credential`, {
      body,
    });

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
