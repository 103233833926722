import React, { useEffect } from "react";
import PageList from "src/components/Pages/PageComponents/PageList";
import { useNavigate, useLocation } from "react-router-dom";
import { H1 } from "src/components/Shared/Text/Headers";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";

const Pages = ({ ...props }) => {
  const router = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getAuthorizedUser();
  }, []);

  const existsPath = props.menus.some((obj) => obj.path === router.pathname);

  if (Object.keys(props.menus).length && !existsPath) {
    navigate("/");
  }

  if (!props?.user?._id) return <></>;
  if (props?.user?.type === "editor" && props?.user?.workspaces?.length === 1) {
    window.location.href = `/workspaces/${props.user.workspaces[0]._id}?tab=Pages`;
    return <></>;
  }

  return (
    <>
      {Object.keys(props.menus).length > 0 && existsPath && (
        <>
          <H1>Pages</H1>
          <PageList pageName="Pages" />
        </>
      )}
    </>
  );
};

// export default pages;

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menus: Object.values(state.menus),
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(Pages);
