import { ArrowPathIcon, Bars3BottomLeftIcon, ExclamationTriangleIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import Image from "../../Shared/Image";
import Tabs from "../../Shared/Tabs/Tabs";
import PagePreview from "../PageModals/PagePreview";

const PageEdit = ({ pageForm, pageDetails, isSliderOpen, ...props }) => {
  const [loadPreview, setLoadPreview] = useState(false);

  const tabs = [
    {
      name: "Details",
      icon: WindowIcon,
      component: (
        <div className="grid grid-cols-2 gap-x-4 gap-y-4">
          <div>{pageForm()}</div>
          <div className="relative flex h-[calc(100vh-15rem)] flex-col overflow-hidden rounded-md border shadow">
            <div
              style={{ background: props?.site?.top_bar_color, color: props?.site?.top_bar_text_color }}
              className="text flex h-14 flex-shrink-0 items-center justify-between px-2 py-1 text-lg">
              <div className="flex h-8 items-center gap-x-3 overflow-hidden">
                <Bars3BottomLeftIcon className="h-7 w-7 stroke-[2px]" />
                <Image
                  image={props?.site?.image_logo}
                  alt="page_workspace_logo"
                  className="object-left"
                />
              </div>
              <div className="flex items-center gap-2 pr-2">
                <div className="h-2 w-14 rounded-full bg-headerTextColor" />
                <div className="h-4 w-4 rounded-full bg-headerTextColor" />
              </div>
            </div>
            {loadPreview ? (
              <div
                style={{ zoom: 0.5 }}
                className="h-full flex-shrink flex-grow !rounded-b-xl">
                <PagePreview
                  isOpen={isSliderOpen}
                  pageDetails={pageDetails}
                />
              </div>
            ) : (
              <div className="flex h-full w-full items-center justify-center ">
                {pageDetails ? (
                  <div
                    onClick={() => setLoadPreview(true)}
                    className="relative flex cursor-pointer items-center justify-center gap-x-3 rounded px-3 py-2">
                    <div className="absolute h-full w-full rounded-md opacity-5 transition-all hover:bg-highlightColor" />
                    <ArrowPathIcon className="h-5 w-5 text-highlightColor" />
                    <p className="text-highlightColor">Load preview</p>
                  </div>
                ) : (
                  <div className="relative flex  items-center justify-center gap-x-2 rounded px-3 py-2">
                    <div className="absolute h-full w-full rounded-md bg-highlightColor opacity-5 transition-all" />
                    <ExclamationTriangleIcon className="h-5 w-5 text-highlightColor" />
                    <p className="text-highlightColor">No content to preview</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ),
      visible: true,
    },
  ];

  return (
    <>
      <Tabs
        queryParamName="userTabs"
        tabs={tabs}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};
export default connect(mapStateToProps, {})(PageEdit);
