import { ArrowPathIcon, ArrowTopRightOnSquareIcon, FunnelIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";
import PillEntry from "../Workspaces/PillEntry";
import IpAddressChecker from "./IpAddressChecker";
import WorkspaceIntegrationFiltersItem from "../Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import NoDataIconWithButton from "../Shared/NoData/NoDataIconWithButton";

const ActivitySettings = ({ activityTypes = [], locations = [], users = [], workspaces = [], groups = [], pages = [], ips = [], site, manageSiteSettings }) => {
  const [activityWarningEnable, setActivityWarningEnable] = useState(site?.activity_warning_enable || false);
  const [activityWarningView, setActivityWarningView] = useState(site?.activity_warning_view || "");
  const [activityWarningMessage, setActivityWarningMessage] = useState(site?.activity_warning_message || "");
  const [activityLockoutEnable, setActivityLockoutEnable] = useState(site?.activity_lockout_enable || false);
  const [activityLockoutView, setActivityLockoutView] = useState(site?.activity_lockout_view || "");
  const [activityLockoutMinute, setActivityLockoutMinute] = useState(site?.activity_lockout_minute || "");
  const [activityLockoutMessage, setActivityLockoutMessage] = useState(site?.activity_lockout_message || "");
  const [disabled, setDisabled] = useState(false);
  const [isSyncingWithDomo, setIsSyncingWithDomo] = useState(false);

  const datasetColumnNames = [
    {
      _id: "created_by",
      name: "Name",
    },
    {
      _id: "type",
      name: "Activity Type",
    },
    {
      _id: "data.workspace_id",
      name: "Workspace",
    },
    {
      _id: "data.group_id",
      name: "Group",
    },
    {
      _id: "data.page_id",
      name: "Page",
    },
    {
      _id: "data.login_portal",
      name: "Location",
    },
    {
      _id: "data.ip_address",
      name: "IP Address",
    },
  ];

  const [datasetId, setDatasetId] = useState("");
  const [credentialId, setCredentialId] = useState(null);
  const [syncEnabled, setSyncEnabled] = useState(false);
  const [datasetFilters, setDatasetFilters] = useState([]);
  const [datasetDisabled, setDatasetDisabled] = useState(false);

  const [ipTag, setIpTag] = useState("");
  const [allowIpAddresses, setAllowIpAddresses] = useState([]);
  const [blockHttpDomain, setBlockHttpDomain] = useState(false);
  const [ipDisabled, setIpDisabled] = useState(false);

  const {
    response: { data: credentials },
  } = useFetch(`/credentials/all`, { query: { code: "domo" } });

  const {
    response: { data: operators },
  } = useFetch("/operators/list");

  const setPageData = () => {
    if (site?._id) {
      setActivityWarningEnable(site.activity_warning_enable || false);
      setActivityWarningView(site.activity_warning_view || "");
      setActivityWarningMessage(site.activity_warning_message || "");
      setActivityLockoutEnable(site.activity_lockout_enable || false);
      setActivityLockoutView(site.activity_lockout_view || "");
      setActivityLockoutMinute(site.activity_lockout_minute || "");
      setActivityLockoutMessage(site.activity_lockout_message || "");
    }
  };

  const setDatasetData = () => {
    setDatasetId(site?.activity_import_dataset_id || "");
    setCredentialId(site?.activity_import_credential_id || null);
    setSyncEnabled(site?.activity_import_sync_enabled || null);
    setDatasetFilters(site?.activity_dataset_filters || []);
  };

  const setIpData = () => {
    setAllowIpAddresses(site?.allow_ip_addresses || []);
    setBlockHttpDomain(site?.block_http_domain || false);
    setIpTag("");
  };

  const viewDataset = async () => {
    setDatasetDisabled(true);
    try {
      const { data } = await apiRequest("POST", `/activities/view-dataset`, { body: { from: "activity" } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      window.open(data?.data?.url, "_blank");
    } catch (error) {}
    setDatasetDisabled(false);
  };

  const onSubmit = async () => {
    try {
      setDisabled(true);
      const message = await manageSiteSettings(
        {
          activity_warning_enable: activityWarningEnable,
          activity_warning_view: activityWarningView,
          activity_warning_message: activityWarningMessage,
          activity_lockout_enable: activityLockoutEnable,
          activity_lockout_view: activityLockoutView,
          activity_lockout_minute: activityLockoutMinute,
          activity_lockout_message: activityLockoutMessage,
        },
        "settings/activities/anti-automation-abuse-prevention",
      );
      setDisabled(false);
      toast.success(message);
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  const handleOnAddDatasetFilter = async () => {
    setDatasetFilters((prevData) => [
      ...prevData,
      {
        operator_id: null,
        column_name: null,
        value_type: "value",
        column_value: null,
        trusted_attribute: null,
      },
    ]);
  };

  const trustedAttributeArr = (column_name) => {
    let values = [];
    if (column_name === "created_by") values = users;
    else if (column_name === "type") values = activityTypes?.map((item) => ({ _id: item?.key, name: item?.value }));
    else if (column_name === "data.workspace_id") values = workspaces;
    else if (column_name === "data.group_id") values = groups;
    else if (column_name === "data.page_id") values = pages;
    else if (column_name === "data.login_portal") values = locations?.map((item) => ({ _id: item?.key, name: item?.value }));
    else if (column_name === "data.ip_address") values = ips?.map((item) => ({ _id: item?._id, name: item?._id }));
    return values;
  };

  const handleOnDatasetColumnNamesFilter = (index) => {
    const otherColumnNames = datasetFilters?.filter((_, i) => i !== index)?.map((item) => item?.column_name);
    return datasetColumnNames?.filter((item) => !otherColumnNames?.includes(item?._id));
  };

  const handleOnManualDatasetSync = async () => {
    setIsSyncingWithDomo(true);
    try {
      await apiRequest("POST", `/activities/push-dataset/domo`, {}, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
    } catch (error) {}
    setIsSyncingWithDomo(false);
  };

  const handleOnDatasetSubmit = async () => {
    setDatasetDisabled(true);
    try {
      const message = await manageSiteSettings({ activity_import_dataset_id: datasetId, activity_import_credential_id: credentialId, activity_import_sync_enabled: syncEnabled, activity_dataset_filters: datasetFilters }, "settings/activities/dataset");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
    setDatasetDisabled(false);
  };

  const handleIpTag = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && ipTag.trim()) {
      if (e.submit !== true) {
        e.preventDefault();
      }
      if (allowIpAddresses.findIndex((tg) => tg.toLowerCase().trim() === ipTag.toLowerCase().trim()) === -1) {
        const addresses = [...allowIpAddresses, ipTag.trim()];
        setAllowIpAddresses(addresses);
        setIpTag("");
      }
    }
  };

  const removeIpTag = async (index) => {
    allowIpAddresses.splice(index, 1);
    setAllowIpAddresses([...allowIpAddresses]);
  };

  const handleOnIpSubmit = async () => {
    try {
      setIpDisabled(true);
      const message = await manageSiteSettings({ allow_ip_addresses: allowIpAddresses, block_http_domain: blockHttpDomain }, "settings/activities/config-ip-address");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIpDisabled(false);
    }
  };

  useEffect(() => {
    setPageData();
    setDatasetData();
    setIpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const filterButton = datasetColumnNames?.length > datasetFilters?.length && (
    <Button
      version="default"
      onClick={handleOnAddDatasetFilter}
      className="!h-10 !px-4 text-highlightColor">
      <div className="flex items-center gap-x-3 transition-all hover:font-semibold">
        <PlusIcon className="h-5 w-5 stroke-2" /> Add a filter
      </div>
    </Button>
  );

  return (
    <>
      <EditContainer
        title="Anti-Automation / Abuse Prevention (BETA)"
        subtitle="Manage your all activity settings from here"
        preview={{ text: "Anti-Automation / Abuse Prevention" }}
        isLoading={disabled}
        onSuccess={onSubmit}
        onCancel={setPageData}
        defaultOptions={{
          onCancelButtonVisible: true,
          onSuccessButtonVisible: true,
        }}
        fullWidth={false}>
        <>
          <div className="relative w-full space-y-20">
            <div className="relative w-full">
              <div className="mb-4 flex gap-x-5 font-medium text-gray-500">
                <ToggleHeader
                  title="Initial warning"
                  subtitle='Warn users of "malicious bot-like" behavior'>
                  <Toggle
                    checked={activityWarningEnable}
                    onChange={setActivityWarningEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex w-full flex-col gap-x-5 gap-y-3 sm:px-8">
                <div className="w-[150px]">
                  <Input
                    disabled={!activityWarningEnable}
                    name="views"
                    label="Max views (per min.)"
                    inline={true}
                    value={activityWarningView}
                    onChange={(e) => setActivityWarningView(e.target.value)}
                  />
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    disabled={!activityWarningEnable}
                    name="message"
                    label="Warning message"
                    inline={true}
                    value={activityWarningMessage}
                    onChange={(e) => setActivityWarningMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div className="mb-4 flex gap-x-5 font-medium text-gray-500">
                <ToggleHeader
                  title="Temporary lockout"
                  subtitle="Lock out abusive users.">
                  <Toggle
                    checked={activityLockoutEnable}
                    onChange={setActivityLockoutEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex w-full flex-col gap-x-5 gap-y-3 sm:px-8">
                <div className="flex gap-x-4">
                  <div className="w-[150px]">
                    <Input
                      disabled={!activityLockoutEnable}
                      name="views"
                      label="Max views (per min.)"
                      inline={true}
                      value={activityLockoutView}
                      onChange={(e) => setActivityLockoutView(e.target.value)}
                    />
                  </div>
                  <div className="w-[150px]">
                    <Input
                      disabled={!activityLockoutEnable}
                      name="locked_min"
                      label="Lockout clock (min.)"
                      inline={true}
                      value={activityLockoutMinute}
                      onChange={(e) => setActivityLockoutMinute(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    disabled={!activityLockoutEnable}
                    name="message"
                    label="Lockout message"
                    inline={true}
                    value={activityLockoutMessage}
                    onChange={(e) => setActivityLockoutMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </EditContainer>
      <EditContainer
        title="Push activity log to Domo Dataset"
        subtitle="Push your all activity log to Domo Dataset from here"
        preview={{ text: "Push activity log to Domo Dataset" }}
        isLoading={datasetDisabled}
        isDisabled={isSyncingWithDomo}
        onSuccess={handleOnDatasetSubmit}
        onCancel={setDatasetData}
        fullWidth={false}>
        <div className="flex w-full max-w-2xl flex-col gap-4">
          <div className="relative w-full max-w-md">
            <SelectMenu
              inline={true}
              label="DOMO credentials"
              emptyListText={site?.activity_import_credential_name || "No listed items"}
              defaultText={site?.activity_import_credential_name || "Select an option"}
              startIndex={credentials?.findIndex((credential) => credential?._id?.toString() === credentialId?.toString())}
              options={credentials?.map((credential) => ({ key: credential?.name, value: credential?._id, object: credential })) || [{ key: "Select credentials", value: -1 }]}
              selectedOptionRendering="key"
              searchableFields={["name"]}
              setOption={(option) => {
                setCredentialId(option.value);
              }}
            />
          </div>
          <div>
            <Button
              version="default"
              className={classNames("text-grey-500 group flex !h-8 items-center gap-1 text-sm font-light")}
              disabled={datasetDisabled || !datasetId}
              onClick={viewDataset}>
              <p className={classNames(datasetDisabled || !datasetId ? "" : "group-hover:text-highlightColor group-hover:underline")}>View dataset</p>
              <ArrowTopRightOnSquareIcon className={classNames(datasetDisabled || !datasetId ? "" : "group-hover:stroke-highlightColor", "h-4 w-4 stroke-1")} />
            </Button>
            <div className="flex gap-x-4">
              <div className="w-full max-w-md">
                <Input
                  name="dataset_id"
                  label="Dataset ID"
                  inline={true}
                  value={datasetId}
                  onChange={(e) => setDatasetId(e.target.value)}
                />
              </div>
              <Button
                disabled={datasetDisabled || !datasetId || isSyncingWithDomo}
                version="gray"
                onClick={handleOnManualDatasetSync}>
                <p className="pb-1">Sync</p>
                <ArrowPathIcon
                  className={classNames("h-5 w-5", !isSyncingWithDomo ? "" : "animate-spin")}
                  role="status"
                />
              </Button>
            </div>
          </div>
          <div className="relative">
            <ToggleHeader
              title="Schedule data push"
              subtitle="This scheduler is set update your dataset every night at 2am."
              position="left">
              <Toggle
                checked={syncEnabled}
                onChange={(e) => {
                  setSyncEnabled(!syncEnabled);
                }}
              />
            </ToggleHeader>
          </div>
          <div className="relative">
            <p className="mb-2">Manage the dataset's filter.</p>
            <div className="mt-2 space-y-3 rounded bg-white pb-3">
              {datasetFilters.length > 0 ? (
                <>
                  {datasetFilters.map((filter, index) => {
                    return (
                      <WorkspaceIntegrationFiltersItem
                        key={index}
                        from={"activity"}
                        isAddWhere={false}
                        filters={datasetFilters}
                        state={filter}
                        operators={operators?.filter((operator) => ["equals", "not_equals", "in", "not_in"]?.includes(operator?.code))}
                        trustedAttributeArr={trustedAttributeArr(filter?.column_name)}
                        setState={(e) => {
                          setDatasetFilters((prevData) => [
                            ...prevData?.map((item, i) => {
                              if (index === i) {
                                return e;
                              } else {
                                return item;
                              }
                            }),
                          ]);
                        }}
                        removeFilter={() => {
                          setDatasetFilters((prevData) => prevData.filter((_, i) => i !== index));
                        }}
                        datasetColumnNames={handleOnDatasetColumnNamesFilter(index)}
                      />
                    );
                  })}
                  <div className="flex">{filterButton}</div>
                </>
              ) : (
                <NoDataIconWithButton
                  icon={FunnelIcon}
                  title="No Filters"
                  subTitle="Apply filters to all data powering cards, dashboards, apps, and reports in this workspace."
                  buttonComponent={filterButton}
                />
              )}
            </div>
          </div>
        </div>
      </EditContainer>
      <EditContainer
        title="Config IP address"
        subtitle="Manage allowed IP address from here"
        preview={{ text: "Config IP address" }}
        isLoading={ipDisabled}
        onSuccess={handleOnIpSubmit}
        onCancel={setIpData}
        fullWidth={false}>
        <div className="w-[400px] max-w-[520px]">
          <div className="flex flex-col gap-4">
            <div className="relative">
              <div className="mb-4 space-y-4">
                <div className="w-96">
                  <div className="flex w-full justify-end">
                    <p className="mb-.5 text-xs text-gray-300">Hit return to add IP to collection.</p>
                  </div>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="tag-name"
                    label="Ip"
                    inline={true}
                    value={ipTag}
                    onChange={(e) => setIpTag(e.target.value)}
                    onKeyDown={handleIpTag}
                  />
                </div>
                {allowIpAddresses?.length > 0 && (
                  <div className="flex flex-wrap items-center gap-2">
                    {allowIpAddresses?.map((ip, index) => {
                      return (
                        <PillEntry
                          index={index}
                          tag={ip}
                          onDelete={removeIpTag}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="relative">
              <ToggleHeader
                title="Prevent API access from HTTP"
                subtitle={`HTTP (or "localhost") access is great for testing APIs, but this can open you up to malicious pings.`}
                position="left">
                <Toggle
                  checked={blockHttpDomain}
                  onChange={(e) => {
                    setBlockHttpDomain(!blockHttpDomain);
                  }}
                />
              </ToggleHeader>
            </div>
          </div>
        </div>
      </EditContainer>
      <EditContainer
        title="IP Location Finder"
        subtitle="Find IP address from here"
        defaultOpen={true}
        preview={{ text: "IP Location Finder" }}
        fullWidth={false}
        defaultOptions={{ onCancelButtonVisible: false, onSuccessButtonVisible: false }}>
        <IpAddressChecker />
      </EditContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ActivitySettings);
