import React from "react";
// import Button from "src/components/Shared/Buttons/Button";
import TableRow from "src/components/Shared/Table/TableRow";
import { cardtypes } from "src/helpers/billingHelper";

const PaymentMethodEntry = ({ data }) => {
  return (
    <TableRow keyId={data?._id}>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5 sm:pl-6">
        <div className="font-medium text-gray-900">{data?.paymentMethod || "Card"}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="flex items-center gap-3">
          <div
            className="w-10"
            dangerouslySetInnerHTML={{ __html: data?.brand ? cardtypes[data?.brand] : "" }}></div>
          <div className="font-medium text-gray-900">{`XXXX-XXXX-XXXX-${data?.last4}`}</div>
        </div>
      </td>
      {/* <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="flex justify-end">
          <Button version="secondary">Change payment method</Button>
        </div>
      </td> */}
    </TableRow>
  );
};

export default PaymentMethodEntry;
