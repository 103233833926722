import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";

const FormPage = ({ forms = [], newPage = {}, setNewPage = () => {} }) => {
  return (
    <SelectMenu
      label="Forms"
      inline={true}
      startIndex={forms?.findIndex((item) => item?._id?.toString() === newPage?.form_id)}
      options={forms?.map((item) => ({ key: item?.name, value: item?._id, object: item }))}
      searchableFields={["name"]}
      dropdownClass={"!min-w-0 w-full"}
      setOption={(option) => {
        setNewPage((prevData) => ({
          ...prevData,
          form_id: option.value,
        }));
      }}
    />
  );
};

export default FormPage;
