import { classNames } from "src/helpers/classNames";

const H1 = ({ className = "", children, item = { defaultCss: "", onHoverCss: "", onClick: null } }) => {
  return (
    <h1 className={`flex items-center gap-x-2 text-2xl font-extrabold text-gray-700 md:mb-6 md:text-3xl xl:text-4xl ${className}`}>
      {item.icon ? (
        <item.icon
          onClick={item.onClick}
          className={classNames("h-10 w-10", item.defaultCss, item.onHoverCss)}
        />
      ) : null}
      {children}
    </h1>
  );
};

const H2 = ({ className = "", margin = true, children }) => {
  return (
    <div className={classNames("-ml-4 flex", margin ? "md:mb-4" : "")}>
      <h2 className={`px-4 py-1 text-xl font-bold text-gray-900 sm:text-2xl xl:text-3xl ${className}`}>{children}</h2>
    </div>
  );
};

const H3 = ({ className = "", caption = "", children, wrapperClass }) => {
  return (
    <div className={classNames("mb-4 flex w-full flex-col sm:my-2 sm:px-0", wrapperClass)}>
      <h3 className={`pb-.5 text-xl font-medium text-gray-900 ${className}`}>{children}</h3>
      {caption && <p className="text-md text-gray-400">{caption}</p>}
    </div>
  );
};

const H4 = ({ className = "", caption = "", margin = true, children }) => {
  return (
    <div className={classNames("-mt-[2px] text-sm text-gray-600", margin ? "ml-3 " : "")}>
      <h4 className={className}>{children}</h4>
      <p className="p-0 text-gray-400/80">{caption}</p>
    </div>
  );
};

const H5 = ({ className = "", caption = "", children, margin = true }) => {
  return (
    <div className={classNames("-mt-[2px] text-sm text-gray-600", margin ? "ml-3 " : "")}>
      <h5 className={className}>{children}</h5>
      <p className="p-0 text-gray-400/80">{caption}</p>
    </div>
  );
};

export { H1, H2, H3, H4, H5 };
