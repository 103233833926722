import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getAuthorizedUser } from "src/actions/auth";
import { getPortalWorkspacesFirstPage } from "src/actions/impersonate";
import { resetWorkspaceDetails } from "src/actions/page";
import { clearUserDetails, manageUserStatus } from "src/actions/user";
import BubbleIconList from "src/components/Shared/BubbleIconList";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import QuickMenu from "src/components/Shared/QuickMenu";
import TableRow from "src/components/Shared/Table/TableRow";
import TRBody from "src/components/Shared/Table/TRBody";
import UserProfile from "src/components/Users/UserProfile";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import { previousDateInString } from "src/helpers/dateHelper";
import { formatDate } from "src/helpers/formatDate";

const UserEntry = ({
  user,
  clearUserDetails,
  manageUserStatus,
  getPages,
  setDeleteId,
  lastRow,
  me,
  site,
  getAuthorizedUser,
  allowMulti = false,
  disableCheckButton = false,
  checkedUsers = [],
  setCheckedUsers = () => {},
  refreshUsers = () => {},
  manageUserRole = () => {},
  workspaceDetails = {},
  removeUserAccess = () => {},
  onClick = () => {},
  ...props
}) => {
  const navigate = useNavigate();
  const URLParams = useParams();
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];

  const [activeStatus, setActiveStatus] = useState(true);

  useEffect(() => {
    setActiveStatus(user.active_status);
  }, [user.active_status]);

  const insertTypes = {
    SSO_LOGIN: "SSO",
    MANUAL_INPUT: "Manually",
    BULK_UPLOAD: "Bulk",
    DOMO_DATASET: "Dataset",
  };

  const setPreviewUserId = async (id) => {
    try {
      const { link } = await getPortalWorkspacesFirstPage({ user_id: id });
      if (link) {
        localStorage.setItem("impersonatedUserId", id);
        window.location.href = link;
      }
    } catch (err) {
      if (err.message !== "CanceledError: canceled") {
        toast.error("Currently user doesn't have any page assigned.");
      }
    }
  };

  const navigateUserMenu = (menuOption) => {
    // onClick();
    navigate(`?user=${user._id}&userTabs=${menuOption}`);
  };

  const handleStatus = async () => {
    try {
      const message = await manageUserStatus(user);
      toast.success(message);
      refreshUsers();
      setActiveStatus(!activeStatus);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const isWorkspaceRoute = window.location.href.includes("workspaces") && URLParams?.id;

  const quickMenuItems = [
    {
      name: "User profile",
      hideWhen: (user?.default_user && !me?.default_user) || (user.type === "admin" && me?.type !== "admin") || !authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]),
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("User+profile");
      },
    },
    {
      name: "Assigned workspaces",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Assigned+workspaces");
      },
    },
    {
      name: "Editor privileges",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Editor+privileges");
      },
    },
    {
      name: "Data restrictions",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Data+restrictions");
      },
    },
    {
      name: "Activity log",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        clearUserDetails(null);
        navigateUserMenu("Activity+log");
      },
    },
    {
      name: `Impersonate`,
      hideWhen: (user?.default_user && !me?.default_user) || user.type === "admin" || me?.type !== "admin" || (!workspaceDetails?._id && user?.workspaces?.length <= 0),
      isBeta: true,
      onClick: () => {
        setPreviewUserId(user._id);
      },
    },
    {
      hideWhen: user.default_user,
      type: "hr",
    },
    {
      name: "Remove from workspace",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin" || !isWorkspaceRoute || !authorizeUserComponentAccess(me, workspaceDetails?._id, "permission", ["update"]),
      isBeta: false,
      onClick: () => removeUserAccess(user._id),
    },
    {
      name: activeStatus ? "Deactivate user" : "Activate user",
      hideWhen: (user?.default_user && !me?.default_user) || me?.type !== "admin",
      onClick: () => {
        handleStatus();
      },
    },
    {
      name: "Delete",
      hideWhen:
        (user?.default_user && !me?.default_user) ||
        user.default_user ||
        (user?.type === "admin" && workspaceDetails?._id) ||
        site.api_state === "DOMO_DATASET" ||
        !authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) ||
        (me?.type !== "admin" && user?.type === "admin") ||
        me?._id === user?._id ||
        site?.api_state === "DOMO_DATASET",
      onClick: () => {
        setDeleteId(user._id);
      },
      color: "text-red-500/50 hover:text-red-500",
    },
  ];

  const hasNoHiddenItems = quickMenuItems.filter((item) => !item.hideWhen && item.type !== "hr");

  const handleOnClick = () => {
    if (authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) && hasNoHiddenItems.length > 0) {
      navigate(user?.type === "admin" ? `?user=${user._id}` : `?user=${user._id}`);
    }
  };

  return (
    <TableRow
      keyId={user?._id}
      lastRow={lastRow}
      disabled={!user?.active_status}
      classes={hasNoHiddenItems.length > 0 ? "" : "!opacity-50"}
      isHoverable={true}>
      {allowMulti && me?.type === "admin" && (
        <TRBody className=" whitespace-nowrap !py-3 !pl-1 text-sm sm:!pl-4">
          <Checkbox
            isChecked={checkedUsers.includes(user._id)}
            onChange={() => {
              if (disableCheckButton) return;
              setCheckedUsers((cur) => {
                if (cur.includes(user._id)) {
                  return cur.filter((item) => item !== user._id);
                } else {
                  return [...cur, user._id];
                }
              });
            }}
          />
        </TRBody>
      )}
      <TRBody
        className={`whitespace-nowrap !py-3 !pl-4 !pr-3 text-sm ${hasNoHiddenItems.length > 0 ? "" : "!cursor-not-allowed"}`}
        onClick={handleOnClick}>
        <div className={classNames("flex items-center", !activeStatus ? "opacity-40" : "")}>
          <div className={classNames("relative hidden h-10 w-10 flex-shrink-0 rounded-full sm:flex")}>
            {
              <UserProfile
                showStatus
                user={user || {}}
              />
            }
          </div>
          <div className="sm:ml-4">
            <div className="flex items-center gap-x-2 font-medium text-gray-900">
              {authorizeUserComponentAccess(me, workspaceDetails?._id, "user", ["update"]) && hasNoHiddenItems.length > 0 ? (
                <Link
                  to={user?.type === "admin" ? `?user=${user._id}` : `?user=${user._id}`}
                  className="font-medium text-gray-900 hover:underline">
                  {user?.name}
                </Link>
              ) : (
                user?.name
              )}

              {user?.type === "admin" && <span className="rounded-full bg-gray-100 px-2 py-[1px] text-gray-400">{user?.default_user && <span>Super</span>} Admin</span>}
            </div>

            {/* <div className="text-gray-500">
              {insertTypes[user.creation_type]} created {formatDate(user.created_at, "MMM DD, YYYY")}
            </div> */}
            <div className="text-gray-500">{user?.email}</div>
          </div>
        </div>
      </TRBody>
      {!workspaceDetails?._id && (
        <TRBody className={`hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500  sm:table-cell ${hasNoHiddenItems.length > 0 ? "" : "!cursor-not-allowed"} ${!activeStatus ? "opacity-60" : ""}`}>
          <Link
            className="hover:underline"
            to={user?.type === "admin" ? `?user=${user._id}&userTabs=Assigned+workspaces` : `?user=${user._id}&userTabs=Assigned+workspaces`}>
            <BubbleIconList
              data={user?.workspaces?.map((item) => ({ name: item?.name, image: item?.image_logo || item?.square_logo || item?.image_favicon, id: item?._id }))}
              type="workspace"
              className="!justify-start"
              rounded={false}
            />
          </Link>
        </TRBody>
      )}
      <TRBody className="hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500 lg:table-cell">
        <div className={classNames("flex w-12 gap-x-[2px] font-medium transition-all duration-100  md:gap-x-1", user?.groups?.length > 0 ? "text-gray-900" : "text-gray-300", !activeStatus ? "opacity-60" : "")}>
          <SquaresPlusIcon className="h-5 w-5" />
          {user?.groups?.length}
        </div>
      </TRBody>
      <TRBody className="hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500 lg:table-cell">
        <div
          data-tooltip-id={`${user._id}-last_login`}
          className={classNames("inline-block w-auto cursor-help rounded-full", !activeStatus ? "opacity-40" : "")}>
          {user?.last_activity_time ? previousDateInString(user?.last_activity_time) : "Hasn't signed in"}
        </div>
        {user?.last_activity_time && (
          <ReactTooltip
            id={`${user._id}-last_login`}
            delayShow={200}
            positionStrategy="fixed"
            className="rounded bg-gray-700 px-2 py-2 opacity-100">
            <div className="font-normal leading-[10px]">
              {formatDate(user?.last_activity_time, "MMM DD, YYYY")} <span className="text-gray-400">{formatDate(user?.last_activity_time, "h:mm:ssA")}</span>
            </div>
          </ReactTooltip>
        )}
        <div className="flex items-center gap-2 text-gray-300">
          {insertTypes[user.creation_type]} created {formatDate(user.created_at, "MMM DD, YYYY")}
        </div>
      </TRBody>
      {workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
        <TRBody className="hidden whitespace-nowrap !px-3 !py-3 text-sm text-gray-500 lg:table-cell">
          <SelectMenu
            options={roles}
            setOption={(e) => manageUserRole(e, user)}
            startIndex={roles.findIndex((rl) => rl.value === user.type)}
          />
        </TRBody>
      )}
      <TRBody className="relative flex justify-end whitespace-nowrap !py-3 !pl-3 !pr-4 text-sm font-medium sm:!pr-6">
        <QuickMenu
          items={quickMenuItems}
          disabled={!hasNoHiddenItems.length}
        />
      </TRBody>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus, getAuthorizedUser, resetWorkspaceDetails })(UserEntry);
