import SeoTitle from "src/components/Shared/Text/SeoTitle";

const BlankLayout = ({ children, site }) => {
  return (
    <>
      <SeoTitle title={site.name} />
      <div className="flex h-full w-full">{children}</div>
    </>
  );
};

export default BlankLayout;
