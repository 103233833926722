import EditContainer from "src/components/Shared/Containers/EditContainer";
import React, { useState } from "react";
import { H4 } from "src/components/Shared/Text/Headers";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { useEffect } from "react";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import LeftNavigation from "src/assets/svgs/left_navigation.svg";
import TopNavigation from "src/assets/svgs/top_navigation.svg";
import { toast } from "react-toastify";
import { manageWorkspaceSettings } from "src/actions/workspace";
import { connect } from "react-redux";
import { manageSiteSettings } from "src/actions/site";

const WorkspaceLayouts = ({ workspaceId = null, ...props }) => {
  const [settings, setSettings] = useState(null);
  const [layoutType, setLayoutType] = useState("");
  const [disabledIframeExpansion, setDisabledIframeExpansion] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const options = [
    {
      value: "LEFT_NAVIGATION",
      key: (
        <div className="flex h-7 items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor"
            src={LeftNavigation}
            alt="left navigation"
          />
          <p className="text-xl">Vertical</p>
        </div>
      ),
    },
    {
      value: "TOP_NAVIGATION",
      key: (
        <div className="flex h-7 items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor hover:text-highlightColor"
            src={TopNavigation}
            alt="top navigation"
          />
          <p className="text-xl">Horizontal</p>
        </div>
      ),
    },
  ];

  const handleLayoutChange = async () => {
    try {
      setButtonDisabled(true);
      const body = { layout_type: layoutType, disabled_iframe_expansion: disabledIframeExpansion ? true : disabledIframeExpansion };
      const message = workspaceId ? await props.manageWorkspaceSettings({ ...body, workspace_id: workspaceId }, "/workspaces/:workspace_id/layouts/type") : await props.manageSiteSettings(body, "/settings/layouts/type");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    setLayoutType(settings?.layout_type);
    setDisabledIframeExpansion(settings?.disabled_iframe_expansion || false);
  }, [settings?.layout_type, settings?.disabled_iframe_expansion]);

  useEffect(() => {
    setSettings(workspaceId ? props?.workspaceDetails : props?.site);
  }, [workspaceId, props?.workspaceDetails, props?.site]);

  return (
    <EditContainer
      title="Layout"
      subtitle={!workspaceId ? "*Applies to user portal only" : ""}
      preview={{ text: options[options.findIndex((option) => option.value === layoutType)]?.key, border: false }}
      onSuccess={handleLayoutChange}
      defaultOptions={{
        onSuccessLoaderVisible: buttonDisabled,
      }}
      onCancel={() => {
        setLayoutType(settings?.layout_type);
        setDisabledIframeExpansion(settings?.disabled_iframe_expansion || false);
      }}>
      <>
        <H4
          caption={workspaceId ? "*Options for this workspace's end user experience." : ""}
          margin={false}>
          Select orientation
        </H4>
        <div className="mb-4 mt-1 w-56">
          <SelectMenu
            size="md"
            options={options}
            startIndex={options.findIndex((option) => option.value === layoutType)}
            setOption={(option) => {
              setLayoutType(option.value);
              setDisabledIframeExpansion(option.value === "TOP_NAVIGATION" ? true : disabledIframeExpansion);
            }}
          />
        </div>
        {layoutType === "LEFT_NAVIGATION" && (
          <div className="min-w-[260px]">
            <ToggleHeader
              title="Allow vertical iframe expansion"
              subtitle="Embedded iframe will expand to the size of the internal content to allow for scrolling in the margins."
              position="left">
              <Toggle
                checked={!disabledIframeExpansion}
                onChange={() => {
                  setDisabledIframeExpansion(!disabledIframeExpansion);
                }}
              />
            </ToggleHeader>
          </div>
        )}
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings })(WorkspaceLayouts);
