import { EnvelopeIcon, GlobeAltIcon, CpuChipIcon, PaintBrushIcon } from "@heroicons/react/24/outline";
import SiteThemeAndStyles from "src/components/Site/ThemesAndStyles/SiteThemeAndStyles";
import GlobalCredentials from "src/components/Site/AuthCredentials/GlobalCredentials";
import DomainSettings from "src/components/Site/DomainsAndDNS/DomainSettings";
import SiteEmailManager from "./EmailProvider/SiteEmailManager";
import { H1 } from "src/components/Shared/Text/Headers";
import { getSiteSettings } from "src/actions/site";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { connect } from "react-redux";
import { useEffect } from "react";

const SiteSection = ({ ...props }) => {
  const tabs = [
    { name: "Themes & Styles", icon: PaintBrushIcon, component: <SiteThemeAndStyles /> },
    { name: "BI Integrations", icon: CpuChipIcon, component: <GlobalCredentials /> },
    { name: "Email Provider", icon: EnvelopeIcon, component: <SiteEmailManager /> },
    { name: "Domains & DNS", icon: GlobeAltIcon, component: <DomainSettings /> },
  ];

  const loadSiteSettings = async () => await props.getSiteSettings();

  useEffect(() => {
    const ac = new AbortController();
    loadSiteSettings({}, ac);
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <H1>Site Settings</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getSiteSettings })(SiteSection);
