import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { ArrowPathIcon, Bars3Icon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";

const features = [
  {
    name: "Automated Social Insights",
    description: "Get real-time, AI-powered insights on your brand’s social media performance. Identify trends, track engagement, and optimize your strategy.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Brand Reputation Monitoring",
    description: "Monitor brand sentiment and audience perception across all platforms. Stay ahead of potential crises with proactive alerts.",
    icon: LockClosedIcon,
  },
  {
    name: "Content Performance Analysis",
    description: "Measure the impact of your content with detailed analytics. Understand what resonates with your audience and refine your strategy.",
    icon: ArrowPathIcon,
  },
  {
    name: "Competitor Benchmarking",
    description: "Compare your performance against competitors and industry leaders. Gain insights to enhance your social media strategy.",
    icon: FingerPrintIcon,
  },
];

const tiers = [
  {
    name: "Starter",
    id: "tier-starter",
    href: "#",
    priceMonthly: "$19",
    description: "Perfect for small businesses looking to track basic social media performance.",
    features: ["Track up to 3 social accounts", "Basic engagement insights", "Monthly performance reports", "Email support"],
    mostPopular: false,
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    priceMonthly: "$49",
    description: "Ideal for growing brands looking to optimize their social presence.",
    features: ["Track up to 10 social accounts", "Advanced engagement insights", "Competitor benchmarking", "Weekly performance reports", "24-hour support"],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "#",
    priceMonthly: "$99",
    description: "For agencies and large teams needing in-depth analytics and automation.",
    features: ["Unlimited social accounts", "AI-driven content recommendations", "Influencer & brand mention tracking", "Daily performance reports", "1-hour priority support"],
    mostPopular: false,
  },
];

const faqs = [
  {
    id: 1,
    question: "How can I track my brand's social media performance?",
    answer: "Our platform provides real-time analytics and engagement insights for all your social media accounts, helping you make data-driven decisions.",
  },
  {
    id: 2,
    question: "Does this platform support multiple social media accounts?",
    answer: "Yes! Depending on your plan, you can track multiple social media profiles, compare engagement, and analyze cross-platform performance.",
  },
  {
    id: 3,
    question: "Can I generate reports for my clients?",
    answer: "Absolutely! Our Growth and Enterprise plans offer automated, customizable reports that you can share with your team or clients.",
  },
];

const footerNavigation = [
  {
    title: "solutions",
    subtitles: [
      { name: "Social Media Analytics", href: "#" },
      { name: "Competitor Analysis", href: "#" },
      { name: "Brand Monitoring", href: "#" },
      { name: "Influencer Tracking", href: "#" },
      { name: "Performance Reporting", href: "#" },
    ],
  },
  {
    title: "support",
    subtitles: [
      { name: "Submit a Ticket", href: "#" },
      { name: "Knowledge Base", href: "#" },
      { name: "Getting Started Guide", href: "#" },
    ],
  },
  {
    title: "company",
    subtitles: [
      { name: "About Us", href: "#" },
      { name: "Case Studies", href: "#" },
      { name: "Careers", href: "#" },
      { name: "Press", href: "#" },
    ],
  },
  {
    title: "legal",
    subtitles: [
      { name: "Terms of Service", href: "#" },
      { name: "Privacy Policy", href: "#" },
      { name: "Data Protection", href: "#" },
    ],
  },
];

const defaultTexts = {
  companyName: "InsightHub",
  loginText: "Log in",
  heroTitle: "Unlock Data-Driven Social Media Growth",
  heroDescription: "Empower your brand with AI-powered insights, competitor benchmarking, and content performance analytics to maximize engagement.",
  getStartedText: "Get Started",
  learnMoreText: "Learn More",
  caseStudyText: "See How We Helped Brands Grow",
  caseStudyDescription: "Our data-driven insights helped brands increase engagement by 45% and save hours on social media strategy planning.",
  featureSectionTitle: "Stay Ahead of the Competition",
  featureSectionSubtitle: "Powerful analytics for smarter marketing decisions",
  featureSectionDescription: "Our platform equips you with the tools to track trends, measure impact, and refine your strategy—all in one place.",
  pricingSectionTitle: "Plans that Scale with Your Business",
  pricingSectionSubtitle: "Find the right fit for your brand",
  pricingSectionDescription: "Whether you're a startup or a large enterprise, our flexible pricing ensures you get the insights you need.",
  faqSectionTitle: "Got Questions? We’ve Got Answers",
  ctaTitle: "Transform Your Social Media Strategy Today",
  ctaDescription: "Join thousands of businesses using our platform to gain actionable insights and boost engagement.",
  testimonialSectionTitle: "What Our Clients Are Saying",
  testimonials: [
    {
      name: "Sarah Thompson",
      position: "Marketing Director, BrightCo",
      quote: "With InsightHub, we finally have a clear view of our social media performance. The competitor analysis helped us refine our strategy and improve engagement by 60%!",
      image: "https://tailwindcss.com/plus-assets/img/logos/workcation-logo-white.svg",
    },
  ],
};

export { defaultTexts, features, tiers, faqs, footerNavigation };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MarketingBasic({ settings }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="relative h-screen w-full overflow-scroll bg-white">
      {/* Header */}
      <div className="absolute inset-x-0 top-0 z-50">
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a
              href="/"
              className="-m-1.5 p-1.5">
              <span className="sr-only">{settings.site_name}</span>
              <img
                alt=""
                src={settings?.image_logo}
                className="h-12 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="size-6"
              />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="/"
              className="text-sm/6 font-semibold text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a
                href="/"
                className="-m-1.5 p-1.5">
                <span className="sr-only">{settings.site_name}</span>
                <img
                  alt=""
                  src="https://tailwindcss.com/plus-assets/img/logos/mark.svg?color=indigo&shade=600"
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700">
                <span className="sr-only">Close menu</span>
                <XMarkIcon
                  aria-hidden="true"
                  className="size-6"
                />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="py-6">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>

      <div className="">
        {/* Hero section */}
        <div className="relative pt-14">
          <div
            aria-hidden="true"
            className="inset-x-0 -z-10 blur-3xl">
            <div
              style={{
                clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="aspect-1155/678 bg-linear-to-tr relative left-[calc(50%-11rem)] w-[36.125rem] -translate-x-1/2 rotate-[30deg] from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>
          <div className="py-24 sm:py-32 lg:pb-40">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">{defaultTexts.heroTitle}</h1>
                <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">{defaultTexts.heroDescription}</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="/"
                    className="shadow-xs rounded-md bg-highlightColor px-3.5 py-2.5 text-sm font-semibold text-white hover:font-bold focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlightColor">
                    Get started
                  </a>
                  <a
                    href="/"
                    className="text-sm/6 font-semibold text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <img
                    alt="App screenshot"
                    src="https://tailwindcss.com/plus-assets/img/component-images/project-app-screenshot.png"
                    width={2432}
                    height={1442}
                    className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <div
              style={{
                clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="aspect-1155/678 bg-linear-to-tr relative left-[calc(50%+3rem)] w-[36.125rem] -translate-x-1/2 from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>
        {/* Logo cloud */}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              alt="Transistor"
              src="https://tailwindcss.com/plus-assets/img/logos/158x48/transistor-logo-gray-900.svg"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            />
            <img
              alt="Reform"
              src="https://tailwindcss.com/plus-assets/img/logos/158x48/reform-logo-gray-900.svg"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            />
            <img
              alt="Tuple"
              src="https://tailwindcss.com/plus-assets/img/logos/158x48/tuple-logo-gray-900.svg"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            />
            <img
              alt="SavvyCal"
              src="https://tailwindcss.com/plus-assets/img/logos/158x48/savvycal-logo-gray-900.svg"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            />
            <img
              alt="Statamic"
              src="https://tailwindcss.com/plus-assets/img/logos/158x48/statamic-logo-gray-900.svg"
              width={158}
              height={48}
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            />
          </div>
          <div className="mt-16 flex justify-center">
            <p className="relative rounded-full px-4 py-1.5 text-sm/6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
              <span className="hidden md:inline">{defaultTexts.caseStudyDescription}</span>
              <a
                href="/"
                className="font-semibold text-highlightColor">
                <span
                  aria-hidden="true"
                  className="absolute inset-0"
                />{" "}
                Read our case study <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>
        {/* Feature section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base/7 font-semibold text-highlightColor">{defaultTexts.featureSectionTitle}</h2>
            <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">{defaultTexts.featureSectionSubtitle}</p>
            <p className="mt-6 text-pretty text-lg/8 text-gray-600">{defaultTexts.featureSectionDescription}</p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="relative pl-16">
                  <dt className="text-base/7 font-semibold text-gray-900">
                    <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-highlightColor">
                      <feature.icon
                        aria-hidden="true"
                        className="size-6 text-white"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        {/* Testimonial section */}
        <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
          <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
              className="absolute inset-0 size-full object-cover brightness-150 saturate-0"
            />
            <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
            <div
              aria-hidden="true"
              className="absolute -left-80 -top-56 transform-gpu blur-3xl">
              <div
                style={{
                  clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
                className="aspect-1097/845 bg-linear-to-r w-[68.5625rem] from-[#ff4694] to-[#776fff] opacity-[0.45]"
              />
            </div>
            <div
              aria-hidden="true"
              className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl">
              <div
                style={{
                  clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
                className="aspect-1097/845 bg-linear-to-r w-[68.5625rem] from-[#ff4694] to-[#776fff] opacity-25"
              />
            </div>
            <div className="relative mx-auto max-w-2xl lg:mx-0">
              <img
                alt=""
                src="https://tailwindcss.com/plus-assets/img/logos/workcation-logo-white.svg"
                className="h-12 w-auto"
              />
              <figure>
                <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl/8">
                  <p>“Amet amet eget scelerisque tellus sit neque faucibus non eleifend. Integer eu praesent at a. Ornare arcu gravida natoque erat et cursus tortor consequat at. Vulputate gravida sociis enim nullam ultricies habitant malesuada lorem ac.”</p>
                </blockquote>
                <figcaption className="mt-6 text-base text-white">
                  <div className="font-semibold">Judith Black</div>
                  <div className="mt-1">CEO of Tuple</div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        {/* Pricing section */}
        <div className="py-24 sm:pt-48">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base/7 font-semibold text-highlightColor">{defaultTexts.pricingSectionTitle}</h2>
              <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">{defaultTexts.pricingSectionSubtitle}</p>
              <p className="mt-6 text-pretty text-lg/8 text-gray-600">{defaultTexts.pricingSectionDescription}</p>
            </div>
            <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier, tierIdx) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                    tierIdx === 0 ? "lg:rounded-r-none" : "",
                    tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                    "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10",
                  )}>
                  <div>
                    <div className="flex items-center justify-between gap-x-4">
                      <h3
                        id={tier.id}
                        className={classNames(tier.mostPopular ? "text-highlightColor" : "text-gray-900", "text-lg/8 font-semibold")}>
                        {tier.name}
                      </h3>
                      {tier.mostPopular ? <p className="bg-highlightColor/10 rounded-full px-2.5 py-1 text-xs/5 font-semibold text-highlightColor">Most popular</p> : null}
                    </div>
                    <p className="mt-4 text-sm/6 text-gray-600">{tier.description}</p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-4xl font-semibold tracking-tight text-gray-900">{tier.priceMonthly}</span>
                      <span className="text-sm/6 font-semibold text-gray-600">/month</span>
                    </p>
                    <ul className="mt-8 space-y-3 text-sm/6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li
                          key={feature}
                          className="flex gap-x-3">
                          <CheckIcon
                            aria-hidden="true"
                            className="h-6 w-5 flex-none text-highlightColor"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular ? "shadow-xs bg-highlightColor text-white hover:font-bold" : "text-highlightColor ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "mt-8 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlightColor",
                    )}>
                    Buy plan
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* FAQs */}
        <div className="mx-auto max-w-2xl px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
          <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Frequently asked questions</h2>
          <dl className="mt-20 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="py-8 first:pt-0 last:pb-0 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt className="text-base/7 font-semibold text-gray-900 lg:col-span-5">{faq.question}</dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base/7 text-gray-600">{faq.answer}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        {/* CTA section */}
        <div className="relative mt-32 px-6 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end">
            <div
              style={{
                clipPath: "polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)",
              }}
              className="aspect-1108/632 bg-linear-to-r w-[69.25rem] flex-none from-[#ff80b5] to-[#9089fc] opacity-25"
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{defaultTexts.ctaTitle}</h2>
            <p className="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-600">{defaultTexts.ctaDescription}</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/"
                className="shadow-xs rounded-md bg-highlightColor px-3.5 py-2.5 text-sm font-semibold text-white hover:font-bold focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlightColor">
                Get started
              </a>
              <a
                href="/"
                className="text-sm/6 font-semibold text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block">
            <div
              style={{
                clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="aspect-1155/678 bg-linear-to-tr w-[72.1875rem] from-[#ff80b5] to-[#9089fc] opacity-30"
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="relative mx-auto mt-32 max-w-7xl px-6 lg:px-8">
        <div className="border-t border-gray-900/10 py-16 sm:py-24 lg:py-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <img
              alt={settings.site_name}
              src={settings?.image_logo}
              className="h-10"
            />
            <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4 xl:col-span-2 xl:mt-0">
              {footerNavigation.map((section) => (
                <div key={section.title}>
                  <h3 className="text-sm font-semibold text-gray-900">{section.title.charAt(0).toUpperCase() + section.title.slice(1)}</h3>
                  <ul className="mt-6 space-y-4">
                    {section.subtitles.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm text-gray-600 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
