import { connect } from "react-redux";
import StylesPreview from "src/components/Shared/Styles/StylesPreview";

const StylesBuilder = ({
  data = {},
  layoutType,
  handleBrowserTabText = null,
  onSubmit = () => {},
  title = "",
  secondaryTitle = "",
  headerContent = null,
  setWorkspace = () => {},
  propsButtonOption = null,
  setSquareLogoButtonIsDisabled = () => {},
  updatedSite = {},
  setUpdatedSite = () => {},
  handleStylesDataChange = () => {},
  handleSquareLogoStylesDataChange = () => {},
  ...props
}) => {
  return (
    <>
      <div className="grid max-w-7xl space-y-8">
        <StylesPreview
          layoutType={layoutType}
          handleBrowserTabText={handleBrowserTabText}
          menu={["Home", "About Us", "Feedback"]}
          styles={updatedSite}
          user={props.user}
          setWorkspace={setWorkspace}
          onChange={handleStylesDataChange}
          workspaceDetails={data}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesBuilder);
