import React from "react";
import { classNames } from "src/helpers/classNames";

const NoDataIconWithButton = ({ title, subTitle, icon: Icon, buttonComponent }) => {
  return (
    <div>
      <div className="flex gap-2">
        <div className="rounded-full bg-slate-100 p-3">
          <Icon className={classNames("h-6 w-6 text-slate-400")} />
        </div>
        <div>
          <div className="font-semibold">{title}</div>
          <div className="text-sm">{subTitle}</div>
        </div>
      </div>
      {buttonComponent}
    </div>
  );
};

export default NoDataIconWithButton;
