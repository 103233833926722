import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { classNames } from "src/helpers/classNames";

const HeaderAction = ({ xPosition = "left", data = [] }) => {
  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-transparent text-sm focus:outline-none">
            <span className="sr-only">Open menu</span>
            <div className="flex h-10 items-center justify-center rounded-full transition-colors duration-100">
              <EllipsisVerticalIcon className="h-6 w-6" />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className={"absolute z-50 mt-2 w-56 origin-top-left divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " + (xPosition === "right" ? "right-0" : "left-0")}>
            <div className="py-1">
              {data.map((item, i) =>
                item.type === "hr" ? (
                  <div
                    key={"hr-" + i}
                    className="my-1 h-[1px] w-full bg-gray-100"></div>
                ) : (
                  <Menu.Item key={item.name}>
                    <button
                      type="button"
                      className={classNames("group flex h-10 w-full items-center justify-start bg-white px-4 text-left text-base font-medium text-gray-600 transition-all duration-200 hover:bg-highlightColor hover:text-white", item?.buttonClasses)}
                      onClick={item.onClick}>
                      {item.name}
                      {item.hasIcon && (
                        <div className={classNames("ml-2 flex h-6 w-6 items-center justify-center rounded bg-black/10 p-1 text-gray-500 transition-all duration-200 group-hover:bg-black/25 group-hover:text-white", item?.divClasses)}>
                          <item.icon className={classNames("h-5 w-5 stroke-2", item?.iconClasses)} />
                        </div>
                      )}
                    </button>
                  </Menu.Item>
                ),
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default HeaderAction;
