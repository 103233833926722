import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageWorkspaceSettings } from "src/actions/workspace";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";

const WorspaceDataSync = ({ ...props }) => {
  const [disabledSsoAutoUpdated, setDisabledSsoAutoUpdated] = useState(false);
  const [disabledDatasetAutoUpdated, setDisabledDatasetAutoUpdated] = useState(false);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: props?.workspaceDetails?._id, ...updateSettings }, "/workspaces/:workspace_id/settings/data-sync");
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setDisabledSsoAutoUpdated(props.workspaceDetails?.disabled_sso_auto_updated || false);
    setDisabledDatasetAutoUpdated(props.workspaceDetails?.disabled_dataset_auto_updated || false);
  }, [props.workspaceDetails]);

  return (
    <EditContainer
      title="Data Sync Settings"
      subtitle="Control both SSO and dataset sync"
      onSuccess={() => handleUpdate({ disabled_sso_auto_updated: disabledSsoAutoUpdated, disabled_dataset_auto_updated: disabledDatasetAutoUpdated })}
      onCancel={() => {
        setDisabledSsoAutoUpdated(props.workspaceDetails?.disabled_sso_auto_updated);
        setDisabledDatasetAutoUpdated(props.workspaceDetails?.disabled_dataset_auto_updated);
      }}>
      <div className="min-w-[260px]">
        <div className="grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
          <ToggleHeader
            title="SSO auto update"
            subtitle="A user's data will automatically update, on every new login, with attributes from your SSO provider"
            position="left">
            <Toggle
              checked={!disabledSsoAutoUpdated}
              onChange={(e) => {
                setDisabledSsoAutoUpdated(!e);
              }}
            />
          </ToggleHeader>
        </div>
        <div className="mt-6 grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
          <ToggleHeader
            title="Dataset & bulk upload auto update"
            subtitle="A user's data will automatically update when an external dataset synca or csv data is bulk upload"
            position="left">
            <Toggle
              checked={!disabledDatasetAutoUpdated}
              onChange={(e) => {
                setDisabledDatasetAutoUpdated(!e);
              }}
            />
          </ToggleHeader>
        </div>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorspaceDataSync);
