import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "src/helpers/classNames";

const TextAccordion = ({ children, headerText, headerTextPosition = "right", startOpen = false, headerTextClasses, contentClass, containerClasses, iconPosition = "right" }) => {
  const [show, setShow] = useState(startOpen);

  return (
    <div className={classNames("relative w-full rounded-xl transition-all duration-200", containerClasses)}>
      <div className={classNames("mb-1 mt-5 flex", headerTextPosition === "right" ? "justify-end" : "")}>
        <div
          onClick={() => setShow(!show)}
          className={classNames("-mt-[10px] inline-flex cursor-pointer items-center gap-x-1 rounded-md px-2 py-1 text-gray-300 transition-all duration-200 sm:hover:bg-gray-50 sm:hover:text-gray-400", headerTextClasses)}>
          {iconPosition === "left" && <ChevronDownIcon className={classNames("h-5", show ? "" : "-rotate-90")} />}
          <div className="select-none">{headerText}</div>
          {iconPosition === "right" && <ChevronDownIcon className={classNames("h-5", show ? "" : "-rotate-90")} />}
        </div>
      </div>
      <div className={classNames("w-full overflow-hidden transition-all duration-150", contentClass, show ? "max-h-full rounded-md border border-gray-200 px-3 py-4 opacity-100 sm:px-6" : "max-h-[0px] opacity-0")}>{children}</div>
    </div>
  );
};

export default TextAccordion;
