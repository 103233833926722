import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import PillEntry from "../../PillEntry";
import { connect } from "react-redux";
import { manageWorkspaceSettings } from "src/actions/workspace";
import NoDataIconWithButton from "src/components/Shared/NoData/NoDataIconWithButton";
import { PlusIcon, TagIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";

const WorkspaceTags = ({ ...props }) => {
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [showInput, setShowInput] = useState(false);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings(
        {
          workspace_id: props?.workspaceDetails?._id,
          ...updateSettings,
        },
        "/workspaces/:workspace_id/settings/tags",
      );
      toast.success(message);
    } catch (error) {}
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  const handleTag = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && tag.trim()) {
      if (e.submit !== true) {
        e.preventDefault();
      }
      if (tagList.findIndex((tg) => tg.toLowerCase().trim() === tag.toLowerCase().trim()) === -1) {
        setTagList([...tagList, tag.trim()]);
        setTag("");
      }
    }
  };

  useEffect(() => {
    setTagList(props.workspaceDetails?.tags || []);
    if (props.workspaceDetails?.tags?.length === 0) {
      setShowInput(false);
    }
  }, [props.workspaceDetails]);

  const tagAddButton = (
    <Button
      version="default"
      onClick={() => setShowInput(true)}
      className="!h-10 !px-4 text-highlightColor">
      <div className="flex items-center gap-x-3 transition-all hover:font-semibold">
        <PlusIcon className="h-5 w-5 stroke-2" /> Add a tag
      </div>
    </Button>
  );

  return (
    <EditContainer
      title="Workspace Tags"
      subtitle="Add tags for filtering SSO data"
      preview={{
        text: tagList.length ? (
          <>
            {tagList.length} tag{tagList.length > 1 && "s"}
          </>
        ) : (
          "No tag"
        ),
      }}
      onSuccess={() => handleUpdate({ tags: tagList })}
      onCancel={() => {
        setTagList(props.workspaceDetails?.tags || []);
        if (props.workspaceDetails?.tags?.length === 0) {
          setShowInput(false);
        }
      }}>
      <div className="min-w-[260px] flex-shrink">
        {!showInput && tagList.length === 0 ? (
          <NoDataIconWithButton
            icon={TagIcon}
            title="No Tags"
            subTitle="Add tags for SSO to auto-filter data and/or auto-assign users to this workspace and its groups."
            buttonComponent={tagAddButton}
          />
        ) : (
          <div className="mb-4 space-y-4">
            <div className="w-96">
              <div className="flex w-full justify-end">
                <p className="mb-0.5 text-xs text-gray-300">Hit return to add tag to collection.</p>
              </div>
              <Input
                type="text"
                autoComplete="off"
                name="tag-name"
                label="Tag"
                inline={true}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={handleTag}
              />
            </div>
            {tagList?.length > 0 && (
              <div className="flex flex-wrap items-center gap-2">
                {tagList.map((tag, index) => (
                  <PillEntry
                    key={index}
                    index={index}
                    tag={tag}
                    onDelete={removeTag}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => ({
  workspaceDetails: state.workspaceDetails,
});

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceTags);
