import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "src/helpers/classNames";

const GlobalAccordion = ({ children, headerText, headerTextPosition = "right", startOpen = false, headerTextClasses, contentClass, containerClasses, iconPosition = "right" }) => {
  const [show, setShow] = useState(startOpen);

  return (
    <div className="grid gap-y-2">
      <div
        onClick={() => setShow(!show)}
        className={classNames("relative w-full cursor-pointer rounded-md border transition-all duration-200 hover:shadow", containerClasses, show ? "border-transparent hover:border-gray-100 hover:bg-gray-50" : "border-gray-100 bg-gray-50")}>
        <div className={classNames("inline-flex cursor-pointer items-center gap-x-1 rounded-md px-2 py-1 text-gray-300 transition-all duration-200 sm:hover:text-gray-400", headerTextClasses, show ? "" : "border-gray-100 bg-gray-50 ")}>
          {iconPosition === "left" && <ChevronDownIcon className={classNames("h-5 text-gray-500", show ? "" : "-rotate-90")} />}
          <div className="ml-2 w-full select-none">{headerText}</div>
          {iconPosition === "right" && <ChevronDownIcon className={classNames("h-5 text-gray-500", show ? "" : "-rotate-90")} />}
        </div>
      </div>
      <div className="relative w-full rounded-md transition-all duration-200">
        <div className={classNames("w-full transition-all duration-150", show ? "max-h-full overflow-visible rounded-md opacity-100" : "max-h-[0px] overflow-y-hidden opacity-0")}>{children}</div>
      </div>
    </div>
  );
};

export default GlobalAccordion;
