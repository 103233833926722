import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Modal from "src/components/Shared/Modal";
import { Tooltip as ReactTooltip } from "react-tooltip";

const UserAssignToPageModal = ({ isOpen, onCancel, handleUserGroupAssign, loading, domoUserIds, setDomoUserIds }) => {
  return (
    <Modal
      title="Users"
      size="sm"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={() => handleUserGroupAssign("user")}
      defaultOptions={{
        onCancelButtonVisible: true,
        onSuccessButtonVisible: true,
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: loading,
      }}
      defaultStyles={{
        overFlowYVisible: false,
      }}>
      <div className="w-full divide-y divide-gray-200">
        {domoUserIds?.map((user, i) => (
          <div
            key={user.id || user._id}
            className="flex w-full items-center space-x-3 py-2">
            <Checkbox
              isChecked={user.checked || false}
              disabled={user.owner || false}
              forceCheck={user.owner || false}
              onChange={(e) => {
                setDomoUserIds(
                  domoUserIds.map((usr, index) => {
                    if (i === index) {
                      return { ...usr, checked: e.target.checked };
                    } else {
                      return usr;
                    }
                  }),
                );
              }}
            />
            <div className="relative flex items-start gap-x-2">
              <div className="flex flex-col gap-x-2">
                <div>
                  <div className="relative flex items-center gap-2">
                    <div>{user.name}</div>
                    {user.owner && (
                      <div
                        className="rounded bg-purple-100 px-1 py-1 text-xs text-purple-600"
                        data-tooltip-id={`${user.email}-page_owner`}>
                        Owner
                      </div>
                    )}
                    {typeof user?.portalUser === "boolean" && !user?.portalUser && (
                      <div
                        data-tooltip-id={`${user.email}-not_portal_user_search`}
                        className="flex bg-amber-50 py-1 font-light text-amber-400">
                        <ExclamationTriangleIcon className="h-5 w-5 stroke-[2px]" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-sm text-gray-300">{user.email}</div>
              </div>

              <ReactTooltip
                id={`${user.email}-not_portal_user_search`}
                delayShow={200}
                positionStrategy="fixed"
                className="z-[200] rounded bg-gray-700 px-2 py-2 opacity-100">
                <div className="font-normal">
                  <div>This user was created by a Domo admin, but has not yet been give an account to access the application.</div>
                  <div>If you add this user a welcome email will be issued.</div>
                </div>
              </ReactTooltip>
              <ReactTooltip
                id={`${user.email}-page_owner`}
                delayShow={200}
                positionStrategy="fixed"
                className="z-[200] rounded bg-gray-700 px-2 py-2 opacity-100">
                <div className="font-normal">
                  <div>This is the page owner.</div>
                </div>
              </ReactTooltip>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default UserAssignToPageModal;
