import EditContainer from "src/components/Shared/Containers/EditContainer";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Input from "src/components/Shared/Forms/Inputs/Input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const WorkspaceSiteName = ({ ...props }) => {
  const [siteName, setSiteName] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSiteName = async () => {
    try {
      setButtonDisabled(true);
      const message = await props.manageWorkspaceSettings(
        {
          workspace_id: props?.workspaceDetails?._id,
          site_name: siteName,
        },
        "/workspaces/:workspace_id/layouts/site-name",
      );
      setButtonDisabled(false);
      toast.success(message);
    } catch (error) {
      setButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setSiteName(props?.workspaceDetails?.site_name);
  }, [props?.workspaceDetails?.site_name]);

  return (
    <>
      <EditContainer
        title="Site Name"
        preview={{ text: siteName ? siteName : "No site alias found." }}
        defaultOptions={{
          onSuccessLoaderVisible: buttonDisabled,
        }}
        onSuccess={handleSiteName}
        onCancel={() => setSiteName(props?.workspaceDetails?.site_name)}>
        <div className="min-w-[260px]">
          <Input
            name="site-name"
            label={"Site Name"}
            inline={true}
            value={siteName}
            onChange={(e) => setSiteName(e.target.value)}
          />
        </div>
      </EditContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceSiteName);
