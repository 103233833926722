import { AdjustmentsHorizontalIcon, BanknotesIcon, CodeBracketSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { getPageCredentialOptions } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import SlideOver from "src/components/Shared/SlideOver";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { objectToFormData } from "src/helpers";
import useFetch from "src/hooks/useFetch";
import Billing from "src/components/Workspaces/WorkspacesModals/WorkspaceAddSlider/Billing";
import Embedding from "src/components/Workspaces/WorkspacesModals/WorkspaceAddSlider/Embedding";
import General from "src/components/Workspaces/WorkspacesModals/WorkspaceAddSlider/General";

const WorkspacesAddSlider = ({ site, isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [workspaceType, setWorkspaceType] = useState("IFRAME_EMBED");
  const [selectedPlan, setSelectedPlan] = useState("");

  const [name, setName] = useState("");
  const [squareLogo, setSquareLogo] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);

  const [integrationCreds, setIntegrationCreds] = useState([]);

  const createWorkspace = async () => {
    try {
      setDisabled(true);
      const updatedPayload = objectToFormData({
        name,
        ...(selectedPlan?._id ? { plan_id: selectedPlan?._id } : {}),
        square_logo: squareLogo,
        image_logo: headerLogo,
        workspace_type: workspaceType,
        integrations: JSON.stringify(integrationCreds),
      });
      const { data } = await apiRequest("POST", `/workspaces/add`, { body: updatedPayload }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {}, headers: { "Content-Type": "multipart/form-data" } });
      if (data.status === 200) {
        onSuccess();
        setName("");
        setSquareLogo("");
        setHeaderLogo("");
        navigate(`/workspaces/${data?.data?.workspace_id}`);
      }

      setTimeout(() => setDisabled(false), 300);
    } catch (error) {
      setTimeout(() => setDisabled(false), 300);
    }
  };

  const {
    response: { data: integrations },
    refreshData: refreshIntegrations,
  } = useFetch("/pages/integration");

  const onClear = () => {
    setName("");
    setSquareLogo("");
    setHeaderLogo("");
    navigate({ search: "" });
    setWorkspaceType("IFRAME_EMBED");
  };

  useEffect(() => {
    if (!isOpen) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setIntegrationCreds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceType]);

  const tabs = [
    {
      name: "General",
      icon: AdjustmentsHorizontalIcon,
      component: (
        <General
          name={name}
          setName={setName}
          squareLogo={squareLogo}
          headerLogo={headerLogo}
          setHeaderLogo={setHeaderLogo}
          setSquareLogo={setSquareLogo}
        />
      ),
    },
    {
      name: "Embedding",
      icon: CodeBracketSquareIcon,
      component: (
        <Embedding
          integrations={integrations}
          workspaceType={workspaceType}
          setWorkspaceType={setWorkspaceType}
          integrationCreds={integrationCreds}
          refreshIntegrations={refreshIntegrations}
          setIntegrationCreds={setIntegrationCreds}
        />
      ),
    },
    site?.stripe_secret_key && site?.stripe_publishable_key
      ? {
          name: "Billing",
          icon: BanknotesIcon,
          component: (
            <Billing
              site={site}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          ),
        }
      : {},
  ];

  return (
    <SlideOver
      isOpen={isOpen}
      handleClose={() => {
        onCancel();
        onClear();
        setIntegrationCreds([]);
      }}
      title={`Create A New Workspace`}
      description="View, update, and modify workspace details and information below."
      maxWidth="xl:max-w-[1000px] lg:max-w-[800px]">
      <Tabs tabs={tabs} />

      <div className="mt-auto flex w-full  justify-end gap-x-3 pt-3">
        <Button
          version="gray"
          type="button"
          onClick={() => {
            onCancel();
            onClear();
          }}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={createWorkspace}
          disabled={disabled}
          loading={disabled}>
          Submit
        </Button>
      </div>
    </SlideOver>
  );
};

const mapStateToProps = (state) => {
  return {
    credentials: state.pageCredentialOptions,
    site: state.site,
  };
};

export default connect(mapStateToProps, { getPageCredentialOptions })(WorkspacesAddSlider);
