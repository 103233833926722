import { useEffect, useState } from "react";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import Button from "src/components/Shared/Buttons/Button";
import { H3 } from "src/components/Shared/Text/Headers";
import { formatCurrency } from "src/helpers";

const BillingPlanManageModal = ({ title = "", secondaryTitle = "", isOpen, editItem = null, onCancel = () => {}, onSuccess = () => {}, ...props }) => {
  const defaultFormData = {
    name: "",
    description: "",
    pricing: {
      type: "standard",
      rates: [
        { interval: "month", amount: 0, discount_percentage: 0 },
        { interval: "year", amount: 0, discount_percentage: 0 },
      ],
    },
    default: false,
    free_trial: { status: false, days: 0 },
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [disabled, setDisabled] = useState(false);

  const onManage = async () => {
    try {
      setDisabled(true);
      const url = formData?._id ? `/plans/:plan_id` : `/plans`;
      const method = formData?._id ? `PUT` : `POST`;
      const { data } = await apiRequest(method, url, {
        body: formData,
        params: { plan_id: formData?._id },
      });
      setDisabled(false);
      toast.success(data.message);
      onSuccess();
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setFormData(editItem || defaultFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);

  const calculateYearlyAmount = (monthlyAmount, discountPercentage) => {
    const yearlyAmount = monthlyAmount * 12;
    const discount = (yearlyAmount * discountPercentage) / 100;
    return {
      yearlyAmount,
      discount,
      monthlyAmount,
      discountPercentage,
      discountedAmount: yearlyAmount - discount,
    };
  };

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        size={`xxl`}
        onCancel={onCancel}
        onSuccess={onManage}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: disabled,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className={`relative min-h-[200px] overflow-visible py-4 transition-all duration-100 ease-out `}>
          <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
            <div className="w-full px-2">
              <Input
                inline={true}
                name="plan-name"
                label="Name"
                value={formData?.name || ""}
                onChange={(e) => {
                  setFormData((prevData) => ({ ...prevData, name: e.target.value }));
                }}
              />
            </div>
            <div className="w-full px-2">
              <TextArea
                inline={true}
                inputClassNames="w-full h-40 text-sm rounded border-gray-300 border outline-none no-shadow py-2 px-3 focus:border-highlightColor"
                label={"Description ( within 150 words )"}
                value={formData?.description || ""}
                onChange={(e) => {
                  const input = e.target.value;
                  if (input.length <= 1000) {
                    setFormData((prevData) => ({ ...prevData, description: input }));
                  }
                }}
              />
            </div>
            {formData?.pricing?.rates?.map((price, index) => (
              <div
                key={index}
                className="flex w-full flex-col space-y-4 px-2">
                {price?.interval === "month" && (
                  <div className="flex w-full flex-col space-y-2">
                    <Input
                      wrapperClass="flex-shrink flex-grow"
                      type="number"
                      inline={true}
                      name={`${price?.interval}-amount`}
                      label="Monthly Amount"
                      value={price?.amount || ""}
                      onChange={(e) => {
                        const newMonthlyAmount = parseFloat(e.target.value) || 0;
                        setFormData((prevData) => ({
                          ...prevData,
                          pricing: {
                            ...prevData.pricing,
                            rates: prevData.pricing.rates.map((item) => (item?.interval === "month" ? { ...item, amount: newMonthlyAmount } : item?.interval === "year" ? { ...item, amount: newMonthlyAmount * 12 } : item)),
                          },
                        }));
                      }}
                    />
                  </div>
                )}
                {price?.interval === "year" && (
                  <div className="flex w-full flex-col space-y-2">
                    <Input
                      wrapperClass="flex-shrink flex-grow"
                      type="number"
                      inline={true}
                      name={`${price?.interval}-discount`}
                      label="Yearly Discount (%)"
                      value={price?.discount_percentage || ""}
                      onChange={(e) => {
                        const newDiscount = parseFloat(e.target.value) || 0;
                        setFormData((prevData) => ({
                          ...prevData,
                          pricing: {
                            ...prevData.pricing,
                            rates: prevData.pricing.rates.map((item) => (item?.interval === "year" ? { ...item, discount_percentage: newDiscount } : item)),
                          },
                        }));
                      }}
                    />
                  </div>
                )}
              </div>
            ))}

            {formData?.pricing?.rates?.some((rate) => rate.interval === "month" && rate.amount > 0) && (
              <div className=" w-full px-2">
                {formData?.pricing?.rates?.map((rate) => {
                  if (rate.interval === "month" && rate.amount > 0) {
                    const { yearlyAmount, discountPercentage, discountedAmount, monthlyAmount } = calculateYearlyAmount(rate.amount, formData.pricing.rates.find((r) => r.interval === "year")?.discount_percentage || 0);
                    return (
                      <div
                        key={rate.interval}
                        className="text-sm">
                        <InformationAlert
                          type={"info"}
                          content={
                            <>
                              <H3 className="mb-1 mt-0 !text-lg">Price Summary</H3>
                              <div className="space-y-1">
                                <div>
                                  Monthly Amount: <span className="font-semibold">{formatCurrency(monthlyAmount)}</span>
                                </div>
                                <div>
                                  Yearly Amount: <span className={`font-semibold ${discountPercentage > 0 && "line-through"}`}>{formatCurrency(yearlyAmount)}</span> {discountPercentage > 0 && <span className="font-semibold">{formatCurrency(discountedAmount)}</span>}
                                  {discountPercentage > 0 && (
                                    <Button
                                      version="default"
                                      className="ml-2 inline-flex !h-auto rounded-md border border-green-300 px-2 pb-0.5 pt-1 text-xs font-semibold text-green-600">
                                      Save {discountPercentage}%
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
            <div className=" w-full space-y-2 px-2">
              <ToggleHeader
                title="Free trial"
                subtitle="Offer free trial"
                position="left">
                <Toggle
                  checked={formData?.free_trial?.status}
                  onChange={() => {
                    setFormData((prevData) => ({
                      ...prevData,
                      free_trial: {
                        ...prevData?.free_trial,
                        status: !prevData?.free_trial?.status,
                        days: 0,
                      },
                    }));
                  }}
                />
              </ToggleHeader>
              {formData?.free_trial?.status && (
                <Input
                  type="number"
                  inline={true}
                  name="free-trial"
                  label="Free trial (in days)"
                  value={formData?.free_trial?.days || ""}
                  onChange={(e) => {
                    setFormData((prevData) => ({ ...prevData, free_trial: { ...prevData.free_trial, days: e.target.value } }));
                  }}
                />
              )}
              <ToggleHeader
                title="Set as Default"
                subtitle="Checking this will make this plan your default plan"
                position="left">
                <Toggle
                  checked={formData?.default}
                  onChange={() => {
                    setFormData((prevData) => ({
                      ...prevData,
                      default: !prevData?.default,
                    }));
                  }}
                />
              </ToggleHeader>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BillingPlanManageModal;
