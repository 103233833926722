import React, { useState } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html"; // convert the editorState to plain html
// import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"; // style
// import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import Button from "src/components/Shared/Buttons/Button";
import { CodeBracketIcon } from "@heroicons/react/24/outline";

export default function HtmlAndRichTextEditor({ htmlContent = "", setHtmlContent = () => {} }) {
  // const [textContent, setTextContent] = useState(htmlToDraft(htmlContent));

  const [viewWysiwyg, setViewWysiwyg] = useState(false);

  // function for show the plain html and save editorState
  // const handleChange = useCallback((editorState) => {
  //   const raw = convertToRaw(editorState.getCurrentContent()); // get raw data from editor state
  //   setHtmlContent(draftToHtml(raw));
  //   setTextContent(editorState);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div>
      <div className="mb-1 flex justify-end gap-x-1">
        <div
          className="flex space-x-1 rounded-lg bg-slate-100 p-0.5"
          role="tablist"
          aria-orientation="horizontal">
          {/* <Button
            onClick={() => setViewWysiwyg(true)}
            className="flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3 bg-white shadow"
            id="headlessui-tabs-tab-80"
            role="tab"
            type="button"
            version="default"
            aria-selected="true"
            tabindex="0"
            data-headlessui-state="selected"
            aria-controls="headlessui-tabs-panel-82">
            <svg
              className="h-5 w-5 flex-none stroke-sky-500"
              fill="none"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M17.25 10c0 1-1.75 6.25-7.25 6.25S2.75 11 2.75 10 4.5 3.75 10 3.75 17.25 9 17.25 10Z"></path>
              <circle
                cx="10"
                cy="10"
                r="2.25"></circle>
            </svg>
            <span className="sr-only lg:not-sr-only lg:ml-2 text-slate-900">Text</span>
          </Button> */}
          <Button
            onClick={() => setViewWysiwyg(false)}
            className="flex items-center rounded-md py-2 pl-2 pr-2 text-sm font-semibold text-slate-600 lg:pr-3"
            id="headlessui-tabs-tab-81"
            role="tab"
            type="button"
            version="default"
            aria-selected="false"
            tabindex="-1"
            data-headlessui-state=""
            aria-controls="headlessui-tabs-panel-83">
            <CodeBracketIcon className="h-5 w-5 stroke-2" />
            <span className="sr-only inline-block leading-none lg:not-sr-only lg:ml-2">HTML</span>
          </Button>
        </div>
        {/* <Button version="default" onClick={()=>setViewWysiwyg(true)} className={classNames("h-7 px-2 flex items-center justify-center rounded-t border ", viewWysiwyg ? "text-white bg-highlightColor border-highlightColor" : "bg-white border-gray-300")}>Text Editor</Button>
          <Button version="default" onClick={()=>setViewWysiwyg(false)} className="h-7 px-2 bg-white flex items-center justify-center rounded-t border border-gray-300">HTML Code</Button> */}
      </div>
      <div className="relative">
        {viewWysiwyg ? (
          <>
            {/* {textContent ? (
              <div className="border border-gray-300 rounded-md shadow-sm">
                <Editor
                  wrapperClassName="overflow-hidden rounded"
                  editorClassName="border-t border-gray-300 p-3 sm:h-[300px] lg:h-[400px] overflow-y-scroll"
                  toolbarClassName="!border-none"
                  editorState={textContent}
                  onEditorStateChange={handleChange}
                  toolbar={{
                    options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                  }}
                />
              </div>
            ) : (
              <Preloader />
            )} */}
          </>
        ) : (
          <div className="h-[600px] lg:h-[800px]">
            <TextArea
              name="html"
              type="code"
              autoComplete="off"
              value={htmlContent}
              rows={10}
              onChange={(e) => setHtmlContent(e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
