import { connect } from "react-redux";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { H5 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";
import { toast } from "react-toastify";
import validator from "validator";
import { useState } from "react";
import { manageSiteSettings } from "src/actions/site";
import { manageWorkspaceSettings } from "src/actions/workspace";

const TestEmailSetup = ({ workspaceId, type = "mailgun", additionalActions = null, ...props }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const message = workspaceId ? await props.manageWorkspaceSettings({ email, workspace_id: workspaceId, type }, "/workspaces/:workspace_id/smtp/verify") : await props.manageSiteSettings({ email, type }, "/settings/smtp/verify");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-2">
      <div>
        <H5
          margin={false}
          caption="Enter in a testing email address and see if an email comes through.">
          Test your SMTP connection
        </H5>
        {additionalActions && additionalActions}
        <div className="mt-2 flex w-full max-w-2xl  items-center gap-4">
          <div className="relative w-[70%]">
            <Input
              name="test-email-first"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email address"
              inline={true}
            />
          </div>
          <div className="relative">
            <Button
              disabled={isLoading || !validator.isEmail(email)}
              loading={isLoading}
              onClick={onSubmit}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings })(TestEmailSetup);
