import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { manageSiteSettings } from "src/actions/site";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const LoginUrlTitleDescription = ({ manageSiteSettings, site }) => {
  const [siteDomain, setSiteDomain] = useState("");
  const [loginPageTitle, setLoginPageTitle] = useState("");
  const [loginPageSubtitle, setLoginPageSubtitle] = useState("");

  useEffect(() => {
    setSiteDomain(site?.site_domain);
    setLoginPageTitle(site?.login_page_title);
    setLoginPageSubtitle(site?.login_page_subtitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);

  const onCancel = async () => {
    setSiteDomain(site.site_domain);
    setLoginPageTitle(site.login_page_title);
    setLoginPageSubtitle(site.login_page_subtitle);
  };

  const onSubmit = async () => {
    try {
      let body = {
        site_domain: siteDomain,
        login_page_title: loginPageTitle,
        login_page_subtitle: loginPageSubtitle,
      };
      await manageSiteSettings(body, `/settings/login-page/content`);
      toast.success("General settings data successfully updated.");
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <EditContainer
      title="Login page details"
      subtitle="Setup the login page's details and text content for the best user experience."
      preview={{
        text: siteDomain,
      }}
      fullWidth={true}
      defaultOpen={true}
      onSuccess={onSubmit}
      onCancel={onCancel}
      defaultOptions={{ onCancelButtonText: "Undo" }}>
      <div className="mb-8 mt-4 flex w-full max-w-[600px] flex-wrap gap-x-4 gap-y-6">
        <div className="w-full">
          <Input
            name="site-domain"
            label="User Access URL"
            value={siteDomain}
            inline={true}
            onChange={(e) => {
              setSiteDomain(e.target.value);
            }}
          />
        </div>
        <div className="w-full">
          <Input
            name="login-page-title"
            label="Login Page Title"
            value={loginPageTitle}
            inline={true}
            onChange={(e) => {
              setLoginPageTitle(e.target.value);
            }}
          />
        </div>
        <div className="w-full">
          <Input
            name="login-page-subtitle"
            label="Login Page Subtitle"
            value={loginPageSubtitle}
            inline={true}
            onChange={(e) => {
              setLoginPageSubtitle(e.target.value);
            }}
          />
        </div>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(LoginUrlTitleDescription);
