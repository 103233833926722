import { CalendarIcon } from "@heroicons/react/24/outline";
import { getStatusStyles } from "src/helpers/billingHelper";
import { classNames } from "src/helpers/classNames";
import { formatCurrency } from "src/helpers";
import { connect } from "react-redux";
import moment from "moment";
import Button from "src/components/Shared/Buttons/Button";
import AssignPlanModal from "./AssignPlanModal";
import { useEffect, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import useMounted from "src/hooks/useMounted";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";

const PlanDetails = ({ planDetails, workspaceDetails, loadPlanDetails, stripeCredentialChanged }) => {
  const [plans, setPlans] = useState([]);
  const [assignModal, setAssignModal] = useState(false);

  const isMounted = useMounted();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await apiRequest("get", "/plans");
        if (isMounted.current) {
          setPlans(response?.data?.data?.filter((item) => item?.pricing?.type !== "no_charge"));
        }
      } catch (err) { }
    };
    fetchPlans();
  }, [isMounted]);

  return (
    <>
      <div className="relative flex w-full gap-4 pb-2">
        <PlanCard
          setAssignModal={setAssignModal}
          isUpcoming={planDetails?.upcoming_plan ? true : false}
          prevPlan={planDetails?.upcoming_plan ? planDetails : null}
          plan={planDetails?.upcoming_plan ? planDetails?.upcoming_plan : planDetails}
          status={planDetails?.upcoming_plan ? "upcoming" : planDetails?.subscription_status}
          stripeCredentialChanged={stripeCredentialChanged}
          workspaceId={workspaceDetails?._id}
        />
        {/* {planDetails?.upcoming_plan && (
          <PlanCard
            plan={planDetails?.upcoming_plan}
            status="upcoming"
            isUpcoming
          />
        )} */}
      </div>
      <AssignPlanModal
        isOpen={assignModal}
        setIsOpen={setAssignModal}
        setRefresh={loadPlanDetails}
        isActivePlan={planDetails?.subscription_status === "active" || (planDetails?.subscription_status === "canceled" && !planDetails?.payment_required)}
        title={`${workspaceDetails?.name}`}
        plans={plans}
        activePlan={planDetails}
        secondaryTitle={"Billing / Plan Change"}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(PlanDetails);

const PlanCard = ({ plan, status, isUpcoming, prevPlan, setAssignModal, workspaceId, stripeCredentialChanged = false }) => {
  if (!plan) return null;

  const { className, label } = getStatusStyles(isUpcoming && plan?.payment_status === "succeeded" ? "active" : status);
  const selectedRate = plan?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === plan?.subscription?.recurring_type);

  const pricePerInterval = formatCurrency(((selectedRate?.amount || 0) - (selectedRate?.discount_amount || 0)) / (plan?.subscription?.recurring_type === "year" ? 12 : 1));
  const totalPrice = selectedRate ? selectedRate.amount : 0;
  const discountedPrice = totalPrice - (selectedRate?.discount_amount || 0);

  const notifyBillingManager = async () => {
    try {
      const { data } = await apiRequest("get", `/workspaces/:workspace_id/billing/send/email/stripe-credential-changed`, { params: { workspace_id: workspaceId } });
      toast.success(data?.message);
    } catch (err) { }
  };

  return (
    <div className="flex flex-col gap-y-4 ">
      <div className="w-[500px] rounded-md border p-4 pb-2">
        <div className="flex w-full items-center gap-2 text-2xl font-semibold">
          {plan?.plan_details?.name}
          <div className={classNames("flex h-8 items-center justify-center gap-2 rounded-lg border bg-white px-2 text-base", className)}>{label}</div>
        </div>
        <div className="py-1 pl-0.5 text-sm opacity-75">{plan?.plan_details?.description || "No description provided."}</div>

        <div className="relative my-2 flex w-full flex-col gap-4 rounded-md border p-3">
          {selectedRate?.discount_type !== "none" && <div className="absolute right-0 top-0 rounded-bl-lg bg-slate-200 p-1.5 text-sm">{selectedRate?.discount_percentage}% OFF</div>}
          <div className="text-lg font-medium capitalize">{!["active", "canceled"].includes(plan?.subscription_status) && ["pending"].includes(plan?.payment_status) ? "Not Billed Yet" : `Billed ${plan?.subscription?.recurring_type}ly`}</div>
          <div className="flex items-center justify-between">
            <div className="text-lg text-yellow-600">
              {pricePerInterval}
              <span className="pl-0.5 text-base">/month</span>
            </div>
            <div>
              Total: {formatCurrency(discountedPrice)}
              {selectedRate?.discount_amount > 0 && <span className="ml-1 text-slate-500 line-through">{formatCurrency(totalPrice)}</span>}
            </div>
          </div>
        </div>

        {!isUpcoming && plan?.subscription_status !== "pending" && (
          <div className="mt-4 flex flex-col gap-4 pb-2">
            <div className="flex items-center justify-between gap-4 text-lg">
              <h3 className="font-semibold">{["canceled", "expired", "on_trial", "on_trial_expired"].includes(plan?.subscription_status) ? (moment(plan?.end_date).isSameOrAfter(moment(), "day") ? "Expiring on:" : "Expired on:") : "Next Billing Date"}</h3>
              <div className="flex items-center justify-center gap-1 font-medium text-gray-600">
                <CalendarIcon className="h-6 w-6" />
                {moment(plan?.end_date)
                  .add(["canceled", "expired", "on_trial", "on_trial_expired"].includes(plan?.subscription_status) ? 0 : 1, "days")
                  .format("MMM DD, YYYY")}
              </div>
            </div>
          </div>
        )}
        {isUpcoming && (
          <InformationAlert
            content={
              <div className="flex flex-wrap space-x-1">
                {isUpcoming && plan?.payment_status === "succeeded" ? <>The user has resubscribed to the plan</> : <>The user's plan has been updated</>}, and the next billing date is
                <div className="flex items-center justify-center gap-1 font-semibold">
                  <CalendarIcon className="h-6 w-6" />
                  {moment(plan?.start_date).format("MMM DD, YYYY")}
                </div>
              </div>
            }
            type="info"
          />
        )}
        {stripeCredentialChanged && (
          <div>
            <InformationAlert
              content={
                <div className="relative   text-sm">
                  <span className="inline">
                    The stripe credentials are changed, all the card details of the billing manager has deleted, so atleast one card needs to be added
                    {plan?.subscription_status === "active" ? " to continue the subscription or the current plan will expire after next billing date" : ""}.
                  </span>
                  <span
                    className="inline"
                    data-tooltip-id={`notify-tooltip`}>
                    <Button
                      type="button"
                      version="default"
                      className="!inline !h-auto !w-auto !bg-transparent !px-1 !py-0 !text-sm underline !opacity-100"
                      onClick={notifyBillingManager}>
                      Notify billing manager
                    </Button>
                    <Tooltip
                      id={`notify-tooltip`}
                      place="bottom"
                      content={<>Send email to notify billing manager.</>}
                      className="z-80 text bg-yellow-50 text-yellow-700 opacity-100 shadow-lg"
                    />
                  </span>
                </div>
              }
              type="warning"
            />
          </div>
        )}
      </div>
      <div className="mb-2 flex items-center justify-between font-semibold">
        <Button
          version="secondary"
          onClick={() => setAssignModal(true)}>
          <div className="flex gap-x-3">Change Plan</div>
        </Button>
      </div>
    </div>
  );
};
