import { Menu, Transition } from "@headlessui/react";
import { ArrowLeftCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "./Button";

const AddCredentialDropdown = ({ align, buttonClass, dropdownClass, dropdownData, onClick = () => {} }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuData, setSubMenuData] = useState(null);
  const [isMainMenu, setIsMainMenu] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    if (dropdownData?.length > 1) {
      setIsMainMenu(true);
      setIsSubMenu(false);
    } else if (dropdownData?.length === 1) {
      if (dropdownData[0]?.embed_types && dropdownData[0]?.embed_types?.length > 0) {
        if (dropdownData[0]?.embed_types?.length > 1) {
          setIsMainMenu(false);
          setIsSubMenu(true);
          setSubMenu(dropdownData[0]);
        } else if (dropdownData[0]?.embed_types?.length === 1) {
          setIsMainMenu(false);
          setIsSubMenu(false);
        }
      } else {
        setIsMainMenu(false);
        setIsSubMenu(false);
      }
    } else {
      setIsMainMenu(false);
      setIsSubMenu(false);
    }
  }, [dropdownData]);

  return (
    <>
      <Menu
        as="div"
        className="relative">
        <div>
          <Menu.Button
            className={classNames("h-[47px] rounded-md border border-highlightColor bg-highlightColor px-4 py-2 text-white transition-all md:h-[40px] 2xl:h-[47px]", buttonClass)}
            onClick={() => {
              if (dropdownData?.length === 1) {
                if (dropdownData[0]?.embed_types && dropdownData[0]?.embed_types?.length === 1) {
                  onClick(dropdownData[0], dropdownData[0]?.embed_types[0]);
                }
              }
            }}>
            <div className="flex items-center gap-x-3 hover:font-semibold transition-all">
              <PlusIcon className="h-5 w-5 stroke-2" /> Add a credential
            </div>
          </Menu.Button>
        </div>
        {isMainMenu && (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className={classNames("absolute z-50 mt-2 w-96 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none", align === "right" ? "right-0 origin-top-right" : "left-0 origin-top-left", dropdownClass)}>
              <div className="flex overflow-hidden">
                <div className="w-full flex-shrink-0">
                  <div className="scrollbar max-h-96 overflow-y-auto py-2">
                    {dropdownData.length > 0 &&
                      dropdownData.map((item) => (
                        <Button
                          disabled={!item?.embed_types || item?.embed_types.length === 0}
                          type="button"
                          version="default"
                          className="flex w-full items-center gap-2 px-3 py-2 text-left hover:bg-gray-100"
                          key={item._id}
                          onClick={() => {
                            if (item?.embed_types && item?.embed_types?.length > 0) {
                              if (item?.embed_types?.length > 1) {
                                setSubMenuOpen(true);
                                setSubMenuData(item);
                              } else if (item?.embed_types?.length === 1) {
                                onClick(item, item?.embed_types[0]);
                              }
                            }
                          }}>
                          <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-md object-contain">
                            <div
                              className=""
                              dangerouslySetInnerHTML={{ __html: item.image }}
                            />
                          </div>
                          <div className="w-full flex-shrink text-[13px] font-semibold text-gray-600">{item.name}</div>
                        </Button>
                      ))}
                  </div>
                </div>
                <div className={classNames("w-full flex-shrink-0 bg-white transition-all duration-200", subMenuOpen ? "-translate-x-full" : "")}>
                  <div className="space-y-2">
                    <div className="flex py-2">
                      <Button
                        className="flex-shrink-0 !border-none !bg-transparent !px-2 !text-gray-400 !shadow-none focus:!ring-0"
                        onClick={() => {
                          setSubMenuOpen(false);
                          setSubMenuData(null);
                        }}>
                        <ArrowLeftCircleIcon className="h-8 w-8 stroke-1" />
                      </Button>
                      <div className="flex w-full flex-shrink items-center gap-2">
                        <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-md object-contain">
                          <div
                            className=""
                            dangerouslySetInnerHTML={{ __html: subMenuData?.image }}
                          />
                        </div>
                        <div className="w-full flex-shrink text-[13px] font-semibold text-gray-600">{subMenuData?.name}</div>
                      </div>
                    </div>
                    <div className="space-y-1 pb-1">
                      <div className="px-3 text-xs font-semibold uppercase tracking-wider text-gray-400">Actions</div>
                      <div className="scrollbar max-h-[254px] space-y-2 overflow-y-auto">
                        {subMenuData?.embed_types &&
                          subMenuData?.embed_types?.length > 0 &&
                          subMenuData?.embed_types?.map((item) => (
                            <Menu.Item key={item._id}>
                              <Button
                                className="!block !w-full flex-shrink-0 !rounded-none !border-none !bg-transparent !px-3 !text-left !shadow-none hover:!bg-gray-100 focus:!ring-0"
                                onClick={() => {
                                  onClick(subMenuData, item);
                                  setSubMenuOpen(false);
                                  setSubMenuData(null);
                                }}>
                                <div className="mb-auto h-10 w-10 flex-shrink-0 overflow-hidden rounded-md object-contain">
                                  <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: item?.image }}
                                  />
                                </div>
                                <div className="w-full flex-shrink space-y-1">
                                  <div className="flex items-start justify-between">
                                    <div className="text-base font-semibold leading-none text-gray-900">{item?.title}</div>
                                    {/* <div className="text-xs leading-none bg-white text-gray-400 border border-gray-300 rounded-md px-2 py-1">Default</div> */}
                                  </div>
                                  <div className="text-xs text-gray-400">{item?.sub_title}</div>
                                </div>
                              </Button>
                            </Menu.Item>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        )}
        {isSubMenu && (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className={classNames("absolute z-50 mt-2 w-96 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none", align === "right" ? "right-0 origin-top-right" : "left-0 origin-top-left", dropdownClass)}>
              <div className="flex overflow-hidden">
                <div className="w-full flex-shrink-0">
                  <div className="scrollbar max-h-96 overflow-y-auto py-2">
                    {subMenu?.embed_types &&
                      subMenu?.embed_types?.length > 0 &&
                      subMenu?.embed_types?.map((item) => (
                        <Menu.Item key={item._id}>
                          <Button
                            className="!block !w-full flex-shrink-0 !rounded-none !border-none !bg-transparent !px-3 !text-left !shadow-none hover:!bg-gray-100 focus:!ring-0"
                            onClick={() => {
                              onClick(subMenu, item);
                            }}>
                            <div className="mb-auto h-10 w-10 flex-shrink-0 overflow-hidden rounded-md object-contain">
                              <div
                                className=""
                                dangerouslySetInnerHTML={{ __html: item?.image }}
                              />
                            </div>
                            <div className="w-full flex-shrink space-y-1">
                              <div className="flex items-start justify-between">
                                <div className="text-base font-semibold leading-none text-gray-900">{item?.title}</div>
                                {/* <div className="text-xs leading-none bg-white text-gray-400 border border-gray-300 rounded-md px-2 py-1">Default</div> */}
                              </div>
                              <div className="text-xs text-gray-400">{item?.sub_title}</div>
                            </div>
                          </Button>
                        </Menu.Item>
                      ))}
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        )}
      </Menu>
    </>
  );
};

export default AddCredentialDropdown;
