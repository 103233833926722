import { useEffect, useState, useMemo } from "react";
import useFetch from "./useFetch";
import useDeepCompareEffect from "./useDeepCompareEffect";

const useGetListAndSelectItem = ({ url = "", method = "GET", paginationData = null, additionalQuery = {}, params }) => {
  const [limit, setLimit] = useState(paginationData?.limit || 10);
  const [offset, setOffset] = useState(paginationData?.offset || 0);
  const [keyword, setKeyword] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [lists, setLists] = useState([]);

  const memoizedQuery = useMemo(() => ({ limit, offset, keyword, ...(additionalQuery || {}) }), [limit, offset, keyword, additionalQuery]);

  const {
    response: { data, meta },
    status: { done: isLoaded },
    refreshData,
  } = useFetch(url, {
    method,
    params,
    query: memoizedQuery,
  });

  useDeepCompareEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedQuery]);

  useEffect(() => {
    setLists(data);
  }, [data]);

  useEffect(() => {
    if (lists?.length > 0) {
      setSelectedItem(lists[0]);
    } else {
      setSelectedItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists]);

  const handleOnPaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  return {
    lists,
    setLists,
    meta,
    isLoaded,
    limit,
    setLimit,
    offset,
    setOffset,
    keyword,
    setKeyword,
    selectedItem,
    setSelectedItem,
    refreshData,
    handleOnPaginationChange,
  };
};

export default useGetListAndSelectItem;
