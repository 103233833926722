import { connect } from "react-redux";
import useIframeResize from "src/hooks/useIframeResize";

const QuickSightEmbedPlatform = ({ content, workspaceDetails, site, user }) => {
  useIframeResize({ workspaceDetails, site, user });

  return (
    <>
      <div className="relative h-full w-full">
        {content && (
          <div
            className={"mx-auto h-full w-full overflow-auto"}
            style={{ maxWidth: localStorage.getItem("impersonatedUserId") ? workspaceDetails?.max_width : "100%" }}>
            <iframe
              id="iframe"
              title="QuickSight Embed"
              src={content}
              width="100%"
              height="100%"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(QuickSightEmbedPlatform);
