import { FunnelIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useRef, useState } from "react";
import { bottom, left, right, top } from "@popperjs/core";
import { usePopper } from "react-popper";
import MultiRowSelectedCountBubble from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectedCountBubble";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import Search from "src/components/Shared/Search";
import { Transition } from "@headlessui/react";
import Button from "../../Buttons/Button";

const MultiSelectObjectFilterWithTabs = ({ xPlacement, flip = true, createP = true, customSelector = null, itemComponent = null, defaultTab = "", tabs = [] }) => {
  const [activeTab, setActiveTab] = useState("");
  const [tabStates, setTabStates] = useState({});
  const [panelOpen, setPanelOpen] = useState(false);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const ref = useRef();

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement || ""],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      !flip && {
        name: "flip",
        options: {
          flipVariations: false,
          fallbackPlacements: ["bottom-end"],
        },
      },
    ],
  });

  useOnClickOutside(ref, () => setPanelOpen(false));

  const handleSearch = (value) => {
    const filtered = tabStates[activeTab]?.selectedOptions?.filter((option) => tabStates[activeTab]?.searchableFields?.some((field) => option?.object[field]?.toLowerCase()?.includes(value?.toLowerCase())));

    setTabStates((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], keyword: value, filteredOptions: filtered },
    }));
  };

  const handleSelectAll = () => {
    let isAllSelected = tabStates[activeTab]?.selectedOptions?.filter((option) => {
      return option.selected === true;
    }).length;

    if (tabStates[activeTab]?.selectedOptions?.length === isAllSelected) {
      setAllOptionsClickValues(false, "tab");
    } else {
      setAllOptionsClickValues(true, "tab");
    }
  };

  const setAllOptionsClickValues = (value, from = "all") => {
    if (from === "tab") {
      const selectedOptions = tabStates[activeTab]?.selectedOptions?.map((option) => ({ ...option, selected: value }));
      const filteredOptions = tabStates[activeTab]?.filteredOptions?.map((option) => ({ ...option, selected: value }));

      setTabStates((prev) => ({ ...prev, [activeTab]: { ...prev[activeTab], selectedOptions, filteredOptions } }));
      tabStates[activeTab]?.onChange(selectedOptions);
    } else {
      const updatedState = Object.keys(tabStates).reduce((acc, tabName) => {
        const tabState = tabStates[tabName];

        const selectedOptions = tabState?.selectedOptions?.map((option) => ({ ...option, selected: value }));
        const filteredOptions = tabState?.filteredOptions?.map((option) => ({ ...option, selected: value }));

        acc[tabName] = { ...tabState, selectedOptions, filteredOptions };
        tabState?.onChange(selectedOptions);
        return acc;
      }, {});
      setTabStates(updatedState);
    }
  };

  const optionClicked = (key) => {
    const selectedOptions = tabStates[activeTab]?.selectedOptions?.map((option) => ({ ...option, selected: option.key === key ? !option.selected : option?.selected }));
    const filteredOptions = tabStates[activeTab]?.filteredOptions?.map((option) => ({ ...option, selected: option.key === key ? !option.selected : option?.selected }));

    setTabStates((prev) => ({ ...prev, [activeTab]: { ...prev[activeTab], selectedOptions, filteredOptions } }));
    tabStates[activeTab]?.onChange(selectedOptions);
  };

  useEffect(() => {
    if (tabs?.length === 0) return;
    setTabStates((prevState) => {
      const newState = {};

      tabs.forEach((item) => {
        newState[item?.name] = {
          ...item,
          selectedOptions: item?.defaultOptions || [],
          filteredOptions: item?.defaultOptions?.filter((option) => item?.searchableFields?.some((field) => option?.object[field]?.toLowerCase()?.includes(prevState[item?.name]?.keyword?.toLowerCase()))) || [],
          useSelectAll: item?.useSelectAll || false,
          keyword: prevState[item?.name]?.keyword || "", // Preserve keyword
          searchableFields: item?.searchableFields || [],
          placeholder: item?.placeholder || "Search",
          onChange: item?.onChange || (() => {}),
        };
      });

      return newState;
    });
  }, [tabs]);

  useEffect(() => {
    if (tabs?.length === 0) {
      setActiveTab("");
      return;
    }
    setActiveTab(defaultTab || tabs[0]?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs?.length, defaultTab]);

  return (
    <div
      className="relative"
      ref={ref}>
      {createP && (
        <>
          {Object.values(tabStates)
            ?.map((tab) => tab?.selectedOptions.reduce((count, option) => (option.selected ? count + 1 : count), 0))
            ?.reduce((acc, count) => count + acc, 0) > 0 && (
            <MultiRowSelectedCountBubble
              count={Object.values(tabStates)
                ?.map((tab) => tab?.selectedOptions.reduce((count, option) => (option.selected ? count + 1 : count), 0))
                ?.reduce((acc, count) => count + acc, 0)}
              clear={() => setAllOptionsClickValues(false, "all")}
            />
          )}
          <div
            onClick={() => setPanelOpen(true)}
            ref={setTargetElement}>
            {customSelector ? (
              customSelector
            ) : (
              <div className={classNames("relative cursor-pointer rounded text-gray-400 transition-all duration-100 hover:bg-gray-200/30", panelOpen ? "bg-gray-200/30" : "bg-gray-200/0")}>
                <FunnelIcon className="py-.5 relative z-20 h-6 w-6 px-[2px]" />
              </div>
            )}
          </div>
          <Transition
            as={Fragment}
            show={panelOpen}
            className="z-50"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => {
              setPopperElement(null);
            }}
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}>
            <div className={classNames("z-30 max-h-64 min-w-64 overflow-auto rounded-md bg-white shadow-lg")}>
              <div className="flex">
                {tabs.map((tab) => (
                  <Button
                    version="default"
                    key={tab.name}
                    className={classNames("border-b-2 px-4 py-2 transition-all", activeTab === tab.name ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-500")}
                    onClick={() => setActiveTab(tab.name)}>
                    {tab.name}
                  </Button>
                ))}
              </div>

              {tabStates[activeTab]?.searchableFields.length > 0 && (
                <div className="sticky left-0 top-0 z-20 mb-2 flex w-full bg-white p-1.5">
                  <Search
                    placeholder={tabStates[activeTab]?.placeholder}
                    keyword={tabStates[activeTab]?.keyword}
                    setKeyword={handleSearch}
                    inline={true}
                  />
                </div>
              )}
              {tabStates[activeTab]?.useSelectAll && !tabStates[activeTab]?.keyword && (
                <div
                  key="SELECT_ALL"
                  className="group flex w-full cursor-pointer items-center gap-x-3 border-white bg-gray-50 px-4 py-2 font-normal text-gray-400 transition-all duration-100 hover:bg-gray-100 hover:text-gray-600"
                  onClick={handleSelectAll}>
                  <input
                    type="checkbox"
                    checked={
                      tabStates[activeTab]?.selectedOptions?.filter((option) => {
                        return option.selected === true;
                      }).length === tabStates[activeTab]?.selectedOptions?.length
                    }
                    className="cursor-pointer rounded border-gray-400 bg-gray-50 text-highlightColor focus:ring-highlightColor group-hover:border-gray-500"
                    readOnly
                  />
                  Select All
                </div>
              )}
              {tabStates[activeTab]?.filteredOptions?.map((option) => {
                return (
                  <div
                    key={option.key}
                    className="flex w-full cursor-pointer items-center gap-x-3 border-white px-4 py-2 font-normal text-gray-800 transition-all duration-100 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => optionClicked(option.key)}>
                    <input
                      type="checkbox"
                      checked={option.selected}
                      className="cursor-pointer rounded text-highlightColor focus:ring-highlightColor"
                      readOnly
                    />
                    {itemComponent ? itemComponent(option.object) : option.value}
                  </div>
                );
              })}
            </div>
          </Transition>
        </>
      )}
    </div>
  );
};

export default MultiSelectObjectFilterWithTabs;
