import React, { useEffect, useState } from "react";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import { PencilIcon } from "@heroicons/react/24/outline";
import Input from "src/components/Shared/Forms/Inputs/Input";

const MetabasePage = ({ newPage = {}, setNewPage = () => {}, startIndex = -1, pageCredentialOptions = {}, platformEmbeds = [], setStartIndex }) => {
  const [credMenuDisabled, setCredMenuDisabled] = useState(true);
  const [embedTypes, setEmbedTypes] = useState([{ key: "Dashboard", value: "EMBED_PAGE" }]);

  useEffect(() => {
    setEmbedTypes((prevData) => {
      if (platformEmbeds?.length > 0) {
        const newEntry = { key: "Platform", value: "EMBED_PLATFORM" };
        const isDuplicate = prevData.find((entry) => entry.value === newEntry.value);
        if (!isDuplicate) {
          return [...prevData, newEntry];
        }
        return prevData;
      } else {
        return prevData.filter((data) => data?.value !== "EMBED_PLATFORM");
      }
    });
  }, [platformEmbeds]);

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, pageCredentialOptions]);

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value === newPage?.credential_id) {
      setCredMenuDisabled(true);
    }
  }, [newPage?._id, pageCredentialOptions, newPage?.credential_id]);

  return (
    <div>
      <div className="grid gap-y-4">
        <div className="flex w-full flex-col gap-x-3 gap-y-3 sm:flex-row">
          <div className="w-1/3 flex-none">
            {/* Embed Type */}
            <SelectMenu
              label="Embed Type"
              inline={true}
              startIndex={embedTypes.findIndex((item) => item.value === newPage.embed_type)}
              options={embedTypes}
              setOption={(option) => {
                setNewPage((pre) => ({
                  ...pre,
                  embed_type: option.value,
                }));
              }}
            />
          </div>
          <div className="flex-grow">
            {/* Credentials */}
            {newPage?.embed_type !== "EMBED_PLATFORM" ? (
              <div className="relative">
                <SelectMenu
                  disabled={(!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled) || (newPage?._id && pageCredentialOptions.length === 0 && newPage?.credential_name)}
                  label="Metabase credentials"
                  inline={true}
                  emptyListText={newPage?.credential_name || "No listed items"}
                  defaultText={newPage?.credential_name || "Select an option"}
                  startIndex={startIndex}
                  options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
                  setOption={(option) => {
                    setStartIndex(pageCredentialOptions?.findIndex((item) => item?.value === option.value));
                    setNewPage((pre) => ({ ...pre, credential_id: option.value }));
                  }}
                />
                {!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled && (
                  <div className="absolute bottom-0.5 right-0.5 z-30 flex aspect-square w-9 items-center justify-center rounded-md bg-white">
                    <Button
                      version="default"
                      className="!h-6"
                      onClick={() => {
                        setCredMenuDisabled(false);
                        setStartIndex(-1);
                      }}>
                      <PencilIcon className="h-4 w-4 text-gray-400" />
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative">
                <SelectMenu
                  label="Platform Embeds"
                  inline={true}
                  startIndex={platformEmbeds.findIndex((item) => item._id === newPage.platform_embed)}
                  options={platformEmbeds?.map((item) => ({ key: item?.name, value: item?._id })) || [{ key: "Select platform", value: -1 }]}
                  setOption={(option) => {
                    setNewPage((pre) => ({ ...pre, platform_embed: option.value }));
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {newPage?.embed_type !== "EMBED_PLATFORM" && (
          <div className="w-full">
            <Input
              inline={true}
              label="Dashboard ID"
              type="text"
              value={newPage.dashboard_id}
              onChange={(e) => setNewPage((pre) => ({ ...pre, dashboard_id: e.target.value }))}
            />
          </div>
        )}
      </div>
      {newPage?.embed_type !== "EMBED_PLATFORM" && (
        <TextAccordion headerText="Advanced features">
          <div className="flex w-full flex-wrap">
            <div className="mb-4 w-full space-y-4 px-2">
              <div className="border-b border-gray-200 py-2 text-sm font-medium capitalize text-gray-700">Style</div>

              <div className="flex items-center gap-4">
                <div className="flex items-center gap-3">
                  <input
                    id="bordered"
                    checked={newPage.bordered}
                    onChange={(e) => {
                      setNewPage((pre) => ({
                        ...pre,
                        bordered: e.target.checked,
                      }));
                    }}
                    name="bordered"
                    type="checkbox"
                    className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                  />
                  <div className="text-sm font-medium text-gray-700">Border</div>
                </div>
                <div className="flex items-center gap-3">
                  <input
                    id="titled"
                    checked={newPage.titled}
                    onChange={(e) => {
                      setNewPage((pre) => ({
                        ...pre,
                        titled: e.target.checked,
                      }));
                    }}
                    name="titled"
                    type="checkbox"
                    className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                  />
                  <div className="text-sm font-medium text-gray-700">Title</div>
                </div>
              </div>
            </div>
            <div className="mb-4 w-full space-y-4 px-2">
              <div className="border-b border-gray-200 py-2 text-sm font-medium capitalize text-gray-700">Appearance</div>
              <div className="flex items-center gap-4">
                <div className="relative flex items-center gap-3">
                  <input
                    id="light"
                    value={""}
                    checked={newPage.theme === ""}
                    onChange={(e) => {
                      setNewPage((pre) => ({
                        ...pre,
                        theme: e.target.value,
                      }));
                    }}
                    name="theme"
                    type="radio"
                    className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                  />
                  <div className="text-sm font-medium text-gray-700">Light</div>
                </div>
                <div className="relative flex items-center gap-3">
                  <input
                    id="night"
                    value={"night"}
                    checked={newPage.theme === "night"}
                    onChange={(e) => {
                      setNewPage((pre) => ({
                        ...pre,
                        theme: e.target.value,
                      }));
                    }}
                    name="theme"
                    type="radio"
                    className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                  />
                  <div className="text-sm font-medium text-gray-700">Dark</div>
                </div>
                <div className="relative flex items-center gap-3">
                  <input
                    id="transparent"
                    value={"transparent"}
                    checked={newPage.theme === "transparent"}
                    onChange={(e) => {
                      setNewPage((pre) => ({
                        ...pre,
                        theme: e.target.value,
                      }));
                    }}
                    name="theme"
                    type="radio"
                    className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                  />
                  <div className="text-sm font-medium text-gray-700">Transparent</div>
                </div>
              </div>
            </div>
          </div>
        </TextAccordion>
      )}
    </div>
  );
};

export default MetabasePage;
