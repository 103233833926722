import React, { useCallback, useEffect, useState } from "react";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import { H3 } from "src/components/Shared/Text/Headers";
import PaymentMethodEntry from "./PaymentMethodEntry";
import { apiRequest } from "src/async/apiUtils";
import PaginationFooter from "src/components/Shared/PaginationFooter";

const PaymentMethod = ({ workspaceId, ...props }) => {
  // const [paymentLoaded, setPaymentLoaded] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);

  const getPaymentMethodList = useCallback(async () => {
    try {
      setPaymentMethodLoaded(false);
      setPaymentMethods([]);
      const res = await apiRequest("get", `/workspaces/:workspace_id/billing/payment-methods`, {
        queries: { limit: limit, offset: offset * limit },
        params: { workspace_id: workspaceId },
      });

      if (res.data.status === 200) {
        setPaymentMethods(res.data.data || []);
      } else {
        setPaymentMethods([]);
      }
      setPaymentMethodLoaded(true);
    } catch (error) {
    } finally {
      setPaymentMethodLoaded(true);
    }
  }, [limit, offset, workspaceId]);

  useEffect(() => {
    getPaymentMethodList();
  }, [getPaymentMethodList]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  return (
    <div className="relative space-y-6 py-6">
      <H3 className="!text-lg font-semibold">Payment Method</H3>
      <div className="relative">
        <Table
          tableHeader={
            <TRHeader>
              <th
                scope="col"
                className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
                Type
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Particulars
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"></th>
            </TRHeader>
          }
          colSpan="5"
          loaded={paymentMethodLoaded}
          dataExists={paymentMethods?.length > 0}>
          {paymentMethods?.map((item, index) => (
            <PaymentMethodEntry
              key={index}
              data={item}
            />
          ))}
        </Table>
        <PaginationFooter
          itemName="Payment Method record"
          limit={limit}
          offset={offset}
          count={paymentMethods?.length}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default PaymentMethod;
