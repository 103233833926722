import { useEffect, useState } from "react";
import Select from "src/components/Shared/Forms/Selects/Select";
import { validateHex } from "src/helpers";
import { classNames } from "src/helpers/classNames";

const SiteColorItem = ({ color = "", type = "color", value = "", onChange, name, generalInputDisabled = false, dropdownDisabled = false }) => {
  const identifier = !value ? "px" : value.includes("px") ? "px" : "%";

  const [isValidCode, setIsValidCode] = useState(true);

  useEffect(() => {
    setIsValidCode(validateHex(color));
  }, [color]);

  return (
    <div className="relative py-0">
      <div className="mb-1 text-xs font-semibold uppercase leading-none text-black">{name}</div>
      <div className="flex items-center text-gray-800">
        {type === "color" ? (
          <>
            <div className="h-6 w-8">
              <input
                className={"uppercase"}
                id={`color-picker-${name}`}
                type="color"
                value={color}
                onChange={(e) => onChange("#" + e.target.value.split("#")[1]?.toUpperCase())}
              />
            </div>
            <div className="relative">
              <div className="absolute left-1 top-1 sm:top-2">#</div>
              <input
                value={color ? color.split("#")[1]?.toUpperCase() ?? "" : ""}
                className={classNames("h-8 w-full overflow-x-hidden rounded-md bg-transparent focus:ring-0 sm:h-10", !isValidCode ? "!border-red-500" : "border-transparent")}
                onChange={(e) => {
                  setIsValidCode(validateHex("#" + e.target.value?.toUpperCase()));
                  onChange("#" + e.target.value?.toUpperCase());
                }}
              />
            </div>
          </>
        ) : (
          <div className="flex items-center space-x-1">
            <input
              disabled={generalInputDisabled}
              value={value?.replace(/%|px/g, "") || ""}
              className="h-8 w-full overflow-x-hidden rounded-md !border border-gray-300 bg-transparent px-1 py-1 text-sm focus:border-none sm:h-10"
              onChange={(e) => {
                if (e.target.value && /^[0-9]+$/.test(e.target.value)) {
                  onChange(e.target.value + identifier);
                } else if (!e.target.value) {
                  onChange(e.target.value + identifier);
                }
              }}
            />
            <Select
              buttonClasses={"!h-10"}
              disabled={dropdownDisabled}
              selected={{ _id: identifier, name: identifier }}
              options={[
                { _id: "px", name: "px" },
                { _id: "%", name: "%" },
              ]}
              setSelected={(val) => {
                if (!value || ["%", "px"].includes(value.trim())) {
                  onChange(val._id);
                } else {
                  onChange(value.replace(/(\d+)(%|px)/, `$1${val._id}`));
                }
              }}
            />
            {/* <div className="text-[8px] text-black leading-tight">Use percentage or pixel (i.e. "%" or "px")</div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteColorItem;
