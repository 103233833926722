import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import Image from "src/components/Shared/Image";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

// EXAMPLE OPTION:
// const option = {
//   key: 1,
//   value: "text",
//   selected: false,
//   object: {
//     _id: 'ObjectId("HDJNC14HDB792")',
//     name: "John Snow",
//     age: 26
//   },
// };

const MultiSelectObject = ({
  defaultOptions = [],
  searchable = true,
  searchableFields = [],
  searchComponent = null,
  bubbleComponent = null,
  groupedByField = "",
  placeholder = "Search",
  icon = { image: MagnifyingGlassIcon, name: "MagnifyingGlassIcon" },
  title,
  inline = false,
  disabled = false,
  error = false,
  onChange = () => {},
  uniqueIdentifier = null,
  drowdownWindowMaxWidth = "",
  strictOnChangeMode = true,
  dropdownButtonClass = "",
  isSelectAll = true,
  selectAllText = "Select All",
  emptyListText = "No listed Items",
  showSelectedItems = true,
  showSelectedItemsMaxCount = 5,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);
  const [showAllSelectedItems, setShowAllSelectedItems] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setPanelOpen(false));

  useEffect(() => {
    if (selectedOptions.length === 0 || defaultOptions.length === 0 || !strictOnChangeMode) {
      setSelectedOptions(defaultOptions || []);
      setFilteredOptions(defaultOptions || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions, strictOnChangeMode]);

  useEffect(() => {
    if (searchable && searchableFields) {
      let updatedFilteredOptions = selectedOptions.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, selectedOptions, searchable, searchableFields]);

  const findObjectValue = (object) => {
    let keys = groupedByField.split(".");
    return keys.reduce((value, key) => {
      return value[key];
    }, object);
  };

  const optionClicked = (key, option) => {
    let optionUniqueId = null;

    if (uniqueIdentifier) {
      let keys = uniqueIdentifier.split(".");
      optionUniqueId = keys.reduce((value, key) => {
        return value.object ? value.object[key] : value[key];
      }, option);
    }

    let updatedOptions = selectedOptions.map((option) => {
      if (option.key === key) {
        if (uniqueIdentifier) {
          let keys = uniqueIdentifier.split(".");
          let uniqueId = keys.reduce((value, key) => {
            return value.object ? value.object[key] : value[key];
          }, option);

          if (option.selected) option.selected = false;
          else if (!option.selected && uniqueId === optionUniqueId) option.selected = true;
          else option.selected = false;
        } else {
          if (option.selected) option.selected = false;
          else option.selected = true;
        }
      }
      return option;
    });

    setSelectedOptions(updatedOptions || []);

    if (!uniqueIdentifier) {
      onChange(updatedOptions);
    } else {
      onChange(updatedOptions, option?.object || {});
    }
  };

  const selectAllOptionClicked = () => {
    let updatedOptions = selectedOptions?.map((option) => ({ ...option, selected: filteredOptions?.filter((item) => !item?.disabled)?.find((item) => item?.key === option?.key) && !checkedSelectAll ? true : false }));
    setSelectedOptions(updatedOptions || []);
    onChange(updatedOptions);
  };

  useEffect(() => {
    const updatefilteredOptions = filteredOptions?.filter((item) => !item?.disabled);
    setCheckedSelectAll(updatefilteredOptions?.length === updatefilteredOptions?.filter((item) => item?.selected)?.length ? true : false);
  }, [filteredOptions]);

  return (
    <div className="relative">
      {title && !inline && (
        <div className={classNames("mb-1 flex justify-between text-sm font-medium text-gray-700", error ? "text-red-600" : "")}>
          {title} <div className="pl-2">{error ? "*Required" : ""}</div>
        </div>
      )}
      <div
        className="relative"
        ref={ref}>
        <div className="relative">
          <div
            className={classNames(
              inline ? "relative h-[47px] w-full cursor-pointer rounded-md border border-gray-300 bg-white px-0 pt-2  text-left hover:bg-gray-50 focus:outline-none sm:text-sm md:h-[40px] md:pt-1 2xl:h-[47px] 2xl:pt-2" : "relative flex w-full rounded-md",
              panelOpen ? "border-highlightColor" : "",
              dropdownButtonClass,
            )}
            onClick={() => setPanelOpen(true)}>
            {inline && <div className="m-0 flex justify-between pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3">{title}</div>}
            <div className={classNames(inline ? "flex h-8 w-full items-center  rounded-none border-none px-3 py-0 leading-none shadow-none focus:ring-0 sm:text-sm md:h-7 md:px-2 2xl:h-8 2xl:px-3" : "w-full")}>
              <div
                className={classNames("pointer-events-none absolute  left-0 flex items-center pl-3", inline ? "bottom-2" : "inset-y-0")}
                aria-hidden="true">
                <icon.image
                  className="mr-3 h-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                disabled={disabled}
                autoComplete="off"
                name="search"
                id="multi-search"
                value={keyword}
                className={classNames(
                  disabled ? "cursor-not-allowed bg-gray-50/50 text-gray-400" : "",
                  inline
                    ? "block h-8 w-full rounded-none border-none bg-transparent px-3 py-0 !pl-6 leading-none shadow-none focus:ring-0 sm:text-sm md:h-7 md:px-2 2xl:h-8 2xl:px-3"
                    : "block h-[47px] w-full rounded-md border-gray-300 pl-9 text-sm focus:border-highlightColor focus:ring-0 md:h-[40px] 2xl:h-[47px]",
                )}
                placeholder={placeholder}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
          </div>
          <div className={`absolute left-0 top-full z-50 w-full ${drowdownWindowMaxWidth} flex justify-between`}>
            <div className={classNames("absolute z-30 max-h-56 min-w-full overflow-auto rounded-md bg-white shadow", panelOpen ? "flex flex-col" : "hidden")}>
              {filteredOptions?.length > 0 && isSelectAll && (
                <div>
                  <div
                    className="flex w-full cursor-pointer items-center gap-x-3 truncate border-white px-4 py-2 hover:bg-gray-100"
                    onClick={selectAllOptionClicked}>
                    <input
                      type="checkbox"
                      checked={checkedSelectAll}
                      className="cursor-pointer rounded text-highlightColor focus:ring-highlightColor"
                      readOnly
                    />
                    {selectAllText}
                  </div>
                </div>
              )}
              {filteredOptions.length === 0 ? (
                <div
                  key="no-option"
                  value={0}
                  className="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-400 hover:bg-gray-50">
                  {emptyListText}
                </div>
              ) : (
                filteredOptions.map((option, i, arr) => {
                  return (
                    <div key={option.key + option.value + option.secondaryValue}>
                      {groupedByField && (i === 0 || findObjectValue(arr[i - 1].object) !== findObjectValue(option.object)) && <p className="bg-gray-200/10 p-2 px-3 text-gray-500">{findObjectValue(option.object)}</p>}
                      <div
                        key={option.key}
                        className={classNames("flex w-full items-center gap-x-3 truncate border-white px-4 py-2 hover:bg-gray-100", option.disabled ? "cursor-text opacity-50" : "cursor-pointer")}
                        onClick={() => (option.disabled ? {} : optionClicked(option.key, option))}>
                        <input
                          type="checkbox"
                          checked={option.selected}
                          disabled={option.disabled || false}
                          className={classNames("rounded text-highlightColor focus:ring-highlightColor", option.disabled ? "cursor-text" : "cursor-pointer")}
                          readOnly
                        />
                        {searchComponent ? searchComponent(option.object) : option.value}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      {showSelectedItems && selectedOptions.filter((selectedOption) => selectedOption.selected).length > 0 && (
        <div className="mt-3 flex max-h-[200px] flex-wrap gap-2 overflow-auto">
          {selectedOptions.length > 0 ? (
            <>
              {[...new Map(selectedOptions.map((item) => [item["key"], item])).values()]
                .filter((selectedOption) => selectedOption.selected)
                .slice(0, showAllSelectedItems ? selectedOptions.length : showSelectedItemsMaxCount)
                .map((selectedOption) => (
                  <div
                    className="flex items-center rounded-full border border-highlightColor py-[2px] pl-[8px] pr-[4px] text-highlightColor"
                    key={selectedOption.key + "selected"}>
                    {selectedOption?.image && (
                      <div className="mx-1 h-5 w-5 overflow-hidden rounded bg-white/10">
                        <Image
                          image={selectedOption?.image}
                          isDate={false}
                        />
                      </div>
                    )}
                    {bubbleComponent ? bubbleComponent(selectedOption.object) : <p className="max-h-9 pb-[1px] text-center">{selectedOption.value}</p>}
                    <div
                      onClick={() => optionClicked(selectedOption.key, selectedOption)}
                      className="ml-1 cursor-pointer rounded-full p-1 hover:bg-gray-100">
                      <XMarkIcon className="h-4 w-4 text-highlightColor" />
                    </div>
                  </div>
                ))}

              {selectedOptions.filter((option) => option.selected).length > showSelectedItemsMaxCount && (
                <button
                  onClick={() => setShowAllSelectedItems(!showAllSelectedItems)}
                  className="ml-3 flex h-7 items-center justify-center text-highlightColor">
                  <span className="underline opacity-70 hover:opacity-100">{showAllSelectedItems ? "Show Less" : "Show more"}</span>
                  <span className="pl-3">

                  {showAllSelectedItems ? "" : `+${selectedOptions.filter((option) => option.selected).length - showSelectedItemsMaxCount} others`}
                  </span>
                </button>
              )}
            </>
          ) : (
            <div className="flex h-6 items-center">None selected</div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectObject;
