import React from "react";
import { formatCurrency } from "src/helpers";
import { classNames } from "src/helpers/classNames";

const BillingPlanItems = ({ data = null }) => {
  return (
    <div className="relative flex w-full items-center gap-x-2">
      <div className="w-full space-y-1">
        <div className="flex items-center justify-between space-x-3">
          <p className="truncate font-semibold text-gray-700">{data?.name}</p>
        </div>
        <div className="flex items-center justify-between space-x-3">
          <div className="flex space-x-3">
            {data?.pricing?.type === "no_charge" ? (
              <div className="truncate text-xs text-gray-600">Free Account - {formatCurrency(0)}</div>
            ) : (
              data?.pricing?.rates?.map((price) => (
                <p
                  key={price?._id}
                  className="truncate text-xs text-gray-600">
                  {formatCurrency(price?.amount - price?.discount_amount, data?.currency)}/{price?.interval}
                </p>
              ))
            )}
          </div>
          <div className="flex space-x-2">
            {data?.default && (
              <p className="flex-shrink-0 truncate text-xs">
                <span className={classNames("inline-block rounded-full  border-blue-400 bg-blue-100 px-2 py-1 !leading-none text-blue-600")}>{"Default"}</span>
              </p>
            )}
            <p className="flex-shrink-0 truncate text-xs">
              <span className={classNames("inline-block rounded-full  px-2 py-1 !leading-none ", data?.status ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600")}>{data?.status ? "Active" : "Inactive"}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPlanItems;
