import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useEffect } from "react";

const WorkspaceEmailProvider = ({ ...props }) => {
  const [useWorkspaceSmtp, setUseWorkspaceSmtp] = useState(false);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: props?.workspaceDetails?._id, ...updateSettings }, "/workspaces/:workspace_id/settings/email-provider");
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setUseWorkspaceSmtp(props.workspaceDetails?.use_workspace_smtp);
  }, [props.workspaceDetails]);

  return (
    <EditContainer
      title="Email Provider"
      subtitle="Connect an existing SMTP server"
      preview={{
        style: "success",
        isSuccess: useWorkspaceSmtp,
        text: useWorkspaceSmtp ? (
          <>
            <CheckIcon className="h-5 w-5" /> Enabled
          </>
        ) : (
          <>
            <NoSymbolIcon className="h-5 w-5" /> Disabled
          </>
        ),
      }}
      onSuccess={() => handleUpdate({ use_workspace_smtp: useWorkspaceSmtp })}
      onCancel={() => setUseWorkspaceSmtp(props.workspaceDetails?.use_workspace_smtp)}>
      <div className="min-w-[260px]">
        <ToggleHeader
          title="Use workspace-level email provider"
          subtitle="Does your client want to use their own SMTP? Or do you have a different email address you'd like users in this workspace to recieve emails from? This will override default SMTP settings."
          position="left">
          <Toggle
            checked={useWorkspaceSmtp}
            onChange={() => {
              setUseWorkspaceSmtp(!useWorkspaceSmtp);
            }}
          />
        </ToggleHeader>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceEmailProvider);
