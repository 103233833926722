import React, { useState } from "react";
import Modal from "../Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import SelectMenu from "../Shared/Forms/Selects/SelectMenu";

const BillingPlanDeleteModal = ({ isOpen, selectedItem, refreshData, onCancel, ...props }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedAssignPlan, setSelectedAssignPlan] = useState(null);

  const deletePlan = async () => {
    try {
      setIsDeleting(true);
      const queries = { assign_plan_id: selectedAssignPlan };
      const params = { plan_id: selectedItem?._id };
      const { data } = await apiRequest("delete", `/plans/:plan_id`, { params, queries });
      toast.success(data.message);
      onCancel();
      refreshData();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      size="lg"
      title="Plan"
      secondaryTitle="Delete"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={deletePlan}
      isLoading={isDeleting}
      defaultOptions={{
        onSuccessButtonText: "Delete",
      }}>
      {selectedItem?.workspaces > 0 ? (
        <>
          <div className="text-sm">
            This plan is being used by <span className="font-semibold text-highlightColor">{selectedItem?.workspaces} workspace(s) </span>, If you still want to delete you have assign a new plan to those workspace.
          </div>
          <div className="relative my-2 w-1/2">
            <SelectMenu
              label="Assign a new plan"
              inline={true}
              startIndex={-1}
              options={props?.plans?.filter?.((item) => item?._id !== selectedItem?._id)?.map((pln) => ({ key: pln?.name, value: pln?._id }))}
              setOption={(option) => {
                setSelectedAssignPlan(option.value);
              }}
            />
          </div>
        </>
      ) : (
        <>
          Are you sure you want to delete <span className="font-semibold text-highlightColor">{selectedItem?.name}</span> ?
        </>
      )}
    </Modal>
  );
};

export default BillingPlanDeleteModal;
