import React from "react";
import { classNames } from "src/helpers/classNames";

const TextArea = ({ rows = 3, label, name, disabled = false, value = "", error = false, type = "text", styles = {}, inputClassNames, labelClassNames = "", onChange = () => {}, placeholder = "", inline = false, wrapperClass }) => {
  const labelClasses = classNames(labelClassNames, "flex text-sm font-medium mb-1", !inline && disabled && "cursor-not-allowed", inline && disabled && "bg-transparent");
  const inputClasses = classNames(
    "min-h-[80px] block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor",
    error && "border-red-300",
    disabled ? "text-gray-400" : "text-gray-700",
    !inline && disabled && "cursor-not-allowed",
    inline && disabled && "bg-transparent",
  );

  return (
    <div className={classNames(inline ? "relative h-full space-y-2 overflow-hidden rounded-md border px-0 pt-2 focus-within:border-highlightColor" : "relative h-full", inline && disabled ? "border-gray-100 bg-gray-50" : "border-gray-300 shadow-sm", wrapperClass)}>
      <label
        htmlFor={name}
        className={inline ? classNames("m-0 flex pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3", labelClasses) : labelClasses}>
        {label} {error && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
      </label>
      <textarea
        style={styles}
        disabled={disabled}
        className={classNames(inputClassNames ? inputClassNames : inputClasses, type === "code" ? "font-mono" : "", inline ? "h-28 rounded-none border-none bg-transparent py-0 shadow-none focus:border-none focus:ring-0" : "")}
        placeholder={placeholder}
        rows={rows}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
