import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import GroupAssignToUserModal from "src/components/Groups/GroupAssignToUserModal";
import GroupManageSlider from "src/components/Groups/GroupManageSlider";
import Modal from "src/components/Shared/Modal";
import MultiColumnContent from "src/components/Shared/MultiColumnContent/MultiColumnContent";
import WarningModal from "src/components/Shared/WarningModal";
import WorkspaceGroupHeader from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupHeader";
import WorkspaceGroupItem from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupItem";
import WorkspaceGroupIconManageModal from "src/components/Workspaces/Workspace/WorkspaceGroupIconManageModal";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import useFetch from "src/hooks/useFetch";
import PagePreview from "src/components/Pages/PageModals/PagePreview";
import ScheduleReportModal from "src/components/Groups/ScheduleReportModal";
import WorkspaceGroupTagManageModal from "src/components/Workspaces/Workspace/WorkspaceGroupTagManageModal";
import { useSearchParams } from "react-router-dom";
import Image from "src/components/Shared/Image";
import UserProfile from "src/components/Users/UserProfile";

const WorkspaceGroupList = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", workspaceDetails = {}, ...props }) => {
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const [searchParams] = useSearchParams();
  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupModalOpen, setGroupModalOpen] = useState(false);

  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [publicGroup, setPublicGroup] = useState(null);
  const [cloneGroup, setCloneGroup] = useState(null);
  const [manageTagGroup, setManageTagGroup] = useState(null);
  const [addIconGroup, setAddIconGroup] = useState(null);
  const [isDeleteGroupLoading, setIsDeleteGroupLoading] = useState(null);
  const [isPublicGroupLoading, setIsPublicGroupLoading] = useState(null);
  const [isCloneGroupLoading, setIsCloneGroupLoading] = useState(null);
  const [isAddIconGroupLoading, setIsAddIconGroupLoading] = useState(null);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [defaultSelectedGroup, setDefaultSelectedGroup] = useState([]);

  const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successModalStart, setSuccessModalStart] = useState(false);
  const [groupAssignToUserModalOpen, setGroupAssignToUserModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupChanges, setGroupChanges] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [listToggle, setListToggle] = useState(false);

  // Page preview
  const [prevModalOpen, setPrevModalOpen] = useState(false);
  const [prevPageDetails, setPrevPageDetails] = useState(null);

  // Schedule reports
  const [scheduleReports, setScheduleReports] = useState(null);

  // Flatten Pages
  const [flattenPages, setFlattenPages] = useState(null);
  const [isFlattenPagesLoading, setIsFlattenPagesLoading] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const {
    response: { data: pageOptions },
  } = useFetch(`/workspaces/:workspace_id/pages/editor`, { query: { sort: "ASC", page_type: ["DOMO", "QUICKSIGHT", "METABASE", "CUSTOM_HTML", "EXTERNAL_LINK", "FORM"] }, params: { workspace_id: workspaceId } });

  const {
    response: { data: operatorOptions },
  } = useFetch("/operators/list");

  const {
    response: { data: defaultGroups },
  } = useFetch(!workspaceId ? `/workspaces/:workspace_id/groups/details` : "", { params: { workspace_id: workspaceId } });

  const {
    response: { data: workspaceUsers },
    refreshData: refreshWorkspaceUsers,
  } = useFetch("/workspaces/:workspace_id/users", { params: { workspace_id: workspaceId }, query: { authToken: workspaceDetails?.auth_token, active_status: true } });

  // const {
  //   response: { data: groups = [] },
  //   refreshData: refreshGroups,
  // } = useFetch(
  //   pageName === "user" ? "/workspaces/:workspace_id/users/:user_id/groups" : `/workspaces/:workspace_id/domo-groups?auth_token=${workspaceDetails?.auth_token}&userFetch=GET`,
  //   pageName === "user" ? { params: { workspace_id: workspaceId } } : { params: { authToken: workspaceDetails?.auth_token, active_status: true } }
  // );

  useEffect(() => {
    // refreshGroups();
    setGroups(defaultGroups);
  }, [defaultGroups]);

  const loadGroupsData = async (users = []) => {
    try {
      setLoaded(false);
      let workspaceGroupsData = {};
      if (pageName === "user") {
        workspaceGroupsData = await apiRequest("GET", `/workspaces/:workspace_id/users/:user_id/groups`, {
          queries: { keyword },
          params: { workspace_id: workspaceId, user_id: userId },
        });
      } else {
        workspaceGroupsData = await apiRequest("GET", `/workspaces/:workspace_id/domo-groups`, {
          queries: { keyword, userFetch: "GET" },
          params: { workspace_id: workspaceId },
        });
      }
      workspaceGroupsData = workspaceGroupsData?.data;
      if (isMounted.current && workspaceGroupsData?.status === 200) {
        // refreshGroups();
        setGroups(workspaceGroupsData?.data || []);
        setLoaded(true);

        if (selectedGroup) {
          setSelectedGroup((group) => workspaceGroupsData?.data?.find((item) => item._id === group._id) || null);
        }

        return workspaceGroupsData?.data;
      } else {
        if (isMounted.current) setLoaded(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadGroupsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (workspaceDetails?._id) {
      loadGroupsData();
      refreshWorkspaceUsers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id]);

  useEffect(() => {
    if (workspaceDetails?._id && workspaceUsers?.length > 0 && groups?.length > 0) {
      setGroups(
        groups.map((group) => ({
          ...group,
          users: !Array.isArray(group.userIds) ? group.users : workspaceUsers.filter((user) => group.userIds.includes(user.id)),
        })),
      );
      // refreshGroups();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id, workspaceUsers?.length, groups?.length]);

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (isMounted.current && resData.status === 200 && resData.data) {
          setCustomFields(resData?.data || []);
        }
      } catch (err) {
        // console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const handleDeleteGroup = async () => {
    try {
      setIsDeleteGroupLoading(true);

      await apiRequest("delete", `/workspaces/:workspace_id/groups/:group_id`, {
        params: { workspace_id: workspaceId, group_id: deleteGroup._id },
      });
      setDeleteGroup(null);
      let tempGroups = await loadGroupsData();
      if (deleteGroup._id === selectedGroup._id) {
        if (tempGroups[0]) {
          setSelectedGroup(tempGroups[0]);
        } else {
          setSelectedGroup({});
        }
      }
      setSuccessModalStart(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteGroupLoading(false);
    }
  };

  const handlePublicGroup = async () => {
    try {
      setIsPublicGroupLoading(true);
      await apiRequest("get", `/workspaces/:workspace_id/groups/:group_id/public`, {
        params: { workspace_id: workspaceId, group_id: publicGroup._id || publicGroup.id },
      });
      setPublicGroup(null);
      loadGroupsData();
      setIsPublicGroupLoading(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsPublicGroupLoading(false);
    }
  };

  const handleCloneGroup = async () => {
    try {
      setIsCloneGroupLoading(true);
      await apiRequest("get", `/workspaces/:workspace_id/groups/:group_id/clone`, {
        params: { workspace_id: workspaceId, group_id: cloneGroup._id || cloneGroup.id },
      });
      setCloneGroup(null);
      loadGroupsData();
      setIsCloneGroupLoading(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsCloneGroupLoading(false);
    }
  };

  const handleFlattenPages = async () => {
    try {
      setIsFlattenPagesLoading(true);
      await apiRequest("get", `/workspaces/:workspace_id/groups/:group_id/flatten-pages`, {
        params: { workspace_id: workspaceId, group_id: flattenPages._id || flattenPages.id },
      });
      setFlattenPages(null);
      loadGroupsData();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsFlattenPagesLoading(false);
    }
  };

  const handleAddIconGroup = async (body) => {
    try {
      setIsAddIconGroupLoading(true);
      const { status, data } = await apiRequest("put", `/workspaces/:workspace_id/groups/:group_id/icon`, { body, params: { workspace_id: workspaceId, group_id: addIconGroup._id || addIconGroup.id } });
      if (status === 200) {
        setAddIconGroup(null);
        loadGroupsData();
      } else {
        toast.success(data.message);
      }
      setIsAddIconGroupLoading(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsAddIconGroupLoading(false);
    }
  };

  const handleSetGroups = async (groups) => {
    setGroups(groups);
    // refreshGroups();
  };

  const userModalOpen = (active, group) => {
    setDefaultSelectedGroup(group);
    setAddUserModalOpen(active);
  };

  const handleGroupUserChanges = async (users, importStatus, fromImportModal) => {
    try {
      if (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && !fromImportModal) {
        if (workspaceUsers.filter((user) => users.includes(user.id || user._id) && !user.portalUser).length) {
          setAddUserModalOpen(false);
          setGroupImportUserModalOpen(true);
          return;
        }
      }

      setSuccessModalStart(true);

      const { data } = await apiRequest("post", `/workspaces/:workspace_id/groups/users/manage`, {
        body: {
          _id: defaultSelectedGroup._id || defaultSelectedGroup.id,
          user_id: users,
          auth_token: workspaceDetails?.auth_token,
          importStatus,
          password,
          confirmPassword,
          sendWelcomeEmail,
          workspace_id: workspaceDetails?._id,
        },
        params: {
          workspace_id: workspaceDetails?._id,
        },
      });
      loadGroupsData();
      setAddUserModalOpen(false);
      setGroupImportUserModalOpen(false);
      setSuccessModalStart(false);

      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    }
  };

  const updateGroupOrdering = async (newGroups) => {
    try {
      await apiRequest("post", `/workspaces/:workspace_id/groups/ordering/manage`, {
        body: {
          groups: newGroups.map((grp) => grp._id),
          workspace_id: workspaceDetails?._id,
        },
        params: {
          workspace_id: workspaceDetails?._id,
        },
      });
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const pagePreview = async (page) => {
    setPrevPageDetails({ page, body: { user_id: pageName === "user" ? userId : props?.me?._id, page_id: page?.page_id, group_permission_id: page?._id, workspace_id: workspaceId } });
    setPrevModalOpen(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    const newGroups = reorder(groups, result.source.index, result.destination.index);
    setGroups(newGroups);
    // refreshGroups();
    updateGroupOrdering(newGroups);
  };

  useEffect(() => {
    if (!selectedGroup && groups?.length > 0) {
      setSelectedGroup(groups[0]);
    }
    if (groups?.length === 0) {
      setSelectedGroup(null);
    } else {
      if (selectedGroup && !groups?.map((group) => group?._id).includes(selectedGroup?._id) && groups?.length > 0) {
        setSelectedGroup(groups[0]);
      }
    }
  }, [groups, groups?.length, selectedGroup]);

  useEffect(() => {
    const isOpen = searchParams.get("openGroupManageModal");
    if (isOpen) {
      setGroupModalOpen(true);
      setAddGroupStatus(true);
      setEditGroupStatus(false);
      setEditId(null);
    }
  }, [searchParams]);

  return (
    <>
      <MultiColumnContent
        headerContentConfig={{
          headerEnabled: true,
          searchEnabled: true,
          keyword: keyword,
          onKeywordChange: (value) => setKeyword(value),
          addButtonEnabled: pageName === "user" || (!viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])),
          addButtonText: `${pageName === "user" ? "Assign" : "Add"} group`,
          onAddButtonClick: () => {
            if (pageName === "user") {
              setGroupAssignToUserModalOpen(true);
            } else {
              setGroupModalOpen(true);
              setAddGroupStatus(true);
              setEditGroupStatus(false);
              setEditId(null);
            }
          },
        }}
        listContentConfig={{
          listDraggableEnabled: !viewOnly,
          onDragEnd: onDragEnd,
          listLoaded: loaded,
          listData: groups,
          listComponent: (props) => (
            <WorkspaceGroupHeader
              {...props}
              userId={userId}
              workspaceId={workspaceId}
              viewOnly={viewOnly}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              groupChanges={groupChanges}
              setGroupChanges={setGroupChanges}
              workspaceDetails={workspaceDetails}
              setListToggle={setListToggle}
            />
          ),
        }}
        selectedContentConfig={{
          selectedData: selectedGroup,
          selectedComponent: () => (
            <WorkspaceGroupItem
              viewOnly={viewOnly}
              workspaceId={workspaceId}
              defaultGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              groupChanges={groupChanges}
              setGroupChanges={setGroupChanges}
              refreshGroups={loadGroupsData}
              pageOptions={pageOptions}
              operatorOptions={operatorOptions}
              setGroups={handleSetGroups}
              setDeleteGroup={setDeleteGroup}
              setPublicGroup={setPublicGroup}
              setCloneGroup={setCloneGroup}
              setManageTagGroup={setManageTagGroup}
              setAddIconGroup={setAddIconGroup}
              trustedAttributeArr={allTrustedAttributes}
              workspaceDetailsData={workspaceDetails}
              workspaceUsers={workspaceUsers}
              importable
              userModalOpen={userModalOpen}
              workspaceDetails={workspaceDetails}
              groups={groups}
              userId={userId}
              pagePreview={pagePreview}
              listToggle={listToggle}
              setListToggle={setListToggle}
              setScheduleReports={setScheduleReports}
              activeSSO={activeSSO}
              setFlattenPages={setFlattenPages}
            />
          ),
        }}
        listToggle={listToggle}
      />
      <Modal
        title="Public Accessibility"
        isOpen={!!publicGroup}
        onCancel={() => setPublicGroup(null)}
        onSuccess={handlePublicGroup}
        defaultOptions={{
          onSuccessButtonText: "Submit",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: isPublicGroupLoading,
        }}>
        <div className="grid gap-y-8 text-sm text-gray-500">
          {publicGroup?.public
            ? "Are you sure you want to remove the public access from this group?"
            : "Are you sure you want to make this group and the pages contained within public? All the pages will retain their filters. but these page(s) will be accessible at the URL. You can always undo this change."}
        </div>
      </Modal>
      <Modal
        title="Clone group"
        isOpen={!!cloneGroup}
        onCancel={() => setCloneGroup(null)}
        onSuccess={handleCloneGroup}
        defaultOptions={{
          onSuccessButtonText: "Clone",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: isCloneGroupLoading,
        }}>
        <div className="text-sm text-gray-500">
          Are you sure you want to clone <span className="font-semibold text-slate-800">{cloneGroup?.name}</span>?
        </div>
      </Modal>
      <Modal
        title="Disable foldering"
        isOpen={!!flattenPages}
        onCancel={() => setFlattenPages(null)}
        onSuccess={handleFlattenPages}
        defaultOptions={{
          onSuccessButtonText: "Submit",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: isFlattenPagesLoading,
        }}>
        <div className="text-sm text-gray-500">
          Are you sure you want to {flattenPages?.flatten_pages ? "enable" : "disable"} foldering from <span className="font-semibold text-slate-800">{flattenPages?.name}</span> group?
        </div>
      </Modal>
      <WorkspaceGroupTagManageModal
        title={"Tags"}
        secondaryTitle={"Manage"}
        isOpen={!!manageTagGroup}
        onCancel={() => setManageTagGroup(null)}
        workspaceDetails={workspaceDetails}
        groupDetails={manageTagGroup}
        workspaceId={workspaceId}
        onSuccess={() => {
          setManageTagGroup(null);
          loadGroupsData();
        }}
      />
      <WorkspaceGroupIconManageModal
        title="Select icon"
        isOpen={!!addIconGroup}
        onCancel={() => setAddIconGroup(null)}
        onSuccess={handleAddIconGroup}
        workspaceDetails={workspaceDetails}
        isAddIconGroupLoading={isAddIconGroupLoading}
        groupDetails={addIconGroup}
      />
      <WarningModal
        isOpen={!!deleteGroup}
        onSuccess={handleDeleteGroup}
        onCancel={() => setDeleteGroup(null)}
        disabled={isDeleteGroupLoading}
        type="DELETE_DATA"
      />
      {pageName !== "user" && (
        <GroupManageSlider
          isOpen={groupModalOpen}
          defaultWorkspaceId={workspaceId}
          setIsOpen={setGroupModalOpen}
          setRefresh={() => loadGroupsData(workspaceUsers)}
          activeSSO={activeSSO}
          defaultOptions={{
            onSuccessButtonText: "Save",
          }}
          defaultStyles={{
            overFlowYVisible: false,
          }}
          title="Group"
          secondaryTitle="To add a new group fill the information below."
          loadUsers={refreshWorkspaceUsers}
          users={workspaceUsers}
          addGroupStatus={addGroupStatus}
          setAddGroupStatus={setAddGroupStatus}
          editGroupStatus={editGroupStatus}
          setEditGroupStatus={setEditGroupStatus}
          editId={editId}
          setEditId={setEditId}
          workspaceDetails={workspaceDetails}
          trustedAttributeArr={allTrustedAttributes}
          operators={operatorOptions}
          importable
        />
      )}
      <WorkspaceManageUsersModal
        title={`${workspaceDetails?.name} / Users`}
        secondaryTitle="Add"
        isOpen={addUserModalOpen}
        defaultSelectedUsers={defaultSelectedGroup?.users?.map((user) => user._id || user.id)}
        onSuccess={(users, importStatus, fromImportModal) => {
          handleGroupUserChanges(users, importStatus, fromImportModal);
        }}
        onCancel={() => setAddUserModalOpen(false)}
        workspaceId={workspaceDetails?._id}
        workspaceDetailsData={workspaceDetails}
        workspaceUsers={workspaceUsers}
        isImportModalOpen={groupImportUserModalOpen}
        setIsImportModalOpen={setGroupImportUserModalOpen}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        hideCrossIcon={false}
        disableStatus={successModalStart}
      />
      <GroupAssignToUserModal
        isOpen={groupAssignToUserModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupAssignToUserModalOpen}
        userDetails={props?.userDetails}
        setRefresh={loadGroupsData}
        title={`${props?.userDetails?.name}/${workspaceDetails?.name}/Group Assign`}
        groups={props?.workspace?.groups || []}
        defaultGroups={Array.isArray(groups) ? groups.map((grp) => grp?._id) : []}
      />
      {/* { Page Preview} */}
      <Modal
        title="Page"
        secondaryTitle="View full-screen"
        isOpen={prevModalOpen}
        onCancel={() => {
          setPrevModalOpen(false);
          setPrevPageDetails(null);
        }}
        size="xxxxxl"
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}
        fullScreen={true}
        defaultHeader={false}
        customHeader={
          <div
            style={{ background: workspaceId ? workspaceDetails?.top_bar_color : props?.site?.top_bar_color, color: workspaceId ? workspaceDetails?.top_bar_text_color : props?.site?.top_bar_text_color }}
            className="text flex h-16 flex-shrink-0 items-center justify-between px-2 py-1 text-lg shadow-md">
            <div className="flex h-14  max-w-96  items-center gap-x-3 overflow-hidden">
              <Image
                image={workspaceId ? workspaceDetails?.image_logo : props?.site?.image_logo}
                alt="page_workspace_logo"
                className="object-left"
              />
            </div>
            <div className="flex items-center justify-center text-sm">
              <div>{props?.me?.name ? props?.me?.name : null}</div>
              <div className="ml-3 h-8 w-8 overflow-hidden rounded-full shadow">{<UserProfile user={props?.me || {}} />}</div>
            </div>
          </div>
        }>
        <PagePreview
          pageDetails={prevPageDetails}
          isOpen={prevModalOpen}
        />
      </Modal>
      {/* { Schedule reports } */}
      <ScheduleReportModal
        group={scheduleReports}
        isOpen={scheduleReports?._id ? true : false}
        workspaceId={workspaceId}
        onCancel={() => setScheduleReports(null)}
        onSuccess={() => {
          setScheduleReports(null);
          loadGroupsData();
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  manageGroupUser,
})(WorkspaceGroupList);
