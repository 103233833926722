import React from "react";
import TRHeader from "src/components/Shared/Table/TRHeader";

const PageListHeader = ({ workspaceId, pageType }) => {
  return (
    <TRHeader>
      <th
        scope="col"
        className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
        Name
      </th>
      {!workspaceId && (
        <th
          scope="col"
          className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:pl-6 sm:text-sm">
          Workspace
        </th>
      )}
      <th
        scope="col"
        className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:pl-6 sm:text-sm">
        {pageType === "IFRAME_EMBED" && "Type"}
        {pageType === "JWT_FULL_EMBED" && "Users"}
      </th>
      {pageType && pageType === "JWT_FULL_EMBED" && (
        <th
          scope="col"
          className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:pl-6 sm:text-sm">
          Groups
        </th>
      )}
      {pageType && pageType === "IFRAME_EMBED" && (
        <th
          scope="col"
          className="overflow-hidden  rounded-tr-lg px-3 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
      )}
    </TRHeader>
  );
};

export default PageListHeader;
