import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { H5 } from "src/components/Shared/Text/Headers";

const TestEmailSMTPModal = ({ isOpen, smtpType = "mailgun", onCancel = () => {}, workspaceId = null }) => {
  const [email, setEmail] = useState("");
  const [isEmailSendLoading, setIsEmailSendLoading] = useState(false);
  const [isEmailSendDisabled, setIsEmailSendDisabled] = useState(true);

  const sendTestEmail = async () => {
    try {
      setIsEmailSendLoading(true);
      setIsEmailSendDisabled(true);

      await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/smtp/email/test` : `/settings/smtp/email/test`, { body: { email, type: smtpType }, params: { workspace_id: workspaceId } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      setEmail("");
    } catch (error) {
    } finally {
      setIsEmailSendDisabled(true);
      setIsEmailSendLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      title="Test"
      secondaryTitle="SMTP"
      isOpen={isOpen}
      onCancel={onCancel}
      defaultOptions={{
        onSuccessButtonVisible: false,
        onCancelButtonVisible: false,
      }}>
      <div className="w-full max-w-4xl flex-shrink">
        <div className="flex w-full flex-col justify-between  gap-y-3">
          <H5
            margin={false}
            caption="Enter in a testing email address and see if an email comes through.">
            Test your SMTP connection
          </H5>
          <div className="flex w-full max-w-2xl items-center  gap-3">
            <div className="relative w-full">
              <Input
                name="test-email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsEmailSendDisabled(false);
                }}
                label="Email address"
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                className="px-3"
                disabled={isEmailSendDisabled}
                onClick={sendTestEmail}
                type="button">
                Submit{isEmailSendLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TestEmailSMTPModal;
