import { H3 } from "src/components/Shared/Text/Headers";
import BillingManager from "../BillingManager";
import React from "react";

const BillingContacts = ({ workspaceId }) => {
  return (
    <div className="relative max-w-3xl space-y-6 py-6">
      <H3
        className="!text-lg font-semibold"
        caption="Anyone you add as a billing contact will receive email notifications when billing changes are made to your account. Please note, only Admin have the ability to access and make billing changes within an account.">
        Manage billing contacts
      </H3>
      <div className="w-full">
        <BillingManager />
      </div>
    </div>
  );
};

export default BillingContacts;
