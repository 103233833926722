import { useEffect, useState } from "react";
import { noProfile } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { formatName } from "src/helpers/formatName";
import { getS3ImageDetails } from "src/helpers/image";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function UserProfile({ user, className = "", size = "md", showStatus = false }) {
  const [s3Image, setS3Image] = useState({
    loading: false,
    data: null,
  });

  const [invalidImage, setInvalidImage] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    if (user?.image && !user.image.includes("data:image/")) {
      setInvalidImage(false);

      const loadS3Image = async () => {
        setS3Image((data) => ({ ...data, loading: true }));

        const res = await getS3ImageDetails(user.image, ac.signal);
        if (res.status === 200) {
          setInvalidImage(false);
          setS3Image({ loading: false, data: res.data });
        }
      };

      loadS3Image();
    } else {
      setInvalidImage(true);
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  return (
    <>
      {user?.image && s3Image.loading ? (
        <div className={classNames("relative h-full w-full flex-shrink-0 overflow-hidden rounded-full bg-white", className)}>
          <img
            className={classNames("h-full w-full object-cover", "opacity-40")}
            src={noProfile}
            alt="no-profile"
          />
        </div>
      ) : (
        <>
          {invalidImage || (!s3Image.loading && !s3Image.data) ? (
            <div className={`flex h-full w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-full text-gray-900 text-${size} bg-gray-200 uppercase ` + className}>{formatName(user?.name, user?.email)}</div>
          ) : (
            <div className={"relative h-full w-full flex-shrink-0 overflow-hidden rounded-full" + className}>
              <img
                className={classNames("h-full w-full object-cover", user?.image && s3Image.data ? "opacity-100 transition-opacity duration-150" : "opacity-40")}
                src={s3Image.loading ? noProfile : s3Image.data}
                alt="no-profile"
                onError={() => setInvalidImage(true)}
              />
            </div>
          )}
        </>
      )}
      {showStatus && !user?.active_status && (
        <>
          <div
            data-tooltip-id={`${user._id}-folder_pages`}
            className={classNames(`absolute right-0 top-[1px] m-0 aspect-square w-2.5 flex-shrink-0 rounded-full border p-0`, user?.active_status ? "border-green-700/40 bg-green-100" : "border-gray-700/80 bg-gray-300")}></div>
          <ReactTooltip
            id={`${user._id}-folder_pages`}
            delayShow={200}
            positionStrategy="fixed"
            className="z-[200] rounded bg-gray-700 px-2 py-2 opacity-100">
            <div className="font-normal leading-[10px]">
              This {user?.active_status ? "green" : "gray"} color indicates that this user has been {user?.active_status ? "active" : "deactivated"}.
            </div>
          </ReactTooltip>
        </>
      )}
    </>
  );
}
