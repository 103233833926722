import { FunnelIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { managePageData, removePage, setPageData, updateDomoPage } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import PageEntry from "src/components/Pages/PageComponents/PageEntry";
import PagePreview from "src/components/Pages/PageModals/PagePreview";
import GlobalAccordion from "src/components/Shared/Accordions/GlobalAccordion";
import Button from "src/components/Shared/Buttons/Button";
import Image from "src/components/Shared/Image";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import UserProfile from "src/components/Users/UserProfile";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { credentialTypes, newPageJsonArr } from "src/helpers/pageHelper";
import useFetch from "src/hooks/useFetch";
import { validate as uuidValidate } from "uuid";
import MultiSelectObject from "../../Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import GroupAssignToPageModal from "../PageModals/GroupAssignToPageModal";
import PageManageSlider from "../PageModals/PageManageSlider";
import UserAssignToPageModal from "../PageModals/UserAssignToPageModal";
import PageListHeader from "./PageListHeader";

const PageList = ({ workspaceId = "", ...props }) => {
  const [userAssignModal, setUserAssignModal] = useState(false);
  const [groupAssignModal, setGroupAssignModal] = useState(false);
  const [successLoaderStart, setSuccessLoaderStart] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [isDeleteDisable, serIsDeleteDisable] = useState(false);
  const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newPage, setNewPage] = useState(newPageJsonArr(workspaceId, workspaceId ? false : true));

  const [pageCredentialOptions, setPageCredentialOptions] = useState([]);
  const [editPageCredentialOptions, setEditPageCredentialOptions] = useState([]);

  // Users and groups
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const [domoPageId, setDomoPageId] = useState([]);
  const [domoUserIds, setDomoUserIds] = useState([]);
  const [domoGroupIds, setDomoGroupIds] = useState([]);
  const [domoPageIdForRefresh, setDomoPageIdForRefresh] = useState([]);
  const [pageSelectedId, setPageSelectedId] = useState([]);

  // Page preview
  const [prevModalOpen, setPrevModalOpen] = useState(false);
  const [prevPageDetails, setPrevPageDetails] = useState(null);
  const [pageSlider, setPageSlider] = useState(false);

  // List Query
  const [keyword, setKeyword] = useState("");
  const [meta, setMeta] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [globalLimit, setGlobalLimit] = useState(10);
  const [globalOffset, setGlobalOffset] = useState(0);

  const [pagesLoaded, setPagesLoaded] = useState(false);
  const [pageType, setPageType] = useState(null);

  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState([]);

  const [globalPagesLoaded, setGlobalPagesLoaded] = useState(false);
  const [globalPages, setGlobalPages] = useState([]);
  const [globalMeta, setGlobalMeta] = useState(null);
  const [workspace_ids, setWorkspace_ids] = useState([]);

  const resetPagination = () => {
    setOffset(0);
    setMeta(null);
  };
  const resetGlobalPagination = () => {
    setGlobalOffset(0);
    setGlobalMeta(null);
  };

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post", data: { includes: ["authorized_workspaces", "forms"] } });

  const workspacePages = useCallback(async () => {
    const params = { workspace_id: workspaceId };
    const res = await apiRequest("get", `/workspaces/:workspace_id/domo-pages`, {
      queries: { sort: "ASC", limit, offset, keyword },
      params,
    });

    if (res.data.status === 200) {
      setPages(res.data.data || []);
      setPagesLoaded(true);
      setMeta(res.data.meta);
    } else {
      setPages([]);
      setPagesLoaded(true);
      setMeta(null);
    }

    try {
      apiRequest("post", `/workspaces/${workspaceId}/domo-data-count`, { body: {} });
    } catch (err) {}
  }, [workspaceId, limit, offset, keyword]);

  const menuPageList = useCallback(async () => {
    try {
      setPagesLoaded(false);
      const res = await apiRequest("post", workspaceId ? `/workspaces/:workspace_id/pages/list` : `/pages/list`, {
        body: { limit, offset, keyword, isGlobal: false, workspace_id: workspaceId, workspace_ids, workspace_type: "IFRAME_EMBED" },
        params: { workspace_id: workspaceId },
      });

      if (res.data.status === 200) {
        setPages(res.data.data || []);
        setMeta(res.data.meta);
        setPagesLoaded(true);
        setAllPages(res.data.allPages);
      } else {
        setPagesLoaded(true);
        setPages([]);
        setMeta(null);
      }
    } catch (error) {}
  }, [limit, offset, keyword, workspaceId, workspace_ids]);

  const globalPagesList = useCallback(async () => {
    try {
      setGlobalPagesLoaded(false);
      const global_page_response = await apiRequest("post", `/pages/global/list`, {
        body: { limit: globalLimit, offset: globalOffset, keyword, isGlobal: true, workspace_id: workspaceId, workspace_ids, workspace_type: "IFRAME_EMBED" },
        params: { workspace_id: workspaceId },
      });

      if (global_page_response?.data?.status === 200) {
        setGlobalPages(global_page_response?.data?.data || []);
        setGlobalMeta(global_page_response?.data?.meta);
        setGlobalPagesLoaded(true);
      } else {
        setGlobalPages([]);
        setGlobalMeta(null);
        setGlobalPagesLoaded(true);
      }
    } catch (error) {}
  }, [workspaceId, workspace_ids, globalLimit, globalOffset, keyword]);

  useEffect(() => {
    if (!workspaceId) {
      setPageType("IFRAME_EMBED");
      setPagesLoaded(false);
      menuPageList();
      globalPagesList();
    } else {
      if (props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        setPageType("JWT_FULL_EMBED");
        setPagesLoaded(false);
        workspacePages();
      } else if (props?.workspaceDetails?.workspace_type === "IFRAME_EMBED") {
        setPageType("IFRAME_EMBED");
        setPagesLoaded(false);
        menuPageList();
      }
    }
  }, [workspacePages, menuPageList, globalPagesList, props?.workspaceDetails, workspaceId, workspace_ids]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const handleGlobalPaginationChange = ({ limit, offset }) => {
    setGlobalLimit(limit);
    setGlobalOffset(offset);
  };

  const loadUserGroupData = useCallback(async () => {
    const params = { workspace_id: props?.workspaceDetails?._id };
    const res = await apiRequest("post", `/workspaces/:workspace_id/user-groups`, {
      body: {
        authToken: props?.workspaceDetails?.auth_token,
        workspace_id: props?.workspaceDetails?._id,
      },
      params,
    });

    if (res.data.status === 200) {
      setUsers(res.data.data.users || []);
      setGroups(res.data.data.groups || []);
    }
  }, [props?.workspaceDetails]);

  const allCredentials = useCallback(async () => {
    const res = await apiRequest("get", `/credentials/all`);

    if (res.data.status === 200) {
      setEditPageCredentialOptions(res.data.data || []);
    }
  }, []);

  const workspaceCredentials = useCallback(async () => {
    const params = { workspace_id: workspaceId };
    const res = await apiRequest("get", `/workspaces/:workspace_id/credentials`, { params });

    if (res.data.status === 200) {
      setEditPageCredentialOptions(res.data.data || []);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId || props?.workspaceDetails?.workspace_type === "IFRAME_EMBED") {
      if (props?.user?.type === "admin") {
        allCredentials();
      } else if (workspaceId) {
        workspaceCredentials();
      }
    }
  }, [allCredentials, workspaceCredentials, workspaceId, props?.user, props?.workspaceDetails]);

  useEffect(() => {
    if (props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
      loadUserGroupData();
    }
  }, [loadUserGroupData, props?.workspaceDetails]);

  const deletePage = async () => {
    if (deleteId && !uuidValidate(deleteId)) {
      try {
        serIsDeleteDisable(true);
        const message = await props.removePage({
          workspaceId,
          deleteId,
        });
        if (pages?.length === 1 && offset !== 0) {
          setOffset(offset - 1);
        } else {
          menuPageList();
          globalPagesList();
        }
        setDeleteId(null);
        toast.success(message);
      } catch (error) {
        toast.error(error.message);
      } finally {
        serIsDeleteDisable(false);
      }
    }
  };

  const editPage = async (page) => {
    if (page.workspace_id.length > 0 || page.is_global) {
      let workspace_id = [];
      for (const workspaceid of page.workspace_id) {
        workspace_id.push(workspaceid._id);
      }
      setSelectedWorkspace(workspace_id);
      setNewPage({ ...page, workspace_id: "", is_global: true });
    } else {
      setSelectedWorkspace([]);
      setNewPage({ ...page, workspace_id: page.workspace_id?._id, is_global: false });
    }
    openSilder();
    setPrevPageDetails({ page, body: { user_id: props?.user?._id, page_id: page?._id, workspace_id: workspaceId } });
  };

  const addPage = async (pageType) => {
    setSelectedWorkspace([]);
    setNewPage({ ...newPageJsonArr(workspaceId, false), credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value || null });
    openSilder();
  };

  const pagePrev = async (page) => {
    setPrevPageDetails({ page, body: { user_id: props?.user?._id, page_id: page?._id, workspace_id: workspaceId } });
    setPrevModalOpen(true);
  };

  useEffect(() => {
    let creds = [];
    // eslint-disable-next-line array-callback-return
    editPageCredentialOptions?.map((credentialOption) => {
      let insert = false;
      const { _id, workspace_id, name, code, is_global } = credentialOption;
      if (workspace_id && newPage?.workspace_id === workspace_id) {
        insert = true;
      }
      if (props.user?.type === "admin" && is_global) {
        insert = true;
      }
      if (insert === true && creds.findIndex((cr) => cr.value === _id) === -1 && newPage?.page_type.toLowerCase() === code) {
        const credentialComponent = () => credentialTypes(props?.user?._id)[credentialOption.default ? "DEFAULT" : "NORMAL"](credentialOption);
        creds.push({ value: _id, key: name, code: code, default: credentialOption.default, object: credentialOption, Component: credentialComponent });
      }
    });
    setPageCredentialOptions(creds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPageCredentialOptions, newPage?.workspace_id, newPage?.page_type, props.user]);

  useEffect(() => {
    if (!newPage?._id) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value || null,
      }));
    }
  }, [newPage.workspace_id, newPage?._id, pageCredentialOptions]);

  const activeUserAssignModal = async (pageId, userIds = [], ownerId) => {
    setDomoPageId(pageId);
    let userWithSelected = [];
    for (const user of users) {
      userWithSelected.push({ ...user, checked: userIds.findIndex((usr) => usr === user.id) === -1 ? false : true, owner: user.id === ownerId ? true : false });
    }
    setDomoUserIds(userWithSelected);
    setUserAssignModal(true);
  };

  const activeGroupAssignModal = async (pageId, groupIds = []) => {
    setDomoPageId(pageId);
    let groupWithSelected = [];
    for (const group of groups) {
      groupWithSelected.push({ ...group, checked: groupIds.findIndex((grp) => grp === group.id) === -1 ? false : true });
    }
    setDomoGroupIds(groupWithSelected);
    setGroupAssignModal(true);
  };

  const handleUserGroupAssign = async (type, importStatus = undefined) => {
    try {
      let ids;
      if (type === "user") {
        let filterArr = domoUserIds.filter((dt) => dt.checked);
        ids = filterArr.map((dt) => dt.id);
      } else {
        let filterArr = domoGroupIds.filter((dt) => dt.checked);
        ids = filterArr.map((dt) => dt.id);
      }

      if (typeof importStatus !== "boolean" && ids.length && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        if (users.filter((user) => ids.includes(user.id || user._id) && !user.portalUser).length) {
          setUserAssignModal(false);
          setGroupImportUserModalOpen(true);
          return;
        }
      }

      setSuccessLoaderStart(true);

      for (const pageId of domoPageId) {
        await updateDomoPage({
          page_id: pageId,
          workspace_id: workspaceId,
          userIds: type === "user" ? ids : undefined,
          groupIds: type === "group" ? ids : undefined,
          auth_token: props?.workspaceDetails?.auth_token,
          importStatus,
          password,
          confirmPassword,
          sendWelcomeEmail,
        });
      }
      setDomoPageIdForRefresh(domoPageId);
      setSuccessLoaderStart(false);
      setUserAssignModal(false);
      setGroupAssignModal(false);
      setGroupImportUserModalOpen(false);
      loadUserGroupData();
    } catch (error) {
      setSuccessLoaderStart(false);
      toast.error(error.message);
    }
  };

  const pageSelect = async (e, pageId) => {
    if (e.target.checked) {
      setPageSelectedId([...pageSelectedId, pageId]);
    } else {
      setPageSelectedId(pageSelectedId.filter((p) => p !== pageId));
    }
  };

  const openSilder = () => {
    setPageSlider(true);
  };

  const closeSlider = () => {
    if (!deleteId) {
      setPageSlider(false);
      setPrevPageDetails(null);
    }
  };

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setWorkspace_ids(updatedSelectedWorkspaces);
    resetPagination();
    resetGlobalPagination();
  };

  const renderTable = (is_global, pages, loaded) => (
    <Table
      tableHeader={
        <PageListHeader
          pageType={pageType}
          workspaceId={workspaceId}
        />
      }
      colSpan="5"
      loaded={loaded}
      dataExists={pages?.length > 0}>
      {pages?.map((page) => (
        <PageEntry
          key={page._id || page.id}
          users={users}
          page={page}
          depthLevel={0}
          groups={groups}
          editPage={editPage}
          pagePrev={pagePrev}
          pageType={pageType}
          pageSelect={pageSelect}
          is_global={is_global}
          workspaceId={workspaceId}
          setDeleteId={setDeleteId}
          workspaceDetails={props?.workspaceDetails}
          domoPageIdForRefresh={domoPageIdForRefresh}
          activeUserAssignModal={activeUserAssignModal}
          activeGroupAssignModal={activeGroupAssignModal}
          setDomoPageIdForRefresh={setDomoPageIdForRefresh}
        />
      ))}
    </Table>
  );

  return (
    <>
      <TableOptions
        leftContentClasses="!max-w-[670px]"
        leftContent={
          <div className="grid grid-cols-6 gap-x-3">
            <div className="col-span-4">
              <Search
                keyword={keyword}
                setKeyword={(val) => {
                  resetPagination();
                  resetGlobalPagination();
                  setKeyword(val);
                }}
                placeholder={"Search"}
                inline={true}
              />
            </div>

            {!workspaceId && (
              <div className="col-span-2">
                <MultiSelectObject
                  placeholder={workspace_ids?.length > 0 ? `${workspace_ids?.length} Selected Workspace${workspace_ids?.length === 1 ? "" : "s"}` : "No filter"}
                  inline={true}
                  title="Filter workspaces"
                  icon={{ image: FunnelIcon, name: "FunnelIcon" }}
                  defaultOptions={
                    workspacesLoaded &&
                    workspaces
                      .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                      .map((wrk) => {
                        return { key: wrk._id, value: wrk.name, selected: workspace_ids.includes(wrk._id), object: wrk };
                      })
                  }
                  searchableFields={["name"]}
                  onChange={handleSelectedWorkspaces}
                  showSelectedItems={false}
                />
              </div>
            )}
          </div>
        }
        rightContent={
          (workspaceId && props.workspaceDetails?._id && props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && authorizeUserComponentAccess(props.user, workspaceId, "page", ["update"])) || (props.user?.type === "admin" && !workspaceId) ? (
            <Button
              version="secondary"
              onClick={() => addPage("DOMO")}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              Add page
            </Button>
          ) : (
            workspaceId &&
            props.workspaceDetails?._id &&
            props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" &&
            authorizeUserComponentAccess(props.user, workspaceId, "page", ["update"]) && (
              <div className="relative z-10 flex items-center justify-between">
                <div className="flex h-10 gap-x-2">
                  <Button
                    version="secondary"
                    disabled={pageSelectedId.length === 0}
                    onClick={() => {
                      activeUserAssignModal(pageSelectedId);
                    }}>
                    <PlusIcon className="h-4 w-4 stroke-[3px]" />
                    Add users
                  </Button>
                  <Button
                    version="secondary"
                    disabled={pageSelectedId.length === 0}
                    onClick={() => {
                      activeGroupAssignModal(pageSelectedId);
                    }}>
                    <PlusIcon className="h-4 w-4 stroke-[3px]" />
                    Add groups
                  </Button>
                </div>
              </div>
            )
          )
        }
      />
      <div className="grid gap-y-2">
        {!workspaceId && (
          <GlobalAccordion
            iconPosition="left"
            headerTextPosition="left"
            startOpen={true}
            headerText={
              <div className="flex flex-col">
                <p className="text-lg font-bold text-gray-600">Global Pages</p>
                <p className="-mt-1 text-sm font-medium text-gray-300">
                  {globalMeta?.count ? globalMeta?.count : 0} page{globalMeta?.count === 1 ? "" : "s"}
                </p>
              </div>
            }>
            {renderTable(true, globalPages, globalPagesLoaded)}
            {pageType === "IFRAME_EMBED" && (
              <PaginationFooter
                itemName="Global Page"
                limit={globalLimit}
                offset={globalOffset}
                count={globalMeta?.count}
                onChange={handleGlobalPaginationChange}
              />
            )}
          </GlobalAccordion>
        )}
        {workspaceId ? (
          <div>
            {renderTable(false, pages, pagesLoaded)}

            {pageType === "IFRAME_EMBED" && (
              <PaginationFooter
                itemName="Page"
                limit={limit}
                offset={offset}
                count={meta?.count}
                onChange={handlePaginationChange}
              />
            )}
          </div>
        ) : (
          <GlobalAccordion
            iconPosition="left"
            headerTextPosition="left"
            startOpen={true}
            headerText={
              <div className="flex flex-col">
                <p className="text-lg font-bold text-gray-600">Workspace Specific</p>
                <p className="-mt-1 text-sm font-medium text-gray-300">
                  {meta?.count ? meta?.count : 0} page{meta?.count === 1 ? "" : "s"}
                </p>
              </div>
            }>
            {renderTable(false, pages, pagesLoaded)}

            {pageType === "IFRAME_EMBED" && (
              <PaginationFooter
                itemName="Page"
                limit={limit}
                offset={offset}
                count={meta?.count}
                onChange={handlePaginationChange}
              />
            )}
          </GlobalAccordion>
        )}
      </div>
      <Modal
        title="Page"
        secondaryTitle="Delete"
        isOpen={deleteId ? true : false}
        onCancel={() => {
          setDeleteId(null);
        }}
        onSuccess={deletePage}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="space-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {Array.isArray(pages) && pages?.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>
      <PageManageSlider
        isOpen={pageSlider}
        newPage={newPage}
        allPages={allPages}
        workspaces={workspaces}
        setNewPage={setNewPage}
        handleClose={closeSlider}
        workspaceId={workspaceId}
        workspacesLoaded={workspacesLoaded}
        prevPageDetails={prevPageDetails}
        selectedWorkspace={selectedWorkspace}
        setPrevPageDetails={setPrevPageDetails}
        successLoaderStart={successLoaderStart}
        setSelectedWorkspace={setSelectedWorkspace}
        setSuccessLoaderStart={setSuccessLoaderStart}
        pageCredentialOptions={pageCredentialOptions}
        editPageCredentialOptions={editPageCredentialOptions}
        menuPageList={() => {
          menuPageList();
          globalPagesList();
        }}
      />
      <Modal
        title="Page"
        secondaryTitle="Preview"
        isOpen={prevModalOpen}
        onCancel={() => {
          setPrevModalOpen(false);
          setPrevPageDetails(null);
        }}
        fullScreen={true}
        defaultHeader={false}
        customHeader={
          <div
            style={{ background: workspaceId ? props?.workspaceDetails?.top_bar_color : props?.site?.top_bar_color, color: workspaceId ? props?.workspaceDetails?.top_bar_text_color : props?.site?.top_bar_text_color }}
            className="text flex h-16 flex-shrink-0 items-center justify-between border-b px-2 py-1 text-lg shadow-md">
            <div className="flex h-14  max-w-96  items-center gap-x-3 overflow-hidden">
              <Image
                image={workspaceId ? props?.workspaceDetails?.image_logo : props?.site?.image_logo}
                alt="page_workspace_logo"
                className="object-left"
              />
            </div>
            <div className="flex items-center justify-center text-sm">
              <div>{props?.user?.name ? props?.user?.name : null}</div>
              <div className="ml-3 h-8 w-8 overflow-hidden rounded-full shadow">{<UserProfile user={props?.user || {}} />}</div>
            </div>
          </div>
        }
        size="xxxxxl"
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}>
        <PagePreview
          isOpen={prevModalOpen}
          pageDetails={prevPageDetails}
        />
      </Modal>
      <WorkspaceImportUserModal
        isImportModalOpen={groupImportUserModalOpen}
        setIsImportModalOpen={setGroupImportUserModalOpen}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        selectedUsers={domoUserIds.filter((dt) => dt.checked).map((item) => item.id)}
        workspaceUsers={users}
        onSuccess={(users, status) => handleUserGroupAssign("user", status)}
        workspaceDetailsData={props?.workspaceDetails}
        hideCrossIcon={false}
        disableStatus={successLoaderStart}
      />
      <UserAssignToPageModal
        isOpen={userAssignModal}
        domoGroupIds={domoGroupIds}
        loading={successLoaderStart}
        setDomoUserIds={setDomoUserIds}
        onCancel={() => setUserAssignModal(false)}
        handleUserGroupAssign={handleUserGroupAssign}
      />
      <GroupAssignToPageModal
        isOpen={groupAssignModal}
        domoGroupIds={domoGroupIds}
        loading={successLoaderStart}
        setDomoGroupIds={setDomoGroupIds}
        onCancel={() => setGroupAssignModal(false)}
        handleUserGroupAssign={handleUserGroupAssign}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {
  managePageData,
  removePage,
  setPageData,
})(PageList);
