import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";

const GlobalCredentials = () => {
  return (
    <WorkspaceIntegrationsList
      workspaceId={null}
      workspaceDetails={null}
      is_global={true}
      isDefaultOpen={true}
    />
  );
};

export default GlobalCredentials;
