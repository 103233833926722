import { Switch } from "@headlessui/react";
import { classNames } from "src/helpers/classNames";

export default function Toggle({ checked, onChange, disabled = false, defaultSize = "default", className }) {
  const size = {
    default: {
      switchClassName: "h-[22px] w-11",
      spanClassName: "h-[18px] w-[18px]",
      translateClass: "translate-x-5",
    },
    sm: {
      switchClassName: "h-[16px] w-[30px]",
      spanClassName: "h-[13px] w-[13px]",
      translateClass: "translate-x-4",
    },
  };
  return (
    <div className={classNames(className, disabled ? "cursor-not-allowed" : "")}>
      <Switch.Group
        as="div"
        className="flex items-center">
        <Switch
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={classNames(
            disabled ? "cursor-not-allowed" : "cursor-pointer",
            disabled && checked ? "bg-gray-300" : checked ? "bg-highlightColor" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
            size[defaultSize]?.switchClassName || size["default"]?.switchClassName,
          )}>
          <span className="sr-only">Toggle item</span>
          <span
            aria-hidden="true"
            className={classNames(
              disabled ? "cursor-not-allowed" : "",
              checked ? size[defaultSize]?.translateClass || size["default"]?.translateClass : "translate-x-0",
              "pointer-events-none inline-block  transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
              size[defaultSize]?.spanClassName || size["default"]?.spanClassName,
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}
