import { useEffect, useState } from "react";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import UserProfile from "src/components/Users/UserProfile";
import { formatDate } from "src/helpers/formatDate";

const ResponseEntry = ({ response = null, viewResponse = () => {}, ...props }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    let item = [];

    item.push({ name: "View", onClick: () => viewResponse(response), color: "text-grey-500 hover:text-highlightColor" });

    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <TableRow>
      <TRBody className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="flex items-center">
          {response?.user?._id ? (
            <>
              <div className="hidden h-10 w-10 flex-shrink-0 overflow-hidden rounded-full sm:block">
                {
                  <UserProfile
                    user={response?.user || {}}
                    className={"!p-1"}
                  />
                }
              </div>
              <div className="sm:ml-4">
                <div className="flex flex-col items-start gap-x-2 font-medium text-gray-900">
                  {response?.user?.name}
                  <span>{response?.user?.email}</span>
                </div>
              </div>
            </>
          ) : (
            <div className="text-gray-400">{response?.public ? "*Publicly Access*" : "*User Deleted*"}</div>
          )}
        </div>
      </TRBody>
      {!props?.workspaceId && (
        <TRBody className="break-word block w-full whitespace-pre-wrap !px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
          {response?.workspace_id?.name ? <div className="font-medium text-gray-900">{response?.workspace_id?.name}</div> : <div className="text-gray-400">*Workspace Deleted*</div>}
        </TRBody>
      )}
      <TRBody className="break-word block w-full whitespace-pre-wrap !px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        {response?.group_id ? <>{response?.group?.name ? <div className="font-medium text-gray-900">{response?.group?.name}</div> : <div className="text-gray-400">*Group Deleted*</div>}</> : <div className="text-gray-400">N/A</div>}
      </TRBody>
      <TRBody className="break-word block w-full whitespace-pre-wrap !px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        {response?.page?.name ? <div className="font-medium text-gray-900">{response?.page?.name}</div> : <div className="text-gray-400">*Page Deleted*</div>}
      </TRBody>
      <TRBody className="break-word block w-full whitespace-pre-wrap !px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="hidden text-gray-900 2xl:block">{formatDate(response?.created_at, "LLLL")}</div>
        <div className="flex flex-col break-words text-gray-900 2xl:hidden">
          {formatDate(response?.created_at, "MM/DD/YYYY")} <span className="text-gray-400">{formatDate(response?.created_at, "h:mm:ssA")}</span>
        </div>
      </TRBody>
      <TRBody className="whitespace-pre-wrap">
        <div className="flex justify-end">
          <QuickMenu
            items={items}
            disabled={items?.length === 0 ? true : false}
          />
        </div>
      </TRBody>
    </TableRow>
  );
};

export default ResponseEntry;
