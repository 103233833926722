import { connect } from "react-redux";
import Section from "src/components/Shared/Containers/Section";
import DomainSection from "src/components/Site/DomainsAndDNS/DomainSection";
import LoginUrlTitleDescription from "src/components/Site/DomainsAndDNS/LoginUrlTitleDescription";

const DomainSettings = ({ siteData }) => {
  return (
    <>
      <div className="flex flex-col gap-y-4">
        <LoginUrlTitleDescription siteData={siteData}/>
        {siteData?.manage_subdomains && (
          <Section>
            <DomainSection />
          </Section>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, {})(DomainSettings);
