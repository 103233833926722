import React from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Modal from "src/components/Shared/Modal";
import UserProfile from "src/components/Users/UserProfile";

const GroupAssignToPageModal = ({ isOpen, onCancel, handleUserGroupAssign, loading, domoGroupIds, setDomoGroupIds }) => {
  return (
    <Modal
      title="Groups"
      size={"sm"}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={() => handleUserGroupAssign("group")}
      defaultOptions={{
        onCancelButtonVisible: true,
        onSuccessButtonVisible: true,
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: loading,
      }}
      defaultStyles={{
        overFlowYVisible: false,
      }}>
      <div className="w-full divide-y divide-gray-200">
        {domoGroupIds?.map((group, i) => (
          <div
            key={group._id || group.id}
            className="flex w-full items-center space-x-3 py-2">
            <Checkbox
              isChecked={group.checked || false}
              onChange={(e) => {
                setDomoGroupIds(
                  domoGroupIds.map((grp, index) => {
                    if (i === index) {
                      return { ...grp, checked: e.target.checked };
                    } else {
                      return grp;
                    }
                  }),
                );
              }}
            />
            <UserProfile
              user={group}
              className={"!h-10 !w-10 overflow-hidden rounded-full"}
            />
            <div>{group.name}</div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default GroupAssignToPageModal;
