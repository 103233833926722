import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import GroupAssignToUserModal from "src/components/Groups/GroupAssignToUserModal";
import GroupModalAdd from "src/components/Groups/GroupManageSlider";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import NoData from "src/components/Shared/NoData/NoData";
import { H3 } from "src/components/Shared/Text/Headers";
import DomoWorkspaceGroupItem from "src/components/Workspaces/Workspace/PlatformEmbed/Domo/Groups/DomoWorkspaceGroupItem";
import WorkspaceGroupIconManageModal from "src/components/Workspaces/Workspace/WorkspaceGroupIconManageModal";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";

const DomoWorkspaceGroupList = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", workspaceDetails = {}, ...props }) => {
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupModalOpen, setGroupModalOpen] = useState(false);

  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [cloneGroup, setCloneGroup] = useState(null);
  const [addIconGroup, setAddIconGroup] = useState(null);
  const [isDeleteGroupLoading, setIsDeleteGroupLoading] = useState(null);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [isCloneGroupLoading, setIsCloneGroupLoading] = useState(null);
  const [isAddIconGroupLoading, setIsAddIconGroupLoading] = useState(null);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [defaultSelectedGroup, setDefaultSelectedGroup] = useState([]);

  const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successModalStart, setSuccessModalStart] = useState(false);
  const [groupAssignToUserModalOpen, setGroupAssignToUserModalOpen] = useState(false);
  const [updatedGroupData, setUpdatedGroupData] = useState(null);

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const {
    response: { data: pageOptions },
  } = useFetch(`/workspaces/:workspace_id/pages/editor`, { query: { sort: "ASC", page_type: ["DOMO", "CUSTOM_HTML", "EXTERNAL_LINK", "FORM"] }, params: { workspace_id: workspaceId } });

  const {
    response: { data: operatorOptions },
  } = useFetch("/operators/list");

  const {
    response: { data: defaultGroups },
  } = useFetch(!workspaceId ? `/workspaces/:workspace_id/groups/details` : "", { params: { workspace_id: workspaceId } });

  useEffect(() => {
    setGroups(defaultGroups);
  }, [defaultGroups]);

  const loadWorkspaceUserData = async (users = [], url = "") => {
    try {
      const res = await apiRequest("get", url || `/workspaces/:workspace_id/domo-users/list`, {
        queries: { active_status: true },
        params: { workspace_id: workspaceDetails?._id },
      });

      if (res.data) {
        setWorkspaceUsers(res.data.data);
      }
    } catch (error) {}
  };

  const loadGroupsData = async (users = []) => {
    try {
      setLoaded(false);

      try {
        let url = `/workspaces/:workspace_id/domo-groups`;
        let queries = {
          userFetch: "GET",
        };
        if (pageName === "user") {
          url = `/workspaces/:workspace_id/users/:user_id/groups`;
          queries = {};
        }
        const { status, data } = await apiRequest("GET", url, { queries, params: { workspace_id: workspaceId, user_id: userId } });

        if (status === 200) {
          setGroups(data.data);
          setLoaded(true);
          return data.data;
        } else {
          setLoaded(true);
          throw new Error(data.message);
        }
      } catch (error) {
        setLoaded(true);
        throw new Error(error.message);
      }
    } catch (error) {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (workspaceDetails?._id) {
      loadGroupsData();
      loadWorkspaceUserData(workspaceUsers, workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" ? "/users/list" : `/workspaces/:workspace_id/domo-users/list`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id]);

  useEffect(() => {
    if (workspaceDetails?._id && workspaceUsers?.length > 0 && groups?.length > 0) {
      setGroups(
        groups.map((group) => ({
          ...group,
          users: !Array.isArray(group.userIds) ? group.users : workspaceUsers.filter((user) => group.userIds.includes(user.id)),
        })),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id, workspaceUsers?.length, groups?.length]);

  const handleDeleteGroup = async () => {
    try {
      setIsDeleteGroupLoading(true);
      await apiRequest("delete", `/workspaces/:workspace_id/groups/:group_id`, { params: { workspace_id: workspaceId, group_id: deleteGroup._id || deleteGroup.id } });
      setDeleteGroup(null);
      loadGroupsData();
      setSuccessModalStart(false);
    } catch (error) {
    } finally {
      setIsDeleteGroupLoading(false);
    }
  };

  const handleCloneGroup = async () => {
    try {
      setIsCloneGroupLoading(true);
      await apiRequest("get", `/workspaces/:workspace_id/groups/:group_id/clone`, { params: { workspace_id: workspaceId, group_id: cloneGroup._id || cloneGroup.id } });
      setCloneGroup(null);
      loadGroupsData();
      setIsCloneGroupLoading(false);
    } catch (error) {
    } finally {
      setIsCloneGroupLoading(false);
    }
  };

  const handleAddIconGroup = async (body) => {
    try {
      setIsAddIconGroupLoading(true);
      const { status, data } = await apiRequest("put", `/workspaces/:workspace_id/groups/:group_id/icon`, { body, params: { workspace_id: workspaceId, group_id: addIconGroup._id || addIconGroup.id } });
      if (status === 200) {
        setAddIconGroup(null);
        setUpdatedGroupData({
          _id: addIconGroup._id || addIconGroup.id,
          image: data.data.image,
        });
      } else {
        toast.success(data.message);
      }
      setIsAddIconGroupLoading(false);
    } catch (error) {
    } finally {
      setIsAddIconGroupLoading(false);
    }
  };

  const handleSetGroups = async (groups) => {
    setGroups(groups);
  };

  const userModalOpen = (active, group) => {
    setDefaultSelectedGroup(group);
    setAddUserModalOpen(active);
  };

  const handleGroupUserChanges = async (users, importStatus, fromImportModal) => {
    try {
      if (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && !fromImportModal) {
        if (workspaceUsers.filter((user) => users.includes(user.id || user._id) && !user.portalUser).length) {
          setAddUserModalOpen(false);
          setGroupImportUserModalOpen(true);
          return;
        }
      }

      setSuccessModalStart(true);

      const { data } = await apiRequest("post", `/workspaces/:workspace_id/groups/users/manage`, {
        body: {
          _id: defaultSelectedGroup._id || defaultSelectedGroup.id,
          user_id: users,
          auth_token: workspaceDetails?.auth_token,
          importStatus,
          password,
          confirmPassword,
          sendWelcomeEmail,
          workspace_id: workspaceDetails?._id,
        },
        params: {
          workspace_id: workspaceDetails?._id,
        },
      });
      setUpdatedGroupData({
        _id: defaultSelectedGroup._id || defaultSelectedGroup.id,
        users: workspaceUsers.filter((user) => users.includes(user._id || user.id)),
        userIds: users,
        user_id: users,
      });
      setAddUserModalOpen(false);
      setGroupImportUserModalOpen(false);
      setSuccessModalStart(false);

      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    }
  };

  return (
    <>
      <Section className="gap-y-8">
        <div className="flex gap-x-10 px-2 sm:items-center">
          {pageName !== "user" && <H3 caption="Organize your pages into packages and assign users to have access.">Workspace Groups</H3>}
          <div className="flex items-center justify-center">
            <div className="ml-auto flex justify-end gap-2">
              {!viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && (
                <Button
                  version="secondary"
                  className="whitespace-nowrap"
                  onClick={() => {
                    setGroupModalOpen(true);
                    setAddGroupStatus(true);
                    setEditGroupStatus(false);
                    setEditId(null);
                  }}>
                  <PlusIcon className="h-4 w-4 stroke-[3px]" />
                  Add group
                </Button>
              )}
              {pageName === "user" && (
                <Button
                  version="secondary"
                  onClick={() => {
                    setGroupAssignToUserModalOpen(true);
                  }}>
                  <PlusIcon className="h-4 w-4 stroke-[3px]" />
                  Add group
                </Button>
              )}
            </div>
          </div>
        </div>
        {loaded ? (
          <div className="relative grid w-full gap-y-4">
            {groups?.length > 0 ? (
              <div className={classNames("space-y-4", pageName !== "user" ? "pl-2" : "")}>
                {groups.map((group) => {
                  return (
                    <DomoWorkspaceGroupItem
                      key={group.id}
                      viewOnly={viewOnly}
                      workspaceId={workspaceId}
                      defaultGroup={group}
                      pageOptions={pageOptions}
                      operatorOptions={operatorOptions}
                      setGroups={handleSetGroups}
                      setDeleteGroup={setDeleteGroup}
                      setCloneGroup={setCloneGroup}
                      setAddIconGroup={setAddIconGroup}
                      trustedAttributeArr={trustedAttributeArr}
                      workspaceDetailsData={workspaceDetails}
                      workspaceUsers={workspaceUsers}
                      importable
                      userModalOpen={userModalOpen}
                      workspaceDetails={workspaceDetails}
                      groups={groups}
                      updatedGroupData={updatedGroupData}
                    />
                  );
                })}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        ) : (
          <div className={classNames("h-20 transition-all duration-300", "opacity-100")}>
            <Preloader
              className="h-[80px]"
              circleDimension="10"
              size="base"
            />
          </div>
        )}
      </Section>
      <GroupModalAdd
        isOpen={groupModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupModalOpen}
        setRefresh={() => loadGroupsData(workspaceUsers)}
        activeSSO={activeSSO}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}
        title="Group"
        secondaryTitle="Add"
        loadUsers={() => loadWorkspaceUserData(workspaceUsers, workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" ? "/users/list" : `/workspaces/${workspaceDetails?._id}/domo-users/list`)}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
        users={workspaceUsers}
        workspaceDetails={workspaceDetails}
        importable
      />
      <Modal
        title="Group"
        secondaryTitle="Clone"
        isOpen={!!cloneGroup}
        onCancel={() => setCloneGroup(null)}
        onSuccess={handleCloneGroup}
        defaultOptions={{
          onSuccessButtonText: "Clone",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: isCloneGroupLoading,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to clone {cloneGroup?.name} ?</div>
      </Modal>
      <WorkspaceGroupIconManageModal
        title={`${addIconGroup?.name} / Icon`}
        secondaryTitle={`${addIconGroup?.image ? "Edit" : "Add"}`}
        isOpen={!!addIconGroup}
        onCancel={() => setAddIconGroup(null)}
        onSuccess={handleAddIconGroup}
        workspaceDetails={workspaceDetails}
        isAddIconGroupLoading={isAddIconGroupLoading}
        groupDetails={addIconGroup}
      />
      <Modal
        title="Group"
        secondaryTitle="Delete"
        isOpen={!!deleteGroup}
        onCancel={() => setDeleteGroup(null)}
        onSuccess={handleDeleteGroup}
        isLoading={isDeleteGroupLoading}
        defaultOptions={{
          onSuccessButtonText: "Delete",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}></Modal>
      <WorkspaceManageUsersModal
        title={`${workspaceDetails?.name} / Users`}
        secondaryTitle="Add"
        isOpen={addUserModalOpen}
        defaultSelectedUsers={defaultSelectedGroup?.users?.map((user) => user._id || user.id)}
        onSuccess={(users, importStatus, fromImportModal) => {
          handleGroupUserChanges(users, importStatus, fromImportModal);
        }}
        onCancel={() => setAddUserModalOpen(false)}
        workspaceId={workspaceDetails?._id}
        workspaceDetailsData={workspaceDetails}
        workspaceUsers={workspaceUsers}
        isImportModalOpen={groupImportUserModalOpen}
        setIsImportModalOpen={setGroupImportUserModalOpen}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        hideCrossIcon={false}
        disableStatus={successModalStart}
      />
      <GroupAssignToUserModal
        isOpen={groupAssignToUserModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupAssignToUserModalOpen}
        setRefresh={loadGroupsData}
        title={`${props?.userDetails?.name}/${workspaceDetails?.name}/Group Assign`}
        groups={props?.workspace?.groups || []}
        defaultGroups={Array.isArray(groups) ? groups.map((grp) => grp?._id) : []}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  manageGroupUser,
})(DomoWorkspaceGroupList);
