import { ArrowsRightLeftIcon, Cog8ToothIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import BillingPlan from "src/components/BillingAndPayments/BillingPlan";
import StripeSettings from "src/components/BillingAndPayments/StripeSettings";
import Transactions from "src/components/Workspaces/Workspace/BillingAndPayments/tabComponents/transactions/Transactions";

const Billing = ({ site }) => {
  const tabs = [
    { name: "Billing Plans", icon: CreditCardIcon, component: <BillingPlan /> },
    { name: "Stripe Setup", icon: Cog8ToothIcon, component: <StripeSettings /> },
    { name: "Transaction History", icon: ArrowsRightLeftIcon, component: <Transactions /> },
  ];

  return (
    <>
      <H1>Billing & Payments</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(Billing);
