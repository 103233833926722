import EditOnHoverInput from "src/components/Shared/Forms/Inputs/EditOnHoverInput";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { manageSiteSettings } from "src/actions/site";
import { getSiteData } from "src/helpers/siteHelper";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { objectToFormData, validateHex } from "src/helpers";
import WorkspaceLayouts from "src/components/Workspaces/Workspace/WorkspaceLayoutStyles/WorkspaceLayouts";

const SiteThemeAndStyles = ({ ...props }) => {
  const [siteName, setSiteName] = useState(props?.site.site_name || "");
  const [siteLoading, setSiteLoading] = useState(false);
  const [updatedSite, setUpdatedSite] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (props.site?._id) {
      setUpdatedSite(getSiteData(props.site, "layout"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site._id]);

  const handleStylesUpdate = async (body) => {
    let updatedValues = body ? body : updatedSite;
    setSiteLoading(true);

    if (updatedValues.max_width && !/^(\d{1,2}(\.\d+)?%|100%|\d+(?:\.\d+)?px)$/.test(updatedValues.max_width)) {
      toast.error("Please enter a valid max width. For example, 100% or 100px.");
      return;
    }

    if (updatedValues.image_favicon !== props?.site.image_favicon) {
      // await apiRequest("DELETE", `/images/image_favicon`);
    }
    if (updatedValues.image_logo !== props?.site.image_logo) {
      // await apiRequest("DELETE", `/images/image_logo`);
    }
    if (updatedValues.background_logo !== props?.site.background_logo) {
      // await apiRequest("DELETE", `/images/background_logo`);
    }

    try {
      const message = await props.manageSiteSettings(objectToFormData({ ...updatedValues, site_name: siteName }), "settings/layouts/colors-and-logos", {
        "Content-Type": "multipart/form-data",
      });
      setTimeout(() => {
        toast.success(message);
      }, 300);
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        setTimeout(() => {
          toast.error(error.message);
        }, 300);
      }
    } finally {
      setSiteLoading(false);
    }
  };

  useEffect(() => {
    setSiteName(props?.site.site_name);
  }, [props?.site.site_name]);

  const handleStylesDataChange = (keyValue = {}) => {
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleSquareLogoStylesDataChange = (keyValue = {}) => {
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const onClear = () => {
    setIsDisabled(false);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData(props.site, "all"),
    });
  };

  useEffect(() => {
    const isValid = Object.keys(updatedSite).every((key) => {
      if (key.includes("color") && updatedSite[key]) {
        return validateHex(updatedSite[key]);
      }
      return true;
    });

    setIsDisabled(!isValid);
  }, [updatedSite]);

  return (
    <>
      <WorkspaceLayouts />
      <EditContainer
        title="Change your logos and branding"
        preview={{ text: "White-label the customer experience with customize colors and images." }}
        onSuccess={handleStylesUpdate}
        onCancel={onClear}
        defaultOpen={true}
        fullWidth={true}
        isLoading={siteLoading}
        isDisabled={isDisabled}
        borderBottom={false}>
        <div className="mt-8">
          <StylesBuilder
            data={{ ...props?.site, name: props?.site.site_name }}
            handleBrowserTabText={
              <EditOnHoverInput
                className="max-w-[120px] font-medium text-gray-700"
                value={siteName}
                onChange={(e) => {
                  setSiteName(e.target.value);
                }}
              />
            }
            title="Styles & Text"
            secondaryTitle="Select the perfect set of colors and styles along with a logo and favicon."
            updatedSite={updatedSite}
            setUpdatedSite={setUpdatedSite}
            handleStylesDataChange={handleStylesDataChange}
            handleSquareLogoStylesDataChange={handleSquareLogoStylesDataChange}
            propsButtonOption={{ buttonLoader: siteLoading, siteOnly: true }}
          />
        </div>
      </EditContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteThemeAndStyles);
