import { ClipboardDocumentListIcon, CodeBracketIcon, GlobeAltIcon, LinkIcon, PlusIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import BubbleIconList from "src/components/Shared/BubbleIconList";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const PageEntry = ({ user, page, setDeleteId, editPage, pagePrev, pageType, workspaceId, activeUserAssignModal = () => {}, activeGroupAssignModal = () => {}, pageSelect = () => {}, setDomoPageIdForRefresh, ...props }) => {
  const [items, setItems] = useState([]);
  const [pageDetails, setPageDetails] = useState({});
  const [loader, setLoader] = useState(true);

  const pageWorkspaceId = page.workspace_id?._id || page.workspace_id || workspaceId;

  const iconTypes = {
    DOMO: {
      icon: <PresentationChartBarIcon />,
      title: "Private Embed",
      subTitle: "Domo iFrame",
    },
    QUICKSIGHT: {
      icon: <PresentationChartBarIcon />,
      title: "Private Embed",
      subTitle: "QuickSight iFrame",
    },
    METABASE: {
      icon: <PresentationChartBarIcon />,
      title: "Static Embed",
      subTitle: "Metabase iFrame",
    },
    FORM: {
      icon: <ClipboardDocumentListIcon />,
      title: "Form",
    },
    CUSTOM_HTML: {
      icon: <CodeBracketIcon />,
      title: "Custom HTML",
    },
    EXTERNAL_LINK: {
      icon: <LinkIcon />,
      title: "External Link",
    },
  };

  useEffect(() => {
    let item = [];
    if (!props.is_global) {
      if (page.workspace_id?.page?.is_global_template === true) {
        item.push({ name: "View full-screen", onClick: () => pagePrev(page), color: "text-grey-500 hover:text-highlightColor" });
      } else {
        if (authorizeUserComponentAccess(user, pageWorkspaceId, "page", ["update"]) && (props?.workspaceDetails?._id || user?.type === "admin")) {
          item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500 hover:text-highlightColor" });
        }
        item.push({ name: "View full-screen", onClick: () => pagePrev(page), color: "text-grey-500 hover:text-highlightColor" });
        if (authorizeUserComponentAccess(user, pageWorkspaceId, "page", ["update"]) && (props?.workspaceDetails?._id || user?.type === "admin")) {
          item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
        }
      }
    } else {
      if (user?.type === "admin" && authorizeUserComponentAccess(user, pageWorkspaceId, "page", ["update"])) {
        item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500 hover:text-highlightColor" });
      }
      item.push({ name: "View full-screen", onClick: () => pagePrev(page), color: "text-grey-500 hover:text-highlightColor" });
      if (user?.type === "admin" && authorizeUserComponentAccess(user, pageWorkspaceId, "page", ["update"])) {
        item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
      }
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page, pageWorkspaceId]);

  const getPageDetails = useCallback(async () => {
    const { data } = await apiRequest("GET", `/workspaces/:workspace_id/domo-pages/:page_id`, {
      queries: { workspaceId: props?.workspaceDetails?._id },
      params: { workspace_id: props?.workspaceDetails?._id, page_id: page?.id },
    });
    setPageDetails(data?.data);
    setLoader(false);
  }, [props?.workspaceDetails, page?.id]);

  useEffect(() => {
    if (pageType === "JWT_FULL_EMBED") {
      getPageDetails();
    }
  }, [getPageDetails, pageType]);

  useEffect(() => {
    if (props?.domoPageIdForRefresh && props?.domoPageIdForRefresh.includes(page?.id)) {
      setLoader(true);
      setDomoPageIdForRefresh(props?.domoPageIdForRefresh.filter((p) => p !== page?.id));
      getPageDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPageDetails, props?.domoPageIdForRefresh, page?.id]);

  return (
    <TableRow keyId={page?.id || page?._id}>
      <TRBody className="w-[300px] whitespace-pre-wrap">
        <div className={"flex  items-center gap-3 " + (props.depthLevel > 0 ? `pl-${props.depthLevel}` : "")}>
          {pageType === "JWT_FULL_EMBED" && <Checkbox onChange={(e) => pageSelect(e, page.id)} />}
          <div className="relative flex flex-col font-medium text-gray-900 sm:flex-row sm:items-center">
            <div className="line-clamp-1">{page.name}</div>
            {!props.is_global && page.workspace_id && (page.workspace_id?.page?.is_global_template === true || page.workspace_id.length > 0) && (
              <div
                data-tooltip-id={`${page._id}-is_global`}
                className="-ml-1 mt-1 flex w-[60px] cursor-default items-center gap-x-2 rounded bg-gray-50 px-[3px] py-[2px] text-xs text-gray-300 sm:m-0 sm:ml-3 sm:w-20 sm:bg-transparent">
                <GlobeAltIcon className="h-4 w-4 sm:h-5 sm:w-5" />
                Global
              </div>
            )}
          </div>
          <ReactTooltip
            id={`${page._id}-is_global`}
            delayShow={200}
            positionStrategy="fixed"
            className="rounded bg-gray-700 px-2 py-2 opacity-100">
            <div className="font-normal leading-[10px]">Global pages are managed by admins.</div>
          </ReactTooltip>
        </div>
      </TRBody>
      {!workspaceId && (
        <TRBody className="w-[400px] whitespace-pre-wrap">
          <BubbleIconList
            data={
              props?.is_global
                ? page?.workspace_id?.map((item) => ({ name: item?.name, image: item?.square_logo || item?.image_logo || item?.image_favicon, id: item?._id }))
                : [{ name: page?.workspace_id?.name, image: page?.workspace_id?.square_logo || page?.workspace_id?.image_logo || page?.workspace_id?.image_favicon, id: page?.workspace_id?._id }]
            }
            type="workspace"
            className="!justify-start"
            rounded={false}
          />
        </TRBody>
      )}
      {pageType === "IFRAME_EMBED" && (
        <>
          <TRBody className="whitespace-pre-wrap">
            <div className="relative flex items-center gap-x-4 font-medium text-gray-900">
              <div className="h-6 w-6">{iconTypes[page.page_type]?.icon}</div>
              <div className="flex flex-col items-start">
                <div className="hidden sm:flex">{iconTypes[page.page_type]?.title}</div>
                <div className="hidden text-gray-300 sm:flex">{iconTypes[page.page_type]?.subTitle}</div>
              </div>
            </div>
          </TRBody>
          <TRBody className="whitespace-pre-wrap">
            <div className="flex justify-end">
              <QuickMenu items={items} />
            </div>
          </TRBody>
        </>
      )}
      {pageType === "JWT_FULL_EMBED" && (
        <>
          <TRBody className="!whitespace-nowrap !px-5 !py-3 !text-sm">
            <div className="flex items-center gap-2">
              {loader ? (
                <Preloader
                  className={"!w-[200px] !p-0"}
                  size="sm"
                  circleDimension="6"
                />
              ) : (
                <>
                  <BubbleIconList
                    className="!justify-start"
                    data={props?.users.filter((item) => pageDetails?.visibility?.userIds.includes(item.id))}
                  />
                  <Button
                    version="black"
                    className="hidden !h-8 !px-2 md:flex"
                    onClick={() => {
                      activeUserAssignModal([page.id], pageDetails?.visibility?.userIds, pageDetails?.ownerId);
                    }}>
                    <PlusIcon className="h-5 w-5" /> User
                  </Button>
                </>
              )}
            </div>
          </TRBody>
          <TRBody className="!whitespace-nowrap !px-5 !py-3 !text-sm">
            <div className="flex items-center gap-2">
              {loader ? (
                <Preloader
                  className={"!p-0"}
                  size="sm"
                  circleDimension="6"
                />
              ) : (
                <>
                  <BubbleIconList
                    className="!justify-start"
                    data={props?.groups.filter((item) => pageDetails?.visibility?.groupIds.includes(item.id))}
                  />
                  <Button
                    version="black"
                    className="hidden !h-8 !px-2 md:flex"
                    onClick={() => {
                      activeGroupAssignModal([page.id], pageDetails?.visibility?.groupIds);
                    }}>
                    <PlusIcon className="h-5 w-5" /> Group
                  </Button>
                </>
              )}
            </div>
          </TRBody>
        </>
      )}
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(PageEntry);
