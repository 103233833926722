import React, { useState } from "react";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import WorkspaceIntegrationsList from "../../Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import { v4 } from "uuid";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { classNames } from "src/helpers/classNames";

const Embedding = ({ integrations, refreshIntegrations, workspaceType, setWorkspaceType, integrationCreds, setIntegrationCreds }) => {
  const [jwtCredModalOpen, setJwtCredModalOpen] = useState(false);

  const workspaceDetails = {
    _id: v4().slice(0, 12),
    name: "",
    workspace_type: "IFRAME_EMBED",
  };

  const updateWorkspaceType = () => {
    let type = "IFRAME_EMBED";

    if (workspaceType === "IFRAME_EMBED") {
      type = "JWT_FULL_EMBED";
    }

    if (integrations?.length === 0) {
      setJwtCredModalOpen(true);
    } else {
      setWorkspaceType(type);
    }
  };

  return (
    <>
      <EditContainer
        title="Workspace Type"
        subtitle="Do you want simple view-only or connect a complete self-service analytics environment."
        preview={{
          text: "Workspace behavior is set as: " + workspaceType,
        }}
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}
        defaultOpen={true}
        fullWidth={true}>
        <ToggleHeader
          title="Full JWT application Embed"
          headerContainerClassName="!mb-0"
          subtitle="Embed a full BI tool for your clients to use.">
          <Toggle
            checked={workspaceType === "JWT_FULL_EMBED"}
            onChange={updateWorkspaceType}
          />
        </ToggleHeader>
      </EditContainer>
      {workspaceType === "IFRAME_EMBED" && (
        <div className={classNames("hidden")}>
          <WorkspaceIntegrationsList
            workspaceId={integrations?.length > 0 ? workspaceDetails?._id : null}
            is_global={false}
            isDefaultOpen={true}
            workspaceDetails={integrations?.length > 0 ? workspaceDetails : null}
            workspaceAdd={true}
            blockSave={integrations?.length}
            jwtCredModalOpen={jwtCredModalOpen}
            integrationCreds={integrationCreds}
            setIntegrationCreds={setIntegrationCreds}
            onModalClose={() => setJwtCredModalOpen(false)}
            onModalSuccess={() => {
              if (integrations?.length === 0) {
                setWorkspaceType("JWT_FULL_EMBED");
                setJwtCredModalOpen(false);
                refreshIntegrations();
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default Embedding;
