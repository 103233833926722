import moment from "moment";

export const previousDateInString = (date, format = "MM/DD/YYYY") => {
  const targetDate = moment.utc(date).startOf("day");
  const today = moment.utc().startOf("day");
  const daysDiff = today.diff(targetDate, "days");

  if (daysDiff === 0) return "Today";
  if (daysDiff === 1) return "Yesterday";
  if (daysDiff <= 6) return `${daysDiff} days ago`;
  if (daysDiff <= 13) return "1 week ago";
  if (daysDiff <= 20) return "2 weeks ago";
  if (daysDiff <= 27) return "3 weeks ago";
  if (daysDiff <= 59) return "1 month ago";
  if (daysDiff <= 89) return "2 months ago";
  if (daysDiff <= 119) return "3 months ago";
  if (daysDiff <= 149) return "4 months ago";
  if (daysDiff <= 179) return "5 months ago";
  if (daysDiff <= 209) return "6 months ago";
  if (daysDiff <= 239) return "7 months ago";
  if (daysDiff <= 269) return "8 months ago";
  if (daysDiff <= 299) return "9 months ago";
  if (daysDiff <= 329) return "10 months ago";
  if (daysDiff <= 359) return "11 months ago";
  if (daysDiff <= 365) return "1 year ago";

  return "A long time ago";
};

export const daysDifferent = (date1, date2) => {
  const startDate = moment(new Date(date1));
  const endDate = moment(new Date(date2));
  const daysDiff = endDate.diff(startDate, "days");

  return daysDiff;
};
