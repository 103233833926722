import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyUser } from "src/actions/auth";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import AuthDataLayout from "src/layouts/AuthDataLayout";

const ForgotPasswordContent = ({ site, seoTitle, ...props }) => {
  const [disabled, setDisabled] = useState(false);
  const [cred, setCred] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    try {
      const { message } = await props.verifyUser({ email: cred });
      setCred("");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <AuthDataLayout
      header={
        <AuthHeader
          seoTitle={seoTitle}
          title="Send a reset link"
          site={site}
          switchTo={{
            startText: "Enter your sign up email and we’ll take care of the rest",
            location: site?.site_domain || "/",
          }}
        />
      }>
      <AuthContainer site={site}>
        {/* Email */}
        <div className="w-full">
          <Input
            inline={true}
            name="contact-email"
            label="Email"
            error={"error" === "Email field is required"}
            value={cred}
            onChange={(e) => setCred(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit(e);
              }
            }}
          />
        </div>

        <div className="mt-4 w-full">
          <Button
            className="!text-sm"
            disabled={disabled}
            onClick={onSubmit}
            width="w-full">
            Send reset link
          </Button>
        </div>
      </AuthContainer>
      <p className="text-regular mt-4 text-center font-light text-gray-600">
        Return to
        <Link
          to="/login"
          className="pl-2 text-highlightColor no-underline hover:underline">
          Login page
        </Link>
      </p>
    </AuthDataLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { verifyUser })(ForgotPasswordContent);
