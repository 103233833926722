import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import { classNames } from "src/helpers/classNames";

const WorkspacePermissionItem = ({ data, index, activeUser, setActiveUser = () => {}, setPermissionChanges = () => {}, disabled = false, userPermissions = {}, userType = "admin" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [access, setAccess] = useState("None");

  useEffect(() => {
    if (data?.code === "page") {
      setAccess(activeUser?.page_update ? "Full" : "None");
    } else if (data?.code === "form") {
      setAccess(activeUser?.form_update ? "Full" : "None");
    } else if (data?.code === "user") {
      setAccess(activeUser?.user_update ? "Full" : "None");
    } else if (data?.code === "settings") {
      setAccess(
        (activeUser?.alias_update || activeUser?.alias_update === undefined) &&
          (activeUser?.sso_update || activeUser?.sso_update === undefined) &&
          (activeUser?.smtp_update || activeUser?.smtp_update === undefined) &&
          (activeUser?.billing_update || activeUser?.billing_update === undefined) &&
          (activeUser?.layout_update || activeUser?.layout_update === undefined) &&
          (activeUser?.integration_update || activeUser?.integration_update === undefined) &&
          (activeUser?.workspace_filter_update || activeUser?.workspace_filter_update === undefined) &&
          (activeUser?.workspace_delete || activeUser?.workspace_delete === undefined)
          ? "Full"
          : activeUser?.alias_update || activeUser?.sso_update || activeUser?.smtp_update || activeUser?.billing_update || activeUser?.layout_update || activeUser?.integration_update || activeUser?.workspace_filter_update || activeUser?.workspace_delete
            ? "Partial"
            : "None",
      );
    } else if (data?.code === "group") {
      setAccess(
        (activeUser?.group_content_page_update || activeUser?.group_content_page_update === undefined) && (activeUser?.group_user_update || activeUser?.group_user_update === undefined)
          ? "Full"
          : activeUser?.group_content_page_update || activeUser?.group_user_update
            ? "Partial"
            : "None",
      );
    } else if (data?.code === "permission") {
      setAccess(
        (activeUser?.permission_editor_create || activeUser?.permission_editor_create === undefined) && (activeUser?.permission_editor_update || activeUser?.permission_editor_update === undefined)
          ? "Full"
          : activeUser?.permission_editor_create || activeUser?.permission_editor_update
            ? "Partial"
            : "None",
      );
    }
  }, [data?.code, activeUser]);

  const handleUserPagePermission = (e) => {
    if (data.code === "user") {
      setActiveUser((prevData) => ({
        ...prevData,
        user_update: e.target.checked,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "page") {
      setActiveUser((prevData) => ({
        ...prevData,
        page_update: e.target.checked,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "form") {
      setActiveUser((prevData) => ({
        ...prevData,
        form_update: e.target.checked,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "group") {
      setActiveUser((prevData) => ({
        ...prevData,
        group_update: e.target.checked,
        group_content_page_update: e.target.checked,
        group_user_update: e.target.checked,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "permission") {
      setActiveUser((prevData) => ({
        ...prevData,
        permission_update: e.target.checked,
        permission_editor_update: e.target.checked,
        permission_editor_create: e.target.checked,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "settings") {
      setActiveUser((prevData) => ({
        ...prevData,
        settings_update: e.target.checked,
        alias_update: e.target.checked,
        sso_update: e.target.checked,
        smtp_update: e.target.checked,
        billing_update: e.target.checked,
        layout_update: e.target.checked,
        integration_update: e.target.checked,
        workspace_filter_update: e.target.checked,
        workspace_delete: e.target.checked,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }
  };

  const isChecked = useMemo(() => {
    if (data.code === "user" || data.code === "page" || data.code === "form" || data.code === "permission" || data.code === "settings") {
      return activeUser[`${data?.code}_update`];
    } else if (data.code === "group") {
      if (activeUser?.page_update) {
        return activeUser[`${data?.code}_update`];
      } else {
        return activeUser?.group_user_update;
      }
    }
  }, [activeUser, data?.code]);

  return (
    <>
      <div className={classNames("rounded-lg border border-transparent p-1 transition-all duration-200")}>
        <div className={classNames("relative z-0 flex overflow-hidden", index % 2 === 0 ? "bg-gray-50" : "")}>
          <div className={classNames("absolute left-0 top-0 z-[-1] h-full w-full  rounded-md bg-transparent transition-all duration-200")}></div>
          {(userType === "admin" || userPermissions[`${data?.code}_update`]) && (
            <div className={classNames("py-5 pl-4")}>
              <Checkbox
                forceCheck={disabled || (userType === "editor" && !userPermissions[`${data?.code}_update`])}
                disabled={disabled || (userType === "editor" && !userPermissions[`${data?.code}_update`])}
                isChecked={isChecked}
                onChange={handleUserPagePermission}
                checkboxInputClass={classNames("!w-5 !h-5 !min-w-[20px] !min-h-[20px]")}
                checkboxBoxClass={"!w-5 !h-5 !min-w-[20px] !min-h-[20px]"}
                checkboxIconClass={"!w-5 !h-5"}
              />
            </div>
          )}
          <Button className={classNames("relative z-0 !block !h-auto !w-full cursor-text !justify-start overflow-hidden border-none !py-4 !text-left !shadow-none transition-all duration-200 hover:bg-gray-50 focus:!ring-0", index % 2 === 0 ? "!bg-gray-50" : "!bg-gray-50/50")}>
            <div className="w-full flex-shrink text-gray-950">
              <h2 className={classNames("flex gap-x-4 text-base font-semibold text-gray-900 transition-all duration-200 xl:text-lg")}>
                {data.name}
                <div
                  className={classNames(
                    "flex items-center justify-center gap-2 rounded-full px-3 pb-[2px] text-sm font-semibold text-gray-900 transition-all duration-200 xl:text-base",
                    access === "Full" && "bg-green-50/80",
                    access === "Partial" && "bg-amber-50/60",
                    access === "None" && "bg-gray-50/80",
                  )}>
                  <p className={classNames("font-light", access === "Full" && "text-green-500", access === "Partial" && "text-amber-500", access === "None" && "text-gray-300")}>
                    Access:<span className="pl-2 font-normal opacity-100">{access}</span>
                  </p>
                </div>
              </h2>
              <div className={classNames("text-xs text-gray-400 transition-all duration-200 xl:text-[13px]")}>{data.caption}</div>
            </div>
            <div className="mr-10 flex flex-shrink-0 items-center justify-center"></div>
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={classNames(
                "flex h-8 w-8 flex-shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-black/5 transition-all duration-200",
                isOpen ? "-rotate-180" : "",
                ["user", "page", "form"].includes(data.code) ? "invisible opacity-0" : "visible opacity-100",
              )}>
              {!["user", "page", "form"].includes(data.code) && <ChevronDownIcon className={classNames("h-5 w-5 text-gray-500 transition-all duration-200")} />}
            </div>
          </Button>
        </div>
        <div className={classNames("relative transition-all duration-200", isOpen && !["user", "page", "form"].includes(data.code) ? "max-h-[999999px]" : "max-h-0 overflow-hidden")}>
          <div className="relative min-h-[0px] px-4 py-6">
            {data?.code === "group" && (
              <div className="space-y-4">
                <div className="space-y-3 pl-6">
                  {(userType === "admin" || userPermissions?.group_content_page_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.group_content_page_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.group_content_page_update)}
                        isChecked={activeUser?.group_content_page_update}
                        checkboxName={"group_content_page_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            group_update: e.target.checked && prevData.group_user_update ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Only page and condition control"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.group_user_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.group_user_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.group_user_update)}
                        isChecked={activeUser?.group_user_update}
                        checkboxName={"group_user_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            group_update: e.target.checked && prevData.group_content_page_update ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow only manage access of users"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {data?.code === "permission" && (
              <div className="space-y-4">
                <div className="space-y-3 pl-6">
                  {(userType === "admin" || userPermissions?.permission_editor_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.permission_editor_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.permission_editor_update)}
                        isChecked={activeUser?.permission_editor_update}
                        checkboxName={"permission_editor_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            permission_update: e.target.checked && prevData?.permission_editor_create ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Workspace editor can grant permission access to permissions they hold"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.permission_editor_create) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.permission_editor_create)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.permission_editor_create)}
                        isChecked={activeUser?.permission_editor_create}
                        checkboxName={"permission_editor_create"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            permission_update: e.target.checked && prevData?.permission_editor_update ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow creation of additional editors to workspace."}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {data?.code === "settings" && (
              <div className="space-y-4">
                <div className="space-y-3 pl-6">
                  {(userType === "admin" || userPermissions?.layout_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.layout_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.layout_update)}
                        isChecked={activeUser?.layout_update}
                        checkboxName={"layout_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update: e.target.checked && prevData?.alias_update && prevData?.sso_update && prevData?.smtp_update && prevData?.billing_update && prevData?.integration_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow full access to styling controls"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.alias_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.alias_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.alias_update)}
                        isChecked={activeUser?.alias_update}
                        checkboxName={"alias_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update: e.target.checked && prevData?.sso_update && prevData?.smtp_update && prevData?.billing_update && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow assigning of workspace alias"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.sso_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.sso_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.sso_update)}
                        isChecked={activeUser?.sso_update}
                        checkboxName={"sso_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update:
                              e.target.checked && prevData?.alias_update && prevData?.smtp_update && prevData?.billing_update && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow full access to SSO controls"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.smtp_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.smtp_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.smtp_update)}
                        isChecked={activeUser?.smtp_update}
                        checkboxName={"smtp_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update:
                              e.target.checked && prevData?.sso_update && prevData?.billing_update && prevData?.alias_update && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow full access to SMTP controls"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.billing_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.billing_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.billing_update)}
                        isChecked={activeUser?.billing_update}
                        checkboxName={"billing_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update: e.target.checked && prevData?.sso_update && prevData?.smtp_update && prevData?.alias_update && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow full access to billing & payment controls"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.integration_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.integration_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.integration_update)}
                        isChecked={activeUser?.integration_update}
                        checkboxName={"integration_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update: e.target.checked && prevData?.alias_update && prevData?.sso_update && prevData?.smtp_update && prevData?.billing_update && prevData?.layout_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow full access to integration controls"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.workspace_filter_update) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.workspace_filter_update)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.workspace_filter_update)}
                        isChecked={activeUser?.workspace_filter_update}
                        checkboxName={"workspace_filter_update"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update: e.target.checked && prevData?.alias_update && prevData?.sso_update && prevData?.smtp_update && prevData?.billing_update && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_delete ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow full access to workspace filter controls"}
                      />
                    </div>
                  )}
                  {(userType === "admin" || userPermissions?.workspace_delete) && (
                    <div>
                      <Checkbox
                        forceCheck={disabled || (userType === "editor" && !userPermissions?.workspace_delete)}
                        disabled={disabled || (userType === "editor" && !userPermissions?.workspace_delete)}
                        isChecked={activeUser?.workspace_delete}
                        checkboxName={"workspace_delete"}
                        onChange={(e) => {
                          setActiveUser((prevData) => ({
                            ...prevData,
                            settings_update: e.target.checked && prevData?.alias_update && prevData?.sso_update && prevData?.smtp_update && prevData?.billing_update && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_filter_update ? true : false,
                            [e.target.name]: e.target.checked,
                          }));
                          setPermissionChanges((prevData) => prevData + 1);
                        }}
                        checkboxLabel={"Allow ability to delete workspace"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspacePermissionItem;
