import { ArrowsRightLeftIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import UserProfile from "src/components/Users/UserProfile";
import Button from "src/components/Shared/Buttons/Button";
import BillingManagerManageModal from "./BillingManagerManageModal";
import { apiRequest } from "src/async/apiUtils";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import useFetch from "src/hooks/useFetch";

const BillingManager = ({ workspaceDetails }) => {
  const [assignModal, setAssignModal] = useState(false);
  const [assignedUser, setAssignedUser] = useState({
    loading: true,
    data: null,
  });

  const {
    response: { data: users },
  } = useFetch(`/workspaces/${workspaceDetails?._id}/users`);

  const loadAssignedUser = async () => {
    try {
      const response = await apiRequest("get", `workspaces/${workspaceDetails?._id}/billing/user/assign`);
      setAssignedUser({ loading: false, data: response?.data?.data });
    } catch (err) {
      setAssignedUser({ loading: false, data: null });
    }
  };

  useEffect(() => {
    loadAssignedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails]);

  return (
    <div className="rounded-md border p-4 ">
      {assignedUser?.loading ? (
        <Preloader />
      ) : !assignedUser?.data ? (
        <div className="flex flex-col items-center justify-center rounded-lg border-2 border-dashed p-6">
          <Button
            version="primary"
            onClick={() => setAssignModal(true)}>
            <div className="flex gap-x-3">
              <UserPlusIcon className="h-5 w-5 stroke-2" /> Assign Billing Manager
            </div>
          </Button>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="border-b pb-3 text-xl text-slate-600">Billing Manager</div>
          <div className="flex items-center justify-between ">
            <div className="flex items-center space-x-4">
              <div className="h-16 w-16 overflow-hidden rounded-full border border-gray-200">
                <UserProfile
                  user={assignedUser?.data || {}}
                  className={"!p-1"}
                />
              </div>
              <div>
                <div className="text-2xl font-semibold text-gray-700">{assignedUser?.data?.name || "User Name"}</div>
                <div className="text-sm text-gray-500">{assignedUser?.data?.email || "user@example.com"}</div>
              </div>
            </div>
            <Button
              version="secondary"
              onClick={() => setAssignModal(true)}>
              <div className="flex gap-x-3">
                <ArrowsRightLeftIcon className="h-5 w-5 stroke-2" /> Change
              </div>
            </Button>
          </div>
        </div>
      )}
      <BillingManagerManageModal
        isOpen={assignModal}
        setIsOpen={setAssignModal}
        setRefresh={loadAssignedUser}
        defaultUser={assignedUser?.data?._id || ""}
        title={`${workspaceDetails?.name}`}
        users={users}
        secondaryTitle={"Billing Manager Assign"}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(BillingManager);
