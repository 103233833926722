import React, { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoData from "src/components/Shared/NoData/NoData";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import useFetch from "src/hooks/useFetch";
import SiteEmailManageModal from "./SiteEmailManageModal";

const EmailTemplateList = ({ type = "general" }) => {
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const {
    response: { data: emailTemplates = [], meta },
    status: { done: listLoaded, fail: listLoadedFail },
    refreshData: refreshTemplateSite,
  } = useFetch("/settings/email-templates", { query: { limit, offset, type } });

  useEffect(() => {
    refreshTemplateSite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, type]);
  return (
    <>
      <div className="grid gap-y-2">
        {listLoaded || listLoadedFail ? (
          Array.isArray(emailTemplates) && emailTemplates.length > 0 ? (
            emailTemplates.map((item) => (
              <div
                key={item._id}
                className="relative flex w-full flex-col justify-between space-y-4 rounded-md border border-gray-100 bg-white px-3 py-4 shadow md:space-y-0 lg:h-14 lg:flex-row lg:px-4 lg:py-1">
                <div className="flex flex-col">
                  <div className="">
                    <span className="text-gray-500">Subject:</span> {item.subject}
                  </div>
                  <div className="text-xs text-gray-300">{item.code}</div>
                </div>

                <Button
                  version="default"
                  className="rounded-md px-2 py-1 text-highlightColor"
                  onClick={() => {
                    setTemplateData(item);
                    setOpenTemplateModal(true);
                  }}>
                  Edit
                </Button>
              </div>
            ))
          ) : (
            <NoData />
          )
        ) : (
          <Preloader />
        )}
      </div>
      <PaginationFooter
        itemName="Email"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <SiteEmailManageModal
        data={templateData}
        isOpen={openTemplateModal}
        onCancel={() => setOpenTemplateModal(false)}
        onSuccess={() => {
          setOpenTemplateModal(false);
          refreshTemplateSite();
        }}
      />
    </>
  );
};

export default EmailTemplateList;
