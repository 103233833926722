import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
// import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import { daysDifferent } from "src/helpers/dateHelper";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import { formatCurrency } from "src/helpers";
import moment from "moment";

const AssignPlanModal = ({ isOpen = false, title = "", secondaryTitle = "", workspaceDetails, setRefresh = () => {}, activePlan, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const [isOnHold, setIsOnHold] = useState(false);
  const [isAdjust, setIsAdjust] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiRequest("put", `workspaces/:workspace_id/billing/plan/:plan_id/assign`, { body: { on_hold: !isOnHold, is_adjust: isAdjust }, params: { plan_id: selectedPlan, workspace_id: workspaceDetails?._id } });
      if (data.status === 200) {
        setRefresh();
        props.setIsOpen(!isOpen);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const adjustCalculator = useMemo(() => {
    if (!selectedPlan || !activePlan) return null;

    const selectedChangedPlan = props?.plans?.find((item) => item?._id === selectedPlan);
    if (!selectedChangedPlan) return null;

    const currentPlanDailyPrice = activePlan?.amount / daysDifferent(new Date(activePlan?.start_date), new Date(activePlan?.end_date));
    const yearlyRate = selectedChangedPlan?.pricing?.rates?.find((item) => item?.interval === "year");
    const changePlanDailyPrice = (yearlyRate?.amount - (yearlyRate?.discount_amount || 0)) / 365;

    const currentDate = new Date();
    const startDate = new Date(activePlan?.start_date);

    const daysUsed = daysDifferent(startDate, currentDate);
    const priceUsed = currentPlanDailyPrice * daysUsed;
    const remainingPrice = activePlan?.amount - priceUsed;

    const prevPlanPricePerMonth = activePlan?.amount / (activePlan?.subscription?.recurring_type === "year" ? 12 : 1);
    const changePlanPriceperMonth = (yearlyRate?.amount - (yearlyRate?.discount_amount || 0)) / 12;

    const daysTobeAdjusted = Math.floor(remainingPrice / changePlanDailyPrice);

    const newPlanBillingStartDate = new Date(activePlan?.start_date);
    newPlanBillingStartDate.setDate(newPlanBillingStartDate.getDate() + daysTobeAdjusted);

    return {
      showToggle: changePlanDailyPrice > currentPlanDailyPrice && remainingPrice > changePlanDailyPrice,
      daysDecreased: daysDifferent(activePlan?.start_date, activePlan?.end_date) - daysTobeAdjusted,
      showWarning: isOnHold && changePlanDailyPrice > currentPlanDailyPrice,
      newDailyPrice: changePlanDailyPrice.toFixed(2),
      newPlanStartDate: newPlanBillingStartDate,
      newPlan: selectedChangedPlan?.name,
      yearlyRateWithDiscount: yearlyRate?.amount - yearlyRate?.discount_amount,
      changePlanPriceperMonth,
      prevPlanPricePerMonth,
      currentPlanDailyPrice,
      remainingPrice,
    };
  }, [activePlan, selectedPlan, isOnHold, props?.plans]);

  return (
    <Modal
      title={title}
      secondaryTitle={secondaryTitle}
      isLoading={isLoading}
      isOpen={isOpen}
      onCancel={() => {
        props.setIsOpen(!isOpen);
        setSelectedPlan("");
        setIsOnHold(false);
      }}
      onSuccess={onSubmit}>
      <div className="relative w-full space-y-4">
        <div className="relative mb-4 w-full">
          <SelectMenu
            label="Change Plan"
            inline={true}
            startIndex={-1}
            options={props?.plans?.map((pln) => ({ key: pln?.name, value: pln?._id }))}
            setOption={(option) => {
              setSelectedPlan(option.value);
            }}
          />
        </div>

        {props?.isActivePlan && selectedPlan && adjustCalculator?.showToggle && (
          <div className="flex flex-col space-y-3">
            <div className="w-full rounded-lg border px-3 py-2">
              <ToggleHeader
                title="Instantly assign the new plan"
                subtitle="Enable this toggle to apply the new plan instantly, or  it will take effect after the current plan ends."
                headerContainerClassName="!mb-0"
                position="right">
                <Toggle
                  className="ml-auto"
                  checked={isOnHold}
                  onChange={(val) => {
                    setIsOnHold(val);
                    if (!val) setIsAdjust(false);
                  }}
                />
              </ToggleHeader>
            </div>
            {adjustCalculator?.showWarning && (
              <InformationAlert
                type="warning"
                content={
                  <div className="grid grid-cols-2 gap-1  text-xs">
                    <div className="col-span-2 text-sm">
                      Upgrading to <span className="ml-1 font-semibold">{adjustCalculator?.newPlan}</span> will change the next billing date to
                      <span className="ml-1 font-semibold">{moment(adjustCalculator?.newPlanStartDate).format("MMM DD, YYYY")}</span>.
                    </div>
                    <div className="col-span-2 h-[0.5px] bg-yellow-600" />
                    <div className="flex justify-between border-r-2 border-yellow-600 pr-2">
                      Current Plan:
                      <span className="ml-1 font-semibold">
                        {activePlan?.plan_details?.name} | {formatCurrency(activePlan?.amount)}/{activePlan?.subscription?.recurring_type}
                      </span>
                    </div>
                    <div className="flex justify-between ">
                      Upgrade Plan:
                      <span className="ml-1 font-semibold">
                        {adjustCalculator?.newPlan} | {formatCurrency(adjustCalculator?.yearlyRateWithDiscount)}/year
                      </span>
                    </div>
                    <div className="flex justify-between border-r-2 border-yellow-600 pr-2">
                      <span>Daily Rate:</span>
                      <span className="ml-1 font-semibold">{formatCurrency(adjustCalculator?.currentPlanDailyPrice)}/day</span>
                    </div>
                    <div className="flex justify-between  border-gray-200">
                      <span>Daily Rate:</span>
                      <span className="ml-1 font-semibold">{formatCurrency(adjustCalculator?.newDailyPrice)}/day</span>
                    </div>
                    <div className="flex justify-between border-r-2 border-yellow-600 pr-2">
                      <span> Remaining Balance: </span>
                      <span className="ml-1 font-semibold">{formatCurrency(adjustCalculator?.remainingPrice)}</span>
                    </div>
                    <div className="flex justify-between ">
                      <span> Adjusted Days: </span>
                      <span className="ml-1 font-semibold">{adjustCalculator?.daysDecreased.toFixed(0)} day(s)</span>
                    </div>
                  </div>
                }
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, {})(AssignPlanModal);
