import { RectangleStackIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DropdownWrapper from "src/components/Shared/Forms/DropdownWrapper";
import Image from "src/components/Shared/Image";
import LazyImage from "src/components/Shared/LazyImage";

const Logo = ({ children, ...props }) => {
  const [visibleLogo, setVisibleLogo] = useState(null);

  useEffect(() => {
    const logoExists =
      Object.keys(props?.workspaceDetails).length && (props?.me?.type === "user" || (props?.me?.type === "editor" && localStorage.getItem("impersonatedUserId"))) && !props?.workspaceDetails?.use_global_settings
        ? props?.workspaceDetails?.image_logo
          ? props?.workspaceDetails?.image_logo + "?" + Date.now()
          : null
        : props.site?.image_logo + "?" + Date.now();
    setVisibleLogo(logoExists);
  }, [props?.workspaceDetails, props?.me, props.site]);

  const navigateToBaseURL = () => {
    window.location.href = "/";
  };

  return (
    <div className="flex h-16 max-w-96 flex-shrink-0 items-center">
      {children}
      {props?.workspaces?.length > 1 ? (
        <div>
          <DropdownWrapper
            data={props?.workspaces?.map((workspace) => ({
              ...workspace,
              image: workspace.square_logo || workspace.image_logo || workspace.image_favicon,
            }))}
            onClick={props?.clickWorkspace}
            currentId={props?.extractWorkspaceName}
            content={
              <div className=" h-16 w-auto px-2 pt-1">
                {visibleLogo ? (
                  <div className="mb-[1px] flex h-12 py-[2px] sm:h-14">
                    <LazyImage
                      src={visibleLogo}
                      alt={"logo"}
                      width="100%"
                      height="100%"
                      effect="blur"
                      classes="object-contain object-left"
                      hideImageOnError
                    />
                  </div>
                ) : (
                  <div className="flex h-full w-full items-center justify-center rounded border border-headerColor bg-headerColor px-3 py-2 font-medium text-headerTextColor transition-all hover:border-headerTextColor hover:shadow">
                    Switch workspace
                    <RectangleStackIcon className="ml-[8px] h-5 w-5" />
                  </div>
                )}
              </div>
            }
            buttonLabel="Workspaces"
            buttonClass="w-full max-w-sm"
            buttonIcon={true}
            dropdownContainer="ml-11"
          />
        </div>
      ) : (
        <div
          className="h-16 w-auto py-2"
          onClick={navigateToBaseURL}
          style={{ cursor: "pointer" }}>
          <Image
            image={visibleLogo}
            alt={"logo"}
            className="object-contain object-left"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Logo);
