import EditContainer from "src/components/Shared/Containers/EditContainer";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { manageWorkspaceSettings } from "src/actions/workspace";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { validateHex } from "src/helpers";
import { getSiteData } from "src/helpers/siteHelper";

const WorkspaceColors = ({ workspace, setWorkspace = () => {}, ...props }) => {
  const [buttonDisabled, setButtonIsDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [stylesAndImages, setStylesAndImages] = useState({});
  const [colorSaveButtonDisabled, setIsColorSaveButtonDisabled] = useState(false);

  const handleColorsAndLogosUpdate = async () => {
    try {
      setButtonLoader(true);
      setButtonIsDisabled(true);
      const message = await props.manageWorkspaceSettings(
        {
          workspace_id: workspace?._id,
          ...stylesAndImages,
        },
        "/workspaces/:workspace_id/layouts/colors-and-logos",
        {
          "Content-Type": "multipart/form-data",
        },
      );
      setButtonIsDisabled(false);
      setButtonLoader(false);
      toast.success(message);
    } catch (error) {
      setButtonLoader(false);
      setButtonIsDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const isValid = Object.keys(stylesAndImages).every((key) => {
      if (key.includes("color") && stylesAndImages[key]) {
        return validateHex(stylesAndImages[key]);
      }
      return true;
    });

    setIsColorSaveButtonDisabled(!isValid);
  }, [stylesAndImages]);

  useEffect(() => {
    setStylesAndImages((pre) => ({ ...pre, ...getSiteData(props?.workspaceDetails, "custom_colors") }));
  }, [props?.workspaceDetails]);

  const onClear = () => {
    setStylesAndImages((pre) => ({ ...pre, ...getSiteData(props?.workspaceDetails, "custom_colors") }));
  };

  const handleLogoAndColorCgange = (keyValue = {}) => {
    setButtonLoader(false);
    setStylesAndImages((pre) => ({ ...pre, ...keyValue }));
  };

  return (
    <EditContainer
      title="Colors"
      preview={{ text: "Customize this workspace's themes and styles." }}
      onSuccess={handleColorsAndLogosUpdate}
      onCancel={onClear}
      defaultOpen={true}
      fullWidth={true}
      isDisabled={colorSaveButtonDisabled}
      isLoading={buttonLoader}
      borderBottom={false}>
      <div className="mt-8">
        <StylesBuilder
          data={workspace}
          propsButtonOption={{
            buttonLoader: buttonLoader,
            buttonIsDisabled: buttonDisabled,
          }}
          setButtonIsDisabled={setButtonIsDisabled}
          updatedSite={stylesAndImages}
          setUpdatedSite={setStylesAndImages}
          handleStylesDataChange={handleLogoAndColorCgange}
          title="Layout & Styles"
          secondaryTitle="(Colors, logo, favicon, etc.)"
          layoutType={props?.workspaceDetails?.layout_type}
          setWorkspace={setWorkspace}
        />
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceColors);
