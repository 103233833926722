import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings, deleteMailgunSmtp } from "src/actions/site";
import { manageWorkspaceSettings, deleteWorkspaceMailgunSmtp } from "src/actions/workspace";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import Stepper from "src/components/Shared/Stepper/Stepper";
import { classNames } from "src/helpers/classNames";
import { InformationCircleIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import validator from "validator";
import { H3 } from "src/components/Shared/Text/Headers";
import TestEmailSetup from "./TestEmailSetup";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import { apiRequest } from "src/async/apiUtils";
import NoData from "src/components/Shared/NoData/NoData";

const DnsList = ({ dnsList = [], settings = null }) => (
  <div>
    <div className="flex">
      <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Status</div>
      <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Type</div>
      <div className="flex w-1/2 flex-shrink items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Host</div>
      <div className="flex w-1/2 flex-shrink items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Points To</div>
    </div>
    <div className="-space-y-px">
      {dnsList?.length > 0 ? (
        dnsList?.map((item, index) => (
          <div
            className="flex border border-gray-200"
            key={index}>
            <div className={classNames("flex w-36 flex-shrink-0 items-center px-4 py-4 ")}>
              <div className={classNames("rounded-md px-2 pb-1 pt-1.5 text-xs !leading-none", item?.valid === "valid" ? "bg-green-100 text-green-700/50" : "bg-gray-100 text-gray-400")}>{item?.valid === "valid" ? "Verified" : "Unverified"}</div>
            </div>
            <div className="flex w-36 flex-shrink-0 items-center gap-1 px-4 py-4 text-sm text-gray-700">
              {item?.record_type}
              <div data-tooltip-id={`static-tooltip-${index}`}>
                <InformationCircleIcon className="h-4 w-4 cursor-pointer" />
              </div>
              <Tooltip
                id={`static-tooltip-${index}`}
                content={index === 0 ? "DKIM record" : "SPF record"}
              />
            </div>
            <div className="flex w-1/2 max-w-[calc(50%-4.5rem)] flex-shrink items-center px-4 py-4">
              <div className="flex-shrink flex-grow whitespace-pre-wrap break-all text-sm text-gray-700">{item?.name || settings?.smtp_mailgun_email?.split("@")?.pop()}</div>
              <CopyToClipboard
                text={item?.name || settings?.smtp_mailgun_email?.split("@")?.pop()}
                onCopy={() => toast.success("Copied to clipboard")}>
                <Button
                  type="button"
                  version="default"
                  className="cursor-pointer">
                  <Square2StackIcon className="h-10 w-10 p-2 text-gray-500 hover:text-highlightColor" />
                </Button>
              </CopyToClipboard>
            </div>
            <div className="flex w-1/2 max-w-[calc(50%-4.5rem)] flex-shrink items-center px-4 py-4">
              <div className="flex-shrink flex-grow whitespace-pre-wrap break-all text-sm text-gray-700">{item?.value}</div>
              <CopyToClipboard
                text={item?.value}
                onCopy={() => toast.success("Copied to clipboard")}>
                <Button
                  type="button"
                  version="default"
                  className="cursor-pointer">
                  <Square2StackIcon className="h-10 w-10 p-2 text-gray-500 hover:text-highlightColor" />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        ))
      ) : (
        <NoData />
      )}
    </div>
  </div>
);

const EmailOverview = ({ settings = null, isEnableTestConnectionButton = false, onDelete = () => {}, onTestEmailModalOpen = () => {} }) => (
  <div className="my-2 flex w-full max-w-2xl items-center gap-4">
    <div className="relative w-[70%]">
      <Input
        name="test-email"
        disabled
        value={settings?.smtp_mailgun_email || ""}
        label="SMTP APPROVED EMAIL"
        inline={true}
      />
    </div>

    <div className="relative flex flex-shrink-0 gap-4">
      <Button
        type="button"
        onClick={onDelete}
        version="secondary">
        <TrashIcon className="h-5 w-5" />
      </Button>
      {isEnableTestConnectionButton && (
        <Button
          className=""
          onClick={onTestEmailModalOpen}
          type="button">
          Test Connection
        </Button>
      )}
    </div>
  </div>
);

const EmailSelection = ({ email = null, setEmail = () => {}, onClick = () => {}, isLoading = false }) => (
  <>
    <div className="grid space-y-4 sm:space-y-8">
      <div className="flex w-full flex-wrap justify-between">
        <div className="flex w-full flex-wrap gap-y-6">
          <div>
            <div className="mb-4">
              <div className="pt-2">
                <p className="text-gray-800">Decide on an email address you'd like your emails to be sent from.</p>
                <p className="text-sm text-gray-400">Based on the email address we will create the correct DNS credentials for you.</p>
              </div>
            </div>
            <div className="flex w-full gap-x-2">
              <Input
                label="SMTP Email"
                placeholder="example: support@yourcompany.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                inline={true}
              />{" "}
              <Button
                disabled={isLoading || !validator.isEmail(email)}
                loading={isLoading}
                onClick={onClick}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const OtpSelection = ({ otp = null, setOtp = () => {}, onClick = () => {}, isLoading = false, setCurrentStep = () => {}, setEmail = () => {} }) => (
  <>
    <div className="grid space-y-4 sm:space-y-8">
      <div className="flex w-full flex-wrap justify-between">
        <div className="flex w-full flex-wrap gap-y-6">
          <div>
            <div className="mb-4">
              <div className="pt-2">
                <p className="text-gray-800">Verify Your Email to Continue.</p>
                <p className="text-sm text-gray-400">Enter the one-time password (OTP) sent to your email to complete verification.</p>
              </div>
            </div>
            <div className="flex w-full gap-x-2">
              <Input
                type="number"
                label="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                inline={true}
              />{" "}
              <Button
                disabled={isLoading}
                loading={isLoading}
                onClick={onClick}>
                Submit
              </Button>
              <Button
                version="gray"
                disabled={isLoading}
                onClick={() => {
                  setEmail("");
                  setCurrentStep(0);
                }}>
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const DnsRecords = ({ settings = null, dnsList = [], dnsVerify = false, setDnsVerify = () => {}, isLoading = false, isDnsLoading = false, onClick = () => {}, setIsDeleteDomainModalOpen = () => {} }) => (
  <div className="">
    <H3
      className="!text-base"
      caption="You will need to install the following records to complete the process.">
      Install DNS Records
    </H3>
    <EmailOverview
      settings={settings}
      onDelete={() => setIsDeleteDomainModalOpen(true)}
    />
    <div className="space-y-4">
      {isDnsLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-sm font-semibold">1</div>
              <div className="text-sm font-bold text-gray-600">Install and verify all DNS records listed below.</div>
            </div>
            <div className="w-full pl-12">
              <DnsList
                dnsList={dnsList}
                settings={settings}
              />
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-sm font-semibold">2</div>
              <div className="text-sm font-bold text-gray-600">Confirm you've added these once completed.</div>
            </div>
            <div className="w-full pl-12">
              <Checkbox
                isChecked={dnsVerify}
                onChange={(e) => setDnsVerify(e.target.checked)}
                checkboxLabel={"I've added these records."}
              />
              <div className="ml-auto flex flex-shrink-0 gap-x-4 pt-6">
                <Button
                  disabled={!dnsVerify || isLoading}
                  loading={isLoading}
                  onClick={onClick}>
                  Verify
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);

const FinalStep = ({ settings, dnsList, isDnsLoading = false, setIsDeleteDomainModalOpen = () => {}, onTestEmailModalOpen = () => {} }) => (
  <>
    <EmailOverview
      settings={settings}
      onDelete={() => setIsDeleteDomainModalOpen(true)}
      onTestEmailModalOpen={onTestEmailModalOpen}
      isEnableTestConnectionButton={true}
    />
    <TextAccordion
      headerTextPosition="left"
      headerText="See DNS records">
      {isDnsLoading ? (
        <Preloader />
      ) : (
        <DnsList
          dnsList={dnsList}
          settings={settings}
        />
      )}
    </TextAccordion>
  </>
);

const MailgunSmtpSettings = ({ workspaceId, initialSettings, onTestEmailModalOpen = () => {}, ...props }) => {
  const steps = ["Email Selection", "Email Verification", "DNS Records", "Test Setup"];
  const [settings, setSettings] = useState(initialSettings);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dnsList, setDnsList] = useState([]);
  const [isDnsLoading, setIsDnsLoading] = useState(false);

  const [dnsVerify, setDnsVerify] = useState(false);
  const [isDeleteDomainModalOpen, setIsDeleteDomainModalOpen] = useState(false);
  const [isDeleteDomain, setIsDeleteDomain] = useState(false);

  const getInitialStep = () => {
    if (!settings?.smtp_mailgun_email) return 0;
    if (!["dns_verified", "email_verified"].includes(settings?.smtp_mailgun_status)) return 2;
    if (settings?.smtp_mailgun_status === "dns_verified") return 3;
    if (settings?.smtp_mailgun_status === "email_verified") return 4;
    return 0;
  };

  const [currentStep, setCurrentStep] = useState(getInitialStep());

  const getDnsList = async (from = "") => {
    try {
      if (from !== "onVerify") setIsDnsLoading(true);
      const { data } = await apiRequest("GET", workspaceId ? `/workspaces/:workspace_id/smtp/mailgun/dns` : `/settings/smtp/mailgun/dns`, { params: { workspace_id: workspaceId } });
      setDnsList(data.data || []);
    } catch (error) {
    } finally {
      setIsDnsLoading(false);
    }
  };

  const onHandleEmailSubmit = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/smtp/mailgun/send/otp` : `/settings/smtp/mailgun/send/otp`, { body: { email }, params: { workspace_id: workspaceId } });
      setOtp("");
      setCurrentStep(1);
      toast.success(data?.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleOtpSubmit = async () => {
    try {
      setIsLoading(true);
      const message = workspaceId ? await props.manageWorkspaceSettings({ email, otp, workspace_id: workspaceId }, "/workspaces/:workspace_id/smtp/mailgun") : await props.manageSiteSettings({ email, otp }, "/settings/smtp/mailgun");
      setEmail("");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleDnsVerify = async () => {
    try {
      setIsLoading(true);
      const message = workspaceId ? await props.manageWorkspaceSettings({ email, workspace_id: workspaceId, dnsVerify }, "/workspaces/:workspace_id/smtp/mailgun/dns/verify") : await props.manageSiteSettings({ dnsVerify }, "/settings/smtp/mailgun/dns/verify");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      getDnsList("onVerify");
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteDomain = async () => {
    try {
      setIsDeleteDomain(true);
      const message = workspaceId ? await props?.deleteWorkspaceMailgunSmtp({ workspace_id: workspaceId }) : await props?.deleteMailgunSmtp();
      setIsDeleteDomainModalOpen(false);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteDomain(false);
    }
  };

  useEffect(() => {
    if (settings?.smtp_mailgun_email) getDnsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.smtp_mailgun_email]);

  useEffect(() => {
    if (settings) {
      setCurrentStep(getInitialStep());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    setSettings(workspaceId ? props?.workspaceDetails : props?.site);
  }, [workspaceId, props?.workspaceDetails, props?.site]);

  return (
    <div className="pt-2">
      <div className="grid gap-y-6">
        <div className="">
          <Stepper
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        {currentStep === 0 ? (
          <EmailSelection
            email={email}
            setEmail={setEmail}
            onClick={onHandleEmailSubmit}
            isLoading={isLoading}
          />
        ) : currentStep === 1 ? (
          <OtpSelection
            otp={otp}
            setOtp={setOtp}
            onClick={onHandleOtpSubmit}
            setEmail={setEmail}
            setCurrentStep={setCurrentStep}
            isLoading={isLoading}
          />
        ) : currentStep === 2 ? (
          <DnsRecords
            dnsList={dnsList}
            settings={settings}
            isLoading={isLoading}
            isDnsLoading={isDnsLoading}
            dnsVerify={dnsVerify}
            setDnsVerify={setDnsVerify}
            setIsDeleteDomainModalOpen={setIsDeleteDomainModalOpen}
            onClick={onHandleDnsVerify}
          />
        ) : currentStep === 3 ? (
          <TestEmailSetup
            workspaceId={workspaceId}
            type={"mailgun"}
            additionalActions={
              <EmailOverview
                settings={settings}
                onDelete={() => setIsDeleteDomainModalOpen(true)}
              />
            }
          />
        ) : (
          <FinalStep
            dnsList={dnsList}
            settings={settings}
            isDnsLoading={isDnsLoading}
            setIsDeleteDomainModalOpen={setIsDeleteDomainModalOpen}
            onTestEmailModalOpen={onTestEmailModalOpen}
          />
        )}
      </div>
      <Modal
        title="Domain"
        secondaryTitle="Delete"
        isOpen={isDeleteDomainModalOpen}
        onCancel={() => setIsDeleteDomainModalOpen(false)}
        isLoading={isDeleteDomain}
        onSuccess={onDeleteDomain}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this domain?
          </p>
          <div className="text-gray-400">Once you delete this domain it's gone for good.</div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings, deleteWorkspaceMailgunSmtp, deleteMailgunSmtp })(MailgunSmtpSettings);
