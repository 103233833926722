import { connect } from "react-redux";
import QuickMenu from "src/components/Shared/QuickMenu";
import TableRow from "src/components/Shared/Table/TableRow";
import BubbleIconList from "src/components/Shared/BubbleIconList";
import Button from "src/components/Shared/Buttons/Button";
import { PlusIcon } from "@heroicons/react/24/outline";

const PdpEntry = ({ pdp, setDeleteId, onEditModal, ...props }) => {
  return (
    <TableRow keyId={pdp?.id}>
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm">
        <div className="flex items-center font-medium text-gray-900">{pdp?.name}</div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell">
        <div className="flex items-center font-medium text-gray-900">{pdp?.dataset_name}</div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
        <div className="flex items-center gap-2">
          <BubbleIconList
            className="!justify-start"
            data={props?.users
              .filter((item) => pdp?.users.includes(item.id))
              .map((item) => {
                return { ...item, _id: item.id };
              })}
          />
          <Button
            version="black"
            className="hidden !h-8 !px-2 md:flex"
            onClick={() => {
              onEditModal(pdp, "userAssign");
            }}>
            <PlusIcon className="h-5 w-5" />
            User
          </Button>
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
        <div className="flex items-center gap-2">
          <BubbleIconList
            className="!justify-start"
            data={props?.groups
              .filter((item) => pdp?.groups.includes(item.id))
              .map((item) => {
                return { ...item, _id: item.id };
              })}
          />
          <Button
            version="black"
            className="hidden !h-8 !px-2 md:flex"
            onClick={() => {
              onEditModal(pdp, "groupAssign");
            }}>
            <PlusIcon className="h-5 w-5" />
            Group
          </Button>
        </div>
      </td>
      <td className="relative flex justify-end whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium sm:pr-6">
        <QuickMenu
          items={[
            {
              name: "Edit Policy",
              onClick: () => {
                onEditModal(pdp, "edit");
              },
            },
            {
              name: "Delete",
              onClick: () => {
                setDeleteId(pdp.id);
              },
              color: "text-red-500",
            },
          ]}
        />
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(PdpEntry);
