import { CheckIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="flex w-full max-w-2xl items-center justify-between gap-2">
      {steps.map((step, index) => (
        <div
          className={classNames("flex items-center gap-2", index !== steps.length - 1 ? "flex-shrink flex-grow" : "")}
          key={index}>
          <div className={classNames("flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border text-sm font-semibold", index <= currentStep ? "border-highlightColor bg-white text-highlightColor" : "border-gray-200 bg-gray-200 text-gray-400")}>
            {index < currentStep ? <CheckIcon className="h-5 w-5" /> : index + 1}
          </div>
          <div className={classNames("flex-shrink-0 whitespace-nowrap text-base font-semibold", index <= currentStep ? "text-gray-700" : "text-gray-400")}>{step}</div>
          {index !== steps.length - 1 && <div className={classNames("mt-px flex-shrink flex-grow border-t-2", index <= currentStep - 1 ? "border-highlightColor" : "border-gray-300")} />}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
