import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";

export default function QuickSightIntegrationClient({ classnameDisplay, classnameIntergrationType, credential = {}, getAttributeValue = () => {} }) {
  const [displayName, setDisplayName] = useState("");
  const [displayDescription, setDisplayDescription] = useState("");
  const [integrationType, setIntegrationType] = useState("");
  const [displayURL, setDisplayURL] = useState("");

  useEffect(() => {
    setDisplayName(getAttributeValue("name"));
    setDisplayDescription(getAttributeValue("description"));
    setDisplayURL(getAttributeValue("subscriber_instance_url"));
    if (credential?.integration_type === "IFRAME_EMBED") {
      setIntegrationType("Iframe Embeded");
    } else {
      setIntegrationType("Subscriber Instance");
    }
  }, [credential, getAttributeValue]);

  return (
    <>
      <div className={classnameDisplay}>
        <div className="truncate text-base font-semibold leading-none text-gray-800">{displayName}</div>
        <div className="truncate text-sm text-gray-400">{displayDescription || displayURL}</div>
      </div>
      <div className={classnameIntergrationType}>
        {integrationType && <div className="w-34 flex h-8 items-center justify-center whitespace-nowrap rounded border px-3 text-xs font-semibold text-highlightColor shadow">{integrationType}</div>}
        {!credential?.workspaceAdd && (
          <div className="h-8">
            <div className="min-w-34 flex h-full items-center justify-center gap-x-2 rounded border px-1 text-xs font-semibold text-highlightColor shadow">
              {credential?.workspace ? (
                <div className="h-6 w-6">
                  <Image
                    image={credential?.workspace?.square_logo || credential?.workspace?.image_favicon || credential?.workspace?.image_logo}
                    failImage={noFavicon}
                    alt={credential?.workspace?.name}
                    className="h-full w-full"
                  />
                </div>
              ) : (
                <div className="flex h-full items-center gap-x-1">
                  <GlobeAltIcon className="h-6 w-6 text-gray-400" />
                  <div className="flex-shrink text-xs font-semibold text-gray-400">Global</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
