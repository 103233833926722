import React, { useEffect, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

const Billing = ({ site, selectedPlan, setSelectedPlan }) => {
  const [plans, setPlans] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoader(true);
        const response = await apiRequest("get", "/plans");
        setPlans(response?.data?.data);
        if (!selectedPlan) {
          setSelectedPlan(response?.data?.data?.find((item) => item?.default));
        }
      } catch (err) {
      } finally {
        setLoader(false);
      }
    };
    if (site?.stripe_secret_key && site?.stripe_publishable_key) fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.stripe_secret_key, site?.stripe_publishable_key]);

  return (
    <>
      <EditContainer
        title="Billing Plan"
        subtitle={selectedPlan?.name ? `The current plan is ${selectedPlan?.name} would you like change it?` : "Set to use the default billing plan."}
        preview={{
          text: selectedPlan?.name,
        }}
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}
        defaultOpen={true}
        fullWidth={true}>
        {site?.stripe_secret_key && site?.stripe_publishable_key && (
          <div className="mt-4 max-w-[600px]">
            {loader ? (
              <Preloader />
            ) : (
              <div className="mt-4">
                <SelectMenu
                  label="Select plan"
                  inline={true}
                  startIndex={plans?.findIndex((item) => item?._id === selectedPlan?._id)}
                  options={plans?.map((plan) => ({
                    Component: () => (
                      <div className="flex items-center gap-2 text-sm">
                        <span>{plan?.name}</span>
                        {plan?.default && <span className="rounded-lg border border-blue-300 bg-blue-100 px-1 text-[10px] text-blue-600">Default</span>}
                      </div>
                    ),
                    key: plan?.name,
                    value: plan?._id,
                    obj: plan,
                  }))}
                  setOption={(option) => {
                    setSelectedPlan(option?.obj);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </EditContainer>
    </>
  );
};

export default Billing;
