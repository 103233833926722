import WorkspaceIntegrationFilters from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFilters";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import { deleteWorkspace, manageWorkspacePageEmbeddingSettings, manageWorkspaceSettings } from "src/actions/workspace";
import WorkspaceDomain from "src/components/Workspaces/Workspace/Settings/WorkspaceDomain";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Modal from "src/components/Shared/Modal";
import { H2, H3 } from "src/components/Shared/Text/Headers";
import useFetch from "src/hooks/useFetch";
import WorkspacePageEmbedding from "./WorkspacePageEmbedding";
import WorkspaceTags from "./WorkspaceTags";
import WorkspaceEmailProvider from "./WorkspaceEmailProvider";
import WorkspaceSSOProvider from "./WorkspaceSSOProvider";
import WorspaceDataSync from "./WorspaceDataSync";
import WorspaceImportUser from "./WorspaceImportUser";
import { useState } from "react";

const WorkspaceSettings = ({ workspaceId, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  return (
    <>
      <Section>
        <div className="grid w-full gap-y-10">
          <div className="grid w-full">
            <H2 margin={false}>Workspace Settings</H2>
            {(authorizeUserComponentAccess(props?.me, workspaceId, "integration", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && (
              <WorkspaceIntegrationsList
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
                is_global={false}
              />
            )}
            {props?.me?.type === "admin" && workspaceId && props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && <WorkspacePageEmbedding />}
            {workspaceId && props?.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && (authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "workspace_filter", ["update"])) && (
              <WorkspaceIntegrationFilters workspaceId={workspaceId} />
            )}
            {(authorizeUserComponentAccess(props?.me, workspaceId, "layout", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && activeSSO?.isSSOActive && <WorkspaceTags />}
            {(authorizeUserComponentAccess(props?.me, workspaceId, "smtp", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && <WorkspaceEmailProvider />}
            {props?.me?.type === "admin" && props?.site?.manage_subdomains && (
              <WorkspaceDomain
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
              />
            )}
            {(authorizeUserComponentAccess(props?.me, workspaceId, "sso", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && props?.site?.manage_subdomains && <WorkspaceSSOProvider />}
            {authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) && <WorspaceDataSync />}
            {props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && <WorspaceImportUser />}
          </div>
          {(authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "workspace", ["delete"])) && (
            <div className="mt-8 flex w-full items-center">
              <H3 caption="When deleting a workspace, make sure you extract all needed resources from within it">Delete workspace</H3>
              <Button
                version="secondary"
                className="h-12 border-red-300 text-red-300"
                onClick={() => setDeleteId(workspaceId)}>
                Delete
              </Button>
            </div>
          )}
        </div>
      </Section>
      <Modal
        title={props?.workspaceDetails?.name}
        secondaryTitle="Delete"
        isOpen={deleteId ? true : false}
        onCancel={() => setDeleteId(null)}
        isLoading={deleteIsLoading}
        onSuccess={async () => {
          setDeleteIsLoading(true);
          await props.deleteWorkspace(deleteId);
          navigate("/workspaces");
          setDeleteIsLoading(false);
        }}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this workspace?
          </p>
          <div className="text-gray-400">Once you delete this workspace it's gone for good.</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { deleteWorkspace, manageWorkspaceSettings, manageWorkspacePageEmbeddingSettings })(WorkspaceSettings);
