import { CloudArrowDownIcon, Cog6ToothIcon, FingerPrintIcon, UsersIcon } from "@heroicons/react/24/outline";
import CustomAttributeList from "src/components/Activity/CustomAttributeList";
import ImportUsers from "src/components/Users/Add/ImportUsers/ImportUsers";
import UserSettings from "src/components/Activity/UserSettings";
import { H1 } from "src/components/Shared/Text/Headers";
import UsersList from "src/components/Users/UsersList";
import { getAuthorizedUser } from "src/actions/auth";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { connect } from "react-redux";
import { useEffect } from "react";

const Users = ({ ...props }) => {
  useEffect(() => {
    getAuthorizedUser();
  }, []);

  const tabs = [
    {
      name: "User List",
      icon: UsersIcon,
      component: (
        <UsersList
          title="User"
          filterUserType={["user", "editor"]}
        />
      ),
    },
    { name: "Custom Attributes", icon: Cog6ToothIcon, component: <CustomAttributeList />, hidden: props?.user?.type !== "admin" },
    { name: "Privacy & Security", icon: FingerPrintIcon, component: <UserSettings />, hidden: props?.user?.type !== "admin" },
    { name: "Bulk Import", icon: CloudArrowDownIcon, component: <ImportUsers />, hidden: props?.user?.type !== "admin" },
  ];

  if (!props?.user?._id) return <></>;

  if (props?.user?.type === "editor" && props?.user?.workspaces?.length === 1 && !localStorage.getItem("impersonatedUserId")) {
    window.location.href = `/workspaces/${props.user.workspaces[0]._id}?tab=Users`;
    return <></>;
  }

  return (
    <>
      <H1>Users</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menus: Object.values(state.menus),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(Users);
