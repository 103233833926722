import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { manageWorkspaceSettings } from "src/actions/workspace";
import validator from "validator";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import EditContainer from "src/components/Shared/Containers/EditContainer";

const EmailVariables = ({ workspaceId = null, ...props }) => {
  const [siteEmail, setSiteEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      const message = workspaceId ? await props.manageWorkspaceSettings({ site_email: siteEmail, workspace_id: workspaceId }, "/workspaces/:workspace_id/smtp/support-email") : await props.manageSiteSettings({ site_email: siteEmail }, "/settings/smtp/support-email");
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSiteEmail(workspaceId ? props?.workspaceDetails?.site_email : props?.site?.site_email);
  }, [workspaceId, props?.workspaceDetails, props?.site]);

  return (
    <EditContainer
      title="Email Variables"
      subtitle="Set up your email variables."
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      defaultOpen={true}
      fullWidth={false}>
      {/* <H3 caption="Enter your domain and edit any additional settings.">Authenticate your domain</H3> */}
      <div className="grid space-y-4 sm:space-y-8">
        <div className="flex w-full flex-wrap justify-between">
          <div className="flex w-full flex-wrap gap-y-6">
            <div className="-mx-2 sm:-mx-4">
              <div className="mb-4">
                <p className="text-gray-800">Decide on an email address you'd like for support email.</p>
                <p className="text-sm text-gray-400">User will contact you based on this email address.</p>
              </div>
              <div className="flex w-full gap-x-2">
                <Input
                  label={
                    <p>
                      Support contact <span className="pl-2 text-gray-300">*optional email</span>
                    </p>
                  }
                  value={siteEmail}
                  onChange={(e) => {
                    setSiteEmail(e.target.value);
                    setIsDisabled(false);
                  }}
                  inline={true}
                />{" "}
                <Button
                  disabled={isDisabled || !validator.isEmail(siteEmail)}
                  onClick={onSubmit}
                  isLoading={isLoading}
                  type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings })(EmailVariables);
