import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const Tabs = ({ onClick = () => {}, tabs = [], altDesign = false, changeParams = true, queryParamName = "tab" }) => {
  const [selectedTab, setSelectedTab] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const tabParam = useQuery().get(queryParamName);

  const handleTabSelection = (tab = {}) => {
    onClick();
    if (changeParams) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set(queryParamName, tab?.name);
      const newSearch = currentUrl.searchParams.toString();
      navigate({ pathname: location.pathname, search: newSearch });
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (tabs.length) {
      if (!tabParam) {
        let defaultTab = tabs.filter((tab) => {
          return !tab.hidden;
        })[0];

        setSelectedTab(defaultTab);
      } else {
        let filteredTab = tabs.filter((tab) => tabParam === tab.name)[0];
        if (filteredTab) setSelectedTab(filteredTab);
        else {
          setSelectedTab(tabs[0]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, window.location.search]);

  return (
    <>
      <div className={classNames("hide-scrollbar mt-3 overflow-x-auto md:mt-0", altDesign ? "" : "overflow-y-hidden")}>
        <nav
          className={classNames("hide-scrollbar flex h-10", tabs.length ? "border-b border-gray-200" : "", altDesign ? "space-x-2" : "space-x-8 overflow-y-hidden")}
          aria-label="Tabs">
          {tabs.length > 0 &&
            tabs.map((tab) =>
              tab.hidden ? null : (
                <div
                  key={tab.name}
                  onClick={() => handleTabSelection(tab)}
                  className={classNames(
                    "group inline-flex cursor-pointer select-none items-center  text-sm font-medium transition-all duration-200",
                    altDesign
                      ? classNames(tab.name === selectedTab?.name ? "border-gray-200 border-b-white text-highlightColor" : "border-transparent text-gray-400 hover:border-gray-200 hover:bg-gray-50 hover:text-gray-500", "translate-y-px rounded-t-md border px-3 py-2")
                      : classNames(tab.name === selectedTab?.name ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-400 hover:border-gray-400 hover:text-gray-500", "border-b-2 px-1 pb-3"),
                  )}
                  aria-current={tab.name === selectedTab ? "page" : undefined}>
                  {tab.icon && (
                    <tab.icon
                      className={classNames(tab.name === selectedTab?.name ? "text-highlightColor" : "text-gray-400 group-hover:text-gray-400", "-ml-0.5 mr-2 h-5 w-5 stroke-[2px]")}
                      aria-hidden="true"
                    />
                  )}
                  <span className="block whitespace-nowrap">{tab.name}</span>
                </div>
              ),
            )}
        </nav>
      </div>
      <div className="mt-3 w-full">{selectedTab?.component}</div>
    </>
  );
};

export default Tabs;
