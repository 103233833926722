import "flatpickr/dist/themes/light.css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "src/components/Shared/Modal";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { daysOfWeek, getDaySuffix, intervalTypes, monthlyDayType, scheduleTextGenerator, specificDaysOfWeek } from "src/helpers/formatDate";
import Flatpickr from "react-flatpickr";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

const ScheduleReportModal = ({ isOpen = false, group = null, workspaceId = null, onCancel = () => {}, onSuccess = () => {} }) => {
  const scheduleReportJson = {
    subject: "",
    interval_type: "weekly",
    time: null,
    include_weekends: false,
    weekly_days: ["monday"],
    monthly_day_type: "first_day",
    specific_days_of_week: "first",
    specific_days_of_week_days: [],
    specific_date_of_day: 1,
    link_expire_in_days: 3,
    status: false,
  };
  const [scheduleReport, setScheduleReport] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleOnChange = (key, value) => {
    if (key === "weekly_days" || key === "specific_days_of_week_days") {
      setScheduleReport((prevData) => {
        const updatedDays = prevData[key]?.includes(value) ? prevData[key].filter((item) => item !== value) : [...prevData[key], value];
        return { ...prevData, [key]: updatedDays };
      });
    } else {
      setScheduleReport((prevData) => ({ ...prevData, [key]: value }));
    }
  };

  const handleDayInput = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      handleOnChange(e.target.name, inputValue);
    } else if (/^\d*$/.test(inputValue)) {
      const numberValue = Number(inputValue);
      if (numberValue >= 1 && numberValue <= 31) {
        handleOnChange(e.target.name, inputValue);
      }
    }
  };

  const handleNumberInput = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      handleOnChange(e.target.name, inputValue);
    } else if (/^\d*$/.test(inputValue)) {
      handleOnChange(e.target.name, inputValue);
    }
  };

  const handleOnSave = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest("put", `/workspaces/:workspace_id/groups/:group_id/schedule`, {
        body: { schedule_report: { ...scheduleReport, time: scheduleReport.time ? new Date(scheduleReport.time).toISOString() : null, local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone } },
        params: { workspace_id: workspaceId, group_id: group._id || group.id },
      });
      onSuccess();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setScheduleReport(group?.schedule_report || { ...scheduleReportJson, subject: `${group?.name} Report` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <Modal
      size="xxl"
      title={`Schedule reports`}
      secondaryTitle={`manage`}
      isOpen={isOpen}
      isLoading={disabled}
      onCancel={onCancel}
      onSuccess={handleOnSave}
      defaultStyles={{
        overFlowYVisible: true,
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative">
          <Input
            name="subject"
            label="Subject"
            value={scheduleReport?.subject}
            onChange={(e) => handleOnChange("subject", e.target.value)}
          />
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="relative w-28">
            <SelectMenu
              label="Send Report"
              startIndex={intervalTypes.findIndex((item) => item?.value === scheduleReport?.interval_type)}
              options={intervalTypes}
              dropdownClass={"!min-w-0 w-full"}
              setOption={(option) => handleOnChange("interval_type", option.value)}
            />
          </div>
          {scheduleReport?.interval_type === "monthly" && (
            <>
              <div className="relative w-36 pt-6">
                <SelectMenu
                  label=""
                  startIndex={monthlyDayType.findIndex((item) => item?.value === scheduleReport?.monthly_day_type)}
                  options={monthlyDayType}
                  dropdownClass={"!min-w-48 w-full"}
                  setOption={(option) => handleOnChange("monthly_day_type", option.value)}
                />
              </div>
              {scheduleReport?.monthly_day_type === "specific_days_of_week" && (
                <div className="relative w-28 pt-6">
                  <SelectMenu
                    label=""
                    startIndex={specificDaysOfWeek.findIndex((item) => item?.value === scheduleReport?.specific_days_of_week)}
                    options={specificDaysOfWeek}
                    dropdownClass={"!min-w-0 w-full"}
                    setOption={(option) => handleOnChange("specific_days_of_week", option.value)}
                  />
                </div>
              )}
            </>
          )}
          {scheduleReport?.interval_type === "weekly" && (
            <div className="relative pt-6">
              {daysOfWeek?.length > 0 && (
                <div className="flex h-[47px] divide-x divide-gray-300 overflow-hidden rounded-md border border-gray-300 md:h-[40px] 2xl:h-[47px]">
                  {daysOfWeek?.map((item, index) => (
                    <div key={index}>
                      <Button
                        version="default"
                        className={classNames("!aspect-square justify-center !text-base", scheduleReport?.weekly_days?.includes(item.value) ? "!bg-highlightColor !text-white" : "!text-gray-400")}
                        onClick={() => handleOnChange("weekly_days", item.value)}>
                        {item.key}
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {scheduleReport?.interval_type === "monthly" && scheduleReport?.monthly_day_type === "specific_days_of_week" && (
            <div className="relative pt-6">
              {daysOfWeek?.length > 0 && (
                <div className="flex h-[47px] divide-x divide-gray-300 overflow-hidden rounded-md border border-gray-300 md:h-[40px] 2xl:h-[47px]">
                  {daysOfWeek?.map((item, index) => (
                    <div key={index}>
                      <Button
                        version="default"
                        className={classNames("!aspect-square justify-center !text-base", scheduleReport?.specific_days_of_week_days?.includes(item.value) ? "!bg-highlightColor !text-white" : "!text-gray-400")}
                        onClick={() => handleOnChange("specific_days_of_week_days", item.value)}>
                        {item.key}
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {scheduleReport?.interval_type === "monthly" && scheduleReport?.monthly_day_type === "specific_date" && (
            <div className="relative flex w-28 space-x-2 pt-6">
              <Input
                name="specific_date_of_day"
                value={scheduleReport?.specific_date_of_day}
                onChange={handleDayInput}
              />
              <div className="flex flex-shrink-0 items-center space-x-2 text-base">
                <span className="mr-2 text-gray-400">{getDaySuffix(scheduleReport?.specific_date_of_day)}</span>day
              </div>
            </div>
          )}
          <div className="relative flex w-36 space-x-2 pt-6">
            <Flatpickr
              className="h-[47px] w-24 rounded-md border border-gray-300 md:h-[40px] 2xl:h-[47px]"
              name="time"
              value={scheduleReport?.time}
              onChange={([selectedTime]) => {
                const roundedTime = new Date(selectedTime);
                const minutes = roundedTime.getMinutes();
                const roundedMinutes = Math.round(minutes / 15) * 15;
                roundedTime.setMinutes(roundedMinutes, 0, 0);
                handleOnChange("time", roundedTime);
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
                minuteIncrement: 15,
              }}
            />
            <div className="flex flex-shrink-0 items-center text-base font-semibold">MDT</div>
          </div>
          {scheduleReport?.interval_type === "daily" && (
            <div className="relative flex items-center pt-6">
              <Checkbox
                isChecked={scheduleReport?.include_weekends}
                checkboxInputClass={"!h-6 !w-6 !aspect-square !flex-shrink-0"}
                checkboxBoxClass={"!h-6 !w-6 !aspect-square !flex-shrink-0"}
                checkboxIconClass={"!h-5 !w-5"}
                checkboxLabel={"Include Weekends"}
                checkboxLableClass={"!text-base"}
                onChange={(e) => handleOnChange("include_weekends", e.target.checked)}
              />
            </div>
          )}
        </div>
        <div className="relative">
          <div className="text-base text-gray-400">{scheduleTextGenerator({ scheduleReport })}</div>
        </div>
        <div className="relative">
          <Input
            name="link_expire_in_days"
            label="Link Expired (in days)"
            value={scheduleReport?.link_expire_in_days}
            onChange={handleNumberInput}
          />
        </div>
        <div className="relative">
          <div className="flex items-center gap-5 py-4">
            <div className="w-10 min-w-[40px]">
              <Toggle
                checked={scheduleReport?.status}
                onChange={() => handleOnChange("status", !scheduleReport?.status)}
              />
            </div>
            <div className="w-full font-semibold text-gray-800">Enabled</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(ScheduleReportModal);
