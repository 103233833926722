import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { manageWorkspaceSettings } from "src/actions/workspace";
import { manageSiteSettings } from "src/actions/site";
import { toast } from "react-toastify";
import Section from "src/components/Shared/Containers/Section";
import OptionTabs from "src/components/Shared/Tabs/OptionTabs";
import MailgunSmtpSettings from "./MailgunSmtpSettings";
import SelfHostedSmtpSettings from "./SelfHostedSmtpSettings";
import Button from "src/components/Shared/Buttons/Button";
import TestEmailSMTPModal from "./TestEmailSMTPModal";
import Modal from "src/components/Shared/Modal";

const SmtpSettings = ({ workspaceId = null, ...props }) => {
  const [defaultSmtp, setDefaultSmtp] = useState("clearsquare");
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [smtpType, setSmtpType] = useState(null);
  const [selectedTab, setSelectedTab] = useState("mailgun");

  const [confirmationModal, setConfirmationModal] = useState({ isOpen: false, content: "", type: null });

  const onHandleSetDefaultSmtp = async (value) => {
    try {
      setIsLoading(true);
      const message = workspaceId ? await props.manageWorkspaceSettings({ smtp_default: value, workspace_id: workspaceId }, "/workspaces/:workspace_id/smtp/default") : await props.manageSiteSettings({ smtp_default: value }, "/settings/smtp/default");
      toast.success(message);
      setDefaultSmtp(value);
    } catch (error) {
      setDefaultSmtp(workspaceId ? props?.workspaceDetails?.smtp_default : props?.site?.smtp_default);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setConfirmationModal((pre) => ({ ...pre, isOpen: false }));
    }
  };

  useEffect(() => {
    setSettings(workspaceId ? props?.workspaceDetails : props?.site);
  }, [workspaceId, props?.workspaceDetails, props?.site]);

  useEffect(() => {
    if (settings?.smtp_default) {
      setDefaultSmtp(settings?.smtp_default);
      setSelectedTab(settings?.smtp_default);
    }
  }, [settings?.smtp_default]);

  const confirmModalSuccess = () => {
    onHandleSetDefaultSmtp(confirmationModal?.type);
  };

  const onConfirmModalOpen = (type, content) => {
    setConfirmationModal({ isOpen: true, content: content, type });
  };

  return (
    <EditContainer
      title="Email Domain [SMTP Server]"
      subtitle="Adjust settings to allow for your clients to receive emails from your domain."
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      defaultOpen={true}
      fullWidth={true}>
      <Section className="space-y-4">
        <div>
          <Checkbox
            isChecked={defaultSmtp === "clearsquare"}
            checkboxLabel={`Use our default ${props?.site?.company_email} email until you are ready.`}
            checkboxLableClass={"!text-lg !text-highlightColor"}
            onChange={() => {
              if (settings?.smtp_default !== "clearsquare") {
                onConfirmModalOpen("clearsquare", "Default");
              } else {
                setDefaultSmtp("clearsquare");
              }
            }}
          />
          <div className="ml-8 text-base">Until you have your own settings, you can use this development server to send emails. </div>
        </div>
        <div className="">
          <Checkbox
            isChecked={defaultSmtp !== "clearsquare"}
            checkboxLabel={`Setup emails to come from your own company domain.`}
            checkboxLableClass={"!text-lg !text-highlightColor"}
            onChange={() => {
              setDefaultSmtp(!["mailgun", "self_hosted"]?.includes(settings?.smtp_default) ? "custom" : settings?.smtp_default);
              setSelectedTab(settings?.smtp_default === "clearsquare" ? "mailgun" : settings?.smtp_default);
            }}
          />
          <div className="ml-8 text-base">We will lead you through getting this set up either with our pooled server or through credentialed access from your IT department. </div>
        </div>
        {defaultSmtp !== "clearsquare" && (
          <div>
            <OptionTabs
              tabs={[
                {
                  name: "Easy Method : Give us access to a company email",
                  description: "This is typically the best option. Verify your domain (or a subdomain) and verifying an email and you can immediately start using our SMTP server.",
                  component: (
                    <MailgunSmtpSettings
                      workspaceId={workspaceId}
                      initialSettings={settings}
                      onTestEmailModalOpen={() => setSmtpType("mailgun")}
                    />
                  ),
                  isHighlighted: defaultSmtp === "mailgun",
                  action: defaultSmtp !== "mailgun" && settings?.smtp_mailgun_status === "email_verified" && (
                    <Button
                      disabled={isLoading}
                      isLoading={isLoading}
                      onClick={() => onConfirmModalOpen("mailgun", "Easy Method")}>
                      Connect
                    </Button>
                  ),
                },
                {
                  name: "Advanced Method: Use my own SMTP server (Advanced)",
                  description: "If you have your own SMTP server for sending emails, then select this option and enter in your credentials.",
                  component: (
                    <SelfHostedSmtpSettings
                      workspaceId={workspaceId}
                      initialSettings={settings}
                      onTestEmailModalOpen={() => setSmtpType("self_hosted")}
                    />
                  ),
                  isHighlighted: defaultSmtp === "self_hosted",
                  action: defaultSmtp !== "self_hosted" && settings?.smtp_self_hosted_verified && (
                    <Button
                      disabled={isLoading}
                      isLoading={isLoading}
                      onClick={() => onConfirmModalOpen("self_hosted", "Advanced Method")}>
                      Connect
                    </Button>
                  ),
                },
              ]}
              onTabChange={(tab) => {
                setSelectedTab(tab?.name === "Easy Method : Give us access to a company email" ? "mailgun" : "self_hosted");
              }}
              activeTab={selectedTab === "self_hosted" ? "Advanced Method: Use my own SMTP server (Advanced)" : "Easy Method : Give us access to a company email"}
            />
          </div>
        )}
        <TestEmailSMTPModal
          isOpen={["mailgun", "self_hosted"]?.includes(smtpType) ? true : false}
          smtpType={smtpType}
          workspaceId={workspaceId}
          onCancel={() => setSmtpType(null)}
        />
      </Section>
      <Modal
        title={"Change SMTP Server"}
        isOverflow={false}
        isOpen={confirmationModal?.isOpen}
        defaultOptions={{
          onSuccessButtonText: "Yes",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: isLoading,
          onCancelButtonText: "No",
        }}
        onCancel={() => setConfirmationModal((pre) => ({ ...pre, isOpen: false }))}
        onSuccess={confirmModalSuccess}>
        Are you sure you want to change your smtp settings to <span className="font-semibold text-highlightColor">{confirmationModal?.content} </span>?
      </Modal>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings })(SmtpSettings);
