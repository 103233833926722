import moment from "moment";

export const intervalTypes = [
  { key: "Daily", value: "daily" },
  { key: "Weekly", value: "weekly" },
  { key: "Monthly", value: "monthly" },
];

export const daysOfWeek = [
  { key: "S", value: "sunday" },
  { key: "M", value: "monday" },
  { key: "T", value: "tuesday" },
  { key: "W", value: "wednesday" },
  { key: "T", value: "thursday" },
  { key: "F", value: "friday" },
  { key: "S", value: "saturday" },
];

export const monthlyDayType = [
  { key: "First day", value: "first_day" },
  { key: "Last Day", value: "last_day" },
  { key: "Specific days of week", value: "specific_days_of_week" },
  { key: "Specific date", value: "specific_date" },
];

export const specificDaysOfWeek = [
  { key: "First", value: "first" },
  { key: "Second", value: "second" },
  { key: "Third", value: "third" },
  { key: "Fourth", value: "fourth" },
  { key: "Last", value: "last" },
];

export const getDaySuffix = (day) => {
  if (day > 31 || day < 1) return "";
  const lastDigit = day % 10;
  const suffix =
    day >= 11 && day <= 13
      ? "th"
      : {
          1: "st",
          2: "nd",
          3: "rd",
        }[lastDigit] || "th";
  return suffix;
};

export const formatTo12HourTime = (date) => {
  date = new Date(date);
  if (!(date instanceof Date)) {
    return "";
  }

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  const strTime = `${hours}:${formattedMinutes} ${ampm}`;
  return strTime;
};

const formatDaysMessage = (weekly_days) => {
  const days = [...weekly_days];
  if (days.length === 0) return "";
  if (days.length === 1) return `${days[0]}`;
  if (days.length === 2) return `${days[0]} and ${days[1]}`;
  const lastDay = days.slice(-1);
  return `${days.join(", ")} and ${lastDay}`;
};

export const scheduleTextGenerator = ({ scheduleReport = null }) => {
  let title = "";
  if (scheduleReport?.interval_type === "daily") {
    title = `Occurs every day`;
  } else if (scheduleReport?.interval_type === "weekly" && scheduleReport?.weekly_days?.length > 0) {
    title = `Occurs every ${formatDaysMessage(scheduleReport?.weekly_days)}`;
  } else if (scheduleReport?.interval_type === "monthly") {
    if (scheduleReport?.monthly_day_type === "first_day") {
      title = `Occurs monthly on the first day of the month`;
    } else if (scheduleReport?.monthly_day_type === "last_day") {
      title = `Occurs monthly on the last day of the month`;
    } else if (scheduleReport?.monthly_day_type === "specific_days_of_week" && scheduleReport?.specific_days_of_week && scheduleReport?.specific_days_of_week_days?.length > 0) {
      title = `Occurs monthly, the ${scheduleReport?.specific_days_of_week} week of month on ${formatDaysMessage(scheduleReport?.specific_days_of_week_days)}`;
    } else if (scheduleReport?.monthly_day_type === "specific_date" && scheduleReport?.specific_date_of_day >= 1 && scheduleReport?.specific_date_of_day <= 31) {
      title = `Occurs monthly on the ${scheduleReport?.specific_date_of_day}${getDaySuffix(scheduleReport?.specific_date_of_day)}`;
    }
  }

  return title && scheduleReport?.time ? `${title} at ${formatTo12HourTime(scheduleReport?.time)} MDT. Actual delivery times will vary.` : "";
};

export const formatDate = (date = null, format = "MM/DD/YYYY") => {
  if (!date) return "";
  return moment(new Date(date)).format(format);
};
