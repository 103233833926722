import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import Search from "src/components/Shared/Search";
import Button from "src/components/Shared/Buttons/Button";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { PlusIcon } from "@heroicons/react/24/outline";
import TRHeader from "src/components/Shared/Table/TRHeader";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import FormListItem from "./FormListItem";
import FormEntry from "./FormEntry";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import SlideOver from "../Shared/SlideOver";
import FormManage from "src/pages/form/formManage";

const FormList = ({ workspaceId = null, isGlobal = false, ...props }) => {
  const [forms, setForms] = useState([]);
  const [meta, setMeta] = useState(null);
  const [formsLoaded, setFormsLoaded] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteDisable, setIsDeleteDisable] = useState(false);

  const [formSliderOpen, setFormSliderOpen] = useState(false);

  const { width } = useWindowDimensions();
  const router = useLocation();
  const navigate = useNavigate();

  const getFormList = useCallback(async () => {
    try {
      setFormsLoaded(false);
      const res = await apiRequest("get", workspaceId ? `/workspaces/:workspace_id/forms` : `/forms`, {
        queries: { limit: limit || 20, offset: offset || 0, keyword: keyword || "", is_global: isGlobal },
        params: { workspace_id: workspaceId },
      });

      setForms(res?.data?.data || []);
      setMeta(res?.data?.meta || null);
      setFormsLoaded(true);
    } catch (error) {}
  }, [limit, offset, keyword, workspaceId, isGlobal]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const updateForm = async (form, tab = "Questions") => {
    const params = new URLSearchParams(router.search);
    if (!workspaceId) {
      params.set("tab", isGlobal ? "Global+forms" : "All+forms");
    }
    params.set("id", form?._id);
    params.set("formTab", tab);
    if (workspaceId) {
      params.set("workspace_id", workspaceId);
    } else if (form?.workspace_id?._id) {
      params.set("workspace_id", form?.workspace_id?._id);
    }
    params.set("is_global", isGlobal);
    params.set("pathname", router.pathname);
    navigate(`${router.pathname}?${params.toString()}`.replace(/%2B/g, "+"));
    setEditItem(form);
    setFormSliderOpen(true);
  };

  const deleteForm = async () => {
    try {
      setIsDeleteDisable(true);
      const { data } = await apiRequest("delete", workspaceId ? `/workspaces/:workspace_id/forms/:form_id` : `/forms/:form_id`, {
        params: { workspace_id: workspaceId, form_id: deleteId },
      });
      toast.success(data?.message);
      if (forms.length === 1 && offset !== 0) {
        setOffset(offset - 1);
      } else {
        getFormList();
      }
      setDeleteId(null);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsDeleteDisable(false);
    }
  };

  useEffect(() => {
    getFormList();
  }, [getFormList]);

  const closeSlider = () => {
    const params = new URLSearchParams(router.search);
    setFormSliderOpen(false);
    params.delete("workspace_id");
    params.delete("is_global");
    params.delete("pathname");
    params.delete("closeModal");
    if (params.get("id")) params.delete("id");
    if (params.get("formTab")) params.delete("formTab");
    navigate(`${router.pathname}?${params.toString()}`.replace(/%2B/g, "+"));
    setEditItem(null);
  };

  const handleAddForm = () => {
    const params = new URLSearchParams(router.search);
    if (!workspaceId) {
      params.set("tab", isGlobal ? "Global+forms" : "All+forms");
    }
    params.set("workspace_id", workspaceId || "");
    params.set("is_global", isGlobal);
    params.set("pathname", router.pathname);
    navigate(`${router.pathname}?${params.toString()}`.replace(/%2B/g, "+"));

    setFormSliderOpen(true);
  };

  useEffect(() => {
    const params = new URLSearchParams(router.search);
    if (params.get("closeModal") && !editItem) {
      getFormList();
      closeSlider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormList, router.search, editItem]);

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
            placeholder={"Search"}
            inline={true}
          />
        }
        rightContent={
          ((workspaceId && props.workspaceDetails?._id && authorizeUserComponentAccess(props.user, workspaceId, "page", ["update"])) || (props.user?.type === "admin" && !workspaceId)) && (
            <Button
              version="secondary"
              onClick={handleAddForm}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              {width > 640 ? "Add form" : "Form"}
            </Button>
          )
        }></TableOptions>

      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:pl-6 sm:text-sm">
              Responses
            </th>
            <th
              scope="col"
              className="overflow-hidden rounded-tr-lg px-3 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
          </TRHeader>
        }
        colSpan="5"
        loaded={formsLoaded}
        dataExists={formsLoaded && forms?.length > 0}>
        {forms.map((form, i) => {
          return (
            <FormListItem
              key={form._id + "_" + workspaceId + "_" + form.workspace_id?._id || form.workspace_id || workspaceId}
              form={form}
              forms={forms}
              i={i}
              workspaceId={workspaceId}>
              <FormEntry
                workspaceId={form.workspace_id?._id || form.workspace_id || workspaceId}
                key={form._id + "_" + workspaceId + "_" + form.workspace_id?._id || form.workspace_id || workspaceId}
                form={form}
                setDeleteId={setDeleteId}
                updateForm={updateForm}
                isGlobal={isGlobal}
                workspaceDetails={props?.workspaceDetails}
                depthLevel={0}
                queryParams={{ workspaceId, isGlobal }}
              />
            </FormListItem>
          );
        })}
      </Table>
      <PaginationFooter
        itemName="Form record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <Modal
        title="Form"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={deleteForm}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {forms?.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>

      <SlideOver
        isOpen={formSliderOpen}
        handleClose={closeSlider}
        title={editItem?._id ? "Edit Form" : "Add Form"}
        description="View, update, and modify page details and information below."
        maxWidth="xl:max-w-[1250px] lg:max-w-[1000px]">
        {formSliderOpen ? <FormManage refreshList={getFormList} /> : <></>}
      </SlideOver>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(FormList);
