import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from "src/actions/auth";
import { getMenuData } from "src/actions/menu";
import AdminNavDesktop from "src/components/RoleViews/Admin/AdminNavDesktop";
import Logo from "src/components/Shared/Icons/Logo";
import UserProfile from "src/components/Users/UserProfile";
import { FeatureFlagsProvider } from "src/context/FeatureFlagContext";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import Button from "src/components/Shared/Buttons/Button";
import { v4 } from "uuid";
import { getPortalWorkspaceList, getPortalWorkspacesPageMenu, getPortalWorkspaceFirstPage } from "src/actions/impersonate";
import { setWorkspaceDetails } from "src/actions/page";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";
import useUrlPathParams from "src/hooks/useUrlPathParams";
import LeftNavigationPanel from "src/components/Navigation/LeftNavigationPanel";
import TopNavigationPanel from "src/components/Navigation/TopNavigationPanel";

const UserLayout = ({ user, children, setWorkspaceDetails, workspaceDetails }) => {
  const userNavigation = [{ name: "Sign out", path: "/" }];
  const navigate = useNavigate();
  const [workspaces, setWorkspaces] = useState([]);
  const [currentNavigation, setCurrentNavigation] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [pageList, setPageList] = useState([]);

  const ref = useRef();
  const { workspace: workspaceId } = useUrlPathParams();
  const { group: groupId } = useUrlQueryParams();

  useOnClickOutside(ref, () => setSidebarOpen(false));

  useEffect(() => {
    setSidebarOpen(pageList.length > 0 ? true : false);
  }, [pageList.length]);

  function extractWorkspaceName() {
    // Assuming the base structure of the URL is "http://localhost:3002/workspace/{workspace-name}/..."
    const url = window.location.href;
    const pathSegments = url.split("/");

    const workspaceIndex = pathSegments.findIndex((segment) => segment === "workspace");

    if (workspaceIndex !== -1 && workspaceIndex < pathSegments.length - 1) {
      const workspaceName = pathSegments[workspaceIndex + 1];
      return workspaceName;
    }

    return null;
  }

  const clickWorkspace = async (workspace = {}) => {
    try {
      let workspaceId = workspace?._id;

      if (!workspaceId) {
        throw new Error("No workspace available to fetch.");
      }

      if ("" + workspaceId === "" + workspaceDetails?._id) {
        return;
      }

      const { link } = await getPortalWorkspaceFirstPage({ user_id: user?._id, workspace_id: workspaceId });
      navigate(link);
    } catch (err) {}
  };

  const updateNavigation = (navItem) => {
    setCurrentNavigation(navItem.slug || navItem.key);
  };

  const getMenuList = async () => {
    try {
      setPageList([]);
      let list = [];
      const { data: response } = await getPortalWorkspacesPageMenu({ user_id: user?._id, workspace_id: workspaceDetails?._id });
      if (response.menu.length > 0 || response.individualMenu.length > 0) {
        for (const indMenu of response.individualMenu) {
          list.push({
            key: indMenu._id || v4(),
            slug: indMenu?.page_id?.slug,
            name: indMenu?.page_id?.name,
            path: indMenu?.page_id?.page_type === "EXTERNAL_LINK" ? indMenu?.page_id?.page_link : `workspace/${workspaceDetails?.slug || workspaceDetails?._id}/embed?page=${indMenu?.page_id?.slug || indMenu._id}`,
            permissions: [],
            isExternal: indMenu?.page_id?.page_type === "EXTERNAL_LINK" ? true : false,
            page_link_newtab: indMenu?.page_id?.page_link_newtab,
          });
        }
        for (const lt of response.menu) {
          let permissions = [];
          if (response.menu.length + response.individualMenu.length > 1) {
            if (lt?.flatten_pages) {
              for (const permission of lt.permissions) {
                list.push({
                  key: permission._id || v4(),
                  slug: permission?.page_slug,
                  name: permission.page.name,
                  path: permission.page?.page_type === "EXTERNAL_LINK" ? permission.page?.page_link : `workspace/${workspaceDetails?.slug || workspaceDetails?._id}/embed?group=${lt.slug || lt._id}&page=${permission?.page_slug || permission._id}`,
                  permissions: [],
                  isExternal: permission.page?.page_type === "EXTERNAL_LINK" ? true : false,
                  page_link_newtab: permission.page?.page_link_newtab,
                });
              }
            } else {
              for (const permission of lt.permissions) {
                permissions.push({
                  key: permission._id || v4(),
                  slug: permission?.page_slug,
                  name: permission.page?.name,
                  path: permission.page?.page_type === "EXTERNAL_LINK" ? permission.page?.page_link : `workspace/${workspaceDetails?.slug || workspaceDetails?._id}/embed?group=${lt.slug || lt._id}&page=${permission?.page_slug || permission._id}`,
                  isExternal: permission.page?.page_type === "EXTERNAL_LINK" ? true : false,
                  page_link_newtab: permission.page?.page_link_newtab,
                });
              }

              list.push({
                key: lt.slug || lt._id || v4(),
                slug: lt.slug,
                name: lt.name,
                image: lt.image,
                toggle: groupId && (groupId === lt._id || groupId === lt.slug) ? true : response.data && response.data?.layout_type === "TOP_NAVIGATION" ? false : false,
                permissions: permissions,
              });
            }
          } else {
            for (const permission of lt.permissions) {
              list.push({
                key: permission._id || v4(),
                slug: permission?.page_slug,
                name: permission.page.name,
                path: permission.page?.page_type === "EXTERNAL_LINK" ? permission.page?.page_link : `workspace/${workspaceDetails?.slug || workspaceDetails?._id}/embed?group=${lt.slug || lt._id}&page=${permission?.page_slug || permission._id}`,
                permissions: [],
                isExternal: permission.page?.page_type === "EXTERNAL_LINK" ? true : false,
                page_link_newtab: permission.page?.page_link_newtab,
              });
            }
          }
        }
      }
      setPageList(list);
    } catch (error) {}
  };

  const getWorkspaceList = async () => {
    try {
      const { data } = await getPortalWorkspaceList({ user_id: user?._id });
      setWorkspaces(data || []);
    } catch (error) {}
  };

  useEffect(() => {
    if (workspaces.length) {
      const currentWorkspace = workspaces.find((workspace) => workspace._id === workspaceId || workspace.slug === workspaceId);
      if (!currentWorkspace) logout();
      setWorkspaceDetails(currentWorkspace || workspaces[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.length, workspaceId]);

  useEffect(() => {
    if (user?._id && workspaceDetails?._id) getMenuList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, workspaceDetails?._id]);

  useEffect(() => {
    if (user?._id) getWorkspaceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  return (
    <FeatureFlagsProvider>
      <div className="flex h-full w-full flex-col">
        <Transition.Root
          show={sidebarOpenMobile}
          as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpenMobile}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-leftNavColor pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <Button
                        type="button"
                        version="default"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:border-highlightColor focus:ring-0"
                        onClick={() => setSidebarOpenMobile(false)}>
                        <span className="sr-only">Close Side Bar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </Button>
                    </div>
                  </Transition.Child>
                  <div className="bg-headerColor pl-4">
                    <Logo />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <LeftNavigationPanel
                      pageList={pageList}
                      currentNavigation={currentNavigation}
                      updateNavigation={updateNavigation}
                      setPageList={setPageList}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div
                className="w-14 flex-shrink-0"
                aria-hidden="true">
                {/* Dummy element to force leftNavColor to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className={classNames("flex w-full transition-all duration-150", sidebarOpen && pageList.length > 1 ? "lg:pl-0" : "")}>
          <div className="fixed top-0 z-50 flex h-16 w-full flex-shrink-0 bg-headerColor shadow-md">
            {workspaceDetails?._id && (
              <Logo
                workspaces={workspaces}
                clickWorkspace={clickWorkspace}
                extractWorkspaceName={extractWorkspaceName}>
                {pageList.length > 1 && (
                  <>
                    <Button
                      type="button"
                      version="default"
                      className="px-4 text-headerTextColor focus:border-highlightColor focus:ring-0 lg:hidden"
                      onClick={() => setSidebarOpenMobile(true)}>
                      <span className="sr-only">Open left navigation</span>
                      <Bars3BottomLeftIcon
                        className="h-6 w-6 text-headerTextColor"
                        aria-hidden="true"
                      />
                    </Button>
                    {workspaceDetails?._id && workspaceDetails.layout_type === "LEFT_NAVIGATION" && (
                      <Button
                        type="button"
                        version="default"
                        className="hidden border-0 px-4 text-gray-500 focus:border-headerTextColor focus:ring-0 lg:flex"
                        onClick={() => setSidebarOpen(!sidebarOpen)}>
                        <span className="sr-only">Open leftNavColor</span>
                        <Bars3BottomLeftIcon
                          className="h-6 w-6 text-headerTextColor"
                          aria-hidden="true"
                        />
                      </Button>
                    )}
                  </>
                )}
              </Logo>
            )}
            <div className="flex w-full items-center justify-end pr-6">
              <div className="ml-4 flex items-center lg:ml-6">
                <Menu
                  as="div"
                  className="flex items-center">
                  <Menu.Button className="flex items-center justify-center">
                    <div className="w-22 m-0 hidden h-6 items-center text-sm font-medium text-headerTextColor sm:flex">{user?.name ? user?.name : null}</div>
                    <span className="sr-only">Open user menu</span>
                    <div className="ml-3 h-8 w-8 overflow-hidden rounded-full shadow">{<UserProfile user={user || {}} />}</div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-7 top-12 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <AdminNavDesktop userNavigation={userNavigation.filter((item) => !item.hidden)} />
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        {workspaceDetails?._id && workspaceDetails.layout_type === "LEFT_NAVIGATION" && (
          <div className="relative flex h-full w-full">
            {pageList.length > 1 && (
              <div className={classNames("fixed top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lg:flex lg:w-80 lg:flex-col ", sidebarOpen && pageList.length > 1 ? "left-0" : "-left-80")}>
                <LeftNavigationPanel
                  pageList={pageList}
                  currentNavigation={currentNavigation}
                  updateNavigation={updateNavigation}
                  setPageList={setPageList}
                />
              </div>
            )}
            <div className={classNames("relative z-10 w-full pt-16 transition-all duration-150", sidebarOpen && pageList.length > 1 ? "lg:pl-80" : "lg:pl-0")}>
              <div className="h-full w-full bg-backgroundColor">{children}</div>
            </div>
          </div>
        )}
        {workspaceDetails?._id && workspaceDetails.layout_type === "TOP_NAVIGATION" && (
          <div className="relative z-40 h-screen w-full pt-16">
            {pageList.length > 1 && (
              <div className="relative z-20 hidden transition-all duration-150 lg:flex">
                <TopNavigationPanel
                  pageList={pageList}
                  currentNavigation={currentNavigation}
                  updateNavigation={updateNavigation}
                  groupId={groupId}
                />
              </div>
            )}
            <div className="relative z-10 h-screen max-h-[calc(100vh-120px)] w-full transition-all duration-100">
              <div
                className="relative flex h-full w-full justify-center bg-white py-0 pl-0 transition-all"
                id="content">
                <div className="flex w-full flex-col bg-backgroundColor lg:overflow-hidden lg:rounded-lg lg:border lg:border-gray-100 lg:shadow-lg">{children}</div>
              </div>
            </div>
          </div>
        )}
        {workspaceDetails?._id && workspaceDetails.layout_type === "NO_NAVIGATION" && (
          <div className="relative h-screen w-full pt-16">
            <div className="relative z-10 h-screen max-h-[calc(100vh-56px)] w-full transition-all duration-100">
              <div className="h-full w-full bg-backgroundColor">{children}</div>
            </div>
          </div>
        )}
        {!workspaceDetails?._id && (
          <div className="relative h-screen w-full pt-16">
            <div className="relative z-10 h-screen max-h-[calc(100vh-56px)] w-full transition-all duration-100">
              <div className="h-full w-full bg-backgroundColor">{children}</div>
            </div>
          </div>
        )}
      </div>
    </FeatureFlagsProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, setWorkspaceDetails })(UserLayout);
