import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

export default function BackIndicator({ onClick }) {
  const navigate = useNavigate();
  let windowElements = window.location.pathname.split("/");
  return (
    <ArrowUturnLeftIcon
      onClick={() => (typeof onClick === "function" ? onClick() : navigate("/" + windowElements[windowElements.length - 2]))}
      className={classNames("h-8 w-8", "heroicon-stroke-w-4 mr-1 cursor-pointer rounded border border-gray-300 p-1 text-gray-400 transition-all duration-200", "hover:border-gray-400 hover:bg-gray-200/50 hover:text-gray-600")}
    />
  );
}
