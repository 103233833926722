import { RectangleStackIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import UserProfile from "src/components/Users/UserProfile";
import { classNames } from "src/helpers/classNames";
import { formatName } from "src/helpers/formatName";

const BubbleIconList = ({ data, type = "user", className = "", rounded = true }) => {
  const [members, setMembers] = useState([]);
  const [invalidImage, setInvalidImage] = useState(false);

  useEffect(() => {
    if (data?.length > 0) {
      setMembers(data.slice(0, 3));
    } else {
      setMembers([]);
    }
  }, [data]);

  return (
    <>
      <div className={`flex items-center justify-center ${className}`}>
        <div className="flex items-center -space-x-2">
          {type === "user" ? (
            members &&
            members?.map((user) => {
              return (
                <div
                  key={user?._id || user?.id}
                  className={classNames("z-20 h-6 w-6 overflow-hidden ring-2 ring-white ring-offset-0", rounded ? "rounded-full" : "rounded-sm")}>
                  <UserProfile
                    user={user}
                    size="sm"
                  />
                </div>
              );
            })
          ) : (
            <>
              {members?.map((item, index) => (
                <div
                  key={item?.id}
                  style={{ height: `${25 - index * 2}px`, width: `${25 - index * 2}px` }}
                  className={classNames("z-20 overflow-hidden ring-1 ring-gray-50 ring-offset-0", rounded ? "rounded-full" : "rounded-sm")}>
                  {invalidImage || !item?.image ? (
                    <div className={classNames("flex h-full w-full flex-shrink-0 items-center justify-center overflow-hidden bg-gray-200 text-xs uppercase text-gray-900", rounded ? "rounded-full" : "rounded-sm")}>{formatName(item?.name, item?.email)}</div>
                  ) : (
                    <div className={classNames("relative h-full w-full flex-shrink-0 overflow-hidden", rounded ? "rounded-full" : "rounded-sm")}>
                      <img
                        className={classNames("h-full w-full bg-white object-contain", "opacity-100 transition-opacity duration-150")}
                        src={item?.image}
                        alt="data-image"
                        onError={() => setInvalidImage(true)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
        <div className="flex items-center justify-center text-sm font-medium text-gray-400">
          {data?.length > 0 ? (
            data?.length === 1 ? (
              <div className="ml-2">{data[0]?.name}</div>
            ) : data?.length > 3 ? (
              <div className="ml-2">
                +{data.length - 3} other{data.length - 3 > 1 ? "s" : ""}
              </div>
            ) : (
              <div className="ml-2">
                {data?.length} {type || "user"}
                {data?.length > 1 ? "s" : ""}
              </div>
            )
          ) : type === "user" ? (
            `No ${type || "user"}s`
          ) : (
            <div className="flex gap-x-[2px] font-medium text-gray-300 transition-all duration-100 md:gap-x-1">
              <RectangleStackIcon className="h-5 w-5" />0 {type}s
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BubbleIconList;
