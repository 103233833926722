import { RectangleStackIcon, TagIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { formatCurrency } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import BillingWorkspaceList from "./BillingWorkspaceList";

const BillingPlanBodyContent = ({ data = null, setLists = () => { }, ...props }) => {
  const [workspaceCount, setWorkspaceCount] = useState(-1);

  return (
    <div className="flex-shrink-0">
      <div className="relative z-0 px-8 pb-6 pt-8">
        {/* bg-[linear-gradient(45deg,_#edf1fe,_#f9faff,_#cfd1e7)] */}
        <div className="absolute left-0 top-0 z-[-1] flex h-80 w-full flex-col justify-end">
          <div className="h-20 w-full bg-gradient-to-t from-white"></div>
        </div>
        <div className="space-y-4">
          <div className="space-y-4">
            <div className="flex w-full items-center justify-between">
              <div className="flex  items-center gap-2 text-3xl font-bold">
                {data?.name || "Plan Name"} {data?.default && <div className={classNames("flex h-8 items-center justify-center gap-2 rounded border border-blue-500 bg-blue-50 px-2 text-base text-blue-500")}>Default</div>}
              </div>
              <div className="inline-flex rounded-full border bg-white px-4 py-2">
                <RectangleStackIcon className="h-5 w-5 text-gray-600" />
                <span className="ml-2 text-sm font-medium capitalize text-gray-600">
                  <span className="font-semibold">{workspaceCount}</span> {workspaceCount === 1 ? "workspace" : "workspaces"}
                </span>
              </div>
            </div>
            <div className="text-base text-gray-700">{data?.description || "No description provided."}</div>
          </div>
          <div className="">
            {data?.free_trial?.status && (
              <div className="inline-flex items-center rounded-full bg-white py-3 pl-4 pr-6">
                <TagIcon className="h-6 w-6 text-gray-600" />
                <span className="ml-3 text-base text-gray-600">{data?.free_trial?.days} day free trial</span>
              </div>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 gap-4">
              {data?.pricing?.rates?.map((price) => {
                let pricePerMonth = ((price?.amount || 0) - (price?.discount_amount || 0)) / 12;
                let totalPrice = price ? price.amount : 0;

                if (price?.interval === "month") {
                  pricePerMonth *= 12;
                  totalPrice *= 12;
                }

                const discountedPrice = totalPrice - (price?.discount_amount || 0);

                return (
                  <div className="relative mt-2 flex w-full flex-col gap-4 rounded-md border p-3">
                    {price?.discount_type !== "none" && <div className="absolute right-0 top-0 rounded-bl-lg bg-slate-200 p-1.5 text-sm">{price?.discount_percentage}% OFF</div>}
                    <div className="text-lg font-medium capitalize">{price?.interval}ly</div>
                    <div className="flex items-center justify-between">
                      <div className="text-lg text-yellow-600">
                        {formatCurrency(pricePerMonth)}
                        <span className="pl-0.5 text-base">/month</span>
                      </div>
                      <div className="font-medium">
                        Total: {formatCurrency(discountedPrice)}/year
                        {price?.discount_amount > 0 > 0 && <span className="ml-1 text-slate-500 line-through">{formatCurrency(totalPrice)}/year</span>}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="">
            <BillingWorkspaceList
              planId={data?._id}
              workspaceCount={workspaceCount}
              setWorkspaceCount={setWorkspaceCount}
              isNocharge={data?.pricing?.type === "no_charge"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(BillingPlanBodyContent);
