import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

export default function CSVFormat({ className = "" }) {
  const downloadCSVFormatData = async () => {
    try {
      const res = await apiRequest("GET", `/users/csv-format-download`);
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: "text/csv" });
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "upload_users_format.csv";
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
      } else {
        toast.error("Server responded with an error:", res.statusText);
      }
    } catch (error) {
      toast.error("Error downloading CSV:", error);
    }
  };
  return (
    <div className={classNames("", className)}>
      <Button
        onClick={downloadCSVFormatData}
        version="default"
        className="flex min-w-[120px] cursor-pointer border-leftNavColor font-medium text-gray-400 underline focus:outline-none">
        <div className="group relative flex items-center gap-x-2 rounded px-2 py-1 hover:bg-gray-200/20">
          <ArrowDownTrayIcon className="relative top-[-2px] h-6 w-6 transition-all duration-100 group-hover:top-[0px]" /> CSV Format
        </div>
      </Button>
    </div>
  );
}
