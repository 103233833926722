import { connect } from "react-redux";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Search from "src/components/Shared/Search";
import MultiColumnLayout from "src/components/Shared/MultiColumnLayout/MultiColumnLayout";
import useGetListAndSelectItem from "src/hooks/useGetListAndSelectItem";
import BillingPlanBodyContent from "./BillingPlanBodyContent";
import BillingPlanManageModal from "./BillingPlanManageModal";
import BillingPlanItems from "./BillingPlanItems";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import BillingPlanDeleteModal from "./BillingPlanDeleteModal";

const BillingPlan = () => {
  const formJson = {
    name: "",
    description: "",
    pricing: {
      type: "standard",
      rates: [
        { interval: "month", amount: 0, discount_percentage: 0 },
        { interval: "year", amount: 0, discount_percentage: 0 },
      ],
    },
    default: false,
    free_trial: { status: false, days: 0 },
  };
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { lists, setLists, meta, isLoaded, limit, offset, setOffset, keyword, setKeyword, selectedItem, setSelectedItem, refreshData, handleOnPaginationChange } = useGetListAndSelectItem({
    url: `/plans`,
    paginationData: { limit: 10, offset: 0 },
  });

  const onOpenEditModal = () => {
    setEditItem(selectedItem);
    setIsAddModalOpen(true);
  };

  const makeDefaultPlan = async () => {
    try {
      const { data } = await apiRequest("PUT", `/plans/${selectedItem?._id}/default`, {});
      toast.success(data.message);
      refreshData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const headerActions = [
    {
      name: selectedItem?.default ? "Default" : "Set as Default",
      onClick: !selectedItem?.default ? makeDefaultPlan : null,
      hasIcon: selectedItem?.default ? true : false,
      icon: CheckIcon,
      divClasses: "!bg-transparent",
      buttonClasses: selectedItem?.default ? "!bg-green-200 !text-green-700" : "",
    },
    { name: "", type: "hr" },
    ...(selectedItem?.pricing?.type === "no_charge" ? [] : [{ name: "Edit", onClick: onOpenEditModal }]),
    ...(selectedItem?.pricing?.type === "no_charge" ? [] : [{ name: "", type: "hr" }]),
    ...(selectedItem?.pricing?.type === "no_charge" ? [] : [{ name: "Delete", onClick: () => setIsDeleteModalOpen(true) }]),
  ];

  return (
    <>
      <MultiColumnLayout
        title={""}
        subTitle={``}
        asideLoaded={isLoaded}
        aside={lists}
        paginationData={{ meta, limit, offset }}
        handleOnPaginationChange={handleOnPaginationChange}
        itemEntry={(item) => <BillingPlanItems data={item} />}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        mainHeaderContent={
          <>
            <div className="w-96">
              <Search
                keyword={keyword}
                setKeyword={(val) => {
                  setOffset(0);
                  setKeyword(val);
                }}
                placeholder={"Search"}
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                version="secondary"
                onClick={() => {
                  setEditItem(formJson);
                  setIsAddModalOpen(true);
                }}>
                <PlusIcon className="h-5 w-5" />
                Add plan
              </Button>
            </div>
          </>
        }
        bodyHeaderActions={headerActions}
        bodyContent={
          <BillingPlanBodyContent
            data={selectedItem}
            setLists={setLists}
          />
        }
      />
      <BillingPlanManageModal
        title="Plan"
        secondaryTitle={editItem ? `Edit - ${editItem?.name}` : "Add"}
        isOpen={isAddModalOpen}
        editItem={editItem}
        onSuccess={() => {
          setIsAddModalOpen(false);
          refreshData();
          setEditItem(null);
        }}
        onCancel={() => {
          setIsAddModalOpen(false);
          setEditItem(null);
        }}
      />
      <BillingPlanDeleteModal
        plans={lists}
        refreshData={refreshData}
        isOpen={isDeleteModalOpen}
        selectedItem={selectedItem}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(BillingPlan);
