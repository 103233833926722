import TableRow from "src/components/Shared/Table/TableRow";
import { connect } from "react-redux";
import moment from "moment";
import { cardtypes, getStatusStyles } from "src/helpers/billingHelper";
import { classNames } from "src/helpers/classNames";
import { formatCurrency } from "src/helpers";
import { Tooltip } from "react-tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { daysDifferent } from "src/helpers/dateHelper";
import { useMemo } from "react";
// import { toast } from "react-toastify";
// import { apiRequest } from "src/async/apiUtils";
// import Button from "src/components/Shared/Buttons/Button";

const TransactionEntry = ({ data, workspaceId, showPlanName }) => {
  const { className, label } = getStatusStyles(data?.payment_status, "transaction");
  // const [loading, setLoading] = useState(false);

  // const downloadInvoicePdf = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await apiRequest(
  //       "get",
  //       workspaceId ? `/workspaces/:workspace_id/billing/transactions/:transaction_id/invoice` : "/billing/transactions/:transaction_id/invoice",
  //       {
  //         params: { workspace_id: workspaceId, transaction_id: data?._id },
  //       },
  //       { responseType: "blob" },
  //     );

  //     // Create a URL for the Blob object
  //     const pdfUrl = URL.createObjectURL(res?.data);

  //     // Open the PDF in a new tab
  //     window.open(pdfUrl, "_blank");

  //   } catch (error) {
  //     toast.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const isAdjust = data?.adjusted_transaction_details?.transaction_id;

  const prevTransaction = data?.adjusted_transaction_details?.transaction_id;
  const prevPlan = prevTransaction?.plan_details;

  const adjustCalculator = useMemo(() => {
    const currentPlanDailyPrice = prevTransaction?.amount / daysDifferent(new Date(prevTransaction?.start_date), new Date(prevTransaction?.end_date));
    const yearlyRate = data?.plan_details?.pricing?.rates?.find((item) => item?.interval === "year");
    const changePlanDailyPrice = (yearlyRate?.amount - yearlyRate?.discount_amount) / 365;

    const currentDate = data?.created_at;
    const startDate = new Date(prevTransaction?.start_date);
    const prevPlanEndDate = new Date(prevTransaction?.end_date);

    const daysUsed = daysDifferent(startDate, currentDate);
    const priceUsed = currentPlanDailyPrice * daysUsed;
    const remainingPrice = prevTransaction?.amount - priceUsed;

    const daysTobeAdjusted = Math.floor(remainingPrice / changePlanDailyPrice);

    const newPlanBillingStartDate = new Date(prevTransaction?.start_date);
    newPlanBillingStartDate.setDate(newPlanBillingStartDate.getDate() + daysTobeAdjusted);

    return {
      daysDecreased: daysDifferent(prevTransaction?.start_date, prevTransaction?.end_date) - daysTobeAdjusted,
      newDailyPrice: changePlanDailyPrice.toFixed(2),
      newPlanStartDate: newPlanBillingStartDate,
      newPlan: data?.plan_details?.name,
      prevPlanEndDate: prevPlanEndDate,
      yearlyRateWithDiscount: yearlyRate?.amount - yearlyRate?.discount_amount,
      currentPlanDailyPrice,
      remainingPrice,
    };
  }, [prevTransaction, data?.plan_details, data?.created_at]);

  const tooltipData = () => {
    return (
      <div className="rounded-lg border border-gray-300 p-2">
        <div className="border-b border-highlightColor pb-1 text-sm">
          Upgraded to <span className="ml-1 font-semibold">{data?.plan_details?.name}</span>
        </div>
        <div className="mt-1 flex gap-2">
          <div className="border-r pr-2">
            <div className="flex justify-between border-b border-gray-200 py-1">
              <span>Previous plan:</span>
              <span className="ml-1 font-semibold">
                {prevPlan?.name} | {formatCurrency(prevTransaction?.amount)}/{prevTransaction?.subscription?.recurring_type}
              </span>
            </div>
            <div className="flex justify-between border-b border-gray-200 py-1">
              <span>Daily Rate:</span>
              <span className="ml-1 font-semibold">{formatCurrency(adjustCalculator?.currentPlanDailyPrice)}/day</span>
            </div>
            <div className="flex justify-between border-b border-gray-200 py-1">
              <span>Remaining Balance:</span>
              <span className="mr-2 font-semibold">{formatCurrency(adjustCalculator?.remainingPrice)}</span>
            </div>
          </div>
          <div>
            <div className="flex justify-between border-b border-gray-200 py-1">
              <span>Upgraded plan:</span>
              <span className="ml-1 font-semibold">
                {data?.plan_details?.name} | {formatCurrency(adjustCalculator?.yearlyRateWithDiscount)}/year
              </span>
            </div>

            <div className="flex justify-between border-b border-gray-200 py-1">
              <span>Daily Rate:</span>
              <span className="ml-1 font-semibold">{formatCurrency(adjustCalculator?.newDailyPrice)}/day</span>
            </div>

            <div className="flex justify-between border-b py-1">
              <span>Adjusted Days:</span>
              <span className="ml-1 font-semibold lowercase">
                {adjustCalculator?.daysDecreased.toFixed(0)} day({"s"})
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <TableRow keyId={data?._id}>
      {showPlanName && (
        <td className="break-word block w-full whitespace-pre-wrap px-6 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
          <div className="font-medium text-gray-900">{data?.plan_details?.name}</div>
        </td>
      )}
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="font-medium text-gray-900">{moment(data?.created_at).format("MMM DD, YYYY")}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className=" flex items-center gap-1 font-medium capitalize text-gray-900">
          {`${formatCurrency(isAdjust ? 0 : data?.amount)} | billed ${data?.subscription?.recurring_type}ly ${isAdjust ? "- (adjusted)" : ""}`}
          {isAdjust && (
            <span>
              <div data-tooltip-id={`static-tooltip-${data?._id}`}>
                <InformationCircleIcon className="h-4 w-4 cursor-pointer" />
              </div>
              <Tooltip
                id={`static-tooltip-${data?._id}`}
                content={tooltipData()}
                className="z-80 text rounded-lg bg-slate-50 px-2 text-highlightColor opacity-100 shadow-lg"
              />
            </span>
          )}
        </div>
        {data?.payment_method_details?.card_last4 && (
          <div className=" flex items-center justify-start gap-1 text-xs text-gray-600">
            <div
              className="w-10"
              dangerouslySetInnerHTML={{ __html: data?.payment_method_details?.brand ? cardtypes[data?.payment_method_details?.brand] : "" }}></div>
            <span className="pt-1">{`XXXX-XXXX-XXXX-${data?.payment_method_details?.card_last4}`}</span>
          </div>
        )}
      </td>
      <td className="block w-full whitespace-pre-wrap break-all px-3 py-1 text-xs capitalize text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        {workspaceId ? (isAdjust ? "Upgraded to " + data?.plan_details?.name : data?.plan_details?.name) : data?.workspace?.name || <div className="text-gray-400">*Workspace Deleted*</div>}
      </td>
      <td className={"block w-full whitespace-pre-wrap break-all px-3 py-1 text-xs text-gray-500 sm:table-cell sm:w-auto sm:py-3.5"}>
        <div className={classNames("inline-flex items-center justify-center gap-2 rounded-full py-1 px-3 text-xs font-semibold opacity-50", className)}>{label}</div>
      </td>

      {/* <td className={"block w-full whitespace-pre-wrap break-all px-3 py-1 text-xs text-gray-500 sm:table-cell sm:w-auto sm:py-3.5"}>
        {data?.payment_status !== "canceled" ? (
          <Button
            className="!h-9 !w-9"
            version="secondary"
            onClick={downloadInvoicePdf}>
            {loading ? <ArrowPathIcon className="h-6 w-6 animate-spin" /> : <ArrowDownTrayIcon className="h-6 w-6" />}
          </Button>
        ) : (
          <div className="text-sm">N/A</div>
        )}
      </td> */}
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(TransactionEntry);
