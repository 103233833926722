import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageWorkspaceSettings } from "src/actions/workspace";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import useFetch from "src/hooks/useFetch";

const WorkspacePageEmbedding = ({ ...props }) => {
  const [authorizedWorkspaces, setAuthorizedWorkspaces] = useState([]);

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post", data: { workspace_type: "IFRAME_EMBED" } });

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setAuthorizedWorkspaces(updatedSelectedWorkspaces);
  };

  const handlePageEmbeddingUpdate = async (authorizedWorkspaces) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: props?.workspaceDetails?._id, ...authorizedWorkspaces }, "/workspaces/:workspace_id/settings/page-embedding");
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setAuthorizedWorkspaces(props.workspaceDetails?.authorized_workspaces);
  }, [props.workspaceDetails]);

  return (
    <EditContainer
      title="Page Embedding"
      subtitle="Select authorized workspaces to embed this content as a navigation page"
      preview={{
        style: "success",
        isSuccess: authorizedWorkspaces?.length > 0,
        text: authorizedWorkspaces?.length > 0 ? `${authorizedWorkspaces?.length} workspace(s) selected` : `No workspace selected`,
      }}
      onSuccess={() => handlePageEmbeddingUpdate({ authorized_workspaces: authorizedWorkspaces })}
      onCancel={() => setAuthorizedWorkspaces(props.workspaceDetails?.authorized_workspaces)}>
      <div className="w-full max-w-2xl flex-shrink">
        <MultiSelectObject
          defaultOptions={
            workspacesLoaded &&
            workspaces
              .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
              .map((wrk) => {
                return { key: wrk._id, value: wrk.name, selected: authorizedWorkspaces?.includes(wrk._id?.toString()), object: wrk };
              })
          }
          searchableFields={["name"]}
          title="Assign workspaces"
          onChange={handleSelectedWorkspaces}
          drowdownWindowMaxWidth={"max-w-72 min-w-72"}
          dropdownButtonClass={"max-w-72 min-w-72"}
        />
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspacePageEmbedding);
