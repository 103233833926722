import Tabs from "src/components/Shared/Tabs/Tabs";
import EditContainer from "../../../Shared/Containers/EditContainer";
import EmailTemplateList from "./EmailTemplateList";

const EmailTemplateSettings = () => {
  return (
    <EditContainer
      title="Email Templates"
      subtitle="Customize your email templates so your clients recieve copy and branding that matches your company."
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      fullWidth={true}>
      <div className="w-full pt-4">
        <Tabs
          queryParamName="emailTemplateTabs"
          tabs={[
            {
              name: "General",
              component: <EmailTemplateList type={"general"} />,
            },
            {
              name: "Billing",
              component: <EmailTemplateList type={"billing"} />,
            },
          ]}
        />
      </div>
    </EditContainer>
  );
};

export default EmailTemplateSettings;
