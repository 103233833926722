import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import Section from "src/components/Shared/Containers/Section";
import { H2 } from "src/components/Shared/Text/Headers";
import { getAuthorizedUser } from "src/actions/auth";
import { connect } from "react-redux";
import { useParams } from "react-router";
import WorkspaceColors from "./WorkspaceColors";
import WorkspaceLayouts from "./WorkspaceLayouts";
import WorkspaceSiteName from "./WorkspaceSiteName";
import WorkspaceWhitelabel from "./WorkspaceWhitelabel";
import WorkspaceSquareLogo from "./WorkspaceSquareLogo";

const WorkspaceLayoutStyles = ({ workspace, setWorkspace = () => {}, ...props }) => {
  const { id } = useParams();

  return (
    <Section>
      <div className="">
        <H2 margin={false}>Layout and Styles</H2>
        {id && (
          <>
            {authorizeUserComponentAccess(props?.me, id, "alias", ["update"]) && <WorkspaceSiteName />}

            {authorizeUserComponentAccess(props?.me, id, "layout", ["update"]) && (
              <>
                <WorkspaceWhitelabel workspace={workspace} />
                <WorkspaceSquareLogo setWorkspace={setWorkspace} />
                {workspace.workspace_type === "IFRAME_EMBED" && <WorkspaceLayouts workspaceId={id} />}
                <WorkspaceColors
                  workspace={workspace}
                  setWorkspace={setWorkspace}
                />
              </>
            )}
          </>
        )}
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(WorkspaceLayoutStyles);
