import { Menu } from "@headlessui/react";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, getAuthorizedUser } from "src/actions/auth";
import { classNames } from "src/helpers/classNames";
import { resetWorkspaceDetails } from "src/actions/page";
import Button from "src/components/Shared/Buttons/Button";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

function AdminNavDesktop({ userNavigation, resetWorkspaceDetails, getAuthorizedUser, logout }) {
  const [switchUserButtonDisabled, setSwitchUserButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const handleRemovePreviewUser = async () => {
    localStorage.removeItem("impersonatedUserId");
    window.location.href = "/users";
  };

  const onHandleSwitchToAdmin = async () => {
    try {
      setSwitchUserButtonDisabled(true);
      const { data } = await apiRequest("get", "/users/switch-to-user");
      window.open(data?.data, "_blank");
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSwitchUserButtonDisabled(false);
    }
  };

  return (
    <>
      {userNavigation.map((item, site, updateNavigation) => (
        <Menu.Item key={item.name}>
          {({ active, close }) => (
            <Fragment key={item.name}>
              {item.name === "User portal" ? (
                <Button
                  disabled={switchUserButtonDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    onHandleSwitchToAdmin();
                  }}
                  version="default"
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-highlightColor">
                  {item.name}
                </Button>
              ) : item.name !== "Sign out" ? (
                <div onClick={(navItem) => (updateNavigation.includes(navItem.name) ? navigate(navItem.name) : null)}>
                  {item?.onClick ? (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        item?.onClick();
                        close();
                      }}
                      version="default"
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-highlightColor">
                      {item.name}
                    </Button>
                  ) : item.path.includes("http") || item.reloadLink ? (
                    <a
                      href={item.path}
                      target={item.reloadLink ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                      className={classNames(active ? "bg-color-06 bg-gray-100" : "", "flex px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                      {item.name} {item.icon && <div className="flex pl-2">{item.icon}</div>}
                    </a>
                  ) : (
                    <Link
                      to={item.path}
                      onClick={() => close()}
                      className={classNames(active ? "bg-color-06 bg-gray-100" : "", "flex px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                      {item.name} {item.icon && <div className="flex pl-2">{item.icon}</div>}
                    </Link>
                  )}
                </div>
              ) : (
                <>
                  {userNavigation.length > 1 && <hr className="py-.5 mt-1" />}
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (!localStorage.getItem("impersonatedUserId")) {
                        logout(site);
                      } else {
                        handleRemovePreviewUser();
                      }
                    }}
                    version="default"
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-highlightColor">
                    {item.name}
                  </Button>
                </>
              )}
            </Fragment>
          )}
        </Menu.Item>
      ))}
    </>
  );
}

export default connect(null, { logout, resetWorkspaceDetails, getAuthorizedUser })(AdminNavDesktop);
