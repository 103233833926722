import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import { H3 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";

const EditContainerPreview = ({ text = "", custom = <></>, border = true, style = "default", isSuccess = false }) => {
  return (
    <>
      {text ? (
        <div className={classNames("flex h-10 max-w-[300px] items-center justify-start lg:max-w-[1000px]")}>
          {style === "success" ? (
            <p className={classNames("flex gap-x-2 rounded px-2 py-1 opacity-60", isSuccess ? "bg-green-50 text-green-700" : "bg-gray-50")}>{text}</p>
          ) : (
            <div className={classNames("flex gap-x-2 px-3 py-1", border ? "rounded border border-gray-100 shadow" : "")}>{text}</div>
          )}
        </div>
      ) : (
        custom
      )}
    </>
  );
};

const EditContainer = ({ title, subtitle = "", preview, height = "4000px", onCancel = () => {}, onSuccess = () => {}, defaultOptions = null, isLoading = false, isDisabled = false, fullWidth = false, defaultOpen = false, borderBottom = true, children }) => {
  const [show, setShow] = useState(defaultOpen);

  const [options, setOptions] = useState({
    onCancelButtonVisible: true,
    onCancelButtonText: "Cancel",
    onSuccessButtonVisible: true,
    onSuccessButtonText: "Save",
    onSuccessLoaderVisible: false,
    onSuccessLoaderStart: false,
  });

  useEffect(() => {
    if (defaultOptions) setOptions({ ...options, ...defaultOptions });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  useEffect(() => {
    setShow(defaultOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen]);

  useEffect(() => {
    if (isLoading) {
      setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
    } else {
      setTimeout(() => {
        setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
      }, 300);
    }
  }, [isLoading]);

  return (
    <div className={classNames("relative w-full py-4 transition-all sm:py-8", borderBottom ? "border-b border-slate-200" : "", show ? "z-40" : "")}>
      <div className="absolute top-5 flex w-full justify-between">
        <div className="h-[47px] w-56 flex-shrink-0 md:h-[40px] lg:w-60 xl:w-72 2xl:h-[47px] 2xl:w-80">
          <H3 wrapperClass="my-0">{title}</H3>
        </div>
        <Button
          type="button"
          version="default"
          onClick={() => setShow(!show)}
          className="flex h-8 w-16 items-center justify-center text-lg font-bold text-highlightColor transition-all hover:underline hover:opacity-80 focus:border-2 focus:border-dashed focus:border-gray-300">
          {show ? "Close" : "Edit"}
        </Button>
      </div>
      <div className="flex pr-20">
        <div className="h-[47px] w-56 flex-shrink-0 md:h-[40px] lg:w-60 xl:w-72 2xl:h-[47px] 2xl:w-96" />
        <div className="flex w-full justify-between">
          <div className="w-full">
            {!show && subtitle && <div className="hidden w-full text-sm font-medium text-gray-400 sm:flex">{subtitle}</div>}
            <div className="flex w-full flex-grow items-center">
              <div className={classNames("hidden w-full flex-shrink text-gray-400 transition-all md:block", show ? `max-h-[${height}]` : "max-h-auto w-full justify-center overflow-hidden", show && fullWidth ? "pt-4" : "")}>
                {show ? !fullWidth && children : <>{preview}</> && <EditContainerPreview {...preview} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames("w-full text-gray-400 transition-all", show && fullWidth ? `block w-full max-h-[${height}]` : `md:hidden md:pl-4 max-h-[${height}] md:max-h-auto md:overflow-hidden md:pt-0`)}>{show && children}</div>
      {show && (options.onSuccessButtonVisible || options.onCancelButtonVisible) ? (
        <div className="flex flex-shrink-0 justify-end gap-x-4 py-4">
          {options.onSuccessButtonVisible && (
            <Button
              type="button"
              version="default"
              disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart) || isDisabled}
              className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm ${
                !((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "!bg-highlightColor" : "bg-gray-50 !text-gray-500"
              }`}
              onClick={() => {
                onSuccess();
                if (!defaultOpen) {
                  setShow(false);
                }
              }}>
              {options.onSuccessButtonText}
              {options.onSuccessLoaderVisible && options.onSuccessLoaderStart && !options.onCancelLoaderVisible && (
                <ArrowPathIcon className={`ml-2 h-5 w-5 animate-spin text-white ${!((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "text-white" : "!text-gray-500"}`} />
              )}
            </Button>
          )}
          {options.onCancelButtonVisible && (
            <Button
              disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)}
              type="button"
              version="default"
              className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                onCancel();
                if (!defaultOpen) {
                  setShow(false);
                }
              }}>
              {options.onCancelButtonText}
              {options.onCancelLoaderVisible && options.onCancelLoaderStart && <ArrowPathIcon className="ml-2 h-5 w-5 animate-spin" />}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default EditContainer;
