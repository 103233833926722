import { connect } from "react-redux";
import { CheckCircleIcon, ClockIcon, WindowIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { abortOngoingRequests, apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import PreloaderIcon from "src/components/Shared/LoadingAnimations/PreloaderIcon";
import Modal from "src/components/Shared/Modal";
import { managePageData } from "src/actions/page";
import Button from "src/components/Shared/Buttons/Button";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import { useNavigate } from "react-router";

const WarningContent = () => {
  return <div className="text-lg text-gray-700">Are you sure you want to import all the page?</div>;
};

const PageFetchingContent = () => {
  return (
    <div className="flex h-40 items-center justify-center">
      <Preloader
        text="Fetching pages, please wait"
        size="lg"
        circleDimension="10"
      />
    </div>
  );
};

const PageListItem = ({ item = null, selectPage = () => {} }) => {
  return (
    <div className="relative space-y-2 px-2 py-2">
      <div className="flex w-full justify-between gap-10">
        <div className="flex w-full flex-shrink items-center gap-2">
          <Toggle
            defaultSize={"sm"}
            disabled={item?.disabled}
            checked={item?.checked}
            onChange={() => selectPage(item?.id)}
          />
          <div className="truncate text-sm font-semibold !leading-none text-gray-700">{item?.name}</div>
        </div>
        <div className="flex flex-shrink-0">
          <div className="flex h-5 w-5 flex-shrink-0 items-center justify-center">
            {item?.isWaiting || !item?.checked ? (
              <ClockIcon className="hidden h-[16px] w-[16px] text-amber-500" />
            ) : item?.isLoading ? (
              <PreloaderIcon
                height="h-4"
                width="h-4"
                color="text-gray-400 fill-gray-600"
              />
            ) : item?.isSuccess ? (
              <CheckCircleIcon className="h-[16px] w-[16px] text-green-600" />
            ) : (
              <XCircleIcon className="h-[16px] w-[16px] text-red-600" />
            )}
          </div>
          {item?.isLoading && <div className="ml-2 max-w-64 flex-shrink-0 py-1 text-right text-xs leading-none text-gray-400">Importing</div>}
          {item?.success && <div className="ml-2 max-w-64 flex-shrink-0 py-1 text-right text-xs leading-none text-green-600">{item?.success}</div>}
          {item?.error && <div className="ml-2 max-w-64 flex-shrink-0 py-1 text-right text-xs leading-none text-red-600">{item?.error}</div>}
        </div>
      </div>
    </div>
  );
};

const PageListContent = ({ pageList = [], selectPage = () => {}, globalCheckedDisabled = false, globalChecked = false, setGlobalChecked = () => {} }) => {
  return (
    <>
      <div className="absolute -top-12 right-12 flex items-end">
        <ToggleHeader
          title="Select All"
          titleClassName={"font-semibold"}
          position="left">
          <Toggle
            defaultSize={"sm"}
            disabled={globalCheckedDisabled}
            checked={globalChecked}
            onChange={setGlobalChecked}
          />
        </ToggleHeader>
      </div>
      <div className="scrollbar relative max-h-[calc(100vh-20rem)] divide-y divide-gray-200 overflow-auto">
        {pageList.map((item) => (
          <PageListItem
            key={item?.id}
            item={item}
            selectPage={selectPage}
          />
        ))}
      </div>
    </>
  );
};

const PageImportModal = ({
  isOpen,
  importData = {
    workspaceId: null,
    integrationId: null,
    credentialId: null,
    workspaces: [],
  },
  isImporting = false,
  onCancel = () => {},
  manageColumnNamesByPage = () => {},
  setIsImporting = () => {},
  isImported = false,
  setIsImported = () => {},
  ...props
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [cancelButtonVisible, setCancelButtonVisible] = useState(true);
  const [successButtonVisible, setSuccessButtonVisible] = useState(true);
  const [isMinimize, setIsMinimize] = useState(false);
  const [pageList, setPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [globalChecked, setGlobalChecked] = useState(false);
  const [globalCheckedDisabled, setGlobalCheckedDisabled] = useState(false);

  const onStepHandle = () => {
    if (step === 1) {
      fetchPageListFromDomoByCredential();
    } else if (step === 2) {
      startImporting();
    } else if (step === 3) {
      modalDataReset();
      navigate(importData?.workspaceId ? `/workspaces/${importData?.workspaceId}?tab=Pages` : `/pages?tab=Global+pages`);
    } else {
      setIsMinimize(true);
    }
  };

  const fetchPageListFromDomoByCredential = async () => {
    try {
      setIsLoading(true);
      setIsPageFetching(true);
      setStep(2);
      let url = `/integrations/:integration_id/credentials/:credential_id/domo-page/list`;
      if (importData?.workspaceId) url = `/workspaces/:workspace_id${url}`;
      const params = {
        workspace_id: importData?.workspaceId,
        integration_id: importData?.integrationId,
        credential_id: importData?.credentialId,
      };

      const { data } = await apiRequest("GET", url, { params });

      if (data?.data?.length > 0) {
        setPageList(
          data.data.map((item) => ({
            ...item,
            isWaiting: !item?.isExists,
            checked: item?.isExists,
            disabled: item?.isExists,
            isSuccess: item?.isExists,
            isCompleted: item?.isExists,
            success: item?.isExists ? "Page already been imported." : "",
          })),
        );
        if (data?.data?.length === data?.data?.filter((item) => item?.isExists)?.length) {
          setGlobalCheckedDisabled(true);
          // setSuccessButtonVisible(false);
          setStep(3);
        }
      } else {
        toast.error(`There are no pages in this credential.`);
        modalDataReset();
      }
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        toast.error(error.message);
        modalDataReset();
      }
    } finally {
      setIsPageFetching(false);
      setIsLoading(false);
    }
  };

  const selectPage = (pageId) => {
    setPageList((prevData) => prevData.map((item) => ({ ...item, checked: pageId === item?.id ? !item?.checked : item?.checked })));
  };

  const startImporting = () => {
    if (pageList?.find((page) => page?.checked && !page?.isCompleted)) {
      setGlobalCheckedDisabled(true);
      setIsLoading(true);
      // setCancelButtonVisible(false);
      // setStep(3);
      setIsImporting(true);
      setPageList((prevData) => prevData.map((item) => ({ ...item, disabled: true })));
      setCurrentPage(pageList[0]);
    } else {
      toast.error(`There are no pages enabled for import.`);
    }
  };

  const changeCurrentPage = () => {
    const pageIndex = pageList?.findIndex((item) => item?.id?.toString() === currentPage?.id?.toString());

    if (pageIndex !== -1) {
      if (pageList?.length > pageIndex + 1) {
        setCurrentPage(pageList[pageIndex + 1]);
      } else {
        setCurrentPage(null);
        setIsLoading(false);
        setIsImporting(false);
        setPageList((prevData) => prevData.map((item) => ({ ...item, disabled: item?.isCompleted ? item?.disabled : false })));
        if (pageList?.find((item) => !item?.checked)) {
          setGlobalCheckedDisabled(false);
        }
        setStep(3);
        setIsImported(true);
        toast.success(`Selected pages has been imported`);
      }
    } else {
      modalDataReset();
    }
  };

  const createPage = async () => {
    if (currentPage?.checked && !currentPage?.isCompleted) {
      try {
        setPageList((prevData) => prevData.map((item) => (item?.id?.toString() === currentPage?.id?.toString() ? { ...item, isWaiting: false, isLoading: true } : item)));
        let page = {
          is_global: importData?.workspaceId ? false : true,
          workspace_id: importData?.workspaceId ? [importData.workspaceId] : [],
          credential_id: importData?.credentialId,
          page_type: "DOMO",
          embed_id: currentPage.embed_id,
          embed_type: "EMBED_PAGE",
          name: currentPage.name,
          domo_page_id: currentPage.id,
        };

        const { data } = await props.managePageData({
          pages: [page],
          workspaceId: importData?.workspaceId,
          pageId: null,
        });
        setPageList((prevData) => prevData.map((item) => (item?.id?.toString() === currentPage?.id?.toString() ? { ...item, isWaiting: false, isLoading: false, isSuccess: true, isCompleted: true, success: "Page successfully imported." } : item)));
        // Importing pages columns
        if (data.page_type === "DOMO") {
          manageColumnNamesByPage({ workspaceId: importData?.workspaceId, pageId: data?._id });
        }

        changeCurrentPage();
      } catch (error) {
        if (error.message !== "CanceledError: canceled") {
          setPageList((prevData) => prevData.map((item) => (item?.id?.toString() === currentPage?.id?.toString() ? { ...item, isWaiting: false, isLoading: false, isSuccess: false, error: error.message, isCompleted: true } : item)));
          changeCurrentPage();
        }
      }
    } else {
      changeCurrentPage();
    }
  };

  const modalDataReset = () => {
    abortOngoingRequests();
    onCancel();
    setTimeout(() => {
      setStep(1);
      setIsLoading(false);
      setIsPageFetching(false);
      setCancelButtonVisible(true);
      setSuccessButtonVisible(true);
      setGlobalChecked(false);
      setGlobalCheckedDisabled(false);
      setIsMinimize(false);
      setPageList([]);
      setCurrentPage(null);
    }, 500);
  };

  useEffect(() => {
    setPageList((prevData) => prevData.map((item) => ({ ...item, checked: !item?.isCompleted ? globalChecked : item?.checked })));
  }, [globalChecked]);

  useEffect(() => {
    if (pageList?.length > 0 && currentPage?.id && importData?.credentialId) createPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage?.id, importData?.credentialId, pageList?.length]);

  return (
    <>
      {!isMinimize ? (
        <Modal
          title="Page"
          secondaryTitle="Import"
          isOpen={isOpen}
          onCancel={modalDataReset}
          onSuccess={onStepHandle}
          isLoading={isLoading}
          defaultOptions={{
            onSuccessButtonText: step === 1 ? "Continue" : step === 2 ? (pageList?.length === 0 ? "Continue" : "Submit") : step === 3 ? "Go To Pages" : "Send to background",
            onCancelButtonVisible: cancelButtonVisible,
            onSuccessButtonVisible: successButtonVisible,
          }}>
          <div className="relative w-full">
            {step === 1 ? (
              <WarningContent />
            ) : isPageFetching ? (
              <PageFetchingContent />
            ) : (
              <PageListContent
                pageList={pageList}
                selectPage={selectPage}
                globalCheckedDisabled={globalCheckedDisabled}
                globalChecked={globalChecked}
                setGlobalChecked={setGlobalChecked}
              />
            )}
          </div>
        </Modal>
      ) : (
        <div className="fixed bottom-0 right-10 z-50">
          <div className="flex h-12 translate-y-0 items-center justify-between rounded-t-lg border border-b-0 border-gray-200 bg-white py-2 pl-4 pr-2 shadow-xl transition-all duration-300">
            <div className="w-full min-w-80 max-w-80 overflow-hidden">
              <PageListItem item={currentPage} />
            </div>
            <div className="flex items-center gap-1">
              <Button
                onClick={() => setIsMinimize(false)}
                version="default"
                className="rounded p-1 transition-all duration-200 hover:bg-amber-100 hover:text-amber-500">
                <WindowIcon className="h-4 w-4" />
              </Button>
              <Button
                onClick={modalDataReset}
                version="default"
                className="rounded p-1 transition-all duration-200 hover:bg-red-100 hover:text-red-500">
                <XMarkIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { managePageData })(PageImportModal);
