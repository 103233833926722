import { Bars4Icon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import ActivityList from "src/components/Activity/ActivityList";
import ActivitySettings from "src/components/Activity/ActivitySettings";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import useFetch from "src/hooks/useFetch";

function Activity() {
  const activityTypes = [
    { key: "LOGIN", value: "Logins/Authentication" },
    { key: "PAGE_VIEW", value: "Page View" },
    { key: "WORKSPACE_CREATE", value: "Workspace Create" },
    { key: "WORKSPACE_ORDERING", value: "Workspace Ordering" },
    { key: "PAGE_CREATE", value: "Page Create" },
    { key: "PAGE_UPDATE", value: "Page Update" },
    { key: "PAGE_PREVIEW", value: "Page Preview" },
    { key: "PAGE_DELETE", value: "Page Delete" },
    { key: "FORM_CREATE", value: "Form Create" },
    { key: "FORM_UPDATE", value: "Form Update" },
    { key: "FORM_DELETE", value: "Form Delete" },
    { key: "FORM_SETTINGS_UPDATE", value: "Form Settings Update" },
    { key: "FORM_DATASET_UPDATE", value: "Form Dataset Update" },
    { key: "FORM_SUBMIT", value: "Form Submit" },
    { key: "FORM_RESPONSE_PUSH_TO_DATASET", value: "Form Responses Push To Dataset" },
    { key: "USER_CREATE", value: "User Create" },
    { key: "USER_CREATE_BY_SSO", value: "User Created By SSO" },
    { key: "USER_UPDATE", value: "User Update" },
    { key: "USER_UPDATE_BY_SSO", value: "User Updated By SSO" },
    { key: "USER_PASSWORD_CHANGE", value: "User Password Change" },
    { key: "USER_SEND_RESET_PASSWORD_EMAIL", value: "User Send Reset Password Email" },
    { key: "USER_SEND_WELCOME_EMAIL", value: "User Send Welcome Email" },
    { key: "USER_ASSIGN_TO_WORKSPACE", value: "User Assign To Workspace" },
    { key: "USER_UNASSIGN_FROM_WORKSPACE", value: "User Un-Assign From Workspace" },
    { key: "USER_DOMO_ROLE_CHANGE", value: "User Domo Role Change" },
    { key: "USER_INDIVIDUAL_PERMISSION_UPDATE", value: "User Individual Permission Change" },
    { key: "USER_ASSIGN_TO_GROUP", value: "User Assign To Group" },
    { key: "USER_UNASSIGN_TO_GROUP", value: "User Un-Assign To Group" },
    { key: "USER_DATA_ACCESS_UPDATE", value: "User Data Access Update" },
    { key: "USER_ASSIGN_TO_ADMIN", value: "User Assign To Admin" },
    { key: "ADMIN_ASSIGN_TO_USER", value: "Admin Assign To User" },
    { key: "USER_SSO_AUTO_UPDATE_DISABLE", value: "User SSO Auto Update Disabled" },
    { key: "USER_SSO_AUTO_UPDATE_ENABLE", value: "User SSO Auto Update Enabled" },
    { key: "USER_DATASET_AUTO_UPDATE_DISABLE", value: "User Dataset Auto Update Disabled" },
    { key: "USER_DATASET_AUTO_UPDATE_ENABLE", value: "User Dataset Auto Update Enabled" },
    { key: "USER_STATUS_CHANGE_TO_ACTIVE", value: "User Status Change To Active" },
    { key: "USER_STATUS_CHANGE_TO_INACTIVE", value: "User Status Change To In-Active" },
    { key: "USER_DELETE", value: "User Delete" },
    { key: "USER_DELETE_FROM_WORKSPACE", value: "User Delete From Workspace" },
    { key: "GROUP_CREATE", value: "Group Create" },
    { key: "GROUP_UPDATE", value: "Group Update" },
    { key: "GROUP_DELETE", value: "Group Delete" },
    { key: "GROUP_CLONE", value: "Group Clone" },
    { key: "GROUP_ICON_MANAGE", value: "Group Icon Update" },
    { key: "GROUP_ORDERING", value: "Group Ordering" },
    { key: "GROUP_PUBLIC", value: "Group Public" },
    { key: "GROUP_PRIVATE", value: "Group Private" },
    { key: "GROUP_SCHEDULE_UPDATE", value: "Group Schedule Update" },
    { key: "EDITOR_ASSIGN_TO_WORKSPACE", value: "Editor Assign To Workspace" },
    { key: "EDITOR_UNASSIGN_FROM_WORKSPACE", value: "Editor Un-Assign From Workspace" },
    { key: "EDITOR_PERMISSION_UPDATE", value: "Editor Permission Update" },
    { key: "LAYOUT_UPDATE", value: "Layout Update" },
    { key: "SMTP_UPDATE", value: "SMTP Update" },
    { key: "CREDENTIAL_CREATE", value: "Credential Create" },
    { key: "CREDENTIAL_UPDATE", value: "Credential Update" },
    { key: "CREDENTIAL_DELETE", value: "Credential Delete" },
    { key: "CREDENTIAL_MAKE_DEFAULT", value: "Credential Make Default" },
    { key: "CREDENTIAL_SET_CREATED_BY", value: "Credential Set Created By" },
    { key: "WORKSPACE_FILTER_UPDATE", value: "Workspace Filter Update" },
    { key: "WORKSPACE_DOMAIN_CREATE", value: "Workspace Domain Create" },
    { key: "WORKSPACE_DOMAIN_DELETE", value: "Workspace Domain Delete" },
    { key: "WORKSPACE_DELETE", value: "Workspace Delete" },
    { key: "SSO_CREATE", value: "SSO Create" },
    { key: "SSO_UPDATE", value: "SSO Update" },
    { key: "SSO_DELETE", value: "SSO Delete" },
    { key: "SSO_STATUS_CHANGE_TO_ACTIVE", value: "SSO Status Change To Active" },
    { key: "SSO_STATUS_CHANGE_TO_INACTIVE", value: "SSO Status Change To In-Active" },
    { key: "SSO_MAP_TO_WORKSPACE_GROUP_UPDATE", value: "SSO Map To Workspace Group Settings Update" },
    { key: "SSO_MAP_TO_USER_FIELDS_UPDATE", value: "SSO Map To User Fields Settings Update" },
    { key: "USER_CUSTOM_FIELD_CREATE", value: "Custom Attribute Create" },
    { key: "USER_CUSTOM_FIELD_UPDATE", value: "Custom Attribute Update" },
    { key: "USER_CUSTOM_FIELD_MAKE_REQUIRED", value: "Custom Attribute Set Required" },
    { key: "USER_CUSTOM_FIELD_MAKE_OPTIONAL", value: "Custom Attribute Set Optional" },
    { key: "USER_CUSTOM_FIELD_DELETE", value: "Custom Attribute Delete" },
    { key: "SETTINGS_UPDATE", value: "Settings Update" },
    { key: "EMAIL_TEMPLATE_UPDATE", value: "Email template Update" },
    { key: "USER_PUSH_TO_DATASET", value: "Upload Users To Dataset" },
    { key: "USER_PULL_FROM_DATASET", value: "Pull Users From Dataset" },
    { key: "USER_PULL_FROM_BULK_UPLOAD", value: "Upload Bulk Upload" },
    { key: "WORKSPACE_IMPORT_DOMO_USER", value: "Workspace Import Domo User" },
    { key: "ACTIVITY_PUSH_TO_DATASET", value: "Upload Activities To Dataset" },
    { key: "CHANGE_BILLING_PLAN", value: "Change Billing Plan" },
    { key: "ASSIGN_BILLING_PLAN", value: "Assign Billing Plan" },
    { key: "PAYMENT_SUCCEEDED", value: "Payment Succeeded" },
    { key: "SUBSCRIPTION_CANCELED", value: "Subscription Canceled" },
    { key: "SUBSCRIPTION_EXPIRED", value: "Subscription Expired" },
    { key: "PAYMENT_FAILED", value: "Payment Failed" },
    { key: "UPCOMING_PLAN_ACTIVATED", value: "Upcoming Plan Activated" },
    { key: "PAYMENT_REFUNDED", value: "Payment Refunded" },
  ];

  const locations = [
    { key: "ADMIN_PORTAL", value: "ADMIN_PORTAL" },
    { key: "USER_PORTAL", value: "USER_PORTAL" },
  ];

  const {
    response: { data: users },
  } = useFetch(`/users`);

  const {
    response: { data: workspaces },
  } = useFetch(`/workspaces/list`);

  const {
    response: { data: groups },
  } = useFetch(`/groups`);

  const {
    response: { data: pages },
  } = useFetch(`/pages/list`);

  const {
    response: { data: ips },
  } = useFetch(`/activities/ip/list`);

  const tabs = [
    {
      name: "Activity Log",
      icon: Bars4Icon,
      component: (
        <ActivityList
          activityTypes={activityTypes}
          locations={locations}
          users={users}
          workspaces={workspaces}
          groups={groups}
          pages={pages}
          ips={ips}
        />
      ),
    },
    {
      name: "Settings",
      icon: WrenchScrewdriverIcon,
      component: (
        <ActivitySettings
          activityTypes={activityTypes}
          locations={locations}
          users={users}
          workspaces={workspaces}
          groups={groups}
          pages={pages}
          ips={ips}
        />
      ),
    },
  ];

  return (
    <>
      <H1>Activity List</H1>
      <Tabs tabs={tabs} />
    </>
  );
}

export default Activity;
