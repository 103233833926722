import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "./Buttons/Button";

const SIZE_OPTIONS = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  full: "max-w-full",
};

export default function SlideOver({
  isOpen = false,
  handleClose = () => {},
  title = "",
  description = "",
  size = "2xl",
  maxWidth,
  position = "right",
  overlay = true,
  closeable = true,
  onOpenChange,
  className = "",
  headerClassName = "",
  bodyClassName = "",
  footerClassName = "",
  headerComponent,
  footerComponent,
  children,
}) {
  const [open, setOpen] = useState(isOpen);
  const [isGiantScreen, setIsGiantScreen] = useState(false);

  const sizeClass = (() => {
    if (maxWidth) {
      if (typeof maxWidth === "string") {
        return maxWidth;
      }
      if (typeof maxWidth === "number") {
        return `max-w-[${maxWidth}px]`;
      }
    }

    return SIZE_OPTIONS[size] || SIZE_OPTIONS["2xl"];
  })();

  const positionOptions = {
    left: {
      containerClass: "left-0 flex max-w-full pr-10 sm:pr-16",
      enterFrom: "-translate-x-full",
      enterTo: "translate-x-0",
      leaveTo: "-translate-x-full",
    },
    right: {
      containerClass: "right-0 flex max-w-full pl-10 sm:pl-16",
      enterFrom: "translate-x-full",
      enterTo: "translate-x-0",
      leaveTo: "translate-x-full",
    },
  };
  const positionConfig = positionOptions[position] || positionOptions.right;

  useEffect(() => {
    setOpen(isOpen);
    onOpenChange?.(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsGiantScreen(window.innerWidth > 1920);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnClose = () => {
    if (closeable) {
      setOpen(false);
      handleClose();
    }
  };

  return (
    <Transition.Root
      show={open}
      as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[30]"
        onClose={handleOnClose}>
        {overlay && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>
        )}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className={classNames("pointer-events-none fixed inset-y-0", positionConfig.containerClass)}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom={positionConfig.enterFrom}
                enterTo={positionConfig.enterTo}
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo={positionConfig.leaveTo}>
                <Dialog.Panel className={classNames("pointer-events-auto w-screen", sizeClass, className)}>
                  <div className={classNames("flex h-full flex-col overflow-y-scroll bg-white shadow-xl", isGiantScreen ? "rounded-tl-xl" : "md:rounded-tl-xl")}>
                    <div className={classNames("border-highlightColor/40 sticky top-0 z-10 flex-shrink-0 border-b bg-gray-50 px-4 py-6 sm:px-6", headerClassName)}>
                      <div className="flex items-start justify-between space-x-3">
                        {headerComponent || (
                          <>
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{title}</Dialog.Title>
                              {description && <p className="text-sm text-gray-500">{description}</p>}
                            </div>
                            {closeable && (
                              <div className="flex h-7 items-center">
                                <Button
                                  type="button"
                                  version="default"
                                  className="text-gray-400 hover:text-gray-500"
                                  onClick={handleOnClose}>
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classNames("flex flex-shrink flex-grow flex-col p-7", bodyClassName)}>{children}</div>
                    {footerComponent && <div className={classNames("border-highlightColor/40 sticky bottom-0 border-t bg-gray-50 px-4 py-4 sm:px-6", footerClassName)}>{footerComponent}</div>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
