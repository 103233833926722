import MarketingBasic from "./marketing/basic";
import MarketingMobileFocus from "./marketing/mobileFocus";
import useFetch from "src/hooks/useFetch";

export default function Marketing({ type = "mobileFocus" }) {
  const {
    response: { data: settings },
  } = useFetch(`/settings/details`);

  return (
    <>
      {settings?._id && (
        <>
          {type === "basic" && <MarketingBasic settings={settings} />}
          {type === "mobileFocus" && <MarketingMobileFocus settings={settings} />}
        </>
      )}
    </>
  );
}
