import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { H5 } from "src/components/Shared/Text/Headers";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import React from "react";

const WorspaceImportUser = ({ ...props }) => {
  const [autoImportDomoUser, setAutoImportDomoUser] = useState(false);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: props?.workspaceDetails?._id, ...updateSettings }, "/workspaces/:workspace_id/settings/auto-import");
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setAutoImportDomoUser(props.workspaceDetails?.auto_import_domo_user);
  }, [props.workspaceDetails]);

  return (
    <TextAccordion
      startOpen={true}
      headerTextPosition="left"
      headerText="Advanced details">
      <div className="grid gap-y-6 py-3">
        <div className="flex flex-col justify-between gap-y-3 md:flex-row">
          <H5 caption="Automatically imports users that were added inside Domo and not within the portal (syncs every 15min).">Auto import DOMO users</H5>
          <div className="ml-2">
            <Toggle
              checked={autoImportDomoUser}
              onChange={(e) => {
                setAutoImportDomoUser(e);
                handleUpdate({ auto_import_domo_user: e });
              }}
            />
          </div>
        </div>
      </div>
    </TextAccordion>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorspaceImportUser);
