import {
  GET_PAGE_DATA,
  GET_PAGE_CREDENTIAL_OPTIONS,
  CLEAR_WORKSPACE_DETAILS,
  // REMOVE_PAGE
} from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const setPageData = (body) => (dispatch) => {
  dispatch({ type: GET_PAGE_DATA, payload: body });
};

export const managePageData = (body) => async (dispatch) => {
  try {
    let method;
    let url;
    if (body?.workspaceId) {
      if (body?.pageId) {
        method = `PUT`;
        url = `/workspaces/:workspace_id/pages/:page_id`;
      } else {
        method = `POST`;
        url = `/workspaces/:workspace_id/pages`;
      }
    } else {
      if (body?.pageId) {
        method = `PUT`;
        url = `/pages/:page_id/manage`;
      } else {
        method = `POST`;
        url = `/pages/manage`;
      }
    }
    const params = { workspace_id: body?.workspaceId, page_id: body?.pageId };
    const { data: resData } = await apiRequest(method, url, { body, params });

    if (resData.status === 200) {
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPages = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const { data } = await apiRequest("POST", `/pages/list`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_PAGE_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const getWorkspacePages = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const params = { workspace_id: body.workspace_id };
      const { data } = await apiRequest("POST", `/workspaces/:workspace_id/pages/list`, { body, params }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_PAGE_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const removePage = (body) => {
  return async (dispatch) => {
    try {
      let method = "DELETE";
      let url = `/pages/:page_id`;
      if (body?.workspaceId) {
        method = `DELETE`;
        url = `/workspaces/:workspace_id/pages/:page_id`;
      }
      const params = { workspace_id: body.workspaceId, page_id: body?.deleteId };
      const { data } = await apiRequest(method, url, { params });

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const getPageCredentialOptions = (params, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("GET", `/pages/integration`, null, { signal });

      if (resData.status === 200) {
        dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: resData.data });
        return resData.data;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const filterPageCredentialOptions = (data, signal = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: data });
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const managePageCredentialOptions = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("POST", `/pages/credentials/manage`, { body }, { signal });

      if (resData.status === 200) {
        dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: resData.data });
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const managePageIntegrationOptions = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("POST", "/pages/integration/manage", { body }, { signal });

      if (resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const deletePageCredentialOptions = (body, signal = null) => {
  return async (dispatch) => {
    try {
      let params = { workspace_id: body.workspace_id, credential_id: body.credential_id };
      await apiRequest("DELETE", "/workspaces/:workspace_id/pages/credentials/:credential_id", { params }, { signal });
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const setWorkspaceDetails = (body) => (dispatch) => {
  try {
    dispatch({ type: CLEAR_WORKSPACE_DETAILS, payload: body });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resetWorkspaceDetails = (body) => (dispatch) => {
  try {
    dispatch({ type: CLEAR_WORKSPACE_DETAILS, payload: {} });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPreviousPageDetails = async (body) => {
  try {
    let url = `/previous/page/details`;
    if (body?.workspace_id) {
      url = `/workspaces/:workspace_id/previous/page/details`;
    }
    const params = { workspace_id: body?.workspace_id };
    const { data } = await apiRequest("POST", url, { body, params });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateDomoPage = async (body) => {
  try {
    const params = { workspace_id: body.workspace_id };
    const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/pages/user-group-assign`, { body, params });

    if (data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
