import PlanDetails from "src/components/Workspaces/Workspace/BillingAndPayments/PlanDetails";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Transactions from "./tabComponents/transactions/Transactions";
import { ClipboardDocumentCheckIcon, CreditCardIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { apiRequest } from "src/async/apiUtils";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PaymentMethod from "./tabComponents/PaymentMethod";
import BillingContacts from "./tabComponents/BillingContacts";

const WorkspaceBillingAndPayments = ({ workspaceDetails, site }) => {
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const loadPlanDetails = async () => {
    if (workspaceDetails?._id) {
      try {
        setLoading(true);
        const { data } = await apiRequest("get", `workspaces/${workspaceDetails?._id}/billing/plan`);
        setPlanDetails(data?.data);
      } catch (err) {
        // console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadPlanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails]);

  const tabs = [
    { name: "Payment History", icon: ClipboardDocumentCheckIcon, component: <Transactions workspaceId={workspaceDetails?._id} /> },
    { name: "Payment Method", icon: CreditCardIcon, component: <PaymentMethod workspaceId={workspaceDetails?._id} /> },
    { name: "Billing Contacts", icon: UserGroupIcon, component: <BillingContacts workspaceId={workspaceDetails?._id} /> },
  ];

  return (
    <div className="w-full gap-3">
      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="">
            <div className="w-full">
              <PlanDetails
                planDetails={planDetails?.active_plan}
                loadPlanDetails={loadPlanDetails}
                stripeCredentialChanged={planDetails?.stripe_credential_changed}
              />
            </div>
          </div>
          {planDetails?.active_plan && (
            <div className=" mt-5 w-full border-t pt-5">
              <Tabs
                altDesign={true}
                changeParams={false}
                tabs={tabs}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceBillingAndPayments);
