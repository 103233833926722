import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { CheckBadgeIcon, LinkIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { workspaceIntegrationCredentialCreate, workspaceIntegrationCredentialManage, workspaceIntegrationCredentialVerify } from "src/actions/workspace";
import { toast } from "react-toastify";
import { replacePrivateStringWithStars } from "src/helpers/replacePrivateStringWithStars";
import { apiRequest } from "src/async/apiUtils";
import InputWrapper from "src/components/Shared/Forms/InputWrapper";
import Button from "src/components/Shared/Buttons/Button";
import { v4 } from "uuid";

const FieldItem = ({ field = null, editItem = null, getValue = () => {}, getPlaceholder = () => {}, onChange = () => {}, onRemove = () => {}, onCredentialVerify = () => {}, loadingFields = [], loading = false, errorText = "", hide = false }) => {
  return (
    !hide && (
      <div className="relative">
        {!field?.required ? (
          <InputWrapper
            inline={true}
            type={field?.type}
            name={field?.name}
            label={field?.label}
            placeholder={getPlaceholder(field?.name)}
            autoComplete={`new-password`}
            value={getValue(field?.name)}
            onChange={onChange}
            onRemove={() => onRemove(field?.name)}
            onKeyUp={() => onCredentialVerify(field?.name)}
            loading={loading && loadingFields?.includes(field?.name)}
            errorType={loadingFields?.includes(field?.name) && errorText ? "danger" : ""}
            errorText={errorText}
          />
        ) : (
          <Input
            inline={true}
            type={field?.type}
            name={field?.name}
            label={field?.label}
            placeholder={getPlaceholder(field?.name)}
            autoComplete={`new-password`}
            value={getValue(field?.name)}
            onChange={onChange}
            onRemove={() => onRemove(field?.name)}
            onKeyUp={() => onCredentialVerify(field?.name)}
            loading={loading && loadingFields?.includes(field?.name)}
            errorType={loadingFields?.includes(field?.name) && errorText ? "danger" : ""}
            errorText={errorText}
          />
        )}
      </div>
    )
  );
};

const VerificationValues = ({ title, value }) => {
  return (
    <div>
      <div className="inline-flex rounded-full border border-gray-400 px-[7px] pb-[1px] text-xs text-gray-400">{title}</div>
      <div className="-mt-[1px] pl-1">{value}</div>
    </div>
  );
};

const CredentialVerifiedContent = ({ providerCode = null, embedType = null, credentialVerifiedData = null, resetCredentialVerify = () => {} }) => {
  return (
    <div className="relative mb-4 w-full flex-grow">
      <div className="flex justify-end">
        <Button
          onClick={resetCredentialVerify}
          version="default"
          className="!h-auto px-2 py-1 text-sm text-gray-300 underline transition-all hover:text-gray-400">
          Edit
        </Button>
      </div>
      <div className="relative rounded-md border border-green-600 bg-white px-3 pb-2">
        <div className="absolute -top-8 left-[2px] flex items-center gap-x-2">
          <div className="flex-shrink-0">
            <CheckBadgeIcon className="h-7 w-7 text-green-600" />
          </div>
          <div className="flex-shrink-0 text-lg font-bold text-green-600">Verified Credentials</div>
        </div>
        <div className="relative grid grid-cols-1 gap-2 pb-1 pt-2 sm:grid-cols-2">
          {credentialVerifiedData?.domain && (
            <VerificationValues
              title="Domain"
              value={credentialVerifiedData?.domain}
            />
          )}
          {credentialVerifiedData?.role && (
            <VerificationValues
              title="Role"
              value={credentialVerifiedData?.role}
            />
          )}
          {credentialVerifiedData?.scope && (
            <VerificationValues
              title="Scope"
              value={credentialVerifiedData?.scope?.split(" ").join(", ")}
            />
          )}
          {credentialVerifiedData?.account && (
            <VerificationValues
              title="Account"
              value={credentialVerifiedData?.account}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const WorkspacesIntegrationModal = ({
  isOpen,
  workspaceId = null,
  is_global = false,
  workspaceAdd = false,
  providerDetails = null,
  dataProviderList = [],
  embedType = null,
  editItem = null,
  integrationList = [],
  onCancel = () => {},
  onSuccess = () => {},
  setIntegrationList = () => {},
  setIsPreloading = () => {},
  workspaceDetails,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);
  const [embedAttributes, setEmbedAttributes] = useState([]);
  const [loadingFields, setLoadingFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [credentialVerifiedData, setCredentialVerifiedData] = useState(null);

  const clientId = embedAttributes.find((embedAttribute) => embedAttribute.key === "client_id");
  const clientSecret = embedAttributes.find((embedAttribute) => embedAttribute.key === "client_secret");

  const manageIntegration = async () => {
    try {
      if (verifyCondition()) {
        onCredentialVerify("client_id");
      } else {
        setDisabled(true);
        if (editItem?._id) {
          await props.workspaceIntegrationCredentialManage({ integration_id: providerDetails._id, workspace_id: workspaceId, credential_id: editItem?._id, embed_attributes: embedAttributes });
        } else {
          const data = await props.workspaceIntegrationCredentialCreate({
            integration_id: providerDetails._id,
            workspace_id: workspaceAdd ? null : workspaceId,
            embed_attributes: embedAttributes,
            integration_type: embedType?.type,
            block_save: props?.blockSave && workspaceAdd ? true : false,
          });

          if (integrationList?.length === 0) setIsPreloading(true);

          setIntegrationList((pre) => {
            if (workspaceAdd) {
              const isProviderExists = pre?.find((item) => item?.code === providerDetails?.code);
              if (isProviderExists) {
                return pre?.map((item) => {
                  if (item?.code === providerDetails?.code) {
                    return {
                      ...item,
                      integrations: [
                        ...(item?.integrations || []),
                        {
                          _id: v4(),
                          integration_type: embedType?.type,
                          embed_attributes: embedAttributes,
                          user: {
                            _id: props?.user?._id,
                            name: props?.user?.name,
                          },
                        },
                      ],
                    };
                  } else {
                    return item;
                  }
                });
              } else {
                return [
                  ...pre,
                  {
                    ...providerDetails,
                    integrations: [
                      ...(pre?.integrations || []),
                      {
                        _id: v4(),
                        integration_type: embedType?.type,
                        embed_attributes: embedAttributes,
                        user: {
                          _id: props?.user?._id,
                          name: props?.user?.name,
                        },
                      },
                    ],
                  },
                ];
              }
            } else {
              return data;
            }
          });
        }

        if (workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
          try {
            apiRequest("post", `/workspaces/${workspaceId}/domo-data-count`, { body: {} });
          } catch (err) {}
        }

        onSuccess();
        setTimeout(() => setDisabled(false), 300);
      }
    } catch (error) {
      toast.error(error.message);
      setTimeout(() => setDisabled(false), 300);
    }
  };

  const onCredentialVerify = async (name, onFirstTime = false) => {
    try {
      if (providerDetails?.code === "domo") {
        if (name === "client_id" || name === "client_secret") {
          setErrorText("");
          if ((clientId?.value && clientSecret?.value) || (editItem?._id && (editItem?.workspace_id || editItem?.integration_type === "IFRAME_EMBED"))) {
            setLoadingFields(["client_id", "client_secret"]);
            setLoading(true);
            const data = await props.workspaceIntegrationCredentialVerify({
              integration_id: providerDetails._id,
              workspace_id: workspaceAdd ? null : workspaceId,
              embed_attributes: onFirstTime ? [] : embedAttributes,
              integration_type: embedType?.type,
              credential_id: editItem?._id,
            });
            setCredentialVerifiedData(data);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        setLoading(false);
        setErrorText(error.message);
      }
    }
  };

  const resetCredentialVerify = () => {
    setLoadingFields([]);
    setLoading(false);
    setErrorText("");
    setCredentialVerifiedData({});
  };

  const onRemove = (name) => {
    setEmbedAttributes((preData) => [
      ...preData.map((data) => {
        if (data.key === name) {
          return {
            ...data,
            value: "",
            placeholder: "",
            isHide: true,
          };
        } else {
          return data;
        }
      }),
    ]);
  };

  const onChange = (event) => {
    setErrorText("");
    setEmbedAttributes((preData) => [
      ...preData.map((data) => {
        if (data.key === event.target.name) {
          return {
            ...data,
            value: event.target.value,
            isHide: false,
          };
        } else {
          return data;
        }
      }),
    ]);
  };

  const getValue = (name) => {
    return embedAttributes.find((embedAttribute) => embedAttribute.key === name)?.value;
  };

  const getPlaceholder = (name) => {
    return embedAttributes.find((embedAttribute) => embedAttribute.key === name)?.placeholder;
  };

  useEffect(() => {
    if (embedType?.embed_fields && embedType?.embed_fields?.length > 0) {
      let embedAttributesField = [];
      for (const field of embedType.embed_fields) {
        if (editItem) {
          if (editItem?.workspace_id) {
            if (field?.used_for === "both" || field?.used_for === "workspace") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.type === "text" ? editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value : "",
                placeholder: field?.type === "password" ? replacePrivateStringWithStars(editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value) : "",
              });
            }
          } else {
            if (field?.used_for === "both" || field?.used_for === "global") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.type === "text" ? editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value : "",
                placeholder: field?.type === "password" ? replacePrivateStringWithStars(editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value) : "",
              });
            }
          }
        } else {
          if (workspaceId) {
            if (field?.used_for === "both" || field?.used_for === "workspace") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.default_value || "",
                placeholder: "",
              });
            }
          } else {
            if (field?.used_for === "both" || field?.used_for === "global") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.default_value || "",
                placeholder: "",
              });
            }
          }
        }
      }
      setEmbedAttributes(embedAttributesField);
      setLoadingFields([]);
      setLoading(false);
      setErrorText("");
      setCredentialVerifiedData(null);
      if (editItem?._id) onCredentialVerify("client_id", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedType?.embed_fields, workspaceId, editItem]);

  let verifyCondition = () => {
    if (providerDetails?.code !== "domo") {
      return false;
    } else if (!clientId || !clientSecret) {
      return false;
    } else if (!editItem && providerDetails?.code === "domo" && !credentialVerifiedData?.verified) {
      return true;
    } else if (editItem && !credentialVerifiedData?.verified && !!editItem?.workspace_id && !!(clientId?.value || clientSecret?.value)) {
      return true;
    } else if (editItem && !credentialVerifiedData?.verified && (clientId?.value || clientSecret?.value)) {
      return true;
    } else if (editItem && editItem?.integration_type !== "IFRAME_EMBED") {
      return false;
    } else {
      return false;
    }
  };

  return (
    <Modal
      title="Credential"
      secondaryTitle={editItem?._id ? `Edit` : `Add`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={manageIntegration}
      defaultOptions={{
        onSuccessButtonText: loading ? "Verify" : verifyCondition() ? "Verify" : "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
        onSuccessButtonDisabled: loading || (verifyCondition() && !clientId?.value && !clientSecret?.value),
      }}>
      <div className="relative w-full space-y-2">
        {embedType?.type !== "IFRAME_EMBED" && (
          <div className="relative mb-4 flex w-full">
            <div className="relative rounded-md border border-gray-200/50 bg-white px-4 py-3 shadow-md">
              <div className="flex gap-3">
                <div className="relative z-0 h-10 w-10 flex-shrink-0">
                  <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
                    <ShieldCheckIcon className="h-2.5 w-2.5 text-green-500" />
                  </div>
                  <div className="h-full w-full overflow-hidden rounded object-contain">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: providerDetails?.image }}
                    />
                  </div>
                </div>
                <div className="w-full flex-shrink space-y-1">
                  <div className="text-base font-semibold leading-none text-gray-800">{providerDetails?.modal_title}</div>
                  <div className="text-sm text-gray-500">
                    Obtain keys at
                    <a
                      href={providerDetails?.link}
                      target="_blank"
                      rel="noreferrer"
                      className="mx-1 inline-flex items-center gap-1 text-sm text-gray-500">
                      <span className="underline">{providerDetails?.link_title}</span>
                      <span>
                        <LinkIcon className="h-4 w-4" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {embedType?.embed_fields &&
          embedType?.embed_fields?.length > 0 &&
          embedType?.embed_fields?.map((field) => (
            <Fragment key={field?._id}>
              {!(providerDetails?.code === "domo" && field?.name === "client_id" && credentialVerifiedData?.verified) &&
                embedType?.type === "IFRAME_EMBED" &&
                ((providerDetails?.code === "domo" && field?.name === "client_id") || (providerDetails?.code === "metabase" && field?.name === "subscriber_instance_url")) && (
                  <div className="relative mb-4 flex w-full">
                    <div className="relative rounded-md border border-gray-200/50 bg-white px-4 py-3 shadow-md">
                      <div className="flex gap-3">
                        <div className="relative z-0 h-10 w-10 flex-shrink-0">
                          <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
                            <ShieldCheckIcon className="h-2.5 w-2.5 text-green-500" />
                          </div>
                          <div className="h-full w-full overflow-hidden rounded object-contain">
                            <div dangerouslySetInnerHTML={{ __html: providerDetails?.image }} />
                          </div>
                        </div>
                        <div className="w-full flex-shrink space-y-1">
                          <div className="text-base font-semibold leading-none text-gray-800">{providerDetails?.modal_title}</div>
                          <div className="text-sm text-gray-500">
                            Obtain keys at
                            <a
                              href={providerDetails?.link}
                              target="_blank"
                              rel="noreferrer"
                              className="mx-1 inline-flex items-center gap-1 text-sm text-gray-500">
                              <span className="underline">{providerDetails?.link_title}</span>
                              <span>
                                <LinkIcon className="h-4 w-4" />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {providerDetails?.code === "domo" && field?.name === "client_id" && credentialVerifiedData?.verified && (
                <CredentialVerifiedContent
                  providerCode={providerDetails?.code}
                  embedType={embedType?.type}
                  credentialVerifiedData={credentialVerifiedData}
                  resetCredentialVerify={resetCredentialVerify}
                />
              )}
              {editItem
                ? editItem?.workspace_id
                  ? (field?.used_for === "both" || field?.used_for === "workspace") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                        loadingFields={loadingFields}
                        loading={loading}
                        errorText={errorText}
                        hide={providerDetails?.code === "domo" && loadingFields?.includes(field?.name) && credentialVerifiedData?.verified}
                      />
                    )
                  : (field?.used_for === "both" || field?.used_for === "global") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                        loadingFields={loadingFields}
                        loading={loading}
                        errorText={errorText}
                        hide={providerDetails?.code === "domo" && loadingFields?.includes(field?.name) && credentialVerifiedData?.verified}
                      />
                    )
                : workspaceId
                  ? (field?.used_for === "both" || field?.used_for === "workspace") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                        loadingFields={loadingFields}
                        loading={loading}
                        errorText={errorText}
                        hide={providerDetails?.code === "domo" && loadingFields?.includes(field?.name) && credentialVerifiedData?.verified}
                      />
                    )
                  : (field?.used_for === "both" || field?.used_for === "global") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                        loadingFields={loadingFields}
                        loading={loading}
                        errorText={errorText}
                        hide={providerDetails?.code === "domo" && loadingFields?.includes(field?.name) && credentialVerifiedData?.verified}
                      />
                    )}
            </Fragment>
          ))}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialCreate, workspaceIntegrationCredentialManage, workspaceIntegrationCredentialVerify })(WorkspacesIntegrationModal);
