import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageWorkspaceSettings } from "src/actions/workspace";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";

const WorkspaceWhitelabel = ({ onClear = () => {}, ...props }) => {
  const [useGlobalSettings, setUseGlobalSettings] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleWhiteLabelUpdate = async () => {
    try {
      setButtonDisabled(true);
      const message = await props.manageWorkspaceSettings(
        {
          workspace_id: props?.workspaceDetails?._id,
          use_global_settings: useGlobalSettings,
        },
        "/workspaces/:workspace_id/layouts/white-label",
      );
      setButtonDisabled(false);
      toast.success(message);
    } catch (error) {
      setButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setUseGlobalSettings(props?.workspaceDetails?.use_global_settings);
  }, [props?.workspaceDetails?.use_global_settings]);

  return (
    <EditContainer
      title="White Label"
      defaultOptions={{
        onSuccessLoaderVisible: buttonDisabled,
      }}
      preview={{
        style: "success",
        isSuccess: !useGlobalSettings,
        text: useGlobalSettings ? (
          <>
            <NoSymbolIcon className="h-5 w-5" /> Disabled
          </>
        ) : (
          <>
            <CheckIcon className="h-5 w-5" /> Enabled
          </>
        ),
      }}
      onSuccess={handleWhiteLabelUpdate}
      onCancel={() => setUseGlobalSettings(props?.workspaceDetails?.use_global_settings)}>
      <div className="min-w-[260px]">
        <ToggleHeader
          title="Allow white labeling"
          subtitle="Workspace styles will override defaults."
          position="left">
          <Toggle
            checked={!useGlobalSettings}
            onChange={() => {
              setUseGlobalSettings(!useGlobalSettings);
            }}
          />
        </ToggleHeader>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceWhitelabel);
