import React from "react";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";

const HtmlPage = ({ newPage = {}, setNewPage = () => {} }) => {
  return (
    <TextArea
      label="Custom HTML"
      inline={true}
      styles={{ height: "300px" }}
      value={newPage.custom_html}
      onChange={(e) => setNewPage((pre) => ({ ...pre, custom_html: e.target.value }))}
      placeholder="<iframe> ... </iframe>"
      textType="code"
    />
  );
};

export default HtmlPage;
