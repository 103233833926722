import { ArrowPathIcon, Bars3Icon, CheckCircleIcon, ChevronDownIcon, Cog6ToothIcon, PlusIcon, WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import BubbleIconList from "src/components/Shared/BubbleIconList";
import QuickMenu from "src/components/Shared/QuickMenu";
import WorkspaceGroupAuthorizeUserComponent from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupAuthorizeUserComponent";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useDebounce from "src/hooks/useDebounce";
import { isSVGString } from "src/helpers";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";
import { v4 } from "uuid";

const DomoWorkspaceGroupItem = ({
  viewOnly = false,
  defaultGroup = {},
  pageOptions = [],
  operatorOptions = [],
  workspaceId,
  workspaceDetails,
  trustedAttributeArr,
  workspaceUsers = [],
  importable = false,
  setDeleteGroup = () => {},
  setCloneGroup = () => {},
  setAddIconGroup = () => {},
  refresh = () => {},
  setIsAddUserModal = () => {},
  setCurrentGroupId = () => {},
  setCurrentGroupUserId = () => {},
  userModalOpen = () => {},
  draggableProps,
  dragHandleProps,
  innerRef,
  ...props
}) => {
  // const { id } = useParams();

  const [hideDetails, setHideDetails] = useState(true);
  const [items, setItems] = useState([]);
  const [group, setGroup] = useState(defaultGroup);
  const [groupChanges, setGroupChanges] = useState(0);

  // const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  // const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  const pageJson = {
    _id: null,
    page_id: null,
    filters: [],
    is_draft: true,
  };

  const savePermissionChanges = async () => {
    try {
      const { data } = await apiRequest(
        "put",
        `/workspaces/:workspace_id/groups/:group_id`,
        {
          body: {
            ...group,
            permissions: group.permissions,
          },
          params: { workspace_id: workspaceId, group_id: group._id },
        },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      setGroup({
        ...group,
        permissions: group.permissions.map((permission, index) => {
          let newData = data.data.find((dt, i) => i === index);
          return {
            ...permission,
            _id: newData?._id,
          };
        }),
      });
      setGroupChanges(0);
    } catch (error) {}
  };

  const onClear = () => {
    setGroup(defaultGroup);
    setGroupChanges(0);
  };

  useEffect(() => {
    setGroup({
      ...defaultGroup,
      permissions: defaultGroup?.permissions?.map((permission) => {
        return {
          ...permission,
          displayNameShow: permission?.page_alias ? true : false,
          displayNameChanges: true,
          filters: permission.filters.map((filter) => {
            return {
              ...filter,
            };
          }),
        };
      }),
    });
  }, [defaultGroup]);

  useEffect(() => {
    const _id = group._id ? group._id : group.id;
    if (props?.updatedGroupData?._id && props?.updatedGroupData?._id.toString() === _id.toString()) {
      setGroup({
        ...group,
        ...props?.updatedGroupData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.updatedGroupData]);

  useEffect(() => {
    let defaultItems = [];
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      defaultItems.push({
        name: "Add page",
        onClick: () => {
          setHideDetails(false);
          setGroup({ ...group, permissions: [...group.permissions, pageJson] });
        },
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      defaultItems.push({
        name: `${group?.image ? "Edit" : "Add"} icon`,
        onClick: () => setAddIconGroup(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"], true)) {
      defaultItems.push({
        name: "Manage users",
        onClick: () => userModalOpen(true, group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      defaultItems.push({
        type: "hr",
      });
      defaultItems.push({
        name: "Clone group",
        onClick: () => setCloneGroup(group),
      });
    }
    if (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      if (defaultItems.length > 0)
        defaultItems.push({
          type: "hr",
        });

      defaultItems.push({
        name: "Delete group",
        onClick: () => setDeleteGroup(group),
        color: "text-red-500",
      });
    }

    setItems(defaultItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me, group, workspaceDetails?.workspace_type]);

  const [name, setName] = useState("");

  useEffect(() => {
    if (group) {
      setName(group.name || "");
    }
  }, [group]);

  const loadingColors = {
    IDLE: "group-focus:text-transparent",
    WAITING: "group-focus:text-gray-700/50",
    DONE: "group-focus:text-green-700/50",
  };

  const handleGroupUpdate = async (name = " ") => {
    try {
      await apiRequest("put", `/workspaces/:workspace_id/groups/:group_id`, { body: { name }, params: { workspace_id: workspaceId, group_id: group._id ? group._id : group.id } });
    } catch (error) {}
  };

  const [debouncedNameUpdate, debounceNameStatus] = useDebounce(handleGroupUpdate, 500);

  const handleChange = async (e) => {
    setGroup({
      ...group,
      name: e.target.value,
    });
    debouncedNameUpdate(e.target.value);
  };

  const assignDisplayName = async (permission) => {
    try {
      const { data } = await apiRequest(
        "put",
        `/workspaces/:workspace_id/groups/:group_id/display-name`,
        {
          body: {
            permission: permission,
          },
          params: { workspace_id: workspaceId, group_id: group._id },
        },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      setGroup((prevData) => ({
        ...prevData,
        permissions: prevData.permissions.map((p, index) => {
          if (index === permission?.ordering) {
            return {
              ...p,
              _id: data?.data?._id,
              displayNameChanges: true,
            };
          } else {
            return p;
          }
        }),
      }));
    } catch (error) {}
  };

  return (
    <>
      <div
        className="relative max-w-full rounded-md border border-gray-200 bg-white shadow"
        ref={innerRef}
        {...draggableProps}>
        {authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && !viewOnly && workspaceDetails?.workspace_type === "IFRAME_EMBED" && (
          <Button
            dragHandleProps={dragHandleProps}
            type="button"
            version="default"
            className="absolute -left-[32px] top-[30px] flex h-16 w-8 min-w-[32px] -translate-y-1/2 cursor-move items-center justify-center rounded-lg rounded-r-none border border-gray-200 bg-gray-100 text-gray-700 outline-none ring-0">
            <Bars3Icon className="h-5 w-5 stroke-2" />
          </Button>
        )}
        <div className="flex items-center justify-between gap-10 px-6 py-4">
          <div className="relative flex w-full items-center gap-x-2">
            {workspaceDetails?.workspace_type === "IFRAME_EMBED" ? (
              <Button
                version="default"
                className="group relative flex items-center">
                <div className={classNames("py-.5 absolute -right-7 -top-1 flex items-center gap-x-2 overflow-hidden p-1 px-1 font-light transition-all duration-75", loadingColors[debounceNameStatus])}>
                  {debounceNameStatus === "WAITING" && (
                    <p className="flex items-center rounded-full bg-gray-500/20">
                      <ArrowPathIcon className="h-5 w-5 animate-spin text-white" />
                    </p>
                  )}
                  {debounceNameStatus === "DONE" && (
                    <p className="flex items-center rounded-full bg-green-600/40 text-white">
                      <CheckCircleIcon className="h-5 w-5 text-white" />
                    </p>
                  )}
                </div>
                {group.image && (
                  <div
                    className="mr-2 h-10 w-10 overflow-hidden p-1 py-2"
                    onClick={() => setAddIconGroup(group)}>
                    {isSVGString(group.image) ? (
                      <div dangerouslySetInnerHTML={{ __html: group.image }} />
                    ) : (
                      <Image
                        image={group.image}
                        failImage={noFavicon}
                        alt={"Groups"}
                        onError={() => {
                          setGroup({
                            ...group,
                            image: null,
                          });
                        }}
                      />
                    )}
                  </div>
                )}
                {authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && !viewOnly ? (
                  <Input
                    type="text"
                    autoComplete="on"
                    name="workspace-name"
                    value={name}
                    onChange={handleChange}
                    inputClassNames="w-full pr-4 focus:ring-0 focus:border-highlightColor bg-transparent hover:border-gray-400/20 border border-transparent flex items-center gap-x-2 text-base px-2 py-1 rounded transition-all duration-75 text-gray-800"
                  />
                ) : (
                  <p className="truncate font-semibold text-gray-700">{group.name}</p>
                )}
              </Button>
            ) : (
              <p className="truncate font-semibold text-gray-700">{group.name}</p>
            )}
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-end gap-x-3 pr-4">
              <div className="hidden items-center justify-end sm:flex">
                {group.users?.length > 0 && (
                  <div
                    onClick={() => {
                      userModalOpen(true, group);
                    }}
                    className="relative flex cursor-pointer gap-x-3 hover:underline">
                    {group.users?.length > 0 && <BubbleIconList data={group.users.map((user) => ({ ...user, _id: v4() }))} />}
                  </div>
                )}
                {group.permissions?.length > 0 && (
                  <>
                    {group.users?.length > 0 && <div className="ml-4 mr-2 hidden h-[4px] w-[4px] rounded-full bg-gray-400 md:flex" />}
                    <div className="hidden w-10 md:flex">
                      <div
                        className="relative hidden cursor-pointer items-center gap-x-1 rounded border-transparent px-2 py-2 text-sm text-gray-400 hover:bg-gray-50 hover:text-gray-500 hover:shadow-sm sm:flex"
                        onClick={() => setHideDetails(!hideDetails)}>
                        <WindowIcon className="h-6 w-6" />
                        <p className={classNames("absolute bottom-0 flex h-5 min-w-5 items-center justify-center rounded-full border-[1px] border-gray-100 bg-white p-1", group.permissions?.length > 10 ? "-right-2" : "right-0")}>{group.permissions?.length} </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && !viewOnly && (
                <QuickMenu
                  icon={
                    <div className="flex h-full items-center justify-center rounded-md px-2 py-2 text-gray-400 hover:bg-gray-50 hover:text-gray-500">
                      <Cog6ToothIcon className="h-6 w-6" />
                    </div>
                  }
                  position="RIGHT"
                  items={items}
                />
              )}
            </div>
            {props?.workspaceDetailsData?.workspace_type === "IFRAME_EMBED" && (
              <div className="ml-auto flex items-center gap-5">
                <Button
                  onClick={() => setHideDetails(!hideDetails)}
                  version="secondary"
                  className="!border-0 !px-1 !py-1 !text-gray-300 !shadow-none !outline-none !ring-0 !ring-transparent">
                  <ChevronDownIcon
                    className={classNames("h-5 w-5 transition-all duration-150 ", hideDetails ? "" : "rotate-180")}
                    aria-hidden="true"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className={classNames("relative", !hideDetails ? "max-h-[200000px] border-t border-gray-200 px-3 py-3 sm:px-6 sm:py-4" : "max-h-[0px] overflow-hidden")}>
          <WorkspaceGroupAuthorizeUserComponent
            group={group}
            permissions={group?.permissions || []}
            setGroup={setGroup}
            groupChanges={groupChanges}
            setGroupChanges={setGroupChanges}
            me={props.me}
            pageOptions={pageOptions}
            operatorOptions={operatorOptions}
            viewOnly={viewOnly}
            trustedAttributeArr={trustedAttributeArr}
            workspaceDetails={workspaceDetails}
            workspaceId={workspaceId}
            addPermission={(e, index) => {
              setGroup((preData) => ({
                ...preData,
                permissions: preData.permissions.map((p, i) => {
                  if (i === index) {
                    return e;
                  } else {
                    return p;
                  }
                }),
              }));
            }}
            removePermission={(e, index) => {
              setGroupChanges(groupChanges + 1);
              setGroup({
                ...group,
                permissions: group.permissions.filter((p, i) => i !== index),
              });
            }}
            authorizeUserComponentAccessPermission={authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"])}
            assignDisplayName={assignDisplayName}
          />
          <div className="flex w-full max-w-9xl justify-end pt-4">
            <Button
              version="gray"
              onClick={() => setGroup({ ...group, permissions: [...group.permissions, pageJson] })}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              Add page
            </Button>
          </div>
        </div>
        {!viewOnly && !hideDetails && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && (
          <div className={classNames("mt-20 flex w-full justify-end gap-x-4 border-t-[1px] border-gray-200 p-4 pt-4 transition-all duration-150 sm:mt-4", groupChanges > 0 ? "bg-gray-50" : "")}>
            <Button
              version="gray"
              onClick={onClear}
              disabled={groupChanges < 1}>
              Cancel
            </Button>
            <Button
              onClick={savePermissionChanges}
              disabled={groupChanges < 1}>
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(DomoWorkspaceGroupItem);
