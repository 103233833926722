export const authorizeUserComponentAccess = (user = {}, workspaceId = "", permissionCode = "", permissionTypes = [], show = false) => {
  // Check if user object is provided
  if (user) {
    // Grant full access if user is an admin
    if (user.type === "admin") {
      return true;
    } else if (user.type === "editor") {
      // Ensure permissionCode is provided and user has a permissions object
      if (permissionCode && user.userPermissions) {
        // If specific permission types are provided and workspaceId is given
        if (permissionTypes?.length && workspaceId) {
          // Filter out missing permissions for the user in the given workspace
          const missingPermissions = permissionTypes.filter((permissionType) => {
            if (!user.userPermissions[workspaceId] || !user.userPermissions[workspaceId][`${permissionCode}_${permissionType}`]) {
              return true;
            }
            return false;
          });
          // Return true if no missing permissions, otherwise false
          return !missingPermissions.length;
        }
        // If specific permission types are not provided or workspaceId is missing
        // Check if user has create, update, or delete permissions for the given code in the workspace
        if (permissionCode === "group") {
          return user.userPermissions[workspaceId] && (user.userPermissions[workspaceId][`${permissionCode}_content_page_update`] || user.userPermissions[workspaceId][`${permissionCode}_update`] || user.userPermissions[workspaceId][`${permissionCode}_user_update`]);
        } else if (permissionCode === "permission") {
          return user.userPermissions[workspaceId] && (user.userPermissions[workspaceId][`${permissionCode}_editor_update`] || user.userPermissions[workspaceId][`${permissionCode}_update`] || user.userPermissions[workspaceId][`${permissionCode}_editor_create`]);
        } else if (permissionCode === "workspace") {
          return user.userPermissions[workspaceId] && (user.userPermissions[workspaceId][`${permissionCode}_filter_update`] || user.userPermissions[workspaceId][`${permissionCode}_update`] || user.userPermissions[workspaceId][`${permissionCode}_delete`]);
        } else {
          return user.userPermissions[workspaceId] && user.userPermissions[workspaceId][`${permissionCode}_update`];
        }
      }
      // If permissionCode is not provided or user lacks a permissions object
      return false;
    }
    // If user type is neither admin nor editor, deny access
    else {
      return false;
    }
  }
  // If no user object is provided, the function implicitly returns undefined
};
