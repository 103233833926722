import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, loginTracking, otpVerify } from "src/actions/auth";
import { ssoLoginData } from "src/actions/sso";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import { useLocation } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { apiUrl } from "src/config/host";
import AuthDataLayout from "src/layouts/AuthDataLayout";
import { apiRequest } from "src/async/apiUtils";
import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const Login = ({ seoTitle, ...props }) => {
  const { pathname } = useLocation();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [attemp, setAttemp] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [disabledResendOtp, setDisabledResendOtp] = useState(false);
  const [otpSecond, setOtpSecond] = useState(0);
  const [resendOtpSecond, setResendOtpSecond] = useState(0);

  const navigate = useNavigate();

  const {
    response: { data: ssos },
    status: { done: ssosLoaded },
  } = useFetch("/sso/default-list");

  useEffect(() => {
    if (attemp) {
      let currentTime = new Date();
      let lastTime = new Date(attemp?.updated_at);
      let seconds = (currentTime.getTime() - lastTime.getTime()) / 1000;
      if (seconds > 0 && seconds < 61) {
        let startDate = new Date(attemp?.updated_at);
        startDate.setSeconds(startDate.getSeconds() - (60 - seconds));
        calculateTimeLeft(startDate, lastTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemp]);

  useEffect(() => {
    if (attemp && startDate) {
      let startDate2 = new Date(startDate);
      startDate2.setSeconds(startDate2.getSeconds() + 1);
      let endDate = new Date(attemp?.updated_at);
      const timer = setTimeout(() => {
        calculateTimeLeft(startDate2, endDate);
      }, 1000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const calculateTimeLeft = (startDate, endDate) => {
    if (attemp?.attemp_count < 11) return;

    let difference = +new Date(endDate) - +new Date(startDate);
    let timeLeft = {};
    if (difference > 0) {
      setIsDisabled(true);
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60) > 9 ? Math.floor((difference / 1000 / 60) % 60) : "0" + Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60) > 9 ? Math.floor((difference / 1000) % 60) : "0" + Math.floor((difference / 1000) % 60),
      };
      setTimeLeft(timeLeft);
      setStartDate(startDate);
    } else {
      setTimeLeft("");
      setStartDate(null);
      setIsDisabled(false);
    }
  };

  const loginUser = async (e) => {
    try {
      e.preventDefault();
      if (isDisabled || otpSecond > 0) {
        return;
      }
      setError("");
      setIsDisabled(true);

      const res = await props.login({
        email,
        password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token: localStorage.getItem("access_token"),
      });
      if (res?.requireTwoFactor) {
        setResendOtpSecond(res?.two_factor_otp_resend_interval_timeframe);
      } else if (res?.data?.seconds && res?.data?.seconds > 0) {
        setOtpSecond(res?.data?.seconds);
        toast.error(res?.message);
      } else {
        setError("");
        toast.success(res?.message);
      }
      setIsDisabled(false);
      return res?.message;
    } catch (error) {
      toast.error(error.message);
      // setError(error.message);
      try {
        const attemp = await props.loginTracking();
        setAttemp(attemp);
      } catch (error) {
        // console.dir("ERROR:", error);
      } finally {
        setIsDisabled(false);
      }
    }
  };

  const otpVerify = async (e) => {
    try {
      e.preventDefault();
      if (isSubmitDisabled || otpSecond > 0) {
        return;
      }
      setError("");
      setIsSubmitDisabled(true);

      const res = await props.otpVerify({
        otp,
        email: props?.twoFactorDetails?.email,
        password: props?.twoFactorDetails?.password,
      });
      if (res?.status === 429) {
        setOtpSecond(res?.data.seconds);
        toast.error(res?.message);
      } else {
        setError("");
        toast.success(res?.message);
      }
      setIsSubmitDisabled(false);
      return res?.message;
    } catch (error) {
      setIsSubmitDisabled(false);
      toast.error(error.message);
    }
  };

  let access_token = new URLSearchParams(window.location.search).get("access_token");
  let refresh_token = new URLSearchParams(window.location.search).get("refresh_token");
  let user_id = new URLSearchParams(window.location.search).get("user_id");

  useEffect(() => {
    if (access_token && refresh_token && user_id) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("userId", user_id);
      // Remove user inactive time because login makes user active
      localStorage.removeItem("userLastActiveTime");
      // navigate("/users"); NAVIGATE
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loginTrack = async () => {
      try {
        const attemp = await props.loginTracking();
        setAttemp(attemp);
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };

    loginTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ssos?.length === 0) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssos]);

  useEffect(() => {
    if (otpSecond > 0) {
      const timer = setInterval(() => {
        setOtpSecond((prev) => {
          if (prev === 1) {
            clearInterval(timer); // Clear interval when countdown ends
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [otpSecond]);

  useEffect(() => {
    if (resendOtpSecond > 0) {
      const timer = setInterval(() => {
        setResendOtpSecond((prev) => {
          if (prev === 1) {
            clearInterval(timer); // Clear interval when countdown ends
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [resendOtpSecond]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const resendOtp = async () => {
    try {
      setDisabledResendOtp(true);
      const { data: resData } = await apiRequest("post", "/resend/otp", { body: { email: props?.twoFactorDetails?.email, password: props?.twoFactorDetails?.password } });
      setResendOtpSecond(resData?.data?.seconds);
      toast.success(resData.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisabledResendOtp(false);
    }
  };

  return (
    <>
      {ssosLoaded ? (
        <AuthDataLayout
          header={
            <AuthHeader
              seoTitle={seoTitle}
              title="Administrator Login"
              site={props.site}
              switchTo={{
                startText: "Or switch to",
                text: "User Portal",
                location: props?.site?.site_domain || "/",
              }}
            />
          }>
          <AuthContainer site={props.site}>
            <>
              {(pathname === "/" && ssosLoaded) || pathname === "/login" ? (
                <>
                  {props?.twoFactorDetails?.two_factor_enabled ? (
                    <>
                      <div>
                        <p className="mb-1 flex items-center text-sm text-gray-400">
                          <span>
                            <EnvelopeOpenIcon className="mb-[1px] mr-2 h-4 w-4 stroke-2" />
                          </span>
                          Check the inbox of <span className="px-1 font-bold">{props?.twoFactorDetails?.email}</span> for a validation code.
                        </p>
                        <p className="mb-4 flex items-center text-sm text-gray-400">
                          <Button
                            type="button"
                            version="default"
                            className="!h-auto !bg-transparent !px-1 !py-0 !opacity-100"
                            onClick={resendOtp}
                            disabled={disabledResendOtp || resendOtpSecond > 0}>
                            <span className={classNames("text-sm text-gray-400 underline", disabledResendOtp || resendOtpSecond > 0 ? "opacity-50" : "")}>Resend</span>
                            {resendOtpSecond > 0 && <span className="ml-1">{`(${formatTime(resendOtpSecond)})`}</span>}
                          </Button>
                          verification email
                        </p>
                      </div>
                      <form
                        className="flex w-full flex-col gap-y-3"
                        autoComplete="on">
                        {/* OTP */}
                        <div className="w-full">
                          <Input
                            inline={true}
                            type="number"
                            autoComplete="on"
                            name="contact-otp"
                            label="OTP"
                            error={error === "OTP field is required"}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                otpVerify(e);
                              }
                            }}
                          />
                        </div>
                      </form>
                      <div className="mt-4">
                        <Button
                          width="w-full"
                          type="button"
                          disabled={isSubmitDisabled || otpSecond > 0}
                          onClick={otpVerify}>
                          {otpSecond > 0 ? `You've been blocked for ${formatTime(otpSecond)}` : "Submit"}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <form
                        className="flex w-full flex-col gap-y-2"
                        autoComplete="on">
                        {/* Email */}
                        <div className="w-full">
                          <Input
                            inline={true}
                            type="email"
                            autoComplete="on"
                            name="contact-email"
                            label="Email"
                            error={error === "Email field is required"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                loginUser(e);
                              }
                            }}
                          />
                        </div>

                        {/* Password */}
                        <div className="w-full min-w-[200px]">
                          <Input
                            inline={true}
                            autoComplete="on"
                            type="password"
                            name="password"
                            label="Password"
                            error={error === "Password field is required."}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                loginUser(e);
                              }
                            }}
                          />
                        </div>
                      </form>
                      <div className=" flex w-full justify-end">
                        <Link
                          to="/forgot-password/submit-email"
                          className="my-2 text-sm text-slate-800">
                          Forgot Password?
                        </Link>
                      </div>
                      {attemp && attemp.attemp_count > 10 && timeLeft ? (
                        <div className="mt-4">
                          <Button
                            width="w-full"
                            type="button"
                            disabled={isDisabled}>
                            Your login has been blocked for {timeLeft?.minutes}:{timeLeft?.seconds}
                          </Button>
                        </div>
                      ) : (
                        <div className="mt-2">
                          <Button
                            className="h-9 !py-0 !font-normal "
                            width="w-full"
                            type="button"
                            disabled={isDisabled || otpSecond > 0}
                            onClick={loginUser}>
                            {otpSecond > 0 ? `You've been blocked for ${formatTime(otpSecond)}` : "Login"}
                          </Button>
                        </div>
                      )}
                      {ssos?.length > 0 && (
                        <>
                          <div className="my-5 flex items-center justify-between">
                            <div className="h-[1px] w-1/4 bg-gray-300"></div>
                            <span className="text-xs text-gray-500 sm:text-sm">Or continue with SSO</span>
                            <div className="h-[1px] w-1/4 bg-gray-300"></div>
                          </div>
                          {ssos?.map((item) => (
                            <div
                              key={item?._id}
                              className="mt-4">
                              <Button
                                version="gray"
                                className="border-[1px] text-white"
                                width="w-full"
                                style={{
                                  borderColor: props.site?.sso_button_text_color,
                                  color: props.site?.sso_button_text_color || "#fff",
                                  backgroundColor: props.site?.sso_button_color,
                                }}
                                onClick={() => (window.location.href = `${apiUrl}/login-sso`)}>
                                {props.site.sso_button_text ? props.site.sso_button_text : item.provider_name}
                              </Button>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                !(pathname === "/" || ssos?.length) && !ssosLoaded && <Preloader />
              )}
            </>
          </AuthContainer>
        </AuthDataLayout>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    sso: state.sso,
    twoFactorDetails: state.twoFactorDetails,
  };
};

export default connect(mapStateToProps, { login, loginTracking, ssoLoginData, otpVerify })(Login);
