import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Image from "src/components/Shared/Image";
import { noLogo, noWorkspaceImage } from "src/config/host";
import { classNames } from "src/helpers/classNames";

const General = ({ setHeaderLogo, setSquareLogo, name, setName, squareLogo, headerLogo }) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

  const onImageChange = (e, type) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        type === "header" ? setHeaderLogo(e.target.files[0]) : setSquareLogo(e.target.files[0]);
      }
    }
  };

  const ImageHandler = ({ image = "", label = "", title = "", subTitle = "" }) => {
    let imageSource = useMemo(() => {
      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
      }

      return objUrl;
    }, [image]);

    const imageComponent = useCallback(() => {
      return (
        <div className={`group relative flex flex-col items-start`}>
          {imageSource ? (
            <div className={classNames("h-24 overflow-hidden rounded-lg bg-gray-300/20", label ? "w-48" : "w-24")}>
              <img
                src={imageSource}
                alt="Logo"
                className="h-full w-full object-contain"
                onError={(e) => (e.target.src = noLogo)}
              />
            </div>
          ) : (
            <div className="flex h-24 items-center rounded-lg border border-gray-200/60 bg-gray-300/20">
              <div className={`flex h-full w-full items-center justify-center `}>
                <div className={`overflow-hidden ${label ? "h-20 w-20" : "h-24"}`}>
                  <Image
                    image={noWorkspaceImage}
                    className="h-full w-full object-cover"
                    alt={"Workspace"}
                  />
                </div>
                {label && <div className="max-w-[7rem] text-xl font-semibold text-[#838383]">{label}</div>}
              </div>
            </div>
          )}
          <div className="my-3 ">
            <div className="text-sm text-[#374151]">{title}</div>
            <div className="mt-1 max-w-[300px] flex-wrap text-xs text-gray-400">{subTitle}</div>
          </div>
          <div className="flex-shrink-0">
            <Button
              version="gray"
              className="relative overflow-hidden">
              <CloudArrowUpIcon className="h-6 w-6" />
              <span className="text-sm text-gray-600">Upload image</span>
              <input
                type="file"
                className="absolute right-0 top-0 z-10 h-full w-96 cursor-pointer opacity-0"
                onChange={(e) => onImageChange(e, label ? "header" : "square")}
              />
            </Button>
          </div>
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource]);

    return imageComponent();
  };

  const SquareLogo = useMemo(
    () => (
      <ImageHandler
        image={squareLogo}
        title="Square Logo (1:1)"
        subTitle="Used for preview tiles, the favicon, and dropdowns"
      />
    ),
    [squareLogo],
  );

  const FullLogo = useMemo(
    () => (
      <ImageHandler
        image={headerLogo}
        label="Company Name"
        title="Full Logo (3:4)"
        subTitle="This will be used for the top navigation and the main login page. The best images are typically wider than they are tall."
      />
    ),
    [headerLogo],
  );

  return (
    <div className="relative mt-4 flex w-full flex-col space-y-8">
      <EditContainer
        title="Enter workspace name"
        subtitle="Give a name to identify this agency, group, or company"
        preview={{
          text: name ? name : "Missing: A workspace name is required to submit",
        }}
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}
        fullWidth={true}
        defaultOpen={true}>
        <div className="relative w-full max-w-[600px]">
          <Input
            name="name"
            label="Workspace name"
            inline={true}
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </EditContainer>
      <EditContainer
        title="Workspace Logos"
        subtitle="Give a name to identify this agency, group, or company"
        preview={{
          text: name ? name : "Missing: A workspace name is required to submit",
        }}
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}
        fullWidth={true}
        defaultOpen={true}>
        <div className="flex space-x-20">
          {FullLogo}
          {SquareLogo}
        </div>
      </EditContainer>
    </div>
  );
};

export default General;
